import { useEffect } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import SubmitModalIcon from '../../../assets/submit-modal.png';
import DuplicateModalIcon from '../../../assets/duplicate.png';
import DeleteBinModalIcon from '../../../assets/deletebin-modal.png';
import ApproveModalIcon from '../../../assets/approve-modal.png';
import RejectModalIcon from '../../../assets/reject-modal.png';
import SendbackModalIcon from '../../../assets/sendback-modal.png';
const ModalDialogSmall = ({
  isOpen,
  handleModalCancel,
  handleModalSubmit,
  modalDialogType,
  promoId,
  isError,
  setReason,
  reason,
  setError,
  createdByUser,
  count = 0,
}: any) => {
  useEffect(() => {
    if (isOpen) {
      if (setReason) setReason('');
      if (setError) setError(false);
    }
  }, [isOpen]);

  const getModalIcon = () => {
    if (modalDialogType == 'Approve') {
      return ApproveModalIcon;
    } else if (modalDialogType == 'Reject') {
      return RejectModalIcon;
    } else if (modalDialogType == 'Sendback') {
      return SendbackModalIcon;
    } else if (modalDialogType == 'Delete') {
      return DeleteBinModalIcon;
    } else if (modalDialogType == 'Submit') {
      return SubmitModalIcon;
    } else if (modalDialogType == 'Duplicate') {
      return DuplicateModalIcon;
    } else if (modalDialogType == 'BulkApprove') {
      return ApproveModalIcon;
    } else if (modalDialogType == 'BulkReject') {
      return RejectModalIcon;
    } else if (modalDialogType == 'BulkDelete') {
      return DeleteBinModalIcon;
    } else {
      return SubmitModalIcon;
    }
  };

  return (
    <Modal
      centered={true}
      open={isOpen}
      title={
        <div className="flex flex-row items-center">
          <img src={getModalIcon()} width={40} />
          <span style={{ fontSize: 16, marginLeft: 10 }}>
            {['BulkApprove', 'BulkReject', 'BulkDelete'].includes(
              modalDialogType,
            )
              ? `${
                  modalDialogType == 'BulkApprove'
                    ? 'Approve All'
                    : modalDialogType == 'BulkReject'
                    ? 'Reject All'
                    : 'Delete All'
                } Promotions`
              : `${modalDialogType} Promotion`}
          </span>
        </div>
      }
      className="ut-modal delete-modal"
      onCancel={handleModalCancel}
      footer={[
        <Button className="ut-btn me-4" key="back" onClick={handleModalCancel}>
          Cancel
        </Button>,
        <Button
          className="ut-btn normal-text"
          key="submit"
          type="primary"
          onClick={handleModalSubmit}
        >
          <span>Confirm</span>
        </Button>,
      ]}
    >
      {modalDialogType === 'Approve' && (
        <>
          <p className="ms-1 mb-5">
            Confirm approval of promotion
            <span className="highlight-promo-id"> {promoId} </span>
            by {createdByUser?.firstName + ' ' + createdByUser?.lastName}?
          </p>
        </>
      )}
      {modalDialogType === 'Reject' && (
        <>
          <p className="ms-1 mb-5">
            Confirm rejection of promotion
            <span className="highlight-promo-id"> {promoId} </span>
            submitted by{' '}
            {createdByUser?.firstName + ' ' + createdByUser?.lastName}?
          </p>
        </>
      )}
      {modalDialogType === 'BulkApprove' && (
        <>
          {count == 1 ? (
            <p className="ms-1 mb-5">Confirm approval of {count} promotion?</p>
          ) : (
            <p className="ms-1 mb-5">Confirm approval of {count} promotions?</p>
          )}
        </>
      )}
      {modalDialogType === 'BulkReject' && (
        <>
          {count == 1 ? (
            <p className="ms-1 mb-5">Confirm rejection of {count} promotion?</p>
          ) : (
            <p className="ms-1 mb-5">
              Confirm rejection of {count} promotions?
            </p>
          )}
        </>
      )}
      {modalDialogType === 'Sendback' && (
        <>
          <p className="ms-1 mb-5">
            Confirm sending back of promotion
            <span className="highlight-promo-id"> {promoId} </span>
            submitted by{' '}
            {createdByUser?.firstName + ' ' + createdByUser?.lastName}?
          </p>
        </>
      )}
      {modalDialogType === 'Delete' && (
        <>
          <p className="ms-1 mb-5">
            Confirm deletion of promotion
            <span className="highlight-promo-id"> {promoId}</span>?
          </p>
        </>
      )}
      {modalDialogType === 'BulkDelete' && (
        <>
          {count == 1 ? (
            <p className="ms-1 mb-5">Confirm deletion of {count} promotion?</p>
          ) : (
            <p className="ms-1 mb-5">Confirm deletion of {count} promotions?</p>
          )}
        </>
      )}
      {modalDialogType === 'Submit' && (
        <>
          <p className="ms-1 mb-5">
            Confirm submission of promotion
            <span className="highlight-promo-id"> {promoId}</span>?
          </p>
        </>
      )}
      {modalDialogType === 'Duplicate' && (
        <>
          <p className="ms-1 mb-5">
            Confirm duplication of promotion
            <span className="highlight-promo-id"> {promoId}</span>?
          </p>
        </>
      )}
      {(modalDialogType === 'BulkApprove' ||
        modalDialogType === 'BulkReject' ||
        modalDialogType === 'Approve' ||
        modalDialogType === 'Reject' ||
        modalDialogType === 'Sendback') && (
        <>
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
          >
            <label className="ms-1">
              <span
                style={{
                  color: '#494949',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                }}
              >
                Comments{' '}
              </span>
              <span style={{ color: '#D36500' }}>*</span>
            </label>
            <Input.TextArea
              rows={4}
              value={reason}
              onChange={(e) => {
                if (setReason) setReason(e.target.value);
              }}
              className="mt-1"
            />
          </Form.Item>
          {isError && reason === '' && (
            <p
              style={{
                color: 'red',
                fontSize: 12,
                position: 'relative',
                top: -15,
                textAlign: 'left',
              }}
            >
              Field is required
            </p>
          )}
        </>
      )}
    </Modal>
  );
};
export default ModalDialogSmall;
