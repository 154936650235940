import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IFilterDuration,
  IHeaderFilterState,
  IHeaderPromoDuration,
  IHeaderState,
  INotificationsResponse,
} from './type';
import * as headerSource from './service';
import { ICalendarResponse } from 'app/modules/Promotion/ducks/types';
import { AppDispatch } from 'store';
import { loadingActions } from 'app/molecules/Loader/ducks/slice';
import { toastMessageActions } from 'app/molecules/ToastMessage/ducks/slice';
import { getPromotionDurationApi } from 'app/modules/Promotion/ducks/services';

export const sliceName = 'appHeader';

export const initialState: IHeaderState = {
  isFetchingDuration: false,
  filter: {
    isFilterModalOpen: false,
    duration: {} as IFilterDuration,
  } as IHeaderFilterState,
  loading: false,
  notifications: [],
  recentSearch: [],
  searchResult: [],
  error: '',
  isHierarchy: false,
  isListing: false,
  isCalculations: false,
};

const appHeaderSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    getNotificationInProgress(state) {
      state.loading = true;
    },
    getNotificationSuccess(
      state,
      { payload }: PayloadAction<INotificationsResponse>,
    ) {
      state.loading = false;
      state.notifications = payload.data;
    },
    getNotificationFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    setHeaderFilter(state, { payload }: PayloadAction<[string, any]>) {
      if (state.filter) {
        const [key, value] = payload;
        if (key === 'level') {
          state.filter.productLevelId = value;
        } else if (key === 'promotionTypeId') {
          state.filter.promotionTypeId = value;
        } else if (key === 'promoId') {
          state.filter.promoId = value;
        }
      }
    },
    getPromoDurationInProgress(
      state,
      { payload }: PayloadAction<IHeaderPromoDuration>,
    ) {
      state.isFetchingDuration = true;
      if (state.filter?.duration) {
        state.filter.duration.startDate = payload.startDate;
        state.filter.duration.endDate = payload.endDate;
      }
    },
    getPromoDurationSuccess(
      state,
      { payload }: PayloadAction<ICalendarResponse>,
    ) {
      state.isFetchingDuration = false;
      if (!Array.isArray(payload.data) && state.filter?.duration) {
        state.filter.duration!.weekStart = payload.data.weekStart;
        state.filter.duration!.weekEnd = payload.data.weekEnd;
      }
    },
    getPromoDurationFailed(state, { payload }) {
      state.isFetchingDuration = false;
      state.error = payload.error;
    },

    recentSearchInProgress(state) {
      state.loading = true;
    },
    recentSearchSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.recentSearch = payload.data;
    },
    recentSearchFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    searchResultInProgress(state) {
      state.loading = true;
    },
    searchResultSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.searchResult = payload.data;
    },
    searchResultFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    setHeaderFilterModal(state, { payload }: PayloadAction<boolean>) {
      if (state.filter) {
        state.filter.isFilterModalOpen = payload;
      }
    },
    showHierarchy(state) {
      state.isHierarchy = true;
    },
    hideHierarchy(state) {
      state.isHierarchy = false;
    },
    showIsListing(state) {
      state.isListing = true;
    },
    hideIsListing(state) {
      state.isListing = false;
    },
    showIsCalculations(state) {
      state.isCalculations = true;
    },
    hideIsCalculations(state) {
      state.isCalculations = false;
    },
  },
});

export const { actions: headerActions, reducer } = appHeaderSlice;

export const getHeaderFilterPromoDuration =
  (payload: IHeaderPromoDuration) => async (dispatch: AppDispatch) => {
    dispatch(headerActions.getPromoDurationInProgress(payload));
    try {
      const data = await getPromotionDurationApi(payload, true);
      dispatch(headerActions.getPromoDurationSuccess(data));
    } catch (error) {
      // handle error
      dispatch(headerActions.getPromoDurationFailed({ error }));
    }
  };

export const getNotifications = () => async (dispatch: AppDispatch) => {
  // dispatch(loadingActions.showLoader());
  dispatch(headerActions.getNotificationInProgress());
  try {
    const data = await headerSource.getUserNotificationsApi();
    // dispatch(loadingActions.hideLoader());
    dispatch(headerActions.getNotificationSuccess(data));
  } catch (error) {
    // dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(headerActions.getNotificationFailed({ error }));
  }
};

export const resetNotificationsCount = () => async (dispatch: AppDispatch) => {
  try {
    const data = await headerSource.resetNotificationsCountApi();
  } catch (error) {
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
  }
};

export const getRecentSearch = () => async (dispatch: AppDispatch) => {
  dispatch(headerActions.recentSearchInProgress());
  try {
    const data = await headerSource.recentSearchApi();
    dispatch(headerActions.recentSearchSuccess(data));
  } catch (error) {
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(headerActions.recentSearchFailed({ error }));
  }
};

export const getSearchResult =
  (param: string | number) => async (dispatch: AppDispatch) => {
    dispatch(headerActions.searchResultInProgress());
    try {
      const data = await headerSource.searchResultApi(param);
      dispatch(headerActions.searchResultSuccess(data));
    } catch (error) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(headerActions.searchResultFailed({ error }));
    }
  };

export const clearSearchAndGetRecent = () => async (dispatch: AppDispatch) => {
  dispatch(headerActions.recentSearchInProgress());
  try {
    const data = await headerSource.recentSearchApi();
    dispatch(headerActions.recentSearchSuccess(data));
    dispatch(headerActions.searchResultSuccess({ data: [] }));
  } catch (error) {
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(headerActions.recentSearchFailed({ error }));
  }
};
