import { Col, Progress, Row } from 'antd';
import ColumnExample from './column';
import { IReportPromotion, PromoRowDetailShowState } from './ducks/type';
import { useAppSelector } from 'store/hooks';
import { calculateBudget, formatNumber } from 'utils/helper';
import { AgChartsReact } from 'ag-charts-react';
import {
  ACTUAL_COLOR,
  PLANNED_COLOR,
  getLineConf,
} from './utils/configuration';

interface IProps {
  detail: IReportPromotion;
  visibility: PromoRowDetailShowState;
}
export default function RowPromotionDetail({ detail, visibility }: IProps) {
  const { reportingView } = useAppSelector((state: any) => state);
  const { viewByOption } = reportingView;

  // const exceeded =
  //   viewByOption == 'totalCost'
  //     ? detail.totalCostActual < detail.totalCost
  //     : detail.totalVolumeActual < detail.totalVolume;

  if (visibility === 'isEmpty' || visibility === 'isHidden') {
    return null;
  }

  let obj = calculateBudget(
    detail.totalCostActual,
    detail.totalCost,
    detail.totalVolumeActual,
    detail.totalVolume,
  );

  return (
    <Row
      className={
        'chart-row chart-row-detail' +
        (visibility === 'isShown' ? '' : ' non-visible')
      }
      align={'middle'}
    >
      <Col span={3} className="one">
        <div className="ut-fs-xs ut-color-eleven ut-mb-xs">
          {detail.promoId}
        </div>
        <div className="ut-fs-xs ut-color-six ut-mb-xs">
          {detail.promotionName}
        </div>
        <div className="progress-container">
          <span
            className={
              'ut-fs-xs mr-2 ' +
              (!obj.exceeded ? 'ut-color-thirteen' : 'ut-color-fourteen')
            }
          >
            {obj.totalActualBudget}
          </span>
          <Progress
            showInfo={false}
            percent={obj.percentage}
            strokeColor={[!obj.exceeded ? 'green' : 'red']}
            size="small"
          />
        </div>
      </Col>
      <Col span={21}>
        <Row>
          <div style={{ height: '80px', width: '100%' }}>
            <AgChartsReact
              options={getLineConf(
                detail.monthly,
                {
                  color: ACTUAL_COLOR,
                  xKey: 'month',
                  yKey: viewByOption + 'Actual',
                },
                {
                  color: PLANNED_COLOR,
                  xKey: 'month',
                  yKey: viewByOption,
                },
              )}
            />
          </div>
          {/* {detail.monthly.map((month: any) => (
            <Col span={2} key={month.month}>
              <ColumnExample data={[month]} />
            </Col>
          ))} */}
        </Row>
      </Col>
    </Row>
  );
}
