import React from 'react';
// import { useMixpanel } from 'react-mixpanel-browser';
import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Space, Dropdown, Menu, Tag, Avatar } from 'antd';
import type { MenuProps } from 'antd';
import { EllipsisOutlined, UserOutlined } from '@ant-design/icons';
import { IListView, IListViewPromotionState } from './ducks/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getListView,
  getListViewAll,
  setBulkRecords,
  setDownloadRecords,
  setIsModalOpenBulkDuplicate,
} from './ducks/slice';
import {
  duplicateEvent,
  deleteEvent,
} from 'app/modules/CalendarView/ducks/slice';
import ModalDialogSmall from 'app/atoms/ModalDialogSmall';
import { PAGE_SIZE_LIST_VIEW, capitalizeFirstLetter, clean } from './helper';
import DotsIcon from '../../../assets/dots-action.png';
import UpIcon from '../../../assets/up.png';
import DownIcon from '../../../assets/down.png';
import { createExcelFromJsonObject } from 'utils/dataSheet';

import {
  getPromotion,
  setIsNewTemplateModalVisible,
  setIsTempPromo,
  setNewTemplate,
} from '../Promotion/ducks/slice';
import { formatNumber } from 'utils/helper';

const ListView = (): ReactElement => {
  // const mixpanel = useMixpanel();
  const history = useHistory();
  const dispatch: any = useAppDispatch();
  const {
    listView,
    auth: { user },
    promotions: { singleObj },
  } = useAppSelector((state: any) => state);

  const {
    promotions: { newTemplate },
  } = useAppSelector((state: any) => state);

  const listViewData = listView?.listViewList;
  const listViewTotalCount = listView?.totalCount;
  const recordsToBeDownloaded = listView?.recordsToBeDownloaded;
  const listViewAllForBulkDuplicates = listView?.listViewAllForBulkDuplicates;

  const { isTradeManager, isFinanceManager } = useAppSelector(
    (state) => state.auth,
  );

  const [selectedRowsArray, setSelectedRowsArray] = useState<any>([]);
  const [isModalDialogOpen, setIsModalDialogOpen] = useState(false);
  const [modalDialogType, setModalDialogType] = useState('');
  const [itemInFocus, setItemInFocus] = useState<IListView | any>(null);
  const [pageNo, setPageNo] = useState(PAGE_SIZE_LIST_VIEW);
  const refreshList = () => {
    dispatch(
      getListView({
        pageNumber: 1,
        pageSize: PAGE_SIZE_LIST_VIEW,
      }),
    );
  };

  useEffect(() => {
    refreshList();
  }, []);

  useEffect(() => {
    if (
      recordsToBeDownloaded.length == 0 ||
      listViewAllForBulkDuplicates.length == 0
    ) {
      setSelectedRowsArray([]);
    }
  }, [recordsToBeDownloaded, listViewAllForBulkDuplicates]);

  const showModal = () => {
    setIsModalDialogOpen(true);
  };
  const handleModalDialogEv = (type: string) => {
    setModalDialogType(type);
    showModal();
  };
  function formatNumberWithCommas(numberString: string) {
    const number = parseFloat(numberString);
    if (isNaN(number)) {
      // Handle invalid input (e.g., non-numeric strings)
      return 'Invalid input';
    }
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  function formatNumberWithCommasNoDecimal(numberString: string) {
    const number = parseFloat(numberString);
    if (isNaN(number)) {
      // Handle invalid input (e.g., non-numeric strings)
      return 'Invalid input';
    }
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
  const handleModalDialogClose = () => {
    setIsModalDialogOpen(false);
  };
  const handleDuplicateEvent = async () => {
    await dispatch(duplicateEvent(itemInFocus.promo_id));
    handleModalDialogClose();
    refreshList();
  };
  const handleDeleteEvent = async () => {
    await dispatch(deleteEvent(itemInFocus.promo_id));
    handleModalDialogClose();
    refreshList();
  };

  const handleModalDialogSubmit = () => {
    switch (modalDialogType) {
      case 'Duplicate':
        handleDuplicateEvent();
        break;
      case 'Delete':
        handleDeleteEvent();
        break;
      default:
        break;
    }
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    // mixpanel?.track('Change in page number');
    // mixpanel?.identify(user.email);
    setPageNo(pagination.pageSize);
    dispatch(
      getListView({
        pageNumber: pagination.current,
        pageSize: pagination.pageSize,
      }),
    );
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {};

  const handleDownloadPromoDetailExcel = async (promoId: any) => {
    await dispatch(getPromotion(promoId, true));
  };
  const handleSingleDuplicate = (item: any) => {
    dispatch(setBulkRecords([item]));
    dispatch(setIsModalOpenBulkDuplicate(true));
  };

  var items: MenuProps['items'] = [
    {
      label: <span style={{ fontSize: 12 }}>View</span>,
      key: '0',
      onClick: () => {
        history.push(`/promotion/view/${itemInFocus.promo_id}`);
      },
    },
    {
      label: <span style={{ fontSize: 12 }}>Save as template</span>,
      key: '8',
      disabled: !isTradeManager,
      onClick: () => {
        dispatch(setIsTempPromo(false));
        dispatch(
          setNewTemplate({
            promoId: itemInFocus?.promo_id,
          }),
        );
        dispatch(setIsNewTemplateModalVisible(true));
        // mixpanel?.track('Save as template click', {
        //   metadata: itemInFocus,
        // });
        // mixpanel?.identify(user.email);
      },
    },
    {
      label: <span style={{ fontSize: 12 }}>Download</span>,
      key: '6',
      onClick: () => {
        handleDownloadPromoDetailExcel(itemInFocus.promo_id);
        // mixpanel?.track('Download promotion detail');
        // mixpanel?.identify(user.email);
      },
    },
    {
      label: <span style={{ fontSize: 12 }}>Duplicate</span>,
      key: '2',
      onClick: () => {
        handleSingleDuplicate(itemInFocus);
      },
      disabled: !isTradeManager,
    },
    {
      label: <span style={{ fontSize: 12 }}>Edit</span>,
      key: '1',
      onClick: () => {
        history.push(`/promotion/edit/${itemInFocus?.promo_id}`);
      },
      disabled:
        (itemInFocus?.status !== 'draft' &&
          itemInFocus?.status !== 'sentback') ||
        !isTradeManager,
    },

    {
      label: <span style={{ fontSize: 12 }}>Delete</span>,
      key: '3',
      onClick: () => {
        if (itemInFocus.status !== 'draft') {
          return;
        }
        handleModalDialogEv('Delete');
      },
      disabled: itemInFocus?.status !== 'draft',
    },
    {
      label: <span style={{ fontSize: 12 }}>Share</span>,
      key: '4',
      disabled: true,
    },
    {
      label: <span style={{ fontSize: 12 }}>Print</span>,
      key: '5',
      disabled: true,
    },
    {
      label: <span style={{ fontSize: 12 }}>Copy</span>,
      key: '7',
      disabled: true,
    },
  ];

  //remove all disabled
  if (isFinanceManager || true) {
    items = items.filter((elem: any) => !elem.disabled);
  }

  const getValues = (item: any) => {
    let result;
    if (item > 0) {
      result = `${item} %`;
    } else if (item < 0) {
      result = `${item.toString().split('-')[1]} %`;
    } else if (item === 0 || item === null) {
      result = 'N/A';
    }
    return result;
  };

  const columns: any = [
    {
      title: '',
      key: '',
      width: 10,
      dataIndex: 'action',
      className: 'list-action-column',
      render: (text: any, record: any) => (
        <Space size="large">
          <Dropdown menu={{ items }} trigger={['click']}>
            <a
              onClick={(e) => {
                e.preventDefault();
                setItemInFocus(record);
              }}
            >
              <span
                className="icon"
                style={{ paddingLeft: '5px', paddingRight: '0px' }}
              >
                <img src={DotsIcon} />
              </span>
            </a>
          </Dropdown>
        </Space>
      ),
    },
    Table.SELECTION_COLUMN,
    {
      // title: '',
      key: 'check',
      width: 10,
      dataIndex: '',
      className: 'list-check-column',
    },
    {
      title: 'Promotion ID',
      dataIndex: 'promotion_code',
      key: 'promotion_code',
      width: 180,
      className: 'promo-id',
      onCell: (record: IListView) => ({
        onClick: () => {
          history.push(`/promotion/edit/${record.promo_id}`);
        },
      }),
    },
    {
      title: 'Promotion Name',
      dataIndex: 'promotion_name',
      key: 'promotion_name',
      width: 180,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.status.toLowerCase() == 'Draft'.toLowerCase() && (
            <Tag bordered={false} color="purple" style={{ fontSize: 12 }}>
              {capitalizeFirstLetter(record.status)}
            </Tag>
          )}
          {record.status.toLowerCase() == 'Submitted'.toLowerCase() && (
            <Tag bordered={false} color="yellow" style={{ fontSize: 12 }}>
              {capitalizeFirstLetter(record.status)}
            </Tag>
          )}
          {record.status.toLowerCase() == 'Approved'.toLowerCase() && (
            <Tag bordered={false} color="green" style={{ fontSize: 12 }}>
              {capitalizeFirstLetter(record.status)}
            </Tag>
          )}
          {record.status.toLowerCase() == 'Rejected'.toLowerCase() && (
            <Tag bordered={false} color="red" style={{ fontSize: 12 }}>
              {capitalizeFirstLetter(record.status)}
            </Tag>
          )}
          {record.status.toLowerCase() == 'sentback'.toLowerCase() && (
            <Tag bordered={false} color="orange" style={{ fontSize: 12 }}>
              {capitalizeFirstLetter(record.status)}
            </Tag>
          )}
        </Space>
      ),
    },
    // {
    //   title: 'Created at',
    //   dataIndex: 'created_at', // uncomment this, to include the column in the display
    //   key: 'key',
    // },
    // {
    //   title: 'Modified at',
    //   dataIndex: 'modified_at', // uncomment this, to include the column in the display
    //   key: 'key',
    // },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      width: 180,
      render: (text: any, record: any) => (
        <div
          className=" cursor-pointer"
          key={`${record.id}-${record.firstName}`}
        >
          {/* <Avatar
            style={{
              backgroundColor: '#e6ecf4',
              color: '#164291',
              marginRight: 6,
            }}
          >
            {text.length > 1
              ? text.slice(0, 1).toUpperCase()
              : text.toUpperCase()}
          </Avatar> */}
          {text}
        </div>
      ),
    },
    // {
    //   title: 'Role',
    //   dataIndex: 'role', // uncomment this, to include the column in the display
    //   key: 'key',
    // },
    // {
    //   title: 'Assigned Categories',
    //   dataIndex: 'assigned_categories', // uncomment this, to include the column in the display
    //   key: 'key',
    //   render: (text: any, record: any) => (
    //     <Space size="middle">
    //       {text.map((elem: any) => {
    //         return (
    //           <Avatar style={{ backgroundColor: '#e6ecf4', color: '#164291' }}>
    //             {elem}
    //           </Avatar>
    //         );
    //       })}
    //     </Space>
    //   ),
    // },
    {
      title: 'Planned Start',
      dataIndex: 'planned_start',
      key: 'planned_start',
      width: 190,
    },
    // {
    //   title: 'Planned End',
    //   dataIndex: 'planned_end', // uncomment this, to include the column in the display
    //   key: 'key',
    // },
    {
      title: 'Type',
      dataIndex: 'tactic_type',
      key: 'tactic_type',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Avatar style={{ backgroundColor: '#e6ecf4', color: '#164291' }}>
            {text}
          </Avatar>
        </Space>
      ),
    },
    // {
    //   title: 'Promotion level',
    //   dataIndex: 'promotion_level', // uncomment this, to include the column in the display
    //   key: 'key',
    //   render: (text: any, record: any) => (
    //     <Space size="middle">
    //       <Tag bordered={false} color="blue">
    //         {text}
    //       </Tag>
    //     </Space>
    //   ),
    // },
    // {
    //   title: 'Assigned brands',
    //   dataIndex: 'assigned_brands', // uncomment this, to include the column in the display
    //   key: 'key',
    //   render: (text: any, record: any) => (
    //     <Space size="middle">
    //       {text.map((elem: any) => {
    //         return (
    //           <Avatar style={{ backgroundColor: '#e6ecf4', color: '#164291' }}>
    //             {elem}
    //           </Avatar>
    //         );
    //       })}
    //     </Space>
    //   ),
    // },
    // {
    //   title: 'Volume (Tons)',
    //   dataIndex: 'volume_tons', // uncomment this, to include the column in the display
    //   key: 'key',
    // },
    {
      title: 'Spending (PKR)',
      dataIndex: 'spending_pkr',
      key: 'spending_pkr',
      width: 170,
      align: 'right',
      render: (text: any, record: any) => (
        <Space size="middle">{text ? formatNumber(text) : 'N/A'}</Space>
      ),
    },
    {
      title: 'Profit (PKR)',
      dataIndex: 'profit_pkr',
      key: 'profit_pkr',
      width: 150,
      align: 'right',
      render: (text: any, record: any) => (
        <Space size="middle">
          {text ? formatNumberWithCommas(text) : 'N/A'}
        </Space>
      ),
    },
    {
      title: 'ROI%',
      dataIndex: 'roi_percentage',
      key: 'roi_percentage',
      width: 50,
      align: 'right',
      //className: 'roi',
      render: (text: any, record: any) => (
        <Space style={{ color: text && (text > 0 ? 'green' : 'red') }}>
          {text && text > 0 ? (
            <img src={UpIcon} />
          ) : (
            text !== 0 && text !== null && <img src={DownIcon} />
          )}
          {getValues(text)}
          {/* {(!text || text === 0) && 'N/A'}
          {!text && text !== 0 && text > 0
            ? `${text} %`
            : `${text.toString().split('-')[1]} %`} */}
        </Space>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IListView[]) => {
      setSelectedRowsArray(selectedRowKeys);
      dispatch(setDownloadRecords(selectedRows));
      dispatch(setBulkRecords(selectedRows));

      if (selectedRows.length == 0) {
        // dispatch(getListViewAll());
      }
    },
    selectedRowKeys: selectedRowsArray,
  };
  return (
    <div>
      <Table
        size="small"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={listViewData}
        pagination={{ pageSize: pageNo, total: listViewTotalCount }}
        onChange={handleChange}
        className="promo-list-table"
      />
      <ModalDialogSmall
        isOpen={isModalDialogOpen}
        handleModalCancel={handleModalDialogClose}
        handleModalSubmit={handleModalDialogSubmit}
        modalDialogType={modalDialogType}
        promoId={itemInFocus?.promotion_code}
      />
    </div>
  );
};

export default ListView;
