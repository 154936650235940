/**
 *
 * Forgot Password
 *
 */
/* eslint-disable */
import React, { ReactElement, useState } from 'react';
// import { useMixpanel } from 'react-mixpanel-browser';
import { Form, Col, Row, Input, notification } from 'antd';
import Button from 'app/atoms/Button';
import { TabletOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { selectAuth } from './ducks/selectors';
import { authActions, resetPassword } from './ducks/slice';
import {
  validateMessages,
  PASSWORD_REGEX,
  OTP_REGEX,
  FORGOT_PASSWORD_PAGE,
} from './constants';
import './style.less';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useHistory } from 'react-router-dom';
import { OTP_MESSAGE } from 'configs/constants';
import { ResetPasswordInterface } from './ducks/types';
import * as services from './ducks/services';

const ResetPassword = (): ReactElement => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  // const mixpanel = useMixpanel();
  const { t } = useTranslation();
  const auth = useAppSelector(selectAuth);

  const {
    auth: { user },
  } = useAppSelector((state: any) => state);

  const [form] = Form.useForm();

  const forgotPassEmail = localStorage.getItem('forgotPassEmail') || '';
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: ResetPasswordForm) => {
    setLoading(true);
    const { otp, newPassword }: any = values;
    const payload: ResetPasswordInterface = {
      otp: otp,
      newPassword: newPassword,
      email: forgotPassEmail,
    };
    try {
      let d: any = await services.resetPassword(payload);
      localStorage.removeItem('forgotPassEmail');
      setLoading(false);
      notification.success({ message: d.data.message });
      // mixpanel?.track('reset password success');
      // mixpanel?.identify(payload.email);
      setTimeout(() => {
        history.push('/');
      }, 2000);
    } catch (error) {
      setLoading(false);
      notification.error({ message: error.response.data.data.message });
    } finally {
      setLoading(false);
      form.resetFields();
    }
  };
  const backtoOTP = () => {
    // Resend OTP here
    resendOTP({
      email: forgotPassEmail,
    });
  };
  const resendOTP = async (values: { email: string }) => {
    try {
      let d = await services.forgotPassword({ email: values.email });
      localStorage.setItem('forgotPassEmail', values?.email);
      notification.success({ message: d.data.message });
      // mixpanel?.track('forgot password initiated');
      // mixpanel?.identify(values.email);
    } catch (error) {
      notification.error({ message: error.response.data.data.message });
    }
  };
  return (
    <div className="ut-login-container">
      <div className="main-container">
        <div className="ut-login-body">
          <Row justify="center" align="middle">
            <Col sm={20} md={8}>
              <div className="ut-card radius login">
                <Col span={24}>
                  <Row justify="center" align="middle">
                    <Col md={18}>
                      <h2 className="title">{t('auth.reset.heading')}</h2>
                    </Col>
                  </Row>
                  <Row justify="center" align="middle">
                    <Col md={18}>
                      <Form
                        name="normal_login"
                        layout="vertical"
                        autoComplete="off"
                        className="login-form ut-form"
                        onFinish={onFinish}
                        validateMessages={validateMessages}
                        form={form}
                      >
                        <Form.Item
                          label={t('OTP')}
                          name="otp"
                          rules={[
                            {
                              required: true,
                              pattern: OTP_REGEX,
                              message: t(OTP_MESSAGE),
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter OTP"
                            autoComplete="new-password"
                            prefix={
                              <TabletOutlined className="site-form-item-icon" />
                            }
                            type="text"
                          />
                        </Form.Item>

                        <Form.Item
                          label={t('password')}
                          name="password"
                          rules={[
                            {
                              required: true,
                              pattern: PASSWORD_REGEX,
                              message: t('STRONG_PASSWORD_MESSAGE'),
                            },
                          ]}
                        >
                          <Input
                            prefix={
                              <LockOutlined className="site-form-item-icon" />
                            }
                            placeholder="Enter New Password"
                            type="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Form.Item>

                        <Form.Item
                          label={t('confirmPassword')}
                          name="newPassword"
                          rules={[
                            {
                              required: true,
                              pattern: PASSWORD_REGEX,
                              message: t('STRONG_PASSWORD_MESSAGE'),
                            },
                          ]}
                        >
                          <Input
                            prefix={
                              <LockOutlined className="site-form-item-icon" />
                            }
                            placeholder="Enter Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </Form.Item>

                        {password !== confirmPassword && (
                          <p
                            style={{
                              color: '#ff4d4f',
                              fontSize: '12px',
                              marginTop: '-20px',
                            }}
                          >
                            Password Not match
                          </p>
                        )}
                        <br />
                        <Form.Item>
                          <Button
                            btnclass="button ut-btn ut-mt-l"
                            type="primary"
                            loading={loading}
                            htmlType="submit"
                            block
                            value={t('Reset password')}
                            disabled={password !== confirmPassword}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            btnclass="custom-link-bt forgot-password"
                            type="link"
                            htmlType="button"
                            onClick={backtoOTP}
                            value={t('back_to_forgot')}
                          />
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

interface ResetPasswordForm {
  otp: number;
  newPassword: string;
}

export default ResetPassword;
