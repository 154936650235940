import { Collapse, Row, Col, Input } from 'antd';
import { useState } from 'react';
import DebounceAutocomplete, {
  IPromoOption,
  promoCodeFetcher,
  promoNameFetcher,
} from './PromotionAutoComplete';
import { useAppDispatch } from 'store/hooks';
import { headerActions } from 'app/modules/AppHeader/ducks/slice';
import { IconDownOutlined } from 'app/icons/icons';

const { Panel } = Collapse;

export default function PromotionFilter(props: any) {
  const { handleInputChange, filterPayload } = props;
  // const [inputPromoNameValue, setInputPromoNameValue] = useState<string>('');
  // const [inputPromoCodeValue, setInputPromoCodeValue] = useState<string>('');

  // const dispatch = useAppDispatch();

  // const handleSearchPromoName = (newValue: string) => {
  //   setInputPromoNameValue(newValue);
  //   if (newValue === '') {
  //     // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //     handleSearchPromoCode('');
  //   }
  //   dispatch(headerActions.setHeaderFilter(['promoId', undefined]));
  // };
  // const handleSearchPromoCode = (newValue: string) => {
  //   setInputPromoCodeValue(newValue);
  //   dispatch(headerActions.setHeaderFilter(['promoId', undefined]));
  //   if (newValue === '') {
  //     handleSearchPromoName('');
  //   }
  //
  // };

  // const handleSelectPromo = (newValue: string, option: IPromoOption) => {
  //   dispatch(headerActions.setHeaderFilter(['promoId', option.value]));
  //   setInputPromoNameValue(option.label);
  //   setInputPromoCodeValue(option.promoCode);
  // };

  return (
    <Collapse
      defaultActiveKey={['1']}
      className="ut-collapse"
      expandIcon={({ isActive }) => (
        <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
          <IconDownOutlined />
        </span>
      )}
      items={undefined}
      expandIconPosition={'end'}
    >
      <Panel header="Search By" key="1">
        <Row className="ut-mb-m ut-form">
          <Col span={24} className="ut-mb-s">
            <span className="ut-label">Promotion Name</span>
          </Col>
          <Col span={24}>
            {/* <AutoComplete
              // defaultValue="Ramadan Malamal"
              // className="ut-select w-100"
              style={{ width: '100%' }}
              // suffixIcon={<CaretDownFilled />}
              // onChange={handleChange}
              filterOption={filterPromotionOption}
              notFoundContent={autoCompleteLoading ? <Spin /> : 'No matches'}
              options={options}
            /> */}
            <div className="ut-form">
              {/* <DebounceAutocomplete
                value={inputPromoNameValue}
                placeholder="search promotion by"
                fetchOptions={promoNameFetcher}
                onChange={handleSearchPromoName}
                onSelect={handleSelectPromo}
                style={{
                  width: '100%',
                }}
              /> */}
              <Input
                className="w-full"
                value={filterPayload?.promoName || ''}
                onChange={(e: any) => handleInputChange(e, 'promoName')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="ut-mb-s ut-form">
            <span className="ut-label">Promotion Code</span>
          </Col>
          <Col span={24}>
            {/* <Select
              defaultValue="TPM-TRYUOID20230203"
              className="ut-select w-100"
              suffixIcon={<CaretDownFilled />}
              onChange={handleChange}
              options={[
                { value: '22nd May 2022', label: '22nd May 2022' },
                { value: '22nd May 2023', label: '22nd May 2023' },
                { value: '22nd May 2024', label: '22nd May 2024' },
              ]}
            /> */}
            <div className="ut-form">
              {/* <DebounceAutocomplete
                value={inputPromoCodeValue}
                placeholder="search promotion by code"
                fetchOptions={promoCodeFetcher}
                onChange={handleSearchPromoCode}
                onSelect={handleSelectPromo}
                style={{
                  width: '100%',
                }}
              /> */}
              <Input
                value={filterPayload?.promoCode || ''}
                onChange={(e: any) => handleInputChange(e, 'promoCode')}
              />
            </div>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}
