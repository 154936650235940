import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'store';
import { loadingActions } from 'app/molecules/Loader/ducks/slice';
import { toastMessageActions } from 'app/molecules/ToastMessage/ducks/slice';
import * as calendarViewSource from './services';
import {
  ICalendarView,
  ICalendarViewListFormate,
  ICalendarViewPromotionState,
  ICalendarViewReqestPayload,
} from './types';
import { formateCalendarView } from '../helper';
import {
  getIncreaseTemplateCount,
  getPromotionSummarySuccess,
  getSelectedTemplateDetailSuccess,
  getTemplateList,
  isQuickCreateModalOpen,
} from 'app/modules/Promotion/ducks/slice';
import {
  bulkDuplicateSuccess,
  getListView,
} from 'app/modules/ListView/ducks/slice';
import { PAGE_SIZE_LIST_VIEW } from 'app/modules/ListView/helper';

// The initial state of the {{ properCase ModuleName }} container
export const initialState: ICalendarViewPromotionState = {
  calendarViewList: [],
  // 'dayGridMonth,dayGridWeek',
  calendarViewType: 'dayGridMonth',
  calendarViewFilterList: [],
  loading: false,
  selectedEvent: '',
  selectedPromoId: '',
  error: '',
};
// for async action follow blow link
// https://redux-toolkit.js.org/tutorials/advanced-tutorial#thinking-in-thunks
// OR
// https://redux-toolkit.js.org/api/createAsyncThunk

const calendarViewSlice = createSlice({
  name: 'calendarView',
  initialState,
  reducers: {
    getCalendarViewListInProgress(state) {
      state.loading = true;
    },
    getCalendarViewListSuccess(
      state,
      { payload }: PayloadAction<ICalendarViewListFormate>,
    ) {
      state.loading = false;
      state.calendarViewList = payload.data;
    },
    getCalendarViewListFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    filterPromotionsInProgress(state) {
      state.loading = true;
    },
    filterPromotionsSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.calendarViewFilterList = payload.data;
    },
    filterPromotionsFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    clearFilterInProgress(state) {
      state.loading = true;
    },
    clearFilterSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.calendarViewFilterList = payload;
    },
    clearFilterFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    duplicateEventInProgress(state) {
      state.loading = true;
    },
    duplicateEventSuccess(
      state,
      { payload }: PayloadAction<ICalendarViewListFormate>,
    ) {
      state.loading = false;
      state.selectedEvent = '';
      state.calendarViewList = payload.data;
    },
    duplicateEventFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    createEventInProgress(state) {
      state.loading = true;
    },
    createEventSuccess(
      state,
      { payload }: PayloadAction<ICalendarViewListFormate>,
    ) {
      const newEvent = payload.data[0] as ICalendarView;
      return {
        ...state,
        calendarViewList: [...state.calendarViewList, newEvent],
        loading: false,
        error: '',
      };
    },
    createEventFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    deleteEventInProgress(state) {
      state.loading = true;
    },
    deleteEventSuccess(state, action) {
      const eventId = action.payload;
      state.calendarViewList = state.calendarViewList.filter(
        (event) => event.id !== eventId,
      );
      state.loading = false;
      state.selectedEvent = '';
      state.selectedPromoId = '';
      state.error = '';
    },
    deleteEventFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    submitEventInProgress(state) {
      state.loading = true;
    },
    submitEventSuccess(state, action) {
      state.loading = false;
      state.selectedEvent = '';
      state.selectedPromoId = '';
      state.error = '';
    },
    submitEventFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    approveEventInProgress(state) {
      state.loading = true;
    },
    approveEventSuccess(state, action) {
      state.loading = false;
      state.selectedEvent = '';
      state.selectedPromoId = '';
      state.error = '';
    },
    approveEventFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    bulkEventInProgress(state) {
      state.loading = true;
    },
    bulkEventSuccess(state) {
      state.loading = false;
      state.selectedEvent = '';
      state.selectedPromoId = '';
      state.error = '';
    },
    bulkEventFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    selectedEventInProgress(state) {
      state.loading = true;
    },
    selectedEventSuccess(state, action) {
      state.selectedEvent = action.payload.selectedEvent;
      state.selectedPromoId = action.payload.selectedEventId;
      state.loading = false;
      state.error = '';
    },
    selectedEventFailed(state, { payload }) {
      state.selectedEvent = '';
      state.selectedPromoId = '';
      state.loading = false;
      state.error = payload.error;
    },
    calendarViewTypeInProgress(state) {
      state.loading = true;
    },
    calendarViewTypeSuccess(state, action) {
      state.calendarViewType = action.payload;
      state.loading = false;
      state.error = '';
    },
    calendarViewTypeFailed(state, { payload }) {
      state.calendarViewType = '';
      state.loading = false;
      state.error = payload.error;
    },
  },
});

export const {
  actions: calendarViewActions,
  reducer: calendarViewReducer,
  name: sliceKey,
} = calendarViewSlice;

export const {
  getCalendarViewListInProgress,
  getCalendarViewListSuccess,
  getCalendarViewListFailed,

  filterPromotionsInProgress,
  filterPromotionsSuccess,
  filterPromotionsFailed,

  clearFilterInProgress,
  clearFilterSuccess,
  clearFilterFailed,

  duplicateEventInProgress,
  duplicateEventSuccess,
  duplicateEventFailed,

  createEventInProgress,
  createEventSuccess,
  createEventFailed,

  deleteEventInProgress,
  deleteEventSuccess,
  deleteEventFailed,

  submitEventInProgress,
  submitEventSuccess,
  submitEventFailed,

  approveEventInProgress,
  approveEventSuccess,
  approveEventFailed,

  selectedEventInProgress,
  selectedEventSuccess,
  selectedEventFailed,

  calendarViewTypeInProgress,
  calendarViewTypeSuccess,
  calendarViewTypeFailed,

  bulkEventInProgress,
  bulkEventSuccess,
  bulkEventFailed,
} = calendarViewSlice.actions;

export const getCalendarView =
  (params: ICalendarViewReqestPayload) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(getCalendarViewListInProgress());
    try {
      const data = await calendarViewSource.getCalendarViewApi(params);
      const formateData = formateCalendarView(data);
      dispatch(loadingActions.hideLoader());
      dispatch(getCalendarViewListSuccess(formateData));
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(getCalendarViewListFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };

export const filterPromotions =
  (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(filterPromotionsInProgress());
    try {
      const data = await calendarViewSource.filterPromotionsApi(payload);
      const formateData = formateCalendarView(data);
      dispatch(filterPromotionsSuccess(formateData));
      dispatch(loadingActions.hideLoader());
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(filterPromotionsFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };

export const clearFilterCalendarView = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(clearFilterInProgress());
  try {
    dispatch(clearFilterSuccess([]));
    dispatch(loadingActions.hideLoader());
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(clearFilterFailed({ error }));
  }
};

export const duplicateEvent =
  (params: string) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(duplicateEventInProgress());
    try {
      const data = await calendarViewSource.duplicateEventApi({
        ids: [params],
        mode: 'simple',
      });
      // const formateData = formateCalendarView(data);
      dispatch(loadingActions.hideLoader());
      // dispatch(duplicateEventSuccess(formateData));
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: 'Successfully duplicated',
        }),
      );
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(duplicateEventFailed({ error }));
    }
  };
export const createEvent =
  (
    params: any,
    selectedTemplateDetail: any,
    template = false,
    submit = false,
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(createEventInProgress());
    let dataResponse;
    try {
      dataResponse = await calendarViewSource.createEventApi(params, submit);

      if (selectedTemplateDetail) {
        await dispatch(getIncreaseTemplateCount(selectedTemplateDetail.id));
        await dispatch(getTemplateList());
        dispatch(getSelectedTemplateDetailSuccess({ data: null }));
      }
      dispatch(getPromotionSummarySuccess(dataResponse));
      const formateData = formateCalendarView(dataResponse);
      dispatch(createEventSuccess(formateData));
      dispatch(
        getListView({
          pageNumber: 1,
          pageSize: PAGE_SIZE_LIST_VIEW,
        }),
      );
      if (!template) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'success',
            content: submit
              ? 'Promotion successfuly submitted'
              : 'Promotion successfuly created',
          }),
        );
      }
      dispatch(isQuickCreateModalOpen(false));
      // on save close the Modal dialog
      dispatch(isQuickCreateModalOpen(false));
      dispatch(loadingActions.hideLoader());
    } catch (error) {
      dispatch(getSelectedTemplateDetailSuccess({ data: null }));
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300 && !template) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(createEventFailed({ error: data.message }));
      } else {
        if (!template) {
          dispatch(
            toastMessageActions.showToastMessage({
              type: 'error',
              content: data?.message || 'Something went wrong!',
            }),
          );
        }
      }
    } finally {
      dispatch(getSelectedTemplateDetailSuccess({ data: null }));
    }
    return dataResponse.data;
  };
export const deleteEvent =
  (params: string, history?: any, template: boolean = false) =>
  async (dispatch: AppDispatch) => {
    if (!template) {
      dispatch(loadingActions.showLoader());
      dispatch(deleteEventInProgress());
    }
    try {
      const data = await calendarViewSource.deleteEventApi(params);
      dispatch(loadingActions.hideLoader());
      dispatch(deleteEventSuccess(params));
      if (!template) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'success',
            content: data.message,
          }),
        );
      }
      if (history) {
        history.push('/');
      }
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      if (!template) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: error.message,
          }),
        );
        // handle error
        dispatch(deleteEventFailed({ error }));
      }
    }
  };

export const deleteBulkEvent =
  (promos: any) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(bulkEventInProgress());
    try {
      const data = await calendarViewSource.bulkDeleteApi({
        ids: promos.map((elem: any) => elem.promo_id),
      });
      dispatch(loadingActions.hideLoader());
      dispatch(bulkEventSuccess());
      dispatch(bulkDuplicateSuccess());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: data.message,
        }),
      );
      dispatch(
        getListView({
          pageNumber: 1,
          pageSize: PAGE_SIZE_LIST_VIEW,
        }),
      );
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.response.data.data.message,
        }),
      );
      // handle error
      dispatch(bulkEventFailed({ error }));
      dispatch(bulkDuplicateSuccess());
    }
  };

export const submitEvent =
  (params: string, history: any) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(submitEventInProgress());
    try {
      const data = await calendarViewSource.submitEventApi(params);
      dispatch(loadingActions.hideLoader());
      dispatch(submitEventSuccess(params));
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: data.message,
        }),
      );
      const today = new Date();
      dispatch(
        getCalendarView({
          // Adding 1 because getMonth() returns zero-based index
          monthNumber: today.getMonth() + 1,
          year: today.getFullYear(),
        }),
      );
      history.push('/');
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(submitEventFailed({ error }));
    }
  };

export const approvedEvent =
  (params: string, history: any, payload: any) =>
  async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(approveEventInProgress());
    try {
      const data = await calendarViewSource.approveEventApi(params, payload);
      dispatch(loadingActions.hideLoader());
      dispatch(approveEventSuccess(params));
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: data.message,
        }),
      );
      const today = new Date();
      dispatch(
        getCalendarView({
          // Adding 1 because getMonth() returns zero-based index
          monthNumber: today.getMonth() + 1,
          year: today.getFullYear(),
        }),
      );
      history.push('/');
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(approveEventFailed({ error }));
    }
  };

export const bulkEvent =
  (promos: any, payload: any) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(bulkEventInProgress());
    try {
      const data = await calendarViewSource.bulkEventApi({
        ids: promos.map((elem: any) => elem.promo_id),
        action: payload.status,
        reason: payload.comment,
      });
      dispatch(loadingActions.hideLoader());
      dispatch(bulkEventSuccess());
      dispatch(bulkDuplicateSuccess());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: data.message,
        }),
      );
      dispatch(
        getListView({
          pageNumber: 1,
          pageSize: PAGE_SIZE_LIST_VIEW,
        }),
      );
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.response.data.data.message,
        }),
      );
      // handle error
      dispatch(bulkEventFailed({ error }));
      dispatch(bulkDuplicateSuccess());
    }
  };
export const selectEvent = (params: any) => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(selectedEventInProgress());
  try {
    dispatch(loadingActions.hideLoader());
    dispatch(selectedEventSuccess(params));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: 'Something went wrong! select again...',
      }),
    );
    // handle error
    dispatch(selectedEventFailed({ error }));
  }
};

export const setcalendarViewType =
  (params: string) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(calendarViewTypeInProgress());
    try {
      dispatch(calendarViewTypeSuccess(params));
      dispatch(loadingActions.hideLoader());
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(calendarViewTypeFailed({ error }));
    }
  };
