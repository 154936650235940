import { CaretDownFilled } from '@ant-design/icons';
import { Col, Collapse, Row, Select } from 'antd';
import { IPromoLevel, IPromoType } from 'app/modules/Promotion/ducks/types';
import { useAppSelector } from 'store/hooks';
import { headerActions } from 'app/modules/AppHeader/ducks/slice';
import { useDispatch } from 'react-redux';
import { formateSelectVal } from 'app/modules/Promotion/helper';
import { IconDownOutlined } from 'app/icons/icons';

const { Panel } = Collapse;
const { Option } = Select;
const getPromoLevelOption = (level: IPromoLevel) => ({
  value: level.level_code,
  label: level.level_name,
});

const getPromoTypeOption = (type: IPromoType) => ({
  value: type.id,
  label: type.name,
});

export default function TacticsFilter({ handleChange, filterPayload }: any) {
  const { promotions } = useAppSelector((state: any) => state);
  const { promoStatus, promoTypes } = promotions;
  const withAll = [{ id: 'all', name: 'All', shortCode: 'all' }, ...promoTypes];
  // const promoTypesAll = [{}]
  const filter = useAppSelector((state) => state.appHeader.filter);
  const dispatch = useDispatch();

  return (
    <Collapse
      defaultActiveKey={['1']}
      // onChange={onChange}
      className="ut-collapse ut-form"
      expandIcon={({ isActive }) => (
        <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
          <IconDownOutlined />
        </span>
      )}
      items={undefined}
      expandIconPosition={'end'}
    >
      <Panel header="Promo Tactics" key="1">
        <Row className="ut-mb-m">
          <Col span={24} className="ut-mb-s">
            <span className="ut-label">Promotion Type</span>
          </Col>
          <Col span={24}>
            {/* <Select
              // defaultValue="TPR (Temporary Price Reduction)"
              placeholder="Select Type"
              className="ut-select w-100"
              data-name="productLevelId"
              suffixIcon={<CaretDownFilled />}
              onChange={(value: string) =>
                dispatch(headerActions.setHeaderFilter(['level', value]))
              }
              value={filter?.productLevelId}
              options={promotionData?.promoLevels.map(getPromoLevelOption)}
            /> */}
            <Select
              className="ut-select w-100"
              defaultValue={'Please Select'}
              suffixIcon={<CaretDownFilled />}
              value={filterPayload?.promotionType || 'Please Select'}
              onChange={(e: any) => handleChange(e, 'promotionType')}
              options={withAll?.map((type: any) => {
                return formateSelectVal(type, 'promoType');
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} className="ut-mb-s">
            <span className="ut-label">Promotion Status</span>
          </Col>
          <Col span={24}>
            <Select
              className="ut-select w-100"
              defaultValue={'Please Select'}
              value={filterPayload?.promotionStatus || 'Please Select'}
              suffixIcon={<CaretDownFilled />}
              onChange={(e: any) => handleChange(e, 'promotionStatus')}
            >
              {promoStatus.map((option: string) => (
                <Option key={option} value={option}>
                  <span className="capitalize">{option}</span>
                </Option>
              ))}
            </Select>
            {/* <Select
              // defaultValue="All"
              data-name="promotionTypeId"
              className="ut-select w-100"
              placeholder="Select Status"
              suffixIcon={<CaretDownFilled />}
              value={filter?.promotionTypeId}
              onChange={(value: string) =>
                dispatch(
                  headerActions.setHeaderFilter(['promotionTypeId', value]),
                )
              }
              options={promotionData.promoTypes.map(getPromoTypeOption)}
            /> */}
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}
