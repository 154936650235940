import { Row, Col } from 'antd';

export default function TableHeader() {
  return (
    <Col span={24}>
      <Row>
        <Col span={3}></Col>
        <Col
          span={21}
          style={{
            background: '#054096',
            padding: '16px 4px',
            color: '#fff',
          }}
        >
          <Row>
            <Col className="ut-fs-s center" span={2}>
              Jan
            </Col>
            <Col className="ut-fs-s center" span={2}>
              Feb
            </Col>
            <Col className="ut-fs-s center" span={2}>
              Mar
            </Col>
            <Col className="ut-fs-s center" span={2}>
              Apr
            </Col>
            <Col className="ut-fs-s center" span={2}>
              May
            </Col>
            <Col className="ut-fs-s center" span={2}>
              Jun
            </Col>
            <Col className="ut-fs-s center" span={2}>
              Jul
            </Col>
            <Col className="ut-fs-s center" span={2}>
              Aug
            </Col>
            <Col className="ut-fs-s center" span={2}>
              Sep
            </Col>
            <Col className="ut-fs-s center" span={2}>
              Oct
            </Col>
            <Col className="ut-fs-s center" span={2}>
              Nov
            </Col>
            <Col className="ut-fs-s center" span={2}>
              Dec
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
