/**
 *
 * Auth
 *
 */

import React, { ReactElement, useEffect } from 'react';
import { selectAuth } from './ducks/selectors';
import { AuthProps } from './ducks/types';
import {
  LOGIN_PAGE,
  FORGOT_PASSWORD_PAGE,
  RESET_PASSWORD_PAGE,
} from './constants';
import Login from './Login';
import { authActions, getUser } from './ducks/slice';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Skeleton, Spin } from 'antd';

function AuthComponent(props: AuthProps): ReactElement {
  const dispatch = useAppDispatch();
  const { children } = props;
  const auth = useAppSelector(selectAuth);
  const { activePage, user, fetchLoading, token } = auth;

  useEffect(() => {
    if (token) {
      dispatch(getUser());
    } else {
      dispatch(authActions.finishFetchLoading());
    }
  }, [dispatch, token]);

  if (fetchLoading === 'init' || fetchLoading === 'fetching')
    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );
  if (activePage === FORGOT_PASSWORD_PAGE) return <ForgotPassword />;
  if (user?.changePasswordRequired || activePage === RESET_PASSWORD_PAGE)
    return <ResetPassword />;
  if (!token || activePage === LOGIN_PAGE) return <Login />;

  return <>{children}</>;
}

export default AuthComponent;
