export const FAILED_ERROR = 'Something Unexpected Happened';

export const STRONG_PASSWORD_MESSAGE =
  'Password should contain Alphanumeric with atleast 1 capital and special character';

export const OTP_MESSAGE = '6 Digit numeric OTP Required';
export const INVALID_EMAIL_MESSAGE = 'Not a valid email!';
export const REQUIRED_FEILD = 'This field is required!';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss'; // 'h:mm:ss A' for AM/PM format
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const PROMOTION_SELECT_ERROR_MSG =
  'Promotion not selectable; please contact admin.';
export const PROMOTION_DRAG_ERROR_MSG =
  'Modification is restricted to Draft promotions only';

export const ROLES_SLUG = ['trade_manager', 'finance_manager', 'admin_manager'];
