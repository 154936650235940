/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Steps } from 'intro.js-react';
import { useAppSelector } from 'store/hooks';
import { selectAuth } from 'app/modules/Auth/ducks/selectors';
import 'intro.js/introjs.css';

const OnBoarding = () => {
  const auth = useAppSelector(selectAuth);
  const {
    loader: { loading },
  } = useAppSelector((state: any) => state);
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const steps = [
    {
      intro: 'Welcome to your demo of Uptrade 👋',
    },
    {
      element: '.appbar-head',
      intro:
        'The action ribbon helps you quickly and easily perform common tasks.',
    },
    {
      element: '.create-btn',
      intro: `Start creating promotions by clicking here.`,
    },
    {
      element: '.fc-view-harness',
      intro:
        'The Trade Calendar view allows you to see all your promotions on a single screen.<br/><br/>The intuitive legend helps to easily distinguish between promotions at various stages of their lifecycle.',
    },
    {
      element: '.toolbar-indicator-btn-group',
      intro:
        'Easily filter promotions by status through the intuitive color-coded legend.',
    },
    {
      element: '.edit-btn',
      intro: 'Edit, Save and resume working on draft promotions effortlessly.',
    },
    {
      element: '.cal-view',
      intro: 'Choose from different calendar views for improved planning.',
    },
    {
      element: '.report-btn',
      intro: `The Reports section provides easily accessible promotion analytics.`,
    },
  ];

  const onExit = () => {
    setStepsEnabled(false);
  };

  const toggleSteps = () => {
    setStepsEnabled(!stepsEnabled);
  };

  useEffect(() => {
    setTimeout(() => {
      const tour = localStorage.getItem('viewTour');
      if (!loading && auth?.token?.sessions === 0 && tour === null) {
        toggleSteps();
        setTimeout(() => {
          localStorage.setItem('viewTour', 'true');
        }, 2000);
      }
    }, 1000);
  }, [auth]);

  return (
    <div>
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        options={{
          hideNext: false,
          doneLabel: 'Lets get started!',
        }}
      />
    </div>
  );
};

export default OnBoarding;
