import React, { useState } from 'react';
import { Layout, Drawer } from 'antd';
import { ReactComponent as Logo } from 'assets/logo.svg';
import SideMenu from '../SideMenu';
import { DashboardProps } from './types';
import './dashboard.less';
import AppHeader from 'app/modules/AppHeader/AppHeader';

const { Sider, Content } = Layout;
const DashboardLayout: React.FC<DashboardProps> = (props: DashboardProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { children } = props;

  return (
    <Layout id="dashboard-layout">
      <Layout className="site-layout">
        <AppHeader />
        <Content className="site-layout-background main-body-outer">
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
