import { IListView } from 'app/modules/ListView/ducks/types';
import * as XLSX from 'xlsx';
import { capitalizeFirstLetter, getRoleTitle } from './helper';
import dayjs, { Dayjs } from 'dayjs';
import { formatNumberWithCommasNew } from 'utils/helper';
const ExcelJS = require('exceljs');

export const downloadJsonAsXlsx = (jsonData: any, listName = 'data-list') => {
  const wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(jsonData);
  XLSX.utils.book_append_sheet(wb, ws, listName);
  XLSX.writeFile(wb, `${listName}.xlsx`);
};
export const createExcelFromJsonObject = (
  jsonObject: Record<string, any>,
  sheetName = 'data-sheet',
) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([['Key', 'Value']]);

  const flattenObject = (obj: Record<string, any>, prefix = ''): any[][] => {
    let rows: any[][] = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const nestedKey = prefix ? `${prefix}.${key}` : key;
        const formattedKey = nestedKey
          .replace(/.*\./, '')
          .replace(/_/g, ' ')
          .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
        const value = obj[key];

        if (typeof value === 'object' && value !== null) {
          rows = rows.concat(flattenObject(value, nestedKey));
        } else {
          // Append a new row with formatted key and value
          rows.push([formattedKey, value === null ? 'N/A' : value]);
        }
      }
    }

    return rows;
  };

  const flattenedRows = flattenObject(jsonObject).filter(
    (row) => !/^\d+$/.test(row[0]),
  ); // Remove rows where the first column contains only numbers

  XLSX.utils.sheet_add_aoa(ws, flattenedRows);

  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${sheetName}.xlsx`);
};

const toDataURL = (url: string) => {
  const promise = new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });

  return promise;
};

export const exportFileEXCELJS = async (
  jsonData: any,
  user: any,
  listName = 'data-list',
) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(listName);

  const currentTime = dayjs().format('DD/MM/YYYY, h:mm A');

  sheet.getColumn('A').width = 30; // Set width of column A to 30
  sheet.getRow(1).height = 40; // Set height of row 1 to 100

  const URL: string = 'https://uptrade-dev-app.azurewebsites.net/';

  const result: any = await toDataURL(URL);
  const splitted = URL.split('.');
  const extName = splitted[splitted.length - 1];

  const imageId2 = await workbook.addImage({
    base64: result.base64Url,
    extension: extName,
    editAs: 'absolute',
  });

  await sheet.addImage(imageId2, `A1:A1`);

  sheet.getCell('A3').value = 'Date & Time';
  sheet.getCell('B3').value = currentTime;
  sheet.getCell('A4').value = 'User';
  sheet.getCell('B4').value = user.firstName + ' ' + user.lastName;
  sheet.getCell('A5').value = 'Role';
  sheet.getCell('B5').value = getRoleTitle(user.roles);

  ['A3', 'A4', 'A5'].forEach((cellAddress) => {
    const cell = sheet.getCell(cellAddress);
    cell.font = { bold: true };
  });

  sheet.getRow(7).values = [
    'Promotion ID',
    'Promotion Name',
    'Status',
    'User',
    'Planned Start',
    'Type',
    'Spending (PKR)',
    'Profit (PKR)',
    'ROI %',
  ];

  sheet.getRow(7).fill = {
    type: 'pattern',
    pattern: 'darkVertical',
    fgColor: { argb: '4285f4' },
  };

  sheet.getRow(7).font = {
    family: 4,
    size: 14,
    bold: true,
    color: { argb: '00FFFFFF' },
  };

  const startColumn = 'J'; // Start column
  const endColumn = 'Z'; // End column
  const rowNumber = 7; // Row number

  // Iterate over each cell in the specified range
  for (
    let col = startColumn.charCodeAt(0);
    col <= endColumn.charCodeAt(0);
    col++
  ) {
    const cellAddress = String.fromCharCode(col) + rowNumber; // Construct cell address
    sheet.getCell(cellAddress).fill = {
      type: 'pattern',
      pattern: 'none', // No fill
    };
  }

  sheet.columns = [
    {
      // header: 'Promotion ID',
      key: 'Promotion ID',
      width: 20,
    },
    {
      // header: 'Promotion Name',
      key: 'Promotion Name',
      width: 32,
    },
    {
      // header: 'Status',
      key: 'Status',
      width: 18,
    },
    {
      // header: 'User',
      key: 'User',
      width: 20,
    },
    {
      // header: 'Planned Start',
      key: 'Planned Start',
      width: 20,
    },
    {
      // header: 'Type',
      key: 'Type',
      width: 10,
    },
    {
      // header: 'Spending (PKR)',
      key: 'Spending (PKR)',
      width: 27,
    },
    {
      // header: 'Profit (PKR)',
      key: 'Profit (PKR)',
      width: 27,
    },
    {
      // header: 'ROI %',
      key: 'ROI%',
      width: 10,
    },
  ];
  await Promise.all(
    jsonData?.map(async (elem: any, index: number) => {
      // const rowNumber = index + 1;
      sheet.addRow({
        'Promotion ID': elem['Promotion ID'] || 'N/A',
        'Promotion Name': elem['Promotion Name'] || 'N/A',
        Status: capitalizeFirstLetter(elem?.Status) || 'N/A',
        User: elem?.User || 'N/A',
        'Planned Start': elem['Planned Start'] || 'N/A',
        Type: elem?.Type || 'N/A',
        'Spending (PKR)': elem['Spending (PKR)']
          ? formatNumberWithCommasNew(elem['Spending (PKR)'])
          : null,
        'Profit (PKR)': elem['Profit (PKR)']
          ? formatNumberWithCommasNew(elem['Profit (PKR)'])
          : null,
        'ROI%': elem['ROI%'] ? formatNumberWithCommasNew(elem['ROI%']) : null,
      });
    }),
  );

  const statusCol = sheet.getColumn(3);

  // iterate over all current cells in this column
  statusCol.eachCell((cell: any) => {
    const cellValue = sheet.getCell(cell?.address).value;
    // add a condition to set styling
    if (cellValue == 'Draft') {
      sheet.getCell(cell?.address).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'e1d5e4' },
      };
    } else if (cellValue == 'Submitted') {
      sheet.getCell(cell?.address).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'f6eece' },
      };
    } else if (cellValue == 'Rejected') {
      sheet.getCell(cell?.address).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'e6ced3' },
      };
    } else if (cellValue == 'Approved') {
      sheet.getCell(cell?.address).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'cee4d5' },
      };
    } else if (cellValue == 'Sentback') {
      sheet.getCell(cell?.address).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'f7e1ce' },
      };
    }
  });

  const spendingCol = sheet.getColumn(7);
  const profitCol = sheet.getColumn(8);
  const roiCol = sheet.getColumn(9);

  spendingCol.eachCell((cell: any) => {
    sheet.getCell(cell?.address).alignment = {
      horizontal: 'right',
    };
  });
  profitCol.eachCell((cell: any) => {
    sheet.getCell(cell?.address).alignment = {
      horizontal: 'right',
    };
  });
  roiCol.eachCell((cell: any) => {
    sheet.getCell(cell?.address).alignment = {
      horizontal: 'right',
    };
  });

  workbook.xlsx.writeBuffer().then(function (data: any) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `${listName}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};
