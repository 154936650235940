import {
  FilteredData,
  IListView,
  IListViewListFormate,
  IListViewPromotion,
  IListViewPromotionListResponse,
} from './ducks/types';
import dayjs from 'dayjs';

export const statusClass = (color: string) => {
  let statusC = '';
  switch (color) {
    case 'draft':
      statusC = 'draft';
      break;
    case 'sentback':
      statusC = 'sendback';
      break;
    case 'approved':
      statusC = 'approved';
      break;
    case 'submitted':
      statusC = 'submitted';
      break;
    case 'rejected':
      statusC = 'rejected';
      break;
    default:
      statusC = 'sendback';
  }
  return statusC;
};

export const oneDayMinusFromStringDate = (dateStr: string) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() - 1);
  return date?.toISOString()?.split('T')[0];
};
export const oneDayPlusFromStringDate = (dateStr: string) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() + 1);
  return date?.toISOString()?.split('T')[0];
};
// Function to get ISO week number
const getISOWeek = (date: Date): number => {
  const januaryFirst = new Date(date.getFullYear(), 0, 1);
  const days =
    Math.floor(
      (date.getTime() - januaryFirst.getTime()) / (24 * 60 * 60 * 1000),
    ) + 1;
  return Math.ceil(days / 7);
};
const customFormatDate = (dateString: string): string => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid Date'; // Handle invalid dates as needed
  }

  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  const weekNumber = getISOWeek(date);

  return `${month}-${day}-${year} - Wk ${weekNumber}`;
};

export const formateListView = (
  res: IListViewPromotionListResponse,
): IListViewListFormate => {
  let payload: IListView[] = [];
  if (Array.isArray(res.data.results)) {
    payload = res.data.results.map((view: IListViewPromotion) => {
      const endDate = dayjs(view.endDate).format('YYYY-MM-DD');
      const p: IListView = {
        key: view.id,
        promo_id: view.id,
        promotion_code: view.promoCode,
        promotion_name: view.promoName,
        status: view.status,
        created_at: view.createdAt
          ? dayjs(view.createdAt).format('YYYY-MM-DD')
          : 'N/A',
        modified_at: view.updatedAt
          ? dayjs(view.updatedAt).format('YYYY-MM-DD')
          : 'N/A',
        user: view.firstName + ' ' + view.lastName,
        role: view.role,
        assigned_categories: view.allottedCategories,
        planned_start: view.startDate
          ? customFormatDate(view.startDate)
          : 'N/A',
        planned_end: view.endDate
          ? customFormatDate(oneDayPlusFromStringDate(endDate))
          : 'N/A',
        tactic_type: view.promoType,
        promotion_level: view.promoLevel,
        assigned_brands: view.allottedCategories, // just a placeholder for now
        volume_tons: view.totalVolume,
        spending_pkr: view.totalCost,
        profit_pkr: view.totalLift, // just a placeholder for now
        roi_percentage: view.totalROI,
      };
      return p;
    });
  }
  return { data: payload, totalCount: res.data.totalCount };
};

export const mapToFilteredData = (elem: IListView): FilteredData => {
  return {
    'Promotion ID': elem.promotion_code,
    'Promotion Name': elem.promotion_name,
    Status: elem.status,
    User: elem.user,
    'Planned Start': elem.planned_start,
    Type: elem.tactic_type,
    'Spending (PKR)': elem.spending_pkr,
    'Profit (PKR)': elem.profit_pkr,
    'ROI%': elem.roi_percentage,
  };
};

export const capitalizeFirstLetter = (str: string) => {
  // Check if the input is a non-empty string
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }

  // Capitalize the first letter and concatenate the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const PAGE_SIZE_LIST_VIEW = 20;
export const removeFieldsFromObject = (
  obj: Record<string, any>,
  fieldsToRemove: string[],
): Record<string, any> => {
  const result: Record<string, any> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && !fieldsToRemove.includes(key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursively remove fields from nested objects
        result[key] = removeFieldsFromObject(obj[key], fieldsToRemove);
      } else {
        // Copy non-matching fields
        result[key] = obj[key];
      }
    }
  }

  return result;
};
export const clean = (obj: any): any => {
  let cleaned = removeFieldsFromObject(obj, [
    'promoData',
    'promoCodeFields',
    'selections',
    'createdByUserDetail',
    'selectedProductsSegregated',
  ]);

  return {
    ...cleaned,
    createdAt: dayjs(cleaned.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs(cleaned.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
  };
};
