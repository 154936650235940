import {
  AgAxisLabelFormatterParams,
  AgBarSeriesLabelOptions,
  AgCartesianSeriesLabelFormatterParams,
  AgCartesianSeriesLabelOptions,
  AgCartesianSeriesTooltipRendererParams,
  AgChartOptions,
  AgTooltipRendererResult,
} from 'ag-charts-community';
import { formatNumber } from 'utils/helper';

export const PLANNED_COLOR = '#D36500';
export const ACTUAL_COLOR = '#054096';

const barLabelFormatter = (params: AgCartesianSeriesLabelFormatterParams) =>
  formatNumber(params.value, 0);
const lineLabelFormatter = (params: AgCartesianSeriesLabelFormatterParams) =>
  formatNumber(params.value, 0);
const axesLabelFormatter = (params: AgAxisLabelFormatterParams) => '';

const getBarLabelConf = (color: string): Partial<AgBarSeriesLabelOptions> => {
  return {
    enabled: false,
    fontSize: 9,
    color,
    placement: 'outside',
    formatter: barLabelFormatter,
  };
};
const getLineLabelConf = (
  color: string,
): Partial<AgCartesianSeriesLabelOptions> => {
  return {
    enabled: true,
    color: color,
    fontSize: 8,
    formatter: lineLabelFormatter,
  };
};

interface IDataSet {
  xKey: string;
  yKey: string;
  color: string;
}
export const getBarConf = (
  data: any[],
  set1: IDataSet,
  set2: IDataSet,
): AgChartOptions => {
  const highlightColor = 'gray'; // need to use separate color as individual highlight is not working
  const dataLabelColor = highlightColor;

  const paddingOuter = 0.4;
  const paddingInner = 0.5;

  return {
    autoSize: true,
    data,
    legend: {
      enabled: false,
    },
    background: {
      visible: false,
    },
    theme: {
      overrides: {
        column: {
          // legend: {
          //   position: 'bottom',
          // },
          series: {
            // strokeWidth: 0,
            highlightStyle: {
              series: {
                strokeWidth: 0,
                dimOpacity: 0.2,
              },
            },
          },
        },
      },
    },
    title: {
      enabled: false,
      // text: 'Regular Internet Users',
      // fontSize: 18,
      // spacing: 25,
    },
    footnote: {
      enabled: false,
      // text: 'Source: Office for National Statistics',
    },
    series: [
      {
        type: 'column',
        xKey: set1.xKey,
        yKey: set1.yKey,
        label: getBarLabelConf(dataLabelColor),
        fill: set1.color,
        stroke: set1.color,
        /*  highlightStyle: {
          item: {
            fill: set1Color,
            stroke: set1Color,
          },
        }, */
      },
      {
        type: 'column',
        xKey: set2.xKey,
        yKey: set2.yKey,
        label: getBarLabelConf(dataLabelColor),
        fill: set2.color,
        highlightStyle: {
          item: {
            fill: highlightColor,
            stroke: highlightColor,
          },
        },
      },
    ],
    axes: [
      {
        type: 'category',
        position: 'bottom',
        tick: {
          width: 0,
        },
        label: {
          formatter: axesLabelFormatter,
        },
        line: {
          width: 0,
        },
        paddingOuter,
        paddingInner,
        // groupPaddingInner: 1,
      },
      {
        tick: {
          width: 0,
        },
        type: 'number',
        position: 'left',
        label: {
          // formatter: ({ value }) => `${value / 1000}M`,
          formatter: axesLabelFormatter,
        },
        line: {
          width: 0,
        },
      },
    ],
  };
};

export const getLineConf = (
  data: any[],
  set1: IDataSet,
  set2: IDataSet,
): AgChartOptions => {
  const highlightColor = 'gray'; // need to use separate color as individual highlight is not working
  const dataLabelColor = highlightColor;
  const paddingOuter = 0.3;
  const paddingInner = 1;
  const dataStrokeWidth = 1;

  const dateFormatter = new Intl.DateTimeFormat('en-US');
  const tooltip = {
    renderer: ({
      title,
      xValue,
      yValue,
    }: AgCartesianSeriesTooltipRendererParams): AgTooltipRendererResult => ({
      title,
      // content: `${dateFormatter.format(xValue)}: ${yValue}`,
      content: `${xValue}: ${yValue}`,
    }),
  };

  return {
    autoSize: true,
    data,
    legend: {
      enabled: false,
    },
    background: {
      visible: false,
    },
    theme: {
      overrides: {
        line: {
          legend: {
            // enabled: false,
          },
          series: {
            highlightStyle: {
              series: {
                strokeWidth: 3,
                dimOpacity: 0.2,
              },
            },
          },
        },
      },
    },
    title: {
      // text: 'Road fuel prices',
      // fontSize: 18,
      // spacing: 25,
      enabled: false,
    },
    footnote: {
      enabled: false,
      // text: 'Source: Department for Business, Energy & Industrial Strategy',
    },
    series: [
      {
        type: 'line',
        xKey: 'month',
        yKey: set1.yKey,
        label: getLineLabelConf(set1.color),
        stroke: set1.color,
        marker: {
          stroke: set1.color,
          fill: set1.color,
        },
        highlightStyle: {
          item: {
            fill: set1.color,
            stroke: set1.color,
          },
        },
        strokeWidth: dataStrokeWidth,
        tooltip: {
          enabled: false,
        },
      },
      {
        type: 'line',
        xKey: 'month',
        yKey: set2.yKey,
        label: getLineLabelConf(set2.color),
        stroke: set2.color,
        marker: {
          stroke: set2.color,
          fill: set2.color,
        },
        highlightStyle: {
          item: {
            fill: set2.color,
            stroke: set2.color,
          },
        },
        strokeWidth: dataStrokeWidth,
        tooltip: {
          enabled: false,
        },
      },
    ],
    axes: [
      {
        position: 'bottom',
        type: 'category',
        tick: {
          width: 0,
          // interval: time.month.every(2),
        },
        title: {
          // text: 'Date',
          enabled: false,
        },
        label: {
          // autoRotate: true,
          formatter: axesLabelFormatter,
        },
        line: {
          width: 0,
        },
        paddingOuter,
        paddingInner,
      },
      {
        position: 'left',
        type: 'number',
        tick: { width: 0 },
        line: {
          width: 0,
        },
        title: {
          // text: 'Price in pence',
          enabled: false,
        },
        label: {
          // autoRotate: true,
          formatter: axesLabelFormatter,
        },
        // paddingOuter,
      },
    ],
  };
};
