import React, { ReactElement } from 'react';
import { ConfigProvider } from 'antd';
import { selectDirection } from './ducks/selectors';
import { DirectionProps } from './ducks/types';
import { useAppSelector } from 'store/hooks';

const Direction = (props: DirectionProps): ReactElement => {
  const direction = useAppSelector(selectDirection);
  const { children } = props;
  return <ConfigProvider direction={direction}>{children}</ConfigProvider>;
};

export default Direction;
