import { AgChartsReact } from 'ag-charts-react';
import {
  ACTUAL_COLOR,
  PLANNED_COLOR,
  getLineConf,
} from './utils/configuration';
import { IMonthlyReportData } from './ducks/type';
import { useAppSelector } from 'store/hooks';

/* const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'Nov',
  'December',
];
const series1 = [65, 59, 80, 81, 56, 55, 40, 83, 89, 22, 11, 33];
const series2 = [62, 87, 56, 61, 56, 60, 43, 32, 0, 22, 45, 32];

const theData = months.map((m, index) => ({
  month: m,
  actual: series1[index],
  planned: series2[index],
}));
 */
interface IProps {
  data: IMonthlyReportData[];
}
const ChartExample = ({ data }: IProps) => {
  const { reportingView } = useAppSelector((state: any) => state);
  const { viewByOption } = reportingView;
  return (
    <div style={{ height: '80px' }}>
      <AgChartsReact
        options={getLineConf(
          data,
          {
            color: ACTUAL_COLOR,
            xKey: 'month',
            yKey: viewByOption + 'Actual',
          },
          {
            color: PLANNED_COLOR,
            xKey: 'month',
            yKey: viewByOption,
          },
        )}
      />
    </div>
  );
};

export default ChartExample;
