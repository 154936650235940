import React, {
  useState,
  useEffect,
  useRef,
  ReactElement,
  useCallback,
} from 'react';
// import { useMixpanel } from 'react-mixpanel-browser';
import {
  Collapse,
  Form,
  Input,
  Select,
  Breadcrumb,
  Button,
  Row,
  Col,
  DatePicker,
  InputRef,
  Skeleton,
  message,
  Popover,
  Tag,
  Avatar,
  Tooltip,
  Dropdown,
} from 'antd';
import type { FormItemProps, MenuProps } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getPromotion,
  getPromoDuration,
  updatePromotion,
  getProdcutTree,
  getPromoDurationWeeks,
  setEditPromotionPayload,
  getProductLevels,
  getGeoLevels,
  getChannelLevels,
  updatePromotionsSuccess,
  getOutletList,
  getPromotionHistory,
  getGeoTree,
  getTreeSelectionCount,
  setIsTempPromo,
  setNewTemplate,
  setIsNewTemplateModalVisible,
  setTriggerSubmit,
} from './ducks/slice';
import { useParams } from 'react-router-dom';
import {
  calculateActivityDuration,
  extractKeys,
  formateHierarchyBoxTagsDynamic,
  formateSchemeVal,
  formateSelectVal,
  getLevelWiseSelections,
  removeChildrenKey,
  splitCamelCase,
} from './helper';
import { toastMessageActions } from 'app/molecules/ToastMessage/ducks/slice';
import HierarchyModal from './hierarchyModal';
import { MyFormItemGroupProps } from 'utils/types';
import { IconDownOutlined } from 'app/icons/icons';
import PromoSummary from './promoSummary';
import ProductDetailModal from './productDetailModal';
import SelectionDetailModal from './selectionDetailModal';
import { CaretDownFilled, CheckOutlined } from '@ant-design/icons';
import {
  getPromotionDurationApi,
  getPromotionDurationWeeksApi,
} from './ducks/services';
import { loadingActions } from 'app/molecules/Loader/ducks/slice';
import { headerActions } from '../AppHeader/ducks/slice';
import { useHistory } from 'react-router-dom';
import { REQUIRED_FEILD } from 'configs/constants';

import { Table, Steps, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter } from '../../modules/ListView/helper';
import { getPromotionSummary } from './ducks/slice';
import StepTickIcon from '../../../assets/steptick.png';
import {
  capitalizeFirstLetterOfEachWord,
  formatNumberWithCommasNew,
} from '../../../utils/helper';

const { Panel } = Collapse;
const { TextArea } = Input;
let count = 0;
//This component is used for both View and Edit
const EditPromotion = (): ReactElement => {
  const history = useHistory();
  const [form] = Form.useForm();
  const isViewOnly = history.location.pathname.includes('view');
  const isCreate = history.location.pathname.includes('create');
  // Initializations
  const dispatch: any = useAppDispatch();
  const { id } = useParams() as { id: string };
  const promotionId = id;
  const { promotions } = useAppSelector((state: any) => state);
  const { appHeader } = useAppSelector((state: any) => state);

  const { isHierarchy, isListing, isCalculations } = appHeader;

  const {
    singleObj: {
      promoData: {
        scheme,
        applyOn,
        applyUOM,
        promoType,
        products,
        buyMeasure,
        discountType,
        buyType,
        offerType,
        applyTo,
      } = [{}],
      selectionsCount: { geoNames, productNames, channelNames } = {
        geoNames: {},
        productNames: {},
        channelNames: {},
      },
      other = null,
    } = {},
    singleObj,
    promoSchemes,
    promoLevels,
    promoTypes,
    offerTypes,
    applyTos,
    promoBuyTypes,
    promoBuyUnitOfMeasures,
    promoApplyOns,
    promoApplyUoms,
    promoDiscountTypes,
    productTree,
    geoTree,
    channelTree,
    promoDurationWeeks,
    editPromoPayloadGState,
    levels: { productLevels, geoLevels, channelLevels },
    outlets,
    promoHistory,
    summaryObject,
    promoCustomers,
  } = promotions;

  const {
    promotions: {
      treeSelectionCount: {
        channelsCount,
        productComponentsCount,
        geoComponentCount,
        channelComponentsCount,
        productsCount,
        geosCount,
        outletsCount,
      },
    },
  } = useAppSelector((state: any) => state);

  const {
    auth: { user },
  } = useAppSelector((state: any) => state);

  // const mixpanel = useMixpanel();

  const { isTradeManager, isFinanceManager } = useAppSelector(
    (state) => state.auth,
  );
  const isEdit = history.location.pathname.includes('edit');

  const editPromotionData = promotions.singleObj;
  const [editPromoPayload, setEditPromoPayload] = useState<any>({});
  const [defaultSchemeValue, setDefaultSchemeValue] = useState(null);
  const [defaultLevelValue, setDefaultLevelValue] = useState(null);
  const [defaultTypeValue, setDefaultTypeValue] = useState(null);
  const [defaultBuyTypesVal, setDefaultBuyTypesVal] = useState(null);
  const [defaultBuyUnitOfMeasuresVal, setDefaultBuyUnitOfMeasuresVal] =
    useState(null);
  const [defaultApplyOnsVal, setDefaultApplyOnsVal] = useState(null);
  const [defaultApplyUomsVal, setDefaultApplyUomsVal] = useState(null);
  const [defaultDiscountTypesVal, setDefaultDiscountTypesVal] = useState(null);
  const [promoActivityDuration, setPromoActivityDuration] = useState<any>({});

  const [isEditingHeading, setIsEditingHeading] = useState(false);
  const [isEditingDisruption, setIsEditingDisruption] = useState(false);
  const [outletKeys, setOutletKeys] = useState<React.Key[]>([]);
  const [outletKeysLength, setOutletKeysLength] = useState(null);
  //modal
  const [hierarchyModalType, setHierarchyModalType] = useState('');
  const [isHierarchyModalOpen, setIsHierarchyModalOpen] = useState(false);
  const [isProductDetailModalOpen, setIsProductDetailModalOpen] =
    useState(false);
  const [isSelectionDetailModalOpen, setIsSelectionDetailModalOpen] =
    useState(false);

  const [activeTab, setActiveTab] = useState<number>(1);
  const [isModern, setIsModern] = useState(false);

  let uniqueHolidays = [...new Set(promoActivityDuration.holidays)];

  // Quick Hierarchy Selection
  // const showHierarchyModal = (modalType: string) => {
  //   setHierarchyModalType(modalType);
  //   setIsHierarchyModalOpen(true);
  // };

  const showHideHierarchyModal = useCallback((modalType: string) => {
    setTimeout(() => {
      setHierarchyModalType(modalType);
      setIsHierarchyModalOpen(true);
    }, 500);
  }, []);

  const showHideHierarchyModalCallback = useCallback((modalType: string) => {
    setTimeout(() => {
      setHierarchyModalType(modalType);
      // setIsHierarchyModalOpen(true);
    }, 500);
  }, []);

  const handleHierarchyOk = () => {
    setIsHierarchyModalOpen(false);
    setHierarchyModalType('');
  };

  const handleHierarchyCancel = () => {
    setIsHierarchyModalOpen(false);
    setHierarchyModalType('');
    dispatch(headerActions.hideHierarchy());
  };

  // Product Details modal
  const showProductDetailModal = () => {
    setIsProductDetailModalOpen(true);
  };

  const handleProductDetailOk = () => {
    setIsProductDetailModalOpen(false);
    dispatch(headerActions.hideIsCalculations());
  };

  const handleProductDetailCancel = () => {
    setIsProductDetailModalOpen(false);
    dispatch(headerActions.hideIsCalculations());
  };

  // Selection Details modal
  const handleSelectionDetailModal = () => {
    setIsSelectionDetailModalOpen(true);
  };

  const handleSelectionDetailOk = () => {
    setIsSelectionDetailModalOpen(false);
    dispatch(headerActions.hideIsListing());
  };

  const handleSelectionDetailCancel = () => {
    setIsSelectionDetailModalOpen(false);
    dispatch(headerActions.hideIsListing());
  };

  //tree
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);

  const [geoCheckedKeys, setGeoCheckedKeys] = useState<React.Key[]>([]);
  const [channelCheckedKeys, setChannelCheckedKeys] = useState<React.Key[]>([]);
  // const [outletCheckedKeys, setOutletCheckedKeys] = useState<React.Key[]>([]);
  const [promoDurationWeeksValue, setPromoDurationWeeksValue] = useState<any>(
    [],
  );

  const [changed, setChanged] = useState<boolean>(false);

  const headingInputRef = useRef<InputRef>(null);
  const isFirstRender = useRef(true);
  const descriptionInputRef = useRef<InputRef>(null);
  const handleClickHeading = useCallback(() => {
    if (!isViewOnly) {
      setIsEditingHeading(!isEditingHeading);
      setIsEditingDisruption(false);
    }
  }, [isEditingHeading]);

  const handleClickDisruption = useCallback(() => {
    if (!isViewOnly) {
      setIsEditingDisruption(!isEditingDisruption);
      setIsEditingHeading(false);
    }
  }, [isEditingDisruption]);

  useEffect(() => {
    if (promotionId) {
      const data: any = {};
      setCheckedKeys([]);
      setChannelCheckedKeys([]);
      setGeoCheckedKeys([]);
      setPromoActivityDuration({});
      setEditPromoPayload({});
      dispatch(updatePromotionsSuccess({ data }));
      dispatch(getPromotion(promotionId));
      dispatch(getProductLevels());
      dispatch(getGeoLevels());
      dispatch(getChannelLevels());
      dispatch(getOutletList());
    }
    dispatch(
      getPromoDurationWeeks({ allWeeks: true, year: new Date().getFullYear() }),
    );
    return () => {
      const data: any = {};
      dispatch(updatePromotionsSuccess({ data }));
    };
  }, [dispatch, promotionId]);

  useEffect(() => {
    if (Object.keys(singleObj).length === 0) {
      return;
    }
    if (Object.keys(singleObj).length > 0) {
      setOutletKeys(singleObj.promoData.outlets);
      const {
        promoData: {
          holidays: hDays,
          weekStart,
          weekEnd,
          products: productTreeSObj,
          geos: geoTreeS,
          channels: channelTreeS,
        },
        startDate,
        endDate,
      } = singleObj;

      if (Object.keys(promoActivityDuration).length === 0) {
        setPromoActivityDuration({
          holidays: hDays,
          weekStart: weekStart,
          weekEnd: weekEnd,
          startDate: startDate,
          endDate: endDate,
        });
      }

      // products
      if (productTreeSObj?.length === 0) {
        form.resetFields();
        //handleHierarchyOk();
      }
      if (checkedKeys?.length === 0 && productTree?.length > 0) {
        let checkedKeyVal = [];

        if (
          productTreeSObj &&
          productTreeSObj?.length > 0 &&
          productTreeSObj[0]?.selections[0] === 'all'
          // (productTreeSObj &&
          //   productTreeSObj[0]?.level !== defaultLevelValue &&
          //   defaultLevelValue !== null)
        ) {
          checkedKeyVal = extractKeys(productTree);
        } else {
          checkedKeyVal =
            productTreeSObj?.length > 0
              ? productTreeSObj[0]?.selections?.map((item: any) => item.key)
              : [];
        }

        if (checkedKeys.length === 0) {
          setCheckedKeys(checkedKeyVal);
        }
      }
      if (geoCheckedKeys?.length === 0 && geoTree.length > 0) {
        if (geoCheckedKeys.length > 0) return;
        let geoCheckedKeyVal = [];
        if (geoTreeS?.length > 0 && geoTreeS[0] === 'all') {
          geoCheckedKeyVal = extractKeys(geoTree);
        } else {
          geoCheckedKeyVal = geoTreeS?.map((item: any) => item.key);
        }
        if (geoCheckedKeys.length === 0) {
          setGeoCheckedKeys(geoCheckedKeyVal);
        }
      }

      //for channel default selections
      if (channelCheckedKeys?.length === 0 && channelTree?.length > 0) {
        if (channelCheckedKeys?.length > 0) return;
        let channelCheckedKeyVal = [];
        if (channelTreeS?.length > 0 && channelTreeS[0] === 'all') {
          channelCheckedKeyVal = extractKeys(channelTree);
        } else {
          channelCheckedKeyVal = channelTreeS?.map((item: any) => item.key);
        }
        if (channelCheckedKeys?.length === 0) {
          setChannelCheckedKeys(channelCheckedKeyVal);
        }
      }
    }
  }, [
    singleObj,
    productTree,
    geoTree,
    channelTree,
    promoActivityDuration,
    // checkedKeys,
    // geoCheckedKeys?.length,
    // channelCheckedKeys?.length,
    defaultLevelValue,
    outlets,
  ]);

  useEffect(() => {
    if (promoSchemes && editPromotionData?.length > 0 && promotionId) {
      const filteredPromoSchemes = promoSchemes.filter(
        (schem: { id: string }) =>
          schem.id === editPromotionData?.promoData?.scheme?.id,
      );
      setDefaultSchemeValue(filteredPromoSchemes[0]?.name);
    }
  }, [editPromotionData, promoSchemes, promotionId]);
  useEffect(() => {
    if (promoTypes && editPromotionData?.length > 0 && promotionId) {
      const filteredPromoTypes = promoTypes?.filter(
        (item: { id: string }) =>
          item.id === editPromotionData?.promoData?.promoType?.id,
      );
      setDefaultTypeValue(filteredPromoTypes[0]?.name);
    }
  }, [editPromotionData, promoTypes, promotionId]);
  useEffect(() => {
    if (promoBuyTypes && editPromotionData?.length > 0 && promotionId) {
      const filtered = promoBuyTypes?.filter(
        (item: { id: string }) =>
          item.id === editPromotionData?.promoData?.buyType?.id,
      );
      setDefaultBuyTypesVal(filtered[0]?.name);
    }
  }, [editPromotionData, promoBuyTypes, promotionId]);
  useEffect(() => {
    if (
      promoBuyUnitOfMeasures &&
      editPromotionData?.length > 0 &&
      promotionId
    ) {
      const filtered = promoBuyUnitOfMeasures?.filter(
        (item: { id: string }) =>
          item.id === editPromotionData?.promoData?.buyMeasure?.id,
      );
      setDefaultBuyUnitOfMeasuresVal(filtered[0]?.name);
    }
  }, [editPromotionData, promoBuyUnitOfMeasures, promotionId]);
  useEffect(() => {
    if (promoDiscountTypes && editPromotionData?.length > 0 && promotionId) {
      const filtered = promoDiscountTypes?.filter(
        (item: { id: string }) =>
          item.id === editPromotionData?.promoData?.discountType?.id,
      );
      setDefaultDiscountTypesVal(filtered[0]?.name);
    }
  }, [editPromotionData, promoDiscountTypes, promotionId]);
  useEffect(() => {
    if (promoApplyOns && editPromotionData?.length > 0 && promotionId) {
      const filtered = promoApplyOns?.filter(
        (item: { id: string }) =>
          item.id === editPromotionData?.promoData?.applyOn?.id,
      );
      setDefaultApplyOnsVal(filtered[0]?.name);
    }
  }, [editPromotionData, promoApplyOns, promotionId]);
  useEffect(() => {
    if (promoApplyUoms && editPromotionData?.length > 0 && promotionId) {
      const filtered = promoApplyUoms?.filter(
        (item: { id: string }) =>
          item.id === editPromotionData?.promoData?.applyUOM?.id,
      );
      setDefaultApplyUomsVal(filtered[0]?.name);
    }
  }, [editPromotionData, promoApplyUoms, promotionId]);
  useEffect(() => {
    if (promoBuyTypes && editPromotionData?.length > 0 && promotionId) {
      const filteredPromoTypes = promoBuyTypes?.filter(
        (item: { id: string }) =>
          item.id === editPromotionData?.promoData?.buyType?.id,
      );
      setDefaultBuyTypesVal(filteredPromoTypes[0]?.name);
    }
  }, [editPromotionData, promoBuyTypes, promotionId]);

  useEffect(() => {
    if (promoDurationWeeks?.weeks) {
      const aweeksObject = promoDurationWeeks?.weeks?.map((week: any) => ({
        value: week.toString(),
        label: week.toString(),
      }));
      setPromoDurationWeeksValue(aweeksObject);
    }
    if (promoDurationWeeks?.dateStart) {
      setPromoActivityDuration({
        ...promoActivityDuration,
        endDate: promoDurationWeeks?.dateEnd || promoActivityDuration?.endDate,
        startDate:
          promoDurationWeeks?.dateStart || promoActivityDuration?.startDate,
      });
    }
    if (promoDurationWeeks?.weekStart) {
      setPromoActivityDuration({
        ...promoActivityDuration,
        weekEnd: promoDurationWeeks?.weekEnd || promoActivityDuration?.weekEnd,
        weekStart:
          promoDurationWeeks?.weekStart || promoActivityDuration?.weekStart,
      });
    }
  }, [promoDurationWeeks]);

  useEffect(() => {
    dispatch(getPromotionHistory(promotionId));
  }, [promotionId]);

  useEffect(() => {
    if (singleObj?.id) {
      dispatch(getPromotionSummary(singleObj.id));
    }
  }, [singleObj?.id]);

  useEffect(() => {
    if (
      editPromotionData?.promoData?.promoType?.id == '2' ||
      editPromoPayload?.promoData?.promoType?.id == '2'
    ) {
      setIsModern(true);
    } else {
      setIsModern(false);
    }
  }, [
    editPromotionData?.promoData?.promoType,
    editPromoPayload?.promoData?.promoType,
  ]);

  useEffect(() => {
    if (editPromotionData?.promoData?.promoType?.id == '2') {
      dispatch(getGeoTree(editPromotionData?.promoData?.customers || []));
    } else {
      dispatch(getGeoTree());
    }
  }, [editPromotionData?.promoData?.promoType]);

  // useEffect(() => {
  //   if (isEditingHeading) {
  //     if (headingInputRef.current !== null) {
  //       headingInputRef.current.focus();
  //     }
  //   }
  //   if (isEditingDisruption) {
  //     if (descriptionInputRef.current !== null) {
  //       descriptionInputRef.current.focus();
  //     }
  //   }
  // }, [isEditingHeading, isEditingDisruption]);

  const handleSaveAsTemplate = () => {
    dispatch(setIsTempPromo(false));
    dispatch(
      setNewTemplate({
        promoId: promotionId,
      }),
    );
    dispatch(setIsNewTemplateModalVisible(true));
  };

  const MyFormItemContext = React.createContext<(string | number)[]>([]);

  function toArr(
    str: string | number | (string | number)[],
  ): (string | number)[] {
    return Array.isArray(str) ? str : [str];
  }

  const MyFormItemGroup = ({ prefix, children }: MyFormItemGroupProps) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatPath = React.useMemo(
      () => [...prefixPath, ...toArr(prefix)],
      [prefixPath, prefix],
    );

    return (
      <MyFormItemContext.Provider value={concatPath}>
        {children}
      </MyFormItemContext.Provider>
    );
  };

  const MyFormItem = ({ name, ...props }: FormItemProps) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatName =
      name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Form.Item name={concatName} {...props} />;
  };

  const handleChange = (ID: string, name: string) => {
    let promoState: any = [];
    switch (name) {
      case 'promoType':
        promoState = promoTypes;
        break;
      case 'promoLevel':
        promoState = promoLevels;
        break;
      case 'scheme':
        promoState = promoSchemes;
        break;
      case 'buyType':
        promoState = promoBuyTypes;
        break;
      case 'buyMeasure':
        promoState = promoBuyUnitOfMeasures;
        break;
      case 'discountType':
        promoState = promoDiscountTypes;
        break;
      case 'applyOn':
        promoState = promoApplyOns;
        break;
      case 'applyUOM':
        promoState = promoApplyUoms;
        break;
      case 'offerType':
        promoState = offerTypes;
        break;
      case 'applyTo':
        promoState = applyTos;
        break;
      default:
        promoState = [];
    }

    if (name === 'promoLevel') {
      const promoLevelName = promoLevels.filter(
        (promo: any) => promo.level_code === ID,
      );
      setDefaultLevelValue(promoLevelName[0]?.level_name);
      dispatch(getProdcutTree(promoLevelName[0]?.level_name));
      setCheckedKeys([]);
      const updatedPayload = {
        ...editPromoPayload,

        dimensions: {
          ...editPromoPayload?.dimensions,

          products: [
            {
              level: promoLevelName[0]?.level_name,
              selections: ['all'],
            },
          ],
        },
      };
      setEditPromoPayload(updatedPayload);
      dispatch(
        setEditPromotionPayload({
          promoId: promotionId,
          payload: updatedPayload,
        }),
      );
      dispatch(updatePromotion(updatedPayload, promotionId));
    } else {
      const filteredPromo = promoState?.filter(
        (type: { id: string }) => type.id === ID,
      );
      if (name === 'promoType') {
        setDefaultTypeValue(filteredPromo[0].name);
        if (filteredPromo[0]?.id != '2') {
          form.setFieldValue('PromotionCustomers', undefined);
          setChanged(true);
          dispatch(getGeoTree());
          setIsModern(false);
          let payload = {
            dimensions: {
              products: [
                {
                  level: 'Product Size',
                  selections: ['all'],
                },
              ],
              geos: ['all'],
              channels: ['all'],
              outlets: ['all'],
              promoType: filteredPromo[0],
            },
          };
          dispatch(getTreeSelectionCount(payload));
        } else {
          dispatch(getGeoTree(editPromotionData?.promoData?.customers || []));
          setIsModern(true);
          setGeoCheckedKeys(['all']);
        }
      }
      if (name === 'discountType') {
        setDefaultDiscountTypesVal(filteredPromo[0].name);
      }
      if (name === 'buyMeasure') {
        setDefaultBuyUnitOfMeasuresVal(filteredPromo[0].name);
      }
      const updatedPayload = {
        ...editPromoPayload,
        dimensions: {
          ...editPromoPayload?.dimensions,
          [name]: filteredPromo[0],
        },
      };
      setEditPromoPayload(updatedPayload);
      dispatch(
        setEditPromotionPayload({
          promoId: promotionId,
          payload: updatedPayload,
        }),
      );
    }
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    name: string,
  ) => {
    let value: any = e.target.value;
    if (
      name === 'discountValue' ||
      name === 'purchaseFrom' ||
      name === 'purchaseTo'
    ) {
      value = Number(e.target.value);
    }
    let updatedPayload = {};

    if (name === 'otherdetail') {
      updatedPayload = {
        ...updatedPayload,
        other: {
          otherOffersTab: value,
        },
      };
    } else {
      updatedPayload = {
        ...editPromoPayload,
        [name]: value,
      };
    }

    dispatch(
      setEditPromotionPayload({
        promoId: promotionId,
        payload: updatedPayload,
      }),
    );
    setEditPromoPayload(updatedPayload);
  };

  const onRangeChange = async (
    dates: null | (Dayjs | null)[],
    dateStrings: string[],
  ) => {
    if (dates) {
      // dispatch(
      //   getPromoDuration({
      //     startDate: dateStrings[0],
      //     endDate: dateStrings[1],
      //   }),
      // );
      dispatch(loadingActions.showLoader());
      const payload = {
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      };
      try {
        const { data }: any = await getPromotionDurationApi(payload, true);
        // const lastWeekIndex = data?.length - 1;
        dispatch(loadingActions.hideLoader());
        const newVal = {
          startDate: dateStrings[0],
          endDate: dateStrings[1],
          weekStart: data?.weekStart,
          weekEnd: data?.weekEnd,
          holidays: data?.holidays,
          // weekStart: String(data[0]?.week_of_year),
          // weekEnd: String(data[lastWeekIndex]?.week_of_year),
          // holidays: data?.map((day: any) => day.holiday_name).filter(Boolean),
        };
        setPromoActivityDuration(newVal);
        const updatedPayload = {
          ...editPromoPayload,
          startDate: dateStrings[0],
          endDate: dateStrings[1],
          dimensions: {
            ...editPromoPayload?.dimensions,
            weekStart: data?.weekStart,
            weekEnd: data?.weekEnd,
            holidays: data?.holidays,
          },
        };
        setEditPromoPayload(updatedPayload);
        dispatch(
          setEditPromotionPayload({
            promoId: promotionId,
            payload: updatedPayload,
          }),
        );
      } catch (error) {
        dispatch(loadingActions.hideLoader());
        const { response } = error;
        const { data } = response?.data;
        if (data.statusCode > 300) {
          dispatch(
            toastMessageActions.showToastMessage({
              type: 'error',
              content: data?.message || 'Something went wrong!',
            }),
          );
        } else {
          dispatch(
            toastMessageActions.showToastMessage({
              type: 'error',
              content: data?.message || 'Something went wrong!',
            }),
          );
        }
      }
    } else {
    }
  };

  const onCheck: any = (checkedKeysValue: React.Key[], info: any) => {
    if (info.checked) {
      let updatedPayload = {
        ...editPromoPayload,
      };

      if (hierarchyModalType === 'products') {
        updatedPayload = {
          ...editPromoPayload,

          dimensions: {
            ...editPromoPayload?.dimensions,
            products: [
              {
                level: defaultLevelValue || products?.[0]?.level,
                selections: ['all'],
              },
            ],
          },
        };
        if (info.node.key !== 'all' && checkedKeysValue.length) {
          if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
            updatedPayload = {
              ...editPromoPayload,

              dimensions: {
                ...editPromoPayload?.dimensions,

                products: [
                  {
                    level: defaultLevelValue || products?.[0]?.level,
                    selections: ['all'],
                  },
                ],
              },
            };
          } else {
            const selectedObj = info.checkedNodes.filter((obj: any) =>
              obj.hasOwnProperty('selectionID'),
            );

            updatedPayload = {
              ...editPromoPayload,

              dimensions: {
                ...editPromoPayload?.dimensions,

                products: [
                  {
                    level: defaultLevelValue || products?.[0]?.level,
                    // (editPromoPayload &&
                    //   editPromoPayload?.dimensions?.products?.length &&
                    //   editPromoPayload?.dimensions?.products[0]?.level) ||
                    // defaultLevelValue,
                    selections: selectedObj,
                  },
                ],
              },
            };
          }
        }
        setEditPromoPayload(updatedPayload);
        dispatch(
          setEditPromotionPayload({
            promoId: promotionId,
            payload: updatedPayload,
          }),
        );
        dispatch(updatePromotion(updatedPayload, promotionId));
      } else if (hierarchyModalType === 'geo') {
        updatedPayload = {
          ...editPromoPayload,
          dimensions: {
            ...editPromoPayload?.dimensions,
            geos: ['all'],
          },
        };

        if (info.node.key !== 'all' && checkedKeysValue.length) {
          if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
            updatedPayload = {
              ...editPromoPayload,
              dimensions: {
                ...editPromoPayload?.dimensions,
                geos: ['all'],
              },
            };
          } else {
            updatedPayload = {
              ...editPromoPayload,
              dimensions: {
                ...editPromoPayload?.dimensions,
                geos: getLevelWiseSelections(
                  removeChildrenKey(info.checkedNodes),
                ),
              },
            };
          }
        }
        setEditPromoPayload(updatedPayload);
        dispatch(
          setEditPromotionPayload({
            promoId: promotionId,
            payload: updatedPayload,
          }),
        );
        dispatch(updatePromotion(updatedPayload, promotionId));
      } else if (hierarchyModalType === 'channel') {
        updatedPayload = {
          ...editPromoPayload,
          dimensions: {
            ...editPromoPayload?.dimensions,
            channels: ['all'],
          },
        };

        if (info.node.key !== 'all' && checkedKeysValue.length) {
          if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
            updatedPayload = {
              ...editPromoPayload,
              dimensions: {
                ...editPromoPayload?.dimensions,
                channels: ['all'],
              },
            };
          } else {
            updatedPayload = {
              ...editPromoPayload,
              dimensions: {
                ...editPromoPayload?.dimensions,
                channels: getLevelWiseSelections(
                  removeChildrenKey(info.checkedNodes),
                ),
              },
            };
          }
        }
        setEditPromoPayload(updatedPayload);
        dispatch(
          setEditPromotionPayload({
            promoId: promotionId,
            payload: updatedPayload,
          }),
        );
        dispatch(updatePromotion(updatedPayload, promotionId));
      }
    } else {
      let updatedPayload = {
        ...editPromoPayload,
      };
      if (hierarchyModalType === 'products') {
        if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
          updatedPayload = {
            ...editPromoPayload,

            dimensions: {
              ...editPromoPayload?.dimensions,

              products: [
                {
                  level: defaultLevelValue || products?.[0]?.level,
                  selections: ['all'],
                },
              ],
            },
          };
        } else {
          const selectedObj = info?.checkedNodes?.filter((obj: any) =>
            obj.hasOwnProperty('selectionID'),
          );
          updatedPayload = {
            ...editPromoPayload,

            dimensions: {
              ...editPromoPayload?.dimensions,

              ...(selectedObj.length > 0
                ? {
                    products: [
                      {
                        level: defaultLevelValue || products?.[0]?.level,
                        selections: selectedObj,
                      },
                    ],
                  }
                : {
                    products: [],
                  }),
            },
          };
        }
      } else if (hierarchyModalType === 'geo') {
        const checkLength = getLevelWiseSelections(
          removeChildrenKey(info.checkedNodes),
        ).length;
        updatedPayload = {
          ...editPromoPayload,

          dimensions: {
            ...editPromoPayload?.dimensions,
            ...(checkLength > 0
              ? {
                  geos: getLevelWiseSelections(
                    removeChildrenKey(info.checkedNodes),
                  ),
                }
              : { geos: [] }),
          },
        };
      } else if (hierarchyModalType === 'channel') {
        const checkLength = getLevelWiseSelections(
          removeChildrenKey(info.checkedNodes),
        ).length;
        updatedPayload = {
          ...editPromoPayload,

          dimensions: {
            ...editPromoPayload?.dimensions,
            ...(checkLength > 0
              ? {
                  channels: getLevelWiseSelections(
                    removeChildrenKey(info.checkedNodes),
                  ),
                }
              : { channels: [] }),
          },
        };
      }
      setEditPromoPayload(updatedPayload);
      dispatch(
        setEditPromotionPayload({
          promoId: promotionId,
          payload: updatedPayload,
        }),
      );
      dispatch(updatePromotion(updatedPayload, promotionId));
      // else if (!checkedKeysValue.length) {
      //   dispatch(
      //     toastMessageActions.showToastMessage({
      //       type: 'error',
      //       content: 'Select atleast one Product',
      //     }),
      //   );
      // }
    }

    if (hierarchyModalType === 'products') {
      setCheckedKeys(checkedKeysValue);
    } else if (hierarchyModalType === 'geo') {
      setGeoCheckedKeys(checkedKeysValue);
    } else if (hierarchyModalType === 'channel') {
      setChannelCheckedKeys(checkedKeysValue);
    }

    if (checkedKeysValue.length == 0) {
      setTimeout(() => {
        handleHierarchyCancel();
        // message.error('Please select level first');
      }, 1500);
      return;
    }
  };

  const getPromotionDurationWeeks = async (
    startWeek?: string,
    endWeek?: string,
  ) => {
    const startWeekValue = startWeek || promoActivityDuration?.weekStart;
    const endWeekValue = endWeek || promoActivityDuration?.weekEnd;

    if (+endWeekValue >= +startWeekValue) {
      dispatch(loadingActions.showLoader());
      const payload = {
        startWeek: startWeekValue,
        startYear: new Date().getFullYear(),
        endWeek: endWeekValue,
        endYear: new Date().getFullYear(),
      };
      try {
        const { data }: any = await getPromotionDurationWeeksApi(payload);
        dispatch(loadingActions.hideLoader());

        const newVal = {
          startDate: data.dateStart,
          endDate: data.dateEnd,
          weekStart: startWeekValue,
          weekEnd: endWeekValue,
          holidays: data?.holidays,
        };
        setPromoActivityDuration(newVal);
        const updatedPayload = {
          ...editPromoPayload,
          startDate: data.dateStart,
          endDate: data.dateEnd,
          dimensions: {
            ...editPromoPayload?.dimensions,
            weekStart: startWeekValue,
            weekEnd: endWeekValue,
            holidays: data?.holidays,
          },
        };
        setEditPromoPayload(updatedPayload);
        dispatch(
          setEditPromotionPayload({
            promoId: promotionId,
            payload: updatedPayload,
          }),
        );
      } catch (error) {
        dispatch(loadingActions.hideLoader());
        const { response } = error;
        const { data } = response?.data;
        if (data.statusCode > 300) {
          dispatch(
            toastMessageActions.showToastMessage({
              type: 'error',
              content: data?.message || 'Something went wrong!',
            }),
          );
        } else {
          dispatch(
            toastMessageActions.showToastMessage({
              type: 'error',
              content: data?.message || 'Something went wrong!',
            }),
          );
        }
      }
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: 'End week is less than start week',
        }),
      );
    }
  };

  const onChangeSelectStartWeek = (value: string) => {
    setPromoActivityDuration({
      ...promoActivityDuration,
      weekStart: value,
    });
    getPromotionDurationWeeks(value);
  };

  const onChangeSelectEndWeek = (value: string) => {
    setPromoActivityDuration({
      ...promoActivityDuration,
      weekEnd: value,
    });
    getPromotionDurationWeeks(undefined, value);
  };

  const filterOptionStartWeek = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const filterOptionEndWeek = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (isHierarchy) {
      dispatch(getProdcutTree(products?.[0]?.level));
      showHideHierarchyModal('products');
    }
  }, [isHierarchy]);

  useEffect(() => {
    if (isListing) {
      handleSelectionDetailModal();
    }
  }, [isListing]);

  useEffect(() => {
    if (isCalculations) {
      showProductDetailModal();
    }
  }, [isCalculations]);

  const handleSaveChanges = () => {
    // mixpanel?.track('Promotion Saved', {
    //   promoCode: editPromotionData.promoCode,
    // });
    // mixpanel?.identify(user.email);
    if (editPromoPayloadGState.promoId) {
      dispatch(
        updatePromotion(
          editPromoPayloadGState.payload,
          editPromoPayloadGState.promoId,
        ),
      );
      setChanged(false);
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: 'Promotion updated successfully',
        }),
      );
    }
  };

  const handleProductData = () => {
    // if (isViewOnly) {
    //   return;
    // }
    if (products?.length > 0) {
      dispatch(getProdcutTree(products?.[0]?.level));
      showHideHierarchyModal('products');
    } else {
      message.error('Please select level first');
    }
  };

  useEffect(() => {
    if (!isFirstRender.current && outletKeysLength !== null) {
      const updatedPayload = {
        ...editPromoPayload,
        dimensions: {
          ...editPromoPayload?.dimensions,
          outlets:
            outletKeys.length === outlets.length ? ['all'] : [...outletKeys],
        },
      };

      setEditPromoPayload(updatedPayload);
      dispatch(
        setEditPromotionPayload({
          promoId: promotionId,
          payload: updatedPayload,
        }),
      );
      if (!isFinanceManager) {
        dispatch(updatePromotion(updatedPayload, promotionId));
      }
    } else {
      isFirstRender.current = false;
    }
  }, [outletKeysLength]);

  function formatNumber(n: number): string {
    if (n >= 1000000000) {
      return (n / 1000000000).toFixed(1) + 'B';
    } else if (n >= 1000000) {
      return (n / 1000000).toFixed(1) + 'M';
    } else if (n >= 10000) {
      return (n / 1000).toFixed(1) + 'K';
    } else {
      return n.toLocaleString();
    }
  }

  function handlePageChange() {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 100);
  }

  interface DataType {
    key: React.Key;
    product: string;
    baseline: string;
    liftPercentage: string;
    pricing: string;
    grammage: string;
    gpt: string;
    discount: string;
    incVolume: string;
    totalVolume: string;
    spend: string;
    profit: string;
    roi: string;
    childProducts?: any;
    name?: any;
    productID?: any;
    id?: any;
  }

  const columnsParent: ColumnsType<DataType> = [
    {
      title: 'Product(s)',
      dataIndex: 'product',
      key: 'product',
      className: 'w-[17%]',
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Baseline',
      dataIndex: 'baseline',
      key: 'baseline',
      align: 'right',
      render(value, record, index) {
        return formatNumber(Math.floor(Number(value)));
      },
      className: 'w-[7.5%]',
    },
    {
      title: 'Inc. Volume',
      dataIndex: 'incVolume',
      key: 'incVolume',
      align: 'right',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
      className: 'w-[8.5%]',
    },
    {
      title: 'Total Volume',
      dataIndex: 'totalVolume',
      key: 'totalVolume',
      align: 'right',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
      className: 'w-[11%]',
    },
    {
      title: 'Lift (%)',
      dataIndex: 'liftPercentage',
      key: 'liftPercentage',
      align: 'right',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
      className: 'w-[7.2%]',
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      align: 'right',
      className: 'w-[5.5%]',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
    },
    {
      title: 'Spend',
      dataIndex: 'spend',
      key: 'spend',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
      align: 'right',
      className: 'w-[7%]',
    },
    {
      title: 'ROI ( % )',
      dataIndex: 'roi',
      key: 'roi',
      className: 'roi-perc w-[7.2%]',

      render(value, record, index) {
        return (
          <span
            className={`${Number(record.roi) > 0 ? 'roi-green' : 'roi-red'}`}
          >
            {formatNumberWithCommasNew(record.roi)}%
          </span>
        );
      },
      align: 'right',
    },

    {
      title: 'Pricing',
      dataIndex: 'pricing',
      key: 'pricing',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
      align: 'right',
      className: 'w-[6.5%]',
    },
    {
      title: 'Grammage',
      dataIndex: 'grammage',
      key: 'grammage',
      align: 'right',
      className: 'w-[9%]',
    },
    {
      title: 'GPT',
      dataIndex: 'gpt',
      key: 'gpt',
      className: 'w-[5.5%]',
      align: 'right',
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      render(value, record, index) {
        return (
          <div>
            <span className="child-title-discount">
              {' '}
              {formatNumber(Math.floor(Number(record.discount)))}
            </span>

            <span className="child-sub-title">
              {summaryObject?.promoData?.discountType?.id == 6
                ? 'Amount Off'
                : 'Percent Off'}
              {''}
            </span>
          </div>
        );
      },
      align: 'right',
      className: 'w-[8.2%]',
    },
  ];
  const columnsChild: ColumnsType<DataType> = [
    {
      title: 'Product(s)',
      dataIndex: 'product',
      key: 'product',
      render(value, record, index) {
        return (
          <Tooltip placement="topLeft" title={record.name}>
            {record.product}
          </Tooltip>
        );
      },
      className: 'w-[18%]',
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Baseline',
      dataIndex: 'baseline',
      key: 'baseline',
      className: 'w-[8%]',
      align: 'right',
      render(value, record, index) {
        if (value == 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
    },
    {
      title: 'Inc. Volume',
      dataIndex: 'incVolume',
      key: 'incVolume',
      className: 'w-[8.3%]',
      align: 'right',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
    },
    {
      title: 'Total Volume',
      dataIndex: 'totalVolume',
      key: 'totalVolume',
      className: 'w-[11%]',
      align: 'right',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
    },
    {
      title: 'Lift (%)',
      dataIndex: 'liftPercentage',
      key: 'liftPercentage',
      className: 'w-[7.0%]',
      align: 'right',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      className: 'w-[5.3%]',
      align: 'right',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
    },
    {
      title: 'Spend',
      dataIndex: 'spend',
      key: 'spend',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
      className: 'w-[7%]',
      align: 'right',
    },
    {
      title: 'ROI',
      dataIndex: 'roi',
      key: 'roi',
      className: 'roi-perc w-[7%]',
      render(value, record, index) {
        return (
          <span
            className={`${Number(record.roi) > 0 ? 'roi-green' : 'roi-red'}`}
          >
            {formatNumberWithCommasNew(record.roi)}%
          </span>
        );
      },
      align: 'right',
    },

    {
      title: 'Pricing',
      dataIndex: 'pricing',
      key: 'pricing',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
      className: 'w-[6.5%]',
      align: 'right',
    },
    {
      title: 'Grammage',
      dataIndex: 'grammage',
      key: 'grammage',
      className: 'w-[9%]',
      align: 'right',
      render(value, record, index) {
        // If the numeric value is 0, return '-' directly
        if (value < 1) {
          return '-';
        }

        // Otherwise, apply Math.floor and format the result
        return formatNumber(Math.floor(value));
      },
    },
    {
      title: 'GPT',
      dataIndex: 'gpt',
      key: 'gpt',
      className: 'w-[5.5%]',
      align: 'right',
      render(value, record, index) {
        if (value === 0) {
          return '-';
        }
        return formatNumber(Math.floor(Number(value)));
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      render(value, record, index) {
        return '-';
      },
      className: 'w-[8.2%]',
      align: 'right',
    },
  ];

  let dataParent: DataType[] = [];

  dataParent = summaryObject?.selectedProductsSegregated?.map(
    (elem: any, index: number) => {
      return {
        key: index,
        product: elem.product_variant,
        baseline: elem.totalBaseline,
        liftPercentage: elem.totalLift,
        pricing: elem.totalCost,
        grammage: '--',
        gpt: '--',
        discount: elem.products[0].discount,
        incVolume: elem.totalIncrementalVolume,
        totalVolume: elem.totalVolume,
        spend: elem.totalCost,
        profit: elem.totalLift,
        roi: elem.totalROI,
        childProducts: elem.products,
      };
    },
  );

  function customExpandIcon(props: any) {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <UpOutlined style={{ color: '#164291', fontSize: '8px' }} />
        </a>
      );
    } else {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <DownOutlined style={{ color: '#164291', fontSize: '8px' }} />
        </a>
      );
    }
  }

  function handleTabClick(tab: number) {
    setActiveTab(tab);
  }

  function convertDateFormat(inputDate: string): string {
    const date = dayjs(inputDate);
    return date.format('MMMM D, YYYY');
  }

  function convertTimeFormat(inputDate: string): string {
    const time = dayjs(inputDate).format('h:mm A');
    return time;
  }

  function getInitials(userr: any): string {
    const firstNameInitial = userr.firstName.charAt(0).toUpperCase();
    const lastNameInitial = userr.lastName.charAt(0).toUpperCase();
    return `${firstNameInitial}${lastNameInitial}`;
  }

  function decideCurrentStep(promoHistoryArr: any): number {
    if (
      ['approved', 'rejected'].includes(
        promoHistoryArr[promoHistoryArr.length - 1].status,
      )
    ) {
      return promoHistoryArr.length;
    } else {
      return promoHistoryArr.length - 1;
    }
  }

  function handleMultiSelectCustomersChange(selectedIds: any, allOptions: any) {
    const selectedObjects = allOptions.filter((option: any) =>
      selectedIds.includes(option.id),
    );
    let updatedPayload = {
      ...editPromoPayload,
      dimensions: {
        ...editPromoPayload?.dimensions,
        customers: selectedObjects || [],
      },
    };
    setEditPromoPayload(updatedPayload);
    dispatch(
      setEditPromotionPayload({
        promoId: promotionId,
        payload: updatedPayload,
      }),
    );
    dispatch(getGeoTree(selectedObjects || []));
    let payload = {
      dimensions: {
        products: [
          {
            level: 'Product Size',
            selections: ['all'],
          },
        ],
        geos: ['all'],
        channels: ['all'],
        outlets: ['all'],
        customers: selectedObjects || [],
        promoType: updatedPayload?.dimensions?.promoType,
      },
    };
    dispatch(getTreeSelectionCount(payload));
  }

  const handleButtonClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    handleSaveChanges();
  };

  const handleMenuClick: MenuProps['onClick'] = async (e) => {};

  const items: MenuProps['items'] = [
    {
      label: (
        <span
          style={{
            fontWeight: 'bold',
            color: '#164291',
          }}
        >
          Save as template
        </span>
      ),
      key: '1',
      onClick: () => {
        form.submit();
        handleSaveAsTemplate();
        // mixpanel?.track('Save as template click', {
        //   promoCode: editPromotionData.promoCode,
        // });
        // mixpanel?.identify(user.email);
      },
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const submitPromo = async () => {
    dispatch(setTriggerSubmit(true));
  };

  return (
    <Row gutter={16}>
      <Col span={isCreate ? 24 : 17}>
        <div className="content-body ut-card">
          <Row justify="space-between" align="middle">
            {!isCreate && (
              <Col>
                <Breadcrumb
                  items={[
                    {
                      title: (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          href=""
                          className="ut-fs-xs ut-color-twelve"
                          style={{ pointerEvents: 'none' }}
                        >
                          TPM
                        </a>
                      ),
                    },
                    {
                      title: (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          href=""
                          className="ut-fs-xs ut-color-eleven"
                          style={{ pointerEvents: 'none' }}
                        >
                          Promotion approval
                        </a>
                      ),
                    },
                    // {
                    //   title: (
                    //     // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    //     <a href="" className="ut-fs-xs ut-color-two">
                    //       Promotion approval
                    //     </a>
                    //   ),
                    // },
                    {
                      title: (
                        <span className="ut-fs-xs ut-color-five">
                          {!singleObj.promoCode ? (
                            <Skeleton.Input
                              style={{ width: 50, height: 18 }}
                              active={true}
                              size={'small'}
                            />
                          ) : (
                            <>{editPromotionData?.promoCode}</>
                          )}
                        </span>
                      ),
                    },
                  ]}
                />
              </Col>
            )}
            <Col>
              {singleObj?.status && (
                <span
                  className={`status-bar relative capitalize ${singleObj?.status}`}
                  style={{ top: '-8px' }}
                >
                  {singleObj?.status}
                </span>
              )}
            </Col>
          </Row>
          {!isCreate && (
            <div className="ut-form" style={{ height: 40, marginTop: 8 }}>
              {!singleObj.promoName ? (
                <Skeleton.Input
                  style={{ width: '100%' }}
                  active={true}
                  size={'default'}
                />
              ) : (
                <>
                  {!isEditingHeading && (
                    <h1
                      className="ut-color-tw promo-editable-heading promo-editable-heading-main"
                      onClick={handleClickHeading}
                    >
                      {(editPromoPayload &&
                        capitalizeFirstLetterOfEachWord(
                          editPromoPayload?.promoName || '',
                        )) ||
                        capitalizeFirstLetterOfEachWord(
                          editPromotionData?.promoName || '',
                        )}
                    </h1>
                  )}
                </>
              )}
              {isEditingHeading && (
                <div className="edit-container">
                  <Button
                    type="primary"
                    className="position-bottom"
                    onClick={() => setIsEditingHeading(false)}
                  >
                    <CheckOutlined />
                  </Button>
                  <Input
                    className="focable"
                    ref={headingInputRef}
                    defaultValue={
                      (editPromoPayload && editPromoPayload?.promoName) ||
                      editPromotionData.promoName
                    }
                    onChange={(e: any) => handleInputChange(e, 'promoName')}
                    onBlur={() => setIsEditingHeading(false)}
                    onFocus={() => setIsEditingDisruption(false)}
                  />
                </div>
              )}
            </div>
          )}
          <div className="my-4 hidden">
            <Button type="primary" className="mr-2 ut-btn">
              Overview
            </Button>
            <Button type="primary" className="mr-2 ut-btn light-btn">
              History
            </Button>
            <Button type="primary" className="mr-2 ut-btn grey-btn">
              Detail
            </Button>
            <Button type="primary" className="mr-2 ut-btn grey-btn">
              Insights
            </Button>
            <Button type="primary" className="mr-2 ut-btn grey-btn">
              Comments
            </Button>
            <div className="ut-mt-xl"></div>
          </div>
          {!isCreate && (
            <>
              <h5 className="text-italic mb-4 mt-4 description-heading">
                Description
              </h5>
              <div
                className="ut-form"
                style={{ minHeight: 50, marginTop: 14, marginBottom: 14 }}
              >
                {!singleObj.description ? (
                  <Skeleton.Input
                    style={{ width: '100%' }}
                    active={true}
                    size={'default'}
                  />
                ) : (
                  <>
                    {!isEditingDisruption && (
                      <p
                        className="ut-color-three promo-editable-heading ut-fs-xs"
                        onClick={handleClickDisruption}
                      >
                        {(editPromoPayload && editPromoPayload?.description) ||
                          editPromotionData.description}
                      </p>
                    )}
                  </>
                )}
                {isEditingDisruption && (
                  <div className="edit-container">
                    <Button
                      type="primary"
                      className="position-top"
                      onClick={() => setIsEditingDisruption(false)}
                    >
                      <CheckOutlined />
                    </Button>
                    <TextArea
                      className="focable"
                      ref={descriptionInputRef}
                      defaultValue={
                        (editPromoPayload && editPromoPayload?.description) ||
                        editPromotionData.description
                      }
                      onChange={(e: any) => handleInputChange(e, 'description')}
                      onBlur={() => setIsEditingDisruption(false)}
                      tabIndex={0}
                      onFocus={() => setIsEditingHeading(false)}
                      showCount
                      maxLength={500}
                      style={{ height: 50, marginBottom: 24 }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {['approved', 'rejected', 'submitted'].includes(
            singleObj?.status,
          ) && (
            <div className="detailed-summary-container">
              <div className="tag-parent">
                <Tag
                  bordered={false}
                  className={`${
                    activeTab == 1 ? 'active' : ''
                  } w-[100px] text-center !bg-[#F4F4F4] cursor-pointer`}
                  onClick={() => handleTabClick(1)}
                >
                  Detail
                </Tag>
                <Tag
                  bordered={false}
                  className={`${
                    activeTab == 2 ? 'active ms-2' : 'ms-2'
                  } w-[100px] text-center !bg-[#F4F4F4] cursor-pointer`}
                  onClick={() => handleTabClick(2)}
                >
                  History
                </Tag>
                {/* <Tag
                  bordered={false}
                  className={`${
                    activeTab == 2 ? 'active ms-2' : 'ms-2'
                  } w-[100px] text-center !bg-[#F4F4F4] cursor-pointer`}
                  onClick={() => handleTabClick(2)}
                >
                  Comments
                </Tag> */}
              </div>
              {activeTab == 1 && (
                <Table
                  className="detail-view-table"
                  rowClassName={(record, index) =>
                    index > 0 ? 'table-row-dark' : 'table-row-orange'
                  }
                  size="middle"
                  columns={columnsParent}
                  pagination={{ pageSize: 25, onChange: handlePageChange }}
                  expandable={{
                    expandedRowRender: (rr) => (
                      <Table
                        columns={columnsChild}
                        dataSource={rr.childProducts.map(
                          (elem: any, index: number) => {
                            return {
                              key: index,
                              product: elem.productID,
                              baseline: elem.baseline,
                              liftPercentage: elem.lift,
                              pricing: elem.pricing,
                              grammage: elem.grammage,
                              gpt: elem.gpt,
                              discount: elem.discount,
                              incVolume: elem.incrementalVolume,
                              totalVolume: elem.volume,
                              spend: elem.cost,
                              profit: elem.lift,
                              roi: elem.roi,
                              name: elem.name,
                            };
                          },
                        )}
                        showHeader={false}
                        bordered
                        pagination={false}
                        className="detail-view-child-table"
                        rowClassName={(r, index) => 'table-child-row'}
                      />
                    ),
                  }}
                  dataSource={dataParent}
                  expandIcon={(props) => customExpandIcon(props)}
                />
              )}
              {activeTab == 2 && (
                <>
                  <div className="stepper-parent">
                    <div className="ticks w-[20%]">
                      <Steps
                        direction="vertical"
                        current={decideCurrentStep(promoHistory)}
                        items={promoHistory.map((elem: any) => {
                          return {
                            title: convertDateFormat(elem.createdAt),
                            description: convertTimeFormat(elem.createdAt),
                            icon: <img src={StepTickIcon} />,
                          };
                        })}
                      />
                    </div>
                    <div className="cards w-[20%]">
                      {promoHistory.map((elem: any) => {
                        return (
                          <div className="user">
                            {/* <div className="pic">
                              <Avatar src={''} className="m-1" size={'large'}>
                                {getInitials(elem.user)}
                              </Avatar>
                            </div> */}
                            <div className="name-role">
                              <div className="name">{`${elem.user.firstName} ${elem.user.lastName}`}</div>
                              <div className="role">{elem.user.role}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="cards w-[10%]">
                      {promoHistory.map((record: any) => {
                        return (
                          <div className="status">
                            <Space size="middle">
                              {record.status.toLowerCase() ==
                                'Draft'.toLowerCase() && (
                                <Tag
                                  bordered={false}
                                  color="purple"
                                  style={{ fontSize: 12 }}
                                >
                                  {capitalizeFirstLetter(record.status)}
                                </Tag>
                              )}
                              {record.status.toLowerCase() ==
                                'Submitted'.toLowerCase() && (
                                <Tag
                                  bordered={false}
                                  color="yellow"
                                  style={{ fontSize: 12 }}
                                >
                                  {capitalizeFirstLetter(record.status)}
                                </Tag>
                              )}
                              {record.status.toLowerCase() ==
                                'Approved'.toLowerCase() && (
                                <Tag
                                  bordered={false}
                                  color="green"
                                  style={{ fontSize: 12 }}
                                >
                                  {capitalizeFirstLetter(record.status)}
                                </Tag>
                              )}
                              {record.status.toLowerCase() ==
                                'Rejected'.toLowerCase() && (
                                <Tag
                                  bordered={false}
                                  color="red"
                                  style={{ fontSize: 12 }}
                                >
                                  {capitalizeFirstLetter(record.status)}
                                </Tag>
                              )}
                              {record.status.toLowerCase() ==
                                'sentback'.toLowerCase() && (
                                <Tag
                                  bordered={false}
                                  color="orange"
                                  style={{ fontSize: 12 }}
                                >
                                  {capitalizeFirstLetter(record.status)}
                                </Tag>
                              )}
                            </Space>
                          </div>
                        );
                      })}
                    </div>
                    <div className="cards w-[50%]">
                      {promoHistory.map((elem: any) => {
                        return (
                          <div className="description overflow-ellipsis">
                            <Tooltip
                              title={elem.comment || 'No comment'}
                              placement="topLeft"
                              color="#164291"
                            >
                              <span>{elem.comment || 'No comment'}</span>
                            </Tooltip>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {['sentback', 'draft'].includes(singleObj?.status) && (
            <Form
              name="form_item_path"
              layout="vertical"
              form={form}
              className="editview-form"
            >
              <Collapse
                className="ut-collapse"
                collapsible="header"
                defaultActiveKey={['1']}
                items={undefined}
                expandIcon={({ isActive }) => (
                  <span
                    style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}
                  >
                    <IconDownOutlined />
                  </span>
                )}
                expandIconPosition={'end'}
              >
                <Panel
                  header="Basic Information"
                  key="1"
                  className="ut-collapse"
                >
                  <MyFormItemGroup prefix={['basicInformation']}>
                    <Row gutter={16}>
                      {isCreate && (
                        <>
                          <Col className="gutter-row" span={12}>
                            <MyFormItem
                              className="ut-form"
                              name="promoName"
                              label="Promo Name"
                              rules={[
                                {
                                  required: true,
                                  message: REQUIRED_FEILD,
                                },
                              ]}
                            >
                              <Input
                                onBlur={(e: any) =>
                                  handleInputChange(e, 'promoName')
                                }
                              />
                            </MyFormItem>
                          </Col>
                          <Col className="gutter-row" span={12}>
                            <MyFormItem
                              className="ut-form"
                              name="description"
                              label="Description"
                              rules={[
                                {
                                  required: true,
                                  message: 'Field is required',
                                },
                              ]}
                            >
                              <Input
                                onBlur={(e: any) =>
                                  handleInputChange(e, 'description')
                                }
                              />
                            </MyFormItem>
                          </Col>
                        </>
                      )}
                      <Col className="gutter-row" span={12}>
                        <MyFormItem
                          className="ut-form"
                          name="uptradePromoCode"
                          label="Promotion ID"
                        >
                          <Input
                            defaultValue={editPromotionData.promoCode}
                            disabled={!isCreate ? true : false}
                          />
                        </MyFormItem>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <MyFormItem
                          className="ut-form"
                          name="ReferenceCode"
                          label="Reference Code"
                          rules={[
                            {
                              min: 5,
                              message: 'Code must be equal or more than 5',
                            },
                          ]}
                        >
                          <Input
                            defaultValue={editPromotionData.promoCodeCompany}
                            onBlur={(e: any) =>
                              handleInputChange(e, 'promoCodeCompany')
                            }
                            disabled={isViewOnly}
                          />
                        </MyFormItem>
                      </Col>
                      <Col className="gutter-row ut-form" span={12}>
                        <MyFormItem
                          name="programScheme"
                          label="Program / Scheme"
                        >
                          {promoSchemes.length < 1 || !singleObj ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              disabled={isViewOnly}
                              className="ut-select"
                              suffixIcon={<CaretDownFilled />}
                              defaultValue={formateSchemeVal(
                                scheme || {
                                  id: '00',
                                  name: 'Please select',
                                },
                              )}
                              onChange={(e: any) => handleChange(e, 'scheme')}
                              options={promoSchemes?.map((schem: any) => {
                                return formateSchemeVal(schem);
                              })}
                            />
                          )}
                        </MyFormItem>
                      </Col>
                    </Row>
                  </MyFormItemGroup>
                </Panel>
              </Collapse>
              <Collapse
                collapsible="header"
                className="ut-collapse mt-4"
                defaultActiveKey={['1']}
                items={undefined}
                expandIcon={({ isActive }) => (
                  <span
                    style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}
                  >
                    <IconDownOutlined />
                  </span>
                )}
                expandIconPosition={'end'}
              >
                <Panel header="Duration" key="1">
                  <MyFormItemGroup prefix={['duration']}>
                    <Row gutter={16}>
                      <Col className="gutter-row" span={12}>
                        <Row>
                          <Col span={8} className="ut-mb-s">
                            <span className="ut-label">Start and End Date</span>
                          </Col>
                        </Row>
                        <Row className="ut-mb-m ut-form">
                          {!isCreate &&
                          (!singleObj ||
                            !promoActivityDuration?.startDate ||
                            !promoActivityDuration?.endDate) ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <DatePicker.RangePicker
                              onChange={onRangeChange}
                              defaultValue={[
                                dayjs(
                                  promoActivityDuration?.startDate,
                                  'YYYY-MM-DD',
                                ),
                                dayjs(
                                  promoActivityDuration?.endDate,
                                  'YYYY-MM-DD',
                                ),
                              ]}
                              value={[
                                dayjs(
                                  promoActivityDuration?.startDate,
                                  'YYYY-MM-DD',
                                ),
                                dayjs(
                                  promoActivityDuration?.endDate,
                                  'YYYY-MM-DD',
                                ),
                              ]}
                              format={'YYYY-MM-DD'}
                              className="w-full"
                              disabled={isViewOnly}
                            />
                          )}
                        </Row>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <MyFormItem
                          name="ActivityDuration"
                          label="Activity Duration"
                          className="ut-form"
                        >
                          {!isCreate && !singleObj ? (
                            <Skeleton.Input
                              style={{ width: '100%', marginTop: '3px' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Input
                              defaultValue={
                                singleObj?.promoData?.promotionDuration
                              }
                              disabled
                              style={{ marginTop: '3px' }}
                            />
                          )}
                        </MyFormItem>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Row>
                          <Col span={8} className="ut-mb-s">
                            <span className="ut-label">Start Week</span>
                          </Col>
                        </Row>
                        <Row>
                          {!isCreate &&
                          (!singleObj || !promoActivityDuration?.weekStart) ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              showSearch
                              className="ut-select"
                              placeholder="Select a week"
                              optionFilterProp="children"
                              suffixIcon={<CaretDownFilled />}
                              options={promoDurationWeeksValue}
                              onChange={onChangeSelectStartWeek}
                              filterOption={filterOptionStartWeek}
                              value={promoActivityDuration?.weekStart}
                              defaultValue={promoActivityDuration?.weekStart}
                              disabled={isViewOnly}
                            />
                          )}
                        </Row>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Row>
                          <Col span={8} className="ut-mb-s">
                            <span className="ut-label">End Week</span>
                          </Col>
                        </Row>
                        <Row>
                          {!isCreate &&
                          (!singleObj || !promoActivityDuration?.weekEnd) ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              showSearch
                              className="ut-select"
                              placeholder="Select a week"
                              optionFilterProp="children"
                              suffixIcon={<CaretDownFilled />}
                              onChange={onChangeSelectEndWeek}
                              options={promoDurationWeeksValue}
                              filterOption={filterOptionEndWeek}
                              value={promoActivityDuration?.weekEnd}
                              defaultValue={promoActivityDuration?.weekEnd}
                              disabled={isViewOnly}
                            />
                          )}
                        </Row>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <MyFormItem
                          name="Holidays"
                          label="Holidays"
                          className="ut-form"
                        >
                          <p className="ut-fs-xs ut-fw-300 ut-color-six">
                            {uniqueHolidays?.map((day: any, index: any) => {
                              if (uniqueHolidays?.length - 1 == index) {
                                return (
                                  <span key={`${day}__${Math.random() * 5}`}>
                                    {day}
                                  </span>
                                );
                              } else {
                                return (
                                  <span key={`${day}__${Math.random() * 5}`}>
                                    {day},{' '}
                                  </span>
                                );
                              }
                            })}{' '}
                            {/* {editPromotionData?.promoData?.holidays &&
                            editPromotionData?.promoData?.holidays?.map(
                              (day: string) => <span>{day}, </span>,
                            )}{' '} */}
                            {uniqueHolidays?.length < 1 && (
                              <span>
                                No holidays found within the selected range!
                              </span>
                            )}
                          </p>
                        </MyFormItem>
                      </Col>
                    </Row>
                  </MyFormItemGroup>
                </Panel>
              </Collapse>
              <Collapse
                className="ut-collapse mt-4"
                defaultActiveKey={['1']}
                items={undefined}
                expandIcon={({ isActive }) => (
                  <span
                    style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}
                  >
                    <IconDownOutlined />
                  </span>
                )}
                expandIconPosition={'end'}
              >
                <Panel header="Promo Tactics" key="1">
                  <MyFormItemGroup prefix={['PromoTactics']}>
                    <Row gutter={16}>
                      <Col className="gutter-row ut-form" span={8}>
                        <MyFormItem name="PromotionLevel" label="Level">
                          {promoLevels.length < 1 || !singleObj ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              className="ut-select"
                              suffixIcon={<CaretDownFilled />}
                              defaultValue={formateSelectVal(
                                promoLevels.find(
                                  (item: any) =>
                                    item.level_name ===
                                    (products?.[0]?.level ?? 'Please select'),
                                ) || {
                                  id: '00',
                                  name: 'Please select',
                                },
                                'promoLevel',
                              )}
                              //defaultValue={products?.[0]?.level}
                              onChange={(e: any) =>
                                handleChange(e, 'promoLevel')
                              }
                              options={promoLevels?.map((item: any) => {
                                return formateSelectVal(item, 'promoLevel');
                              })}
                              disabled={isViewOnly}
                            />
                          )}
                        </MyFormItem>
                      </Col>
                      <Col className="gutter-row ut-form" span={8}>
                        <MyFormItem name="PromotionType" label="Type">
                          {promoTypes.length < 1 || !singleObj ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              disabled={isViewOnly}
                              className="ut-select"
                              suffixIcon={<CaretDownFilled />}
                              defaultValue={formateSelectVal(
                                promoType || {
                                  id: '00',
                                  name: 'Please select',
                                },
                                'promoType',
                              )}
                              onChange={(e: any) =>
                                handleChange(e, 'promoType')
                              }
                              options={promoTypes?.map((type: any) => {
                                return formateSelectVal(type, 'promoType');
                              })}
                            />
                          )}
                        </MyFormItem>
                      </Col>
                      {isModern && (
                        <Col className="gutter-row ut-form" span={8}>
                          <MyFormItem
                            name="PromotionCustomers"
                            label="Customers"
                            className="multi-customer"
                          >
                            {promoCustomers.length < 1 ? (
                              <Skeleton.Input
                                active={true}
                                size={'default'}
                                className="!w-[100%]"
                              />
                            ) : (
                              <Select
                                allowClear={true}
                                mode="multiple"
                                className="ut-select"
                                suffixIcon={<CaretDownFilled />}
                                onChange={(selectedIds) =>
                                  handleMultiSelectCustomersChange(
                                    selectedIds,
                                    promoCustomers,
                                  )
                                }
                                options={
                                  true
                                    ? promoCustomers?.map((type: any) => {
                                        return formateSelectVal(
                                          type,
                                          'customers',
                                        );
                                      })
                                    : []
                                }
                                disabled={!isModern || isViewOnly}
                                placeholder="Please select customers"
                                defaultValue={
                                  !changed
                                    ? editPromotionData?.promoData?.customers?.map(
                                        (elem: any) => {
                                          return {
                                            value: elem.id,
                                            label: elem.name,
                                          };
                                        },
                                      )
                                    : undefined
                                }
                              />
                            )}
                          </MyFormItem>
                        </Col>
                      )}
                    </Row>
                  </MyFormItemGroup>
                </Panel>
              </Collapse>
              <Collapse
                className="ut-collapse mt-4"
                defaultActiveKey={['1']}
                items={undefined}
                expandIcon={({ isActive }) => (
                  <span
                    style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}
                  >
                    <IconDownOutlined />
                  </span>
                )}
                expandIconPosition={'end'}
              >
                <Panel header="Hierarchy" key="1">
                  <Row
                    gutter={16}
                    className="ut-hierarchy-columns ut-hierarchy-detail-columns"
                  >
                    <Col span={12}>
                      <Col>
                        {!singleObj ? (
                          <div className="ut-boxes products active">
                            <Skeleton.Avatar
                              shape={'square'}
                              style={{ width: '100%', height: 78 }}
                              active={true}
                            />
                          </div>
                        ) : (
                          <div
                            className="ut-boxes products active"
                            onClick={() => {
                              handleProductData();
                            }}
                          >
                            <div className="top">
                              <h6>
                                {singleObj?.selectionsCount?.productsCount}{' '}
                                Products are selected
                              </h6>
                              <span className="label">Products</span>
                            </div>
                            <div className="bottom">
                              {productLevels?.length > 0 &&
                                productLevels.map(
                                  (product: any, index: number) => {
                                    if (index > 1) {
                                      return null;
                                    } else {
                                      return (
                                        <>
                                          <div>
                                            <span>
                                              {splitCamelCase(
                                                product?.level_name,
                                              )}
                                              :{' '}
                                            </span>
                                            {formateHierarchyBoxTagsDynamic(
                                              productNames,
                                              product.level_name
                                                .toLowerCase()
                                                .split(' ')
                                                .join('_'),
                                              true, //isEdit=true to handle edit flow
                                            )}
                                          </div>
                                        </>
                                      );
                                    }
                                  },
                                )}
                            </div>
                          </div>
                        )}
                      </Col>
                    </Col>
                    <Col span={12}>
                      <Col>
                        {' '}
                        {!singleObj ? (
                          <div className="ut-boxes geo active">
                            <Skeleton.Avatar
                              shape={'square'}
                              style={{ width: '100%', height: 78 }}
                              active={true}
                            />
                          </div>
                        ) : (
                          <div
                            className="ut-boxes geo active"
                            onClick={() => {
                              // if (!isViewOnly) {
                              showHideHierarchyModal('geo');
                              // }
                            }}
                          >
                            <div className="top">
                              <h6>
                                {singleObj?.selectionsCount?.geosCount}{' '}
                                Distributors are selected
                              </h6>
                              <span className="label">Geo</span>
                            </div>
                            <div className="bottom">
                              {geoLevels?.length > 0 &&
                                geoLevels.map((geoItem: any, index: number) => {
                                  if (index > 1) {
                                    return null;
                                  } else {
                                    return (
                                      <>
                                        <div>
                                          <span>
                                            {splitCamelCase(
                                              geoItem?.level_name,
                                            )}
                                            :{' '}
                                          </span>
                                          {formateHierarchyBoxTagsDynamic(
                                            geoNames,
                                            geoItem.level_name
                                              .toLowerCase()
                                              .split(' ')
                                              .join('_'),
                                            true,
                                            3, //tags count
                                          )}
                                        </div>
                                      </>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        )}
                      </Col>
                    </Col>
                    {!isModern && (
                      <Col span={12}>
                        <Col>
                          {!singleObj ? (
                            <div className="ut-boxes channel active">
                              <Skeleton.Avatar
                                shape={'square'}
                                style={{ width: '100%', height: 78 }}
                                active={true}
                              />
                            </div>
                          ) : (
                            <div
                              className="ut-boxes channel active"
                              onClick={() => {
                                // if (!isViewOnly) {
                                showHideHierarchyModal('channel');
                                // }
                              }}
                            >
                              <div className="top">
                                <h6>
                                  {singleObj?.selectionsCount?.channelsCount}{' '}
                                  Channels are selected
                                </h6>
                                <span className="label">Channel</span>
                              </div>
                              <div className="bottom">
                                {channelLevels?.length > 0 &&
                                  channelLevels.map(
                                    (channelItem: any, index: number) => {
                                      if (index > 1) {
                                        return null;
                                      } else {
                                        return (
                                          <>
                                            <div>
                                              <span>
                                                {splitCamelCase(
                                                  channelItem?.level_name,
                                                )}
                                                :{' '}
                                              </span>
                                              {formateHierarchyBoxTagsDynamic(
                                                channelNames,
                                                channelItem.level_name
                                                  .toLowerCase()
                                                  .split(' ')
                                                  .join('_'),
                                                true,
                                                2, //tags count
                                              )}
                                            </div>
                                          </>
                                        );
                                      }
                                    },
                                  )}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Col>
                    )}
                    {!isModern && (
                      <Col span={12}>
                        <Col>
                          {!singleObj ? (
                            <div className="ut-boxes channel active">
                              <Skeleton.Avatar
                                shape={'square'}
                                style={{ width: '100%', height: 78 }}
                                active={true}
                              />
                            </div>
                          ) : (
                            <div
                              className="ut-boxes outlet active"
                              onClick={() => {
                                // if (!isViewOnly) {
                                showHideHierarchyModal('outlet');
                                // }
                              }}
                              style={{
                                height: 100,
                              }}
                            >
                              <div className="top">
                                <h6>
                                  {singleObj?.selectionsCount?.outletsCount}{' '}
                                  Outlets are selected
                                </h6>
                                <span className="label">Outlet</span>
                              </div>
                              {outletKeys?.length === 0 &&
                                outletKeys?.length !== outlets.length && (
                                  <div className="bottom">
                                    <div>
                                      <span>Outlets:</span>
                                    </div>
                                  </div>
                                )}
                              {outletKeys?.length === outlets.length && (
                                <div className="bottom">
                                  <div>
                                    <span>Outlets: All</span>
                                  </div>
                                </div>
                              )}
                              {outletKeys?.length >= 1 &&
                                outletKeys?.length !== outlets.length && (
                                  <div className="bottom">
                                    <div>
                                      <span>Outlets:</span>
                                      {outletKeys?.map(
                                        (item: any, index: any) => {
                                          if (index === 0) {
                                            return (
                                              <Tag key={item}>
                                                {
                                                  outlets.find(
                                                    (outlet: any) =>
                                                      outlet.id === item,
                                                  )?.name
                                                }
                                              </Tag>
                                            );
                                          }
                                        },
                                      )}
                                      {outletKeys?.length > 1 && (
                                        <Popover
                                          content={outletKeys?.map(
                                            (item: any, idx: any) => {
                                              if (idx > 0) {
                                                return (
                                                  <Tag
                                                    style={{ marginBottom: 10 }}
                                                  >
                                                    {
                                                      outlets.find(
                                                        (outlet: any) =>
                                                          outlet.id === item,
                                                      )?.name
                                                    }
                                                  </Tag>
                                                );
                                              }
                                            },
                                          )}
                                          trigger="hover"
                                        >
                                          <Tag>
                                            {outletKeys?.length - 1} more
                                          </Tag>
                                        </Popover>
                                      )}
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}
                        </Col>
                      </Col>
                    )}
                  </Row>
                </Panel>
              </Collapse>
              <Collapse
                collapsible="header"
                className="ut-collapse mt-4"
                defaultActiveKey={['1']}
                items={undefined}
                expandIcon={({ isActive }) => (
                  <span
                    style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}
                  >
                    <IconDownOutlined />
                  </span>
                )}
                expandIconPosition={'end'}
              >
                <Panel header="Offer" key="1" className="discount-panel">
                  <MyFormItemGroup prefix={['discount']}>
                    <Row gutter={16}>
                      {/* <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={12}
                      lg={6}
                      xl={6}
                    >
                      <MyFormItem
                        name="buyType"
                        label="Buy Type"
                        className="ut-form full-w"
                      >
                        {promoBuyTypes.length < 1 || !singleObj ? (
                          <Skeleton.Input
                            style={{ width: '100%' }}
                            active={true}
                            size={'default'}
                          />
                        ) : (
                          <Select
                            className="ut-select"
                            suffixIcon={<CaretDownFilled />}
                            defaultValue={formateSelectVal(
                              buyType || { id: '00', name: 'Please select' },
                              'buyType',
                            )}
                            onChange={(e: any) => handleChange(e, 'buyType')}
                            options={promoBuyTypes?.map((buyTyp: any) => {
                              return formateSelectVal(buyTyp, 'buyType');
                            })}
                          />
                        )}
                      </MyFormItem>
                    </Col> */}
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="Level"
                          label="Level"
                          className="ut-form full-w"
                        >
                          {promoBuyTypes.length < 1 || !singleObj ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              className="ut-select"
                              suffixIcon={<CaretDownFilled />}
                              defaultValue={formateSelectVal(
                                buyType || {
                                  id: '00',
                                  name: 'Please select',
                                },
                                'buyType',
                              )}
                              onChange={(e: any) => handleChange(e, 'buyType')}
                              options={promoBuyTypes?.map((buyTyp: any) => {
                                return formateSelectVal(buyTyp, 'buyType');
                              })}
                              disabled={isViewOnly}
                            />
                          )}
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="Type"
                          label="Type"
                          className="ut-form full-w"
                        >
                          {offerTypes.length < 1 || !singleObj ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              disabled={isViewOnly}
                              className="ut-select"
                              suffixIcon={<CaretDownFilled />}
                              defaultValue={formateSelectVal(
                                offerType || {
                                  id: '00',
                                  name: 'Please select',
                                },
                                'offerType',
                              )}
                              onChange={(e: any) =>
                                handleChange(e, 'offerType')
                              }
                              options={offerTypes?.map((offerTyp: any) => {
                                return formateSelectVal(offerTyp, 'offerType');
                              })}
                            />
                          )}
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="BuyFrom"
                          label="Buy From (Minimum Buy)"
                          className="ut-form full-w"
                        >
                          <Input
                            disabled={isViewOnly}
                            defaultValue={editPromotionData.purchaseFrom}
                            onBlur={(e: any) =>
                              handleInputChange(e, 'purchaseFrom')
                            }
                            placeholder="Write minimum buy quantity or amount"
                            type="number"
                          />
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="BuyTo"
                          label="Buy To (Maximum Buy)"
                          className="ut-form full-w"
                        >
                          <Input
                            disabled={isViewOnly}
                            defaultValue={editPromotionData.purchaseTo}
                            onBlur={(e: any) =>
                              handleInputChange(e, 'purchaseTo')
                            }
                            placeholder="Write maximum buy quantity or amount"
                            type="number"
                          />
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="buyUnitOfMeasure"
                          label="Activity Buy Unit of Measure"
                          className="ut-form full-w"
                        >
                          {promoBuyUnitOfMeasures.length < 1 || !singleObj ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              disabled={isViewOnly}
                              className="ut-select"
                              suffixIcon={<CaretDownFilled />}
                              defaultValue={formateSelectVal(
                                buyMeasure || {
                                  id: '00',
                                  name: 'Please select',
                                },
                                'buyMeasure',
                              )}
                              onChange={(e: any) =>
                                handleChange(e, 'buyMeasure')
                              }
                              options={promoBuyUnitOfMeasures?.map(
                                (unit: any) => {
                                  return formateSelectVal(unit, 'buyMeasure');
                                },
                              )}
                            />
                          )}
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="OtherDetails"
                          label="Other Details"
                          className="ut-form full-w"
                        >
                          <Input
                            disabled={isViewOnly}
                            type="text"
                            onBlur={(e: any) => {
                              handleInputChange(e, 'otherdetail');
                            }}
                            defaultValue={other?.otherOffersTab || ''}
                          />
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="Discount Method"
                          label="Discount Method"
                          className="ut-form full-w"
                        >
                          {promoDiscountTypes.length < 1 || !singleObj ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              disabled={isViewOnly}
                              className="ut-select"
                              suffixIcon={<CaretDownFilled />}
                              defaultValue={formateSelectVal(
                                discountType || {
                                  id: '00',
                                  name: 'Please select',
                                },
                                'discountType',
                              )}
                              onChange={(e: any) =>
                                handleChange(e, 'discountType')
                              }
                              options={promoDiscountTypes?.map(
                                (discount: any) => {
                                  return formateSelectVal(
                                    discount,
                                    'discountType',
                                  );
                                },
                              )}
                            />
                          )}
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="Discount"
                          label="Discount"
                          className="ut-form full-w"
                          rules={[
                            {
                              required: true,
                              message: REQUIRED_FEILD,
                            },
                          ]}
                        >
                          <Input
                            defaultValue={editPromotionData?.discountValue || 0}
                            onBlur={(e: any) => {
                              handleInputChange(e, 'discountValue');
                            }}
                            prefix={
                              editPromoPayload?.dimensions?.discountType?.id ==
                              '7'
                                ? '%'
                                : 'PKR'
                            }
                            type="number"
                            disabled={isViewOnly}
                          />
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="ApplyOn"
                          label="Apply On"
                          className="ut-form full-w"
                        >
                          {' '}
                          {promoApplyOns.length < 1 || !singleObj ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              className="ut-select"
                              suffixIcon={<CaretDownFilled />}
                              value={formateSelectVal(
                                editPromoPayload?.dimensions?.applyOn ||
                                  applyOn || {
                                    id: '00',
                                    name: 'Please select',
                                  },
                                'applyOn',
                              )}
                              onChange={(e: any) => handleChange(e, 'applyOn')}
                              options={promoApplyOns?.map((apply: any) => {
                                return formateSelectVal(apply, 'applyOn');
                              })}
                              disabled={isViewOnly}
                            />
                          )}
                        </MyFormItem>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="ApplyTo"
                          label="Apply To"
                          className="ut-form full-w"
                        >
                          {' '}
                          {applyTos.length < 1 || !singleObj ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              disabled={isViewOnly}
                              className="ut-select"
                              suffixIcon={<CaretDownFilled />}
                              value={formateSelectVal(
                                editPromoPayload?.dimensions?.applyTo ||
                                  applyTo || {
                                    id: '00',
                                    name: 'Please select',
                                  },
                                'applyTo',
                              )}
                              onChange={(e: any) => handleChange(e, 'applyTo')}
                              options={applyTos?.map((at: any) => {
                                return formateSelectVal(at, 'applyTo');
                              })}
                            />
                          )}
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="ActivityBuyUnitofMeasureUOM"
                          label="Apply UOM"
                          className="ut-form full-w"
                        >
                          {' '}
                          {promoApplyUoms.length < 1 || !singleObj ? (
                            <Skeleton.Input
                              style={{ width: '100%' }}
                              active={true}
                              size={'default'}
                            />
                          ) : (
                            <Select
                              disabled={isViewOnly}
                              className="ut-select"
                              suffixIcon={<CaretDownFilled />}
                              value={formateSelectVal(
                                editPromoPayload?.dimensions?.applyUOM ||
                                  applyUOM || {
                                    id: '00',
                                    name: 'Please select',
                                  },
                                'applyUOM',
                              )}
                              onChange={(e: any) => handleChange(e, 'applyUOM')}
                              options={promoApplyUoms?.map((uom: any) => {
                                return formateSelectVal(uom, 'applyUOM');
                              })}
                            />
                          )}
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      ></Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="Budget"
                          label="Budget"
                          className="ut-form full-w"
                        >
                          <Input
                            defaultValue={editPromotionData.budgetAllocated}
                            onBlur={(e: any) =>
                              handleInputChange(e, 'budgetAllocated')
                            }
                            prefix="PKR"
                            disabled={isViewOnly}
                          />
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="MinimumBudget"
                          label="Minimum Budget"
                          className="ut-form full-w fin"
                        >
                          <Input
                            defaultValue={editPromotionData.minimumBudget}
                            onBlur={(e: any) =>
                              handleInputChange(e, 'minimumBudget')
                            }
                            placeholder="Write minimum budget"
                            type="number"
                            prefix="PKR"
                            disabled={isViewOnly}
                          />
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <MyFormItem
                          name="MaximumBudget"
                          label="Maximum Budget"
                          className="ut-form full-w fin"
                        >
                          <Input
                            defaultValue={editPromotionData.maximumBudget}
                            onBlur={(e: any) =>
                              handleInputChange(e, 'maximumBudget')
                            }
                            placeholder="Write maximum budget"
                            type="number"
                            prefix="PKR"
                            disabled={isViewOnly}
                          />
                        </MyFormItem>
                      </Col>
                    </Row>
                  </MyFormItemGroup>
                </Panel>
              </Collapse>
              {!isViewOnly && isCreate && (
                <Button htmlType="submit" className="save-btn">
                  Save
                </Button>
              )}
            </Form>
          )}
          <div className="flex flex-row justify-end mt-3">
            {!isViewOnly &&
              !isCreate &&
              ['sentback', 'draft'].includes(singleObj?.status) &&
              isTradeManager && (
                <Dropdown.Button
                  type="primary"
                  className="w-fit mr-auto dropdown-btn-save"
                  menu={menuProps}
                  onClick={handleButtonClick}
                  icon={<CaretDownFilled />}
                  placement="top"
                >
                  Save
                </Dropdown.Button>
              )}
            {/* <Button
              onClick={handleSaveAsTemplate}
              // className={`save-btn ${
              //   editPromoPayloadGState.promoId ? '' : 'disabled-save-btn'
              // }`}
              htmlType="submit"
              className="save-btn dis mr-2"
              // disabled={editPromoPayloadGState.promoId ? false : true}
            >
              Save as template
            </Button> */}
            {isEdit &&
              !isFinanceManager &&
              (singleObj.status == 'draft' ||
                singleObj.status == 'sentback') && (
                <Button
                  onClick={submitPromo}
                  // className={`save-btn ${
                  //   editPromoPayloadGState.promoId ? '' : 'disabled-save-btn'
                  // }`}
                  htmlType="submit"
                  className="ut-btn text-white dis"
                  // disabled={editPromoPayloadGState.promoId ? false : true}
                >
                  Submit
                </Button>
              )}
          </div>
        </div>
      </Col>
      {!isCreate && (
        <Col span={7}>
          <PromoSummary
            singleObj={singleObj}
            editPromotionData={editPromotionData}
            editPromoPayload={editPromoPayload}
            promoActivityDuration={promoActivityDuration}
            defaultLevelValue={defaultLevelValue || products?.[0]?.level}
            defaultTypeValue={defaultTypeValue || promoType?.name}
            defaultDiscountTypesVal={
              defaultDiscountTypesVal || discountType?.name
            }
            defaultBuyUnitOfMeasuresVal={
              defaultBuyUnitOfMeasuresVal || buyMeasure?.name
            }
            showHideHierarchyModal={showHideHierarchyModal}
          />
        </Col>
      )}

      <HierarchyModal
        modalType={hierarchyModalType}
        isModalOpen={isHierarchyModalOpen}
        handleOk={handleHierarchyOk}
        handleCancel={handleHierarchyCancel}
        handleHierarchyModalType={showHideHierarchyModal}
        handleProductDetailModal={showProductDetailModal}
        handleSelectionDetailModal={handleSelectionDetailModal}
        isProductDetailModal={true}
        isEdit={true}
        editPromoPayload={editPromoPayload}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        geoCheckedKeys={geoCheckedKeys}
        channelCheckedKeys={channelCheckedKeys}
        // outletCheckedKeys={outletCheckedKeys}
        singleObj={singleObj}
        setOutletKeys={setOutletKeys}
        outletKeys={outletKeys}
        setOutletKeysLength={setOutletKeysLength}
        isCustomerDisabled={!isModern}
      />
      {/* Table product detail */}
      <ProductDetailModal
        isModalOpen={isProductDetailModalOpen}
        handleOk={handleProductDetailOk}
        handleCancel={handleProductDetailCancel}
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        defaultApplyUomsVal={defaultApplyUomsVal}
        defaultBuyTypesVal={defaultBuyTypesVal}
        defaultBuyUnitOfMeasuresVal={defaultBuyUnitOfMeasuresVal}
        defaultDiscountTypesVal={defaultDiscountTypesVal || discountType?.name}
        defaultApplyOnsVal={defaultApplyOnsVal}
        singleObjParent={singleObj}
        promoActivityDuration={promoActivityDuration}
      />
      {/* Table selection detail */}
      <SelectionDetailModal
        modalType={hierarchyModalType}
        isModalOpen={isSelectionDetailModalOpen}
        handleHierarchyModalType={showHideHierarchyModalCallback}
        handleOk={handleSelectionDetailOk}
        handleCancel={handleSelectionDetailCancel}
        singleObjParent={singleObj}
      />
    </Row>
  );
};

export default EditPromotion;
