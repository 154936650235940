import {
  createAsyncThunk,
  createReducer,
  PayloadAction,
} from '@reduxjs/toolkit';
import getPermissions from './service';
import { PermissionState, defaults, Permission } from './types';

const initialState: PermissionState = {
  ...defaults,
};

export const sliceName = 'permission';

export const fetchPermissions = createAsyncThunk(
  `${sliceName}/signInUser`,
  async () => getPermissions(),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPermissions.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      fetchPermissions.fulfilled,
      (state, action: PayloadAction<Permission[]>) => {
        state.loading = false;
        state.data = action.payload;
      },
    )
    .addCase(fetchPermissions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    })
    .addCase('auth/signOut', (state) => {
      state.data = [];
    });
});
