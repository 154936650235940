import { createSlice } from '@reduxjs/toolkit';
import { ContainerState } from './types';

// The initial state of the loading container
export const sliceName = 'loader';
export const initialState: ContainerState = {
  loading: false,
};
const loadingSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    showLoader(state) {
      state.loading = true;
    },
    hideLoader(state) {
      state.loading = false;
    },
  },
});

export const { actions: loadingActions, reducer } = loadingSlice;
