import { Input, Tree } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import type { DataNode } from 'antd/es/tree';
import { useAppSelector } from 'store/hooks';
import { extractKeys } from './helper';
import { Checkbox } from 'antd';

import { useLocation } from 'react-router-dom';

const { Search } = Input;

const CheckboxGroup = Checkbox.Group;
//This is the component for the selection from tree of products, geos, channels and outlets.
const TreeView = (props: any) => {
  const {
    onCheck,
    checkedKeys,
    geoCheckedKeys,
    channelCheckedKeys,
    treeType,
    isEdit,
    setOutletKeys,
    outletKeys,
    setOutletKeysLength,
  } = props;

  const {
    promotions: { productTree, geoTree, channelTree, outlets },
  } = useAppSelector((state: any) => state);
  const { promotions } = useAppSelector((state: any) => state);
  const { singleObj } = promotions;
  const { isTradeManager, isFinanceManager } = useAppSelector(
    (state) => state.auth,
  );
  const location = useLocation();
  const currentUrl = location.pathname;

  const treeDataList = useMemo(() => {
    let data = [];
    switch (treeType) {
      case 'products':
        data = productTree;
        break;
      case 'geo':
        data = geoTree;
        break;
      case 'channel':
        data = channelTree;
        break;
      default:
        data = [];
        break;
    }
    return data;
  }, [treeType, productTree, geoTree, channelTree]);

  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>();

  //search
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>();
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onToggleCheckAll = (e: any) => {
    if (e.target.checked) {
      setOutletKeys(outlets.map((item: any) => item.id));
      setOutletKeysLength(outlets.map((item: any) => item.id).length);
    } else {
      setOutletKeys([]);
      setOutletKeysLength(-1);
    }
  };

  const onChangeCheck = (e: any) => {
    let arr: any = [...outletKeys];
    if (e.target.checked) {
      arr.push(e.target.value);
      setOutletKeys(arr);
      setOutletKeysLength(arr.length);
    } else {
      setOutletKeys(arr.filter((item: any) => item !== e.target.value));
      setOutletKeysLength(
        arr.filter((item: any) => item !== e.target.value).length,
      );
    }
  };

  // const onCheckAllChange = (e: any) => {
  //   setCheckedList(e.target.checked ? plainOptions : []);
  // };

  const dataList: {
    key: React.Key;
    title: any;
  }[] = [];

  const generateList = (data: DataNode[]) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key, title } = node;
      dataList.push({ key, title });
      if (node.children) {
        generateList(node.children);
      }
    }
  };

  const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  };

  useEffect(() => {
    generateList(treeDataList);
    switch (treeType) {
      case 'products':
        setExpandedKeys(checkedKeys);
        break;
      case 'geo':
        setExpandedKeys(geoCheckedKeys);
        break;
      case 'channel':
        setExpandedKeys(channelCheckedKeys);
        break;
      default:
        setExpandedKeys([]);
        break;
    }
    const expandAll = () => {
      const newExpandedKeys = dataList
        .map((item: any) => {
          if (item.title.toLowerCase().indexOf('') > -1) {
            return getParentKey(item.key, treeDataList);
          }
          return null;
        })
        .filter(
          (item: any, i: any, self: any) => item && self.indexOf(item) === i,
        );
      setExpandedKeys(newExpandedKeys as React.Key[]);
      setAutoExpandParent(true);
    };
    // expandAll();
  }, [treeType]);

  const onSelect: any = (selectedKeysValue: React.Key[], info: any) => {
    setSelectedKeys(selectedKeysValue);
  };

  //search

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const newExpandedKeys = dataList
      .map((item: any) => {
        if (item.title.toLowerCase().indexOf(value) > -1) {
          return getParentKey(item.key, treeDataList);
        }
        return null;
      })
      .filter(
        (item: any, i: any, self: any) => item && self.indexOf(item) === i,
      );
    setExpandedKeys(newExpandedKeys as React.Key[]);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  useEffect(() => {
    if (!localStorage.getItem('isOutletsChanged')) {
      if (Object.keys(singleObj).length == 0) {
        setOutletKeys(outlets.map((item: any) => item.id));
        setOutletKeysLength(outlets.map((item: any) => item.id).length);
      } else if (singleObj.selectionsCount.outletsCount != outlets.length) {
      } else {
        setOutletKeys(outlets.map((item: any) => item.id));
        setOutletKeysLength(outlets.map((item: any) => item.id).length);
      }
    }
  }, []);

  const treeData = useMemo(() => {
    const loop = (data: DataNode[]): DataNode[] =>
      data.map((item) => {
        const { key, children, ...rest } = item;
        const strTitle = item.title as string;
        const stringTitleLow = strTitle.toLowerCase();
        const index = stringTitleLow.indexOf(searchValue.toLowerCase());
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const title =
          index > -1 ? (
            <span key={beforeStr}>
              {beforeStr}
              <span className="site-tree-search-value">{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return {
            title,
            key: item.key,
            ...rest,
            children: loop(item.children),
          };
        }

        return {
          title,
          key: item.key,
          ...rest,
        };
      });

    return loop(treeDataList);
  }, [searchValue, treeDataList]);

  return (
    <>
      {/* <Search
        style={{ marginBottom: 8 }}
        placeholder="Search"
        Check={Check}
        allowClear
      /> */}
      <div style={{ height: 600, overflow: 'auto' }}>
        {treeType !== 'outlet' ? (
          <Tree
            className="ut-tree"
            checkable
            disabled={
              (Object.keys(singleObj).length > 0 &&
                singleObj.status != 'draft' &&
                singleObj.status != 'sentback') ||
              currentUrl.includes('view') ||
              isFinanceManager
            }
            blockNode
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            treeData={treeData}
          />
        ) : (
          <ul className="outlet-listing">
            <li
              className={outletKeys?.length === outlets?.length ? 'active' : ''}
            >
              Select all{' '}
              <Checkbox
                disabled={
                  (Object.keys(singleObj).length > 0 &&
                    singleObj.status != 'draft' &&
                    singleObj.status != 'sentback') ||
                  currentUrl.includes('view') ||
                  isFinanceManager
                }
                value="all"
                onChange={onToggleCheckAll}
                indeterminate={
                  outletKeys?.length > 0 && outletKeys?.length < outlets?.length
                }
                checked={outletKeys?.length === outlets?.length}
              />
            </li>
            {outlets?.map((item: any) => (
              <li className={outletKeys?.includes(item.id) ? 'active' : ''}>
                {item.name}{' '}
                <Checkbox
                  disabled={
                    (Object.keys(singleObj).length > 0 &&
                      singleObj.status != 'draft' &&
                      singleObj.status != 'sentback') ||
                    currentUrl.includes('view') ||
                    isFinanceManager
                  }
                  value={item.id}
                  onChange={onChangeCheck}
                  checked={outletKeys?.includes(item.id)}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default TreeView;
