import { Col, Pagination } from 'antd';
// import RowCategorySummary from './RowCategorySummary';
import RowBrandPromotionController from './RowBrandPromotionController';
import RowPromotionDetail from './RowPromotionDetail';
import { useState } from 'react';
import { IBrand, PromoRowDetailShowState } from './ducks/type';

interface IProps {
  index: number;
  brand: IBrand;
}

export default function BrandPromoRow({ index, brand }: IProps) {
  // initially detail is empty (null). After that show/hide on css. Except first category that is visible initially.
  const [visibility, setVisibleState] = useState<PromoRowDetailShowState>(() =>
    index === -1 ? 'isShown' : 'isEmpty',
  );

  const toggleVisibility = () =>
    setVisibleState((f) => {
      if (f === 'isEmpty' || f === 'isHidden') {
        return 'isShown';
      }
      return 'isHidden';
    });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = startIdx + pageSize;
  const promotionsToShow = brand.promotions.slice(startIdx, endIdx);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  return (
    <>
      {/* Graph Columns */}
      <Col span={24} className="ut-chart-container">
        <RowBrandPromotionController
          visibility={visibility}
          toggleVisibility={toggleVisibility}
          name={brand.brandName}
          data={brand.aggregatedLineGraphData.monthly}
        />
        {promotionsToShow.map((promo) => (
          <RowPromotionDetail
            key={promo.promoId}
            detail={promo}
            visibility={visibility}
          />
        ))}
        {visibility == 'isShown' && (
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={brand.promotions.length}
            onChange={handlePageChange}
          />
        )}
      </Col>
    </>
  );
}
