import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { ROLES_SLUG } from 'configs/constants';

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const {
    auth: { user },
  } = useAppSelector((state: any) => state);

  const hasAdminManagerRole = user?.roles?.some(
    (role: any) => role.role.slug === ROLES_SLUG[2],
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        hasAdminManagerRole ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default ProtectedRoute;
