import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ContainerState } from './types';

// The initial state of the loading container
export const sliceName = 'toastMessage';
export const initialState: ContainerState = {
  messageType: '',
  content: '',
};
const toastMessageSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    showToastMessage(state, action: PayloadAction<any>) {
      state.messageType = action.payload.type;
      state.content = action.payload.content;
    },
    clearToastMessage(state) {
      state.messageType = '';
      state.content = '';
    },
  },
});

export const { actions: toastMessageActions, reducer } = toastMessageSlice;
