import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MyFormItemGroupProps } from 'utils/types';
import type { FormItemProps } from 'antd';
// import { useMixpanel } from 'react-mixpanel-browser';
import {
  Table,
  Form,
  Button,
  Row,
  Col,
  Modal,
  Radio,
  Space,
  Tag,
  Checkbox,
} from 'antd';
import DuplicateIcon from '../../../assets/duplicate.png';

import { CloseOutlined } from '@ant-design/icons';
import { getTemplateList } from './ducks/slice';
import { IBulkDulicateModalProps } from './ducks/types';
import { PAGE_SIZE_LIST_VIEW, capitalizeFirstLetter } from '../ListView/helper';
import { BulkDupObj } from '../ListView/ducks/types';
import {
  bulkDuplicateEvent,
  getListViewAll,
  getListView,
  setBulkRecords,
} from '../ListView/ducks/slice';

const BulkDuplicateModal = (props: IBulkDulicateModalProps) => {
  const dispatch = useAppDispatch();
  // const mixpanel = useMixpanel();

  const {
    listView,
    auth: { user },
  } = useAppSelector((state: any) => state);

  const listViewData = listView?.listViewAllForBulkDuplicates;
  const isBulkDuplicateModalOpen = listView?.isBulkDuplicateModalOpen;
  const listViewAllForBulkDuplicates = listView?.listViewAllForBulkDuplicates;

  const { isModalOpen, handleOk, handleCancel } = props;

  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);

  const [checkboxState, setCheckboxState] = useState([
    {
      key: 1,
      nextweek: false,
      nextyear: false,
      nextofnextyear: false,
    },
  ]);

  const [nextWeekCheckAll, setNextWeekCheckAll] = useState(false);
  const [nextYearCheckAll, setNextYearCheckAll] = useState(false);
  const [nextOfNextYearCheckAll, setNextOfNextYearCheckAll] = useState(false);

  const handleCheckboxChangeFactory =
    (rowIndex: any, columnKey: any) => (event?: any) => {
      // mixpanel?.identify(user.email);
      setCheckboxState((prevCheckboxState) => {
        const newCheckboxState = [...prevCheckboxState];
        newCheckboxState[rowIndex] = {
          ...prevCheckboxState[rowIndex],
          [columnKey]: event.target.checked,
        };
        return newCheckboxState;
      });
    };

  const handleAllCheckboxChnange = (columnKey: any, state: boolean) => {
    // mixpanel?.identify(user.email);
    setCheckboxState((prevCheckboxState) => {
      const newCheckboxState = prevCheckboxState.map((item) => ({
        ...item,
        [columnKey]: state,
      }));
      return newCheckboxState;
    });
  };

  function hasTrueValue(arr: any) {
    return arr.some(
      (item: any) => item.nextweek || item.nextyear || item.nextofnextyear,
    );
  }

  function removeObjectsWithAllFalse(arr: any): any {
    return arr.filter(
      (item: any) => item.nextweek || item.nextyear || item.nextofnextyear,
    );
  }

  function getYearString(offset: number): string {
    const currentYear = new Date().getFullYear();
    const targetYear = currentYear + offset;
    return targetYear.toString();
  }

  function convertToBulkDupObj(originalObj: any): BulkDupObj[] {
    const result: BulkDupObj[] = [];

    if (originalObj.nextweek) {
      result.push({
        id: originalObj.promo_id,
        mode: 'simple',
        year: getYearString(0),
      });
    }

    if (originalObj.nextyear) {
      result.push({
        id: originalObj.promo_id,
        mode: 'next-year',
        year: getYearString(1),
      });
    }

    if (originalObj.nextofnextyear) {
      result.push({
        id: originalObj.promo_id,
        mode: 'next-year',
        year: getYearString(2),
      });
    }

    return result;
  }

  const refreshList = () => {
    dispatch(
      getListView({
        pageNumber: 1,
        pageSize: PAGE_SIZE_LIST_VIEW,
      }),
    );
  };

  const handleClose = () => {
    handleCancel();
    setIsConfirmEnabled(false);
    dispatch(setBulkRecords([]));
  };

  const handleConfirm = async () => {
    let filteredArr = removeObjectsWithAllFalse(checkboxState);
    const convertedArray: BulkDupObj[] =
      filteredArr.flatMap(convertToBulkDupObj);
    await dispatch(bulkDuplicateEvent({ bulk: convertedArray }));

    setTimeout(() => {
      // mixpanel?.track('Duplicate');
      // mixpanel?.identify(user.email);
    }, 2000);

    setCheckboxState(
      checkboxState.map((elem: any) => {
        return {
          ...elem,
          nextweek: false,
          nextyear: false,
          nextofnextyear: false,
        };
      }),
    );
    setNextWeekCheckAll(false);
    setNextYearCheckAll(false);
    setNextOfNextYearCheckAll(false);
    refreshList();

    handleClose();
  };

  const handleChangeNextWeekAll = () => {
    handleAllCheckboxChnange('nextweek', !nextWeekCheckAll);
    setNextWeekCheckAll(!nextWeekCheckAll);
  };
  const handleChangeNextYearAll = () => {
    handleAllCheckboxChnange('nextyear', !nextYearCheckAll);
    setNextYearCheckAll(!nextYearCheckAll);
  };
  const handleChangeNextOfNextYearAll = () => {
    handleAllCheckboxChnange('nextofnextyear', !nextOfNextYearCheckAll);
    setNextOfNextYearCheckAll(!nextOfNextYearCheckAll);
  };

  // useEffect(() => {
  //   if (
  //     isBulkDuplicateModalOpen &&
  //     listViewAllForBulkDuplicates.length < 1 &&
  //     checkboxState.length < 2
  //   ) {
  //     //only fetch if modal open and not already fetched
  //     dispatch(getListViewAll());
  //   }
  // }, [isBulkDuplicateModalOpen]);

  useEffect(() => {
    if (listViewData.length > 0) {
      let c = listViewData.map((elem: any, index: number) => {
        return {
          ...elem,
          key: index + 1,
          nextweek: false,
          nextyear: false,
          nextofnextyear: false,
        };
      });
      setCheckboxState(c);
    }
  }, [listViewData]);

  useEffect(() => {
    if (hasTrueValue(checkboxState)) {
      setIsConfirmEnabled(true);
    } else {
      setIsConfirmEnabled(false);
    }
  }, [checkboxState]);

  useEffect(() => {
    if (isModalOpen) {
      // Map over the checkboxState array to change key names
      const modifiedState = checkboxState.map((item: any) => {
        // Rename 'promotion_code' key to 'promoCode'
        const { promotion_code, ...rest } = item;
        return { ...rest, promoCode: promotion_code };
      });

      // Fire mixpanel event every time checkboxState changes
      // mixpanel?.track('Year Selection Duplicate', modifiedState);
    }
  }, [checkboxState]);

  const columns: any = [
    {
      title: 'Promotion ID',
      dataIndex: 'promotion_code',
      key: 'promotion_code',
      width: 180,
      className: 'promo-id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (text: any, record: any) => (
        <Space size="middle">
          {record?.status?.toLowerCase() == 'Draft'.toLowerCase() && (
            <Tag bordered={false} color="purple">
              {capitalizeFirstLetter(record?.status)}
            </Tag>
          )}
          {record?.status?.toLowerCase() == 'Submitted'.toLowerCase() && (
            <Tag bordered={false} color="yellow">
              {capitalizeFirstLetter(record?.status)}
            </Tag>
          )}
          {record?.status?.toLowerCase() == 'Approved'.toLowerCase() && (
            <Tag bordered={false} color="green">
              {capitalizeFirstLetter(record?.status)}
            </Tag>
          )}
          {record?.status?.toLowerCase() == 'Rejected'.toLowerCase() && (
            <Tag bordered={false} color="red">
              {capitalizeFirstLetter(record?.status)}
            </Tag>
          )}
          {record?.status?.toLowerCase() == 'sentback'.toLowerCase() && (
            <Tag bordered={false} color="orange">
              {capitalizeFirstLetter(record?.status)}
            </Tag>
          )}
        </Space>
      ),
      className: 'status',
    },
    {
      title: 'Current week',
      dataIndex: 'nextweek',
      key: 'nextweek',
      width: 130,
      render: (value: any, record: any, rowIndex: any) => (
        <Checkbox
          checked={value}
          onChange={handleCheckboxChangeFactory(rowIndex, 'nextweek')}
        />
      ),
      className: 'check',
    },
    {
      title: getYearString(1),
      dataIndex: 'nextyear',
      key: 'nextyear',
      width: 130,
      render: (value: any, record: any, rowIndex: any) => (
        <Checkbox
          checked={value}
          onChange={handleCheckboxChangeFactory(rowIndex, 'nextyear')}
        />
      ),
      className: 'check',
    },
    {
      title: getYearString(2),
      dataIndex: 'nextofnextyear',
      key: 'nextofnextyear',
      width: 130,
      render: (value: any, record: any, rowIndex: any) => (
        <Checkbox
          checked={value}
          onChange={handleCheckboxChangeFactory(rowIndex, 'nextofnextyear')}
        />
      ),
      className: 'check',
    },
  ];

  return (
    <>
      <Modal
        title={
          <div className="flex items-center">
            <img src={DuplicateIcon} style={{ marginRight: 8 }} />
            Duplicate
          </div>
        }
        className="bulk-duplicate-modal m-4"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleClose}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 50,
            }}
          >
            <Button
              key="cancel"
              onClick={handleClose}
              style={{
                padding: '4px 48px',
                fontWeight: '600',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                backgroundColor: 'f6f7f8',
              }}
            >
              Cancel
            </Button>
            <Button
              key="ok"
              type="primary"
              onClick={() => {
                handleConfirm();
              }}
              disabled={!isConfirmEnabled}
              style={{
                padding: '4px 48px',
                fontWeight: '500',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              }}
            >
              Confirm
            </Button>
          </div>
        }
        destroyOnClose={true}
        centered={true}
        closeIcon={
          <CloseOutlined
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
            onClick={handleClose}
          />
        }
      >
        <Row className="py-2">
          <Col span={24}>
            <Space
              style={{
                marginBottom: 32,
                color: '#808080',
                fontSize: '12px',
                fontWeight: 400,
                marginLeft: 5,
                lineHeight: 1.5,
              }}
            >
              {listViewData.length > 0
                ? 'You are about to create a duplicate of the selected promotions.'
                : 'No promotions selected.'}
            </Space>
          </Col>
          <Col span={24}>
            <Row gutter={0}>
              <Col span={24}>
                {listViewData.length > 0 && (
                  <Space
                    style={{
                      gap: 120,
                      position: 'absolute',
                      top: 37,
                      right: 86,
                      zIndex: 55,
                    }}
                  >
                    <Checkbox
                      checked={nextWeekCheckAll}
                      onChange={handleChangeNextWeekAll}
                      style={{ position: 'absolute', left: '-15px', bottom: 0 }}
                    />
                    <Checkbox
                      checked={nextYearCheckAll}
                      onChange={handleChangeNextYearAll}
                    />
                    <Checkbox
                      checked={nextOfNextYearCheckAll}
                      onChange={handleChangeNextOfNextYearAll}
                    />
                  </Space>
                )}
              </Col>
              <Col className="gutter-row" span={24}>
                {listViewData.length > 0 ? (
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={checkboxState}
                    className="bulk-duplicate-table"
                    pagination={false}
                    scroll={{ y: `calc(100vh - 375px)` }}
                  />
                ) : (
                  <div
                    style={{
                      color: '#808080',
                      fontSize: '16px',
                      fontWeight: 400,
                      marginLeft: 5,
                      lineHeight: 1.5,
                      textAlign: 'center',
                    }}
                  >
                    Please select promotions to continue.
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default BulkDuplicateModal;
