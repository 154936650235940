import React, { ReactElement } from 'react';
import { Spin } from 'antd';
import { selectLoader } from './ducks/selectors';
import { useAppSelector } from 'store/hooks';

const Loader = (): ReactElement => {
  const loadingState = useAppSelector(selectLoader);
  const { loading } = loadingState;

  return loading ? <Spin size="large" className="app-loader" /> : <></>;
};

export default Loader;
