import request from 'utils/axios';

import { httpVerbs } from 'configs';
import { IAllCategoriesResponse, IEditUser, IUserRolesResponse } from './types';
import { IHTTPRequestArgs, Method } from 'configs/types';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getUserListAPI = async (): Promise<any> => {
  const url = `${BASE_URL}/administration/all-users`;
  const params: IHTTPRequestArgs = {
    url: url,
    method: httpVerbs.GET as Method,
  };
  const response = await request(params);
  return response;
};

export const getAllCategoriesAPI =
  async (): Promise<IAllCategoriesResponse> => {
    const url = `${BASE_URL}/administration/all-categories`;
    const params: IHTTPRequestArgs = {
      url: url,
      method: httpVerbs.GET as Method,
    };
    const response = await request(params);
    return response;
  };

export const getAllRegionAPI = async (): Promise<IAllCategoriesResponse> => {
  const url = `${BASE_URL}/administration/all-regions`;
  const params: IHTTPRequestArgs = {
    url: url,
    method: httpVerbs.GET as Method,
  };
  const response = await request(params);
  return response;
};
export const getAllChannelAPI = async (): Promise<IAllCategoriesResponse> => {
  const url = `${BASE_URL}/administration/all-channels`;
  const params: IHTTPRequestArgs = {
    url: url,
    method: httpVerbs.GET as Method,
  };
  const response = await request(params);
  return response;
};
export const getAllRolesAPI = async (): Promise<IUserRolesResponse> => {
  const url = `${BASE_URL}/administration/all-roles`;
  const params: IHTTPRequestArgs = {
    url: url,
    method: httpVerbs.GET as Method,
  };
  const response = await request(params);
  return response;
};

export const createUserAPI = async (payload: any): Promise<any> => {
  const url: string = `${BASE_URL}/administration/create-user`;
  const { id, ...newPayload } = payload;
  newPayload.preferredTimezone = 'UTC';
  const params: IHTTPRequestArgs = {
    url: url,
    method: httpVerbs.POST as Method,
    data: newPayload,
  };

  const response = await request(params);
  return response;
};

export const editUserAPI = async (payload: IEditUser): Promise<any> => {
  const url: string = `${BASE_URL}/administration/users/update/${payload.id}`;
  //remove id from payload object
  const { id, password, ...newPayload } = payload;
  newPayload.preferredTimezone = 'UTC';
  const params: IHTTPRequestArgs = {
    url: url,
    method: httpVerbs.PUT as Method,
    data: newPayload,
  };

  const response = await request(params);
  return response;
};
