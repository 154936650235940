import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MyFormItemGroupProps } from 'utils/types';
import { ISelectTemplateModalProps } from './ducks/types';
import type { FormItemProps } from 'antd';

import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Modal,
  Radio,
  Tag,
  Flex,
  Space,
} from 'antd';
import TemplateIcon from '../../../assets/template-down.png';

import { CloseOutlined } from '@ant-design/icons';
import {
  getSelectedTemplateDetailSuccess,
  getTemplateList,
} from './ducks/slice';

const SelectTemplateModal = (props: ISelectTemplateModalProps) => {
  const dispatch = useAppDispatch();

  const promotion = useAppSelector((state) => state.promotions);
  const { isTradeManager, isFinanceManager, isAdmin, user } = useAppSelector(
    (state) => state.auth,
  );

  const { isModalOpen, handleOk, handleCancel } = props;

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (e: any) => {
    setSelectedOption(e.target.value);
  };

  const handleConfirm = () => {
    handleOk(selectedOption);
    setSelectedOption(null);
  };

  const handleClose = () => {
    setSelectedOption(null);
    dispatch(getSelectedTemplateDetailSuccess({ data: null }));
    handleCancel();
  };

  const getTemplateListUI = async () => {
    await dispatch(getTemplateList());
  };

  function toArr(
    str: string | number | (string | number)[],
  ): (string | number)[] {
    return Array.isArray(str) ? str : [str];
  }

  const FormItemContext = React.createContext<(string | number)[]>([]);

  const MyFormItemGroup = ({ prefix, children }: MyFormItemGroupProps) => {
    const prefixPath = React.useContext(FormItemContext);
    const concatPath = React.useMemo(
      () => [...prefixPath, ...toArr(prefix)],
      [prefixPath, prefix],
    );

    return (
      <FormItemContext.Provider value={concatPath}>
        {children}
      </FormItemContext.Provider>
    );
  };

  useEffect(() => {
    if (isTradeManager) {
      getTemplateListUI();
    }
  }, [user, dispatch, isTradeManager]);

  const MyFormItem = ({ name, ...prop }: FormItemProps) => {
    const prefixPath = React.useContext(FormItemContext);
    const concatName =
      name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;

    return <Form.Item name={concatName} {...prop} />;
  };

  return (
    <>
      <Modal
        title={
          <div className="flex items-center">
            <img src={TemplateIcon} style={{ marginRight: 8 }} />
            Templates
          </div>
        }
        className="template-select-modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleClose}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              key="cancel"
              onClick={handleClose}
              style={{
                padding: '4px 48px',
                fontWeight: '600',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                backgroundColor: 'f6f7f8',
              }}
            >
              Cancel
            </Button>
            <Button
              key="ok"
              type="primary"
              onClick={() => {
                handleConfirm();
              }}
              disabled={!selectedOption}
              style={{
                padding: '4px 48px',
                fontWeight: '500',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              }}
            >
              Confirm
            </Button>
          </div>
        }
        destroyOnClose={true}
        centered={true}
        closeIcon={
          <CloseOutlined
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
            onClick={handleClose}
          />
        }
      >
        <Row className="py-2">
          <Col span={24}>
            <Space
              style={{
                marginBottom: 32,
                color: '#808080',
                fontSize: '12px',
                fontWeight: 400,
                marginLeft: 5,
                lineHeight: 1.5,
              }}
            >
              Import your desired template and save valuable time – effortlessly
              streamline your promotional efforts!
            </Space>
          </Col>
          <Col span={24}>
            <Form name="template-selection-form" layout="vertical">
              <MyFormItemGroup prefix={['discount']}>
                <Row gutter={0}>
                  <Col className="gutter-row" span={24}>
                    <MyFormItem name="" label="" className="ut-form">
                      <Radio.Group
                        onChange={handleChange}
                        value={selectedOption}
                        style={{ width: '100%' }}
                      >
                        {promotion.templates.map((elem: any) => {
                          return (
                            <Radio
                              style={{
                                display: 'block',
                                marginBottom: '16px',
                                backgroundColor: '#f3f6f9',
                                width: '100%',
                                border:
                                  selectedOption == elem.id
                                    ? '2px solid #164291'
                                    : '2px solid #ededed',
                                borderRadius: 5,
                              }}
                              value={elem.id}
                              key={elem.id}
                            >
                              <Space
                                direction="vertical"
                                size="middle"
                                style={{ paddingTop: 10, paddingBottom: 6 }}
                              >
                                <div>
                                  <div>
                                    <span
                                      style={{
                                        color: '#164291',
                                        fontWeight: '600',
                                        fontSize: 14,
                                      }}
                                    >
                                      {elem.templateName}
                                    </span>
                                    <br />
                                    <span
                                      className="mt-1"
                                      style={{ color: '#494949' }}
                                    >
                                      Used in {elem.usedCount} promotions
                                      creation
                                    </span>
                                  </div>
                                  <Space className="mt-2">
                                    <Button
                                      type="text"
                                      disabled
                                      style={{
                                        color: '#D36500',
                                        fontWeight: '600',
                                        backgroundColor: '#fbf0e6',
                                        fontSize: '12px',
                                        height: 'fit-content',
                                        opacity: 0.5,
                                      }}
                                    >
                                      Set as default
                                    </Button>
                                    <Button
                                      type="text"
                                      disabled
                                      style={{
                                        color: '#494949',
                                        fontWeight: '600',
                                        backgroundColor: '#ededed',
                                        fontSize: '12px',
                                        height: 'fit-content',
                                        opacity: 0.5,
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </Space>
                                </div>
                              </Space>
                            </Radio>
                          );
                        })}
                      </Radio.Group>
                      {promotion?.templates?.length == 0 && (
                        <div
                          className="flex justify-center"
                          style={{
                            color: '#808080',
                          }}
                        >
                          No templates found
                        </div>
                      )}
                    </MyFormItem>
                  </Col>
                </Row>
              </MyFormItemGroup>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SelectTemplateModal;
