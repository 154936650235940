import React, { ReactElement } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UserList from './userList';

export default function Users(): ReactElement {
  // get path
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={UserList} />
    </Switch>
  );
}
