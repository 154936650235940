import request from 'utils/axios';

import { IRecordId } from 'interfaces/general';
import {
  ICalendarResponse,
  INewTemplate,
  IPromoDuration,
  IPromoDurationWeeks,
  ISinglePromotionsResponse,
} from './types';
import { httpVerbs } from 'configs';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getSinglePromotionApi = async (
  id: IRecordId,
): Promise<ISinglePromotionsResponse> => {
  const url = `${BASE_URL}/promotions/${id}`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};
export const getPromotionSchemesApi =
  async (): Promise<ISinglePromotionsResponse> => {
    const url = `${BASE_URL}/promotion-data/schemes`;
    const params: any = {
      url: url,
      method: httpVerbs.GET,
    };
    const response = await request(params);
    return response;
  };
export const getPromotionDurationApi = async (
  payload: IPromoDuration,
  shortFormat = false,
): Promise<ICalendarResponse> => {
  const url = `${BASE_URL}/promotion-data/calendar?startDate=${payload.startDate}&endDate=${payload.endDate}&shortFormat=${shortFormat}`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getPromotionDurationWeeksApi = async (
  payload: IPromoDurationWeeks,
): Promise<ICalendarResponse> => {
  let url = `${BASE_URL}/promotion-data/weeks?`;
  if (payload?.hasOwnProperty('allWeeks')) {
    url = `${url}&allWeeks=${payload.allWeeks}`;
  }
  if (payload?.hasOwnProperty('year')) {
    url = `${url}&year=${payload.year}`;
  }
  if (payload?.hasOwnProperty('startWeek')) {
    url = `${url}&startWeek=${payload.startWeek}`;
  }
  if (payload?.hasOwnProperty('startYear')) {
    url = `${url}&startYear=${payload.startYear}`;
  }
  if (payload?.hasOwnProperty('endWeek')) {
    url = `${url}&endWeek=${payload.endWeek}`;
  }
  if (payload?.hasOwnProperty('endYear')) {
    url = `${url}&endYear=${payload.endYear}`;
  }

  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getPromotionLevelsApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/product-levels`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getPromotionCustomersApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/direct-customers`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getPromotionTypesApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/promo-types`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};
export const getOfferTypesApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/offer-type`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getApplyToApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/apply-to`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getPromotionStatusApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/promotion-status`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};
export const getPromotionBuyTypesApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/buy-types`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};
export const getPromotionBuyUnitMeasuresApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/buy-measures`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};
export const getPromotionApplyOnsApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/apply-ons`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};
export const getPromotionApplyUomsApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/apply-uoms`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};
export const getPromotionDiscountTypesApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/discount-types`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const updatePromotionApi = async (
  payload: any,
  promoId: string,
): Promise<any> => {
  const url = `${BASE_URL}/promotions/${promoId}/update`;
  const convertedPayload = { ...payload };

  // Check if the properties exist in payload before converting them
  if (payload?.hasOwnProperty('maximumBudget')) {
    convertedPayload.maximumBudget = parseFloat(payload.maximumBudget);
  }

  if (payload?.hasOwnProperty('minimumBudget')) {
    convertedPayload.minimumBudget = parseFloat(payload.minimumBudget);
  }

  if (payload?.hasOwnProperty('budgetAllocated')) {
    convertedPayload.budgetAllocated = parseFloat(payload.budgetAllocated);
  }
  const params: any = {
    url: url,
    method: httpVerbs.PUT,
    data: convertedPayload,
  };
  const response = await request(params);
  return response;
};

export const getProdcutTreeApi = async (productLevel: string): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/product-tree?levelName=${
    productLevel ? productLevel : 'lastLevel'
  }`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};
export const getProdcutLevelsApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/product-levels?all=true`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getGeoTreeApi = async (customers: any = []): Promise<any> => {
  let ids = customers.map((elem: any) => {
    if ('geo_hierarchy_level_code_id' in elem) {
      return elem.geo_hierarchy_level_code_id;
    } else {
      return elem.id;
    }
  });
  const queryParams = `customerIds=${ids.join(',')}`;
  const url =
    customers.length == 0
      ? `${BASE_URL}/promotion-data/geo-tree`
      : `${BASE_URL}/promotion-data/geo-tree?${queryParams}`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getGeoLevelsApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/geo-levels?all=true`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getChannelTreeApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/channels`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getOutletListApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/outlets`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getChannelLevelsApi = async (): Promise<any> => {
  const url = `${BASE_URL}/promotion-data/channel-levels`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getTreeSelectionCountApi = async (payload: any): Promise<any> => {
  const url = `${BASE_URL}/promotion-calculations/count-selections`;
  //only provide product,geo,channel
  const {
    dimensions: { products, channels, geos, customers, outlets },
  } = payload;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data:
      customers &&
      Array.isArray(customers) &&
      customers.length > 0 &&
      payload?.dimensions?.promoType?.id == '2'
        ? { products, channels, geos, customers, outlets }
        : { products, channels, geos, outlets },
  };
  const response = await request(params);
  return response;
};

export const deleteSelectedProductApi = async (payload: any): Promise<any> => {
  const url = `${BASE_URL}/promotion-calculations/delete-product`;
  const params: any = {
    url: url,
    method: httpVerbs.DELETE,
    data: payload,
  };
  const response = await request(params);
  return response;
};

export const updateSelectedProductApi = async (payload: any): Promise<any> => {
  const url = `${BASE_URL}/promotion-calculations/roi-calculation`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: payload,
  };
  const response = await request(params);
  return response;
};

export const getAllTemplatesApi = async (): Promise<any> => {
  const url = `${BASE_URL}/templates/get-all-templates`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getTemplateByIdApi = async (templateId: string): Promise<any> => {
  const url = `${BASE_URL}/templates/${templateId}/get-template`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const increaseTemplateCountApi = async (
  templateId: string,
): Promise<any> => {
  const url = `${BASE_URL}/templates/${templateId}/increase-template-count`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: {
      id: templateId,
    },
  };
  const response = await request(params);
  return response;
};

export const getPromotionHistoryApi = async (promoId: string): Promise<any> => {
  const url = `${BASE_URL}/promotions/${promoId}/history`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const getPromotionSummaryApi = async (promoId: string): Promise<any> => {
  const url = `${BASE_URL}/promotions/${promoId}/detail`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const addTemplateApi = async (payload: any): Promise<any> => {
  const url = `${BASE_URL}/templates/${payload.promoId}/create-template`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: {
      templateName: payload.templateName,
    },
  };
  const response = await request(params);
  return response;
};
