export const APPLICATION_NAME = 'Uptrade';
export const BASE_URL: string = process.env.REACT_APP_BASE_URL || 'BASEURL';
export const TOKEN_KEY: string =
  process.env.REACT_APP_TOKEN_KEY || 'TOKEN_KEY_HERE';
export const APP_EK: string =
  process.env.REACT_APP_ENCRYPTION_KEY || 'ENCRYPTION_KEY_HERE';
export const ENABLE_REDUX_LOGGER: boolean =
  process.env.REACT_APP_ENABLE_REDUX_LOGGER === 'true';

export const httpVerbs = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
};
