import { ReactElement } from 'react';
import ForgotPassword from 'app/modules/Auth/ForgotPassword';

const ForgotPasswordPage = (): ReactElement => (
  <>
    <ForgotPassword />
  </>
);

export default ForgotPasswordPage;
