import { Header } from 'app/molecules/Header';
import HeaderFilterControl from 'app/molecules/HeaderFilterControl';
import HeaderFilterControlDashboard from 'app/molecules/HeaderFilterControlDashboard';
import { useLocation } from 'react-router-dom';
import NewTemplateModal from '../Promotion/newTemplateModal';
import { useAppSelector } from 'store/hooks';

export default function AppHeader() {
  const location = useLocation();
  const currentUrl = location.pathname;

  const {
    promotions: { newTemplate, isNewTemplateModalVisible },
  } = useAppSelector((state: any) => state);

  return (
    <div>
      <Header
        onLogin={() => {}}
        onLogout={() => {}}
        onCreateAccount={() => {}}
      />
      <HeaderFilterControl />
      <NewTemplateModal isNewTemplateModalVisible={isNewTemplateModalVisible} />
    </div>
  );
}
