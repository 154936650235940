import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CurrenUser,
  ModuleState,
  Status,
  // ErrorResponse,
  ForgotPasswordInterface,
  CredsInterface,
  ResetPasswordInterface,
  Token,
} from './types';
import * as services from './services';

export const sliceName = 'auth';

// The initial state of the Auth container
export const initialState: ModuleState = {
  // user: {
  //   id: 1,
  //   username: 'test',
  //   changePasswordRequired: false,
  // },
  token: services.getTokenFromLocal() || null,
  user: null,
  activePage: '',
  loading: false,
  fetchLoading: 'init',
  forgotResponse: null,
  resetResponse: null,
  error: '',
  isAdmin: false,
  isFinanceManager: false,
  isTradeManager: false,
};

// for async action follow blow link
// https://redux-toolkit.js.org/tutorials/advanced-tutorial#thinking-in-thunks
// OR
// https://redux-toolkit.js.org/api/createAsyncThunk

export const signIn = createAsyncThunk(
  `${sliceName}/signInUser`,
  async (credentials: CredsInterface, { rejectWithValue }) => {
    try {
      // Attempt to sign in using your service function
      const response: any = await services.signIn(credentials);

      // Check if the response contains an error property (customize this based on your service)
      if (response.error) {
        // Handle the error condition by rejecting the promise with an error message or payload
        return rejectWithValue(response.errorMessage);
      }

      // If successful, return the data you want to store in your Redux state
      return response; // Adjust this based on your service response structure
    } catch (error) {
      // Handle any unexpected errors, e.g., network issues
      // You can reject the promise with an error message or payload
      return rejectWithValue(error.response.data);
    }
  },
);

export const forgotPassword = createAsyncThunk(
  `${sliceName}/forgotPassword`,
  async (data: ForgotPasswordInterface) => services.forgotPassword(data),
);

export const resetPassword = createAsyncThunk(
  `${sliceName}/resetPassword`,
  async (data: ResetPasswordInterface) => services.resetPassword(data),
);

export const getUser = createAsyncThunk(
  `${sliceName}/getUser`,
  services.getUser,
);

const authSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    finishFetchLoading(state) {
      state.fetchLoading = 'finished';
    },
    changePage(state, action: PayloadAction<string>) {
      state.activePage = action.payload;
    },
    signOut(state) {
      services.removeTokenFromLocal();
      state.loading = false;
      state.user = null;
      state.token = null;
    },
    setIsAdminStore(state, { payload }) {
      state.isAdmin = payload;
    },
    setIsFinanceManagerStore(state, { payload }) {
      state.isFinanceManager = payload;
    },
    setIsTradeManagerStore(state, { payload }) {
      state.isTradeManager = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.fetchLoading = 'fetching';
      })
      .addCase(
        getUser.fulfilled,
        (state, action: PayloadAction<{ data: CurrenUser }>) => {
          state.fetchLoading = 'finished';
          state.user = action.payload.data;
        },
      )
      .addCase(getUser.rejected, (state, action) => {
        /* TODO: for handling error specifically then define type in  'createAsyncThunk'
        https://redux-toolkit.js.org/usage/usage-with-typescript#createasyncthunk
        */
        state.fetchLoading = 'finished';
        state.error = action.error;
      })
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        signIn.fulfilled,
        (state, action: PayloadAction<{ data: Token }>) => {
          state.loading = false;
          services.saveTokenToLocal(action.payload.data);
          state.token = action.payload.data;
          state.activePage = '';
          state.error = '';
        },
      )
      .addCase(signIn.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload.data || action.error;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        forgotPassword.fulfilled,
        (state, action: PayloadAction<{ data: Status }>) => {
          state.loading = false;
          state.forgotResponse = action.payload.data;
        },
      )
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        resetPassword.fulfilled,
        (state, action: PayloadAction<{ data: Status }>) => {
          state.loading = false;
          state.resetResponse = action.payload.data;
        },
      )
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const { actions: authActions, reducer } = authSlice;
