import React, { ReactElement, useEffect, useState } from 'react';
// import { useMixpanel } from 'react-mixpanel-browser';
import {
  Row,
  Col,
  Button,
  Drawer,
  Collapse,
  Select,
  DatePicker,
  Form,
  Input,
  Alert,
  Skeleton,
  Tag,
  Popover,
  Dropdown,
  MenuProps,
} from 'antd';
import { CaretDownFilled, CloseCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  extractKeys,
  formateHierarchyBoxTagsDynamic,
  formateSelectVal,
  getLevelWiseSelections,
  removeChildrenKey,
  splitCamelCase,
} from '../Promotion/helper';
// import Input from 'rc-input';
import dayjs, { Dayjs } from 'dayjs';
import {
  getChannelTree,
  getGeoTree,
  getProdcutTree,
  getPromoDuration,
  getPromoDurationWeeks,
  getPromoDurationWeeksSuccess,
  getTreeSelectionCount,
  isQuickCreateModalOpen,
  updatePromotionsSuccess,
  getOutletList,
  setNewTemplate,
  setIsNewTemplateModalVisible,
  setIsTempPromo,
} from '../Promotion/ducks/slice';
import {
  createEvent,
  createEventInProgress,
  createEventSuccess,
} from './ducks/slice';
import { toastMessageActions } from 'app/molecules/ToastMessage/ducks/slice';
import { loadingActions } from 'app/molecules/Loader/ducks/slice';
import * as calendarViewSource from './ducks/services';
import { formateCalendarView } from './helper';
import { useHistory } from 'react-router-dom';
import { IconDownOutlined } from 'app/icons/icons';
import HierarchyModal from '../Promotion/hierarchyModal';
import { validateMessages } from './constants';
import { REQUIRED_FEILD } from 'configs/constants';
import { IPromoLevel } from '../Promotion/ducks/types';

import {
  getProductLevels,
  getGeoLevels,
  getChannelLevels,
} from '../Promotion/ducks/slice';
import ModalDialogSmall from 'app/atoms/ModalDialogSmall';

const { Panel } = Collapse;

const QuickCreateEventModal = (props: any): ReactElement => {
  const { isOpen, onClose } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    calendarView: { selectedEvent },
    promotions: {
      promoSchemes,
      promoLevels,
      promoTypes,
      promoBuyTypes,
      promoBuyUnitOfMeasures,
      promoApplyUoms,
      promoApplyOns,
      promoDiscountTypes,
      promoDuration,
      promoDurationWeeks,
      productTree,
      geoTree,
      channelTree,
      outlets,
      selectedTemplateDetail,
      promoCustomers,
    },
  } = useAppSelector((state: any) => state);

  const {
    promotions: {
      levels: { productLevels, geoLevels, channelLevels },
      treeSelectionCount: {
        channelsCount,
        productComponentsCount,
        geoComponentCount,
        channelComponentsCount,
        productsCount,
        geosCount,
        productNames,
        geoNames,
        channelNames,
        outletsCount,
      },
    },
  } = useAppSelector((state: any) => state);

  const {
    auth: { user },
  } = useAppSelector((state: any) => state);

  // const mixpanel = useMixpanel();

  const currentDate = dayjs().format('YYYY-MM-DD');
  const [isCustomerDisabled, setIsCustomerDisabled] = useState<boolean>(true);
  const [createPromoPayload, setCreatePromoPayload] = useState<any>({
    startDate: currentDate,
    endDate: currentDate,
    dimensions: {
      // products: [
      //   {
      //     level: 'Product Size',
      //     selections: ['all'],
      //   },
      // ],
      geos: ['all'],
      channels: ['all'],
      outlets: ['all'],
    },
  });

  const [promoDurationValue, setPromoDurationValue] = useState<any>({});
  const [promoDurationWeeksValue, setPromoDurationWeeksValue] = useState<any>(
    [],
  );

  //modal
  const [defaultLevelValue, setDefaultLevelValue] = useState('all');
  const [hierarchyModalType, setHierarchyModalType] = useState('');
  const [isHierarchyModalOpen, setIsHierarchyModalOpen] = useState(false);
  //tree
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [geoCheckedKeys, setGeoCheckedKeys] = useState<React.Key[]>([]);
  const [channelCheckedKeys, setChannelCheckedKeys] = useState<React.Key[]>([]);
  const [outletCheckedKeys, setOutletCheckedKeys] = useState<React.Key[]>([]);
  const [outletKeysLength, setOutletKeysLength] = useState(null);
  const [outletKeys, setOutletKeys] = useState<React.Key[]>([]);
  const [isModalDialogOpen, setIsModalDialogOpen] = useState<boolean>(false);

  const [form] = Form.useForm();
  // Quick Hierarchy Selection
  const showHierarchyModal = (modalType: string) => {
    setHierarchyModalType(modalType);
    setIsHierarchyModalOpen(true);
  };
  const handleHierarchyOk = () => {
    setIsHierarchyModalOpen(false);
    setHierarchyModalType('');
  };

  const handleHierarchyCancel = () => {
    setIsHierarchyModalOpen(false);
    setHierarchyModalType('');
  };

  const onCheck: any = (checkedKeysValue: React.Key[], info: any) => {
    if (info.checked) {
      let updatedPayload = {
        ...createPromoPayload,
      };
      if (hierarchyModalType === 'products') {
        updatedPayload = {
          ...createPromoPayload,

          dimensions: {
            ...createPromoPayload?.dimensions,
            products: [
              {
                level:
                  (createPromoPayload &&
                    createPromoPayload?.dimensions?.products?.length &&
                    createPromoPayload?.dimensions?.products[0]?.level) ||
                  defaultLevelValue,
                selections: ['all'],
              },
            ],
          },
        };

        if (info.node.key !== 'all' && checkedKeysValue.length) {
          if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
            updatedPayload = {
              ...createPromoPayload,

              dimensions: {
                ...createPromoPayload?.dimensions,

                products: [
                  {
                    level:
                      (createPromoPayload &&
                        createPromoPayload?.dimensions?.products?.length &&
                        createPromoPayload?.dimensions?.products[0]?.level) ||
                      defaultLevelValue,
                    selections: ['all'],
                  },
                ],
              },
            };
          } else {
            const selectedObj = info.checkedNodes.filter((obj: any) =>
              obj.hasOwnProperty('selectionID'),
            );

            updatedPayload = {
              ...createPromoPayload,

              dimensions: {
                ...createPromoPayload?.dimensions,

                products: [
                  {
                    level:
                      (createPromoPayload &&
                        createPromoPayload?.dimensions?.products?.length &&
                        createPromoPayload?.dimensions?.products[0]?.level) ||
                      defaultLevelValue,
                    selections: selectedObj,
                  },
                ],
              },
            };
          }
        }
        setCreatePromoPayload(updatedPayload);
        dispatch(getTreeSelectionCount(updatedPayload));
      } else if (hierarchyModalType === 'geo') {
        updatedPayload = {
          ...createPromoPayload,
          dimensions: {
            ...createPromoPayload?.dimensions,
            geos: ['all'],
          },
        };

        if (info.node.key !== 'all' && checkedKeysValue.length) {
          if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
            updatedPayload = {
              ...createPromoPayload,
              dimensions: {
                ...createPromoPayload?.dimensions,
                geos: ['all'],
              },
            };
          } else {
            const selectedObj = info.checkedNodes.filter((obj: any) =>
              obj.hasOwnProperty('selectionID'),
            );
            updatedPayload = {
              ...createPromoPayload,
              dimensions: {
                ...createPromoPayload?.dimensions,
                geos: getLevelWiseSelections(
                  removeChildrenKey(info.checkedNodes),
                ),
              },
            };
          }
        }
        setCreatePromoPayload(updatedPayload);
        dispatch(getTreeSelectionCount(updatedPayload));
      } else if (hierarchyModalType === 'channel') {
        updatedPayload = {
          ...createPromoPayload,
          dimensions: {
            ...createPromoPayload?.dimensions,
            channels: ['all'],
          },
        };

        if (info.node.key !== 'all' && checkedKeysValue.length) {
          if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
            updatedPayload = {
              ...createPromoPayload,
              dimensions: {
                ...createPromoPayload?.dimensions,
                channels: ['all'],
              },
            };
          } else {
            const selectedObj = info.checkedNodes.filter((obj: any) =>
              obj.hasOwnProperty('selectionID'),
            );
            updatedPayload = {
              ...createPromoPayload,
              dimensions: {
                ...createPromoPayload?.dimensions,
                channels: getLevelWiseSelections(
                  removeChildrenKey(info.checkedNodes),
                ),
              },
            };
          }
        }
        setCreatePromoPayload(updatedPayload);
        dispatch(getTreeSelectionCount(updatedPayload));
      }
    } else {
      let updatedPayload;
      if (hierarchyModalType === 'products') {
        if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
          updatedPayload = {
            ...createPromoPayload,

            dimensions: {
              ...createPromoPayload?.dimensions,

              products: [
                {
                  level:
                    (createPromoPayload &&
                      createPromoPayload?.dimensions?.products?.length &&
                      createPromoPayload?.dimensions?.products[0]?.level) ||
                    defaultLevelValue,
                  selections: ['all'],
                },
              ],
            },
          };
        } else {
          const selectedObj = info?.checkedNodes?.filter((obj: any) =>
            obj.hasOwnProperty('selectionID'),
          );
          updatedPayload = {
            ...createPromoPayload,

            dimensions: {
              ...createPromoPayload?.dimensions,
              products: [
                {
                  level:
                    (createPromoPayload &&
                      createPromoPayload?.dimensions?.products?.length &&
                      createPromoPayload?.dimensions?.products[0]?.level) ||
                    defaultLevelValue,
                  selections: selectedObj,
                },
              ],
            },
          };
          if (selectedObj.length === 0) {
            delete updatedPayload.dimensions.products;
          }
        }
        setCreatePromoPayload(updatedPayload);
        dispatch(getTreeSelectionCount(updatedPayload));
      } else if (hierarchyModalType === 'geo') {
        const checkLength = getLevelWiseSelections(
          removeChildrenKey(info.checkedNodes),
        ).length;
        updatedPayload = {
          ...createPromoPayload,

          dimensions: {
            ...createPromoPayload?.dimensions,
            geos: getLevelWiseSelections(removeChildrenKey(info.checkedNodes)),
          },
        };
        if (checkLength === 0) {
          delete updatedPayload.dimensions.geos;
        }
        setCreatePromoPayload(updatedPayload);
        dispatch(getTreeSelectionCount(updatedPayload));
      } else if (hierarchyModalType === 'channel') {
        const checkLength = getLevelWiseSelections(
          removeChildrenKey(info.checkedNodes),
        ).length;
        updatedPayload = {
          ...createPromoPayload,

          dimensions: {
            ...createPromoPayload?.dimensions,
            channels: getLevelWiseSelections(
              removeChildrenKey(info.checkedNodes),
            ),
          },
        };
        if (checkLength === 0) {
          delete updatedPayload.dimensions.channels;
        }
        setCreatePromoPayload(updatedPayload);
        dispatch(getTreeSelectionCount(updatedPayload));
      }
      //dispatch(getTreeSelectionCount(updatedPayload));
      //dispatch(updatePromotion(updatedPayload, promotionId));
      // } else if (!checkedKeysValue.length) {
      //   dispatch(
      //     toastMessageActions.showToastMessage({
      //       type: 'error',
      //       content: 'Select atleast one product',
      //     }),
      //   );
      // }
    }

    if (hierarchyModalType === 'products') {
      setCheckedKeys(checkedKeysValue);
    } else if (hierarchyModalType === 'geo') {
      setGeoCheckedKeys(checkedKeysValue);
    } else if (hierarchyModalType === 'channel') {
      setChannelCheckedKeys(checkedKeysValue);
    }
  };

  useEffect(() => {
    if (true) {
      if (productLevels.length == 0) {
        dispatch(getProductLevels());
      }
      if (geoLevels.length == 0) {
        dispatch(getGeoLevels());
      }
      if (channelLevels.length == 0) {
        dispatch(getChannelLevels());
      }
      // if (!selectedTemplateDetail) {
      //   dispatch(getTreeSelectionCount(treeSelectionPayload));
      // }
    }
  }, []);

  useEffect(() => {
    dispatch(getTreeSelectionCount(createPromoPayload));
  }, [createPromoPayload.dimensions]);

  useEffect(() => {
    if (promoDuration) {
      const lastWeekIndex = promoDuration.length - 1;
      const finalVal =
        Number(promoDuration && promoDuration[lastWeekIndex]?.week_of_year) -
        Number(promoDuration[0]?.week_of_year);
      setPromoDurationValue({
        duration: finalVal,
        startWeek: promoDuration && promoDuration[0]?.week_of_year,
        endWeek: promoDuration && promoDuration[lastWeekIndex]?.week_of_year,
        dateEnd: promoDuration?.dateEnd || promoDurationValue?.dateEnd,
        dateStart: promoDuration?.dateStart || promoDurationValue?.dateStart,
      });
      if (promoDuration && promoDuration[0]?.calendar_date) {
        setCreatePromoPayload((e: any) => ({
          ...e,
          startDate: promoDuration[0]?.calendar_date,
          endDate: promoDuration[lastWeekIndex]?.calendar_date,
          dimensions: {
            ...createPromoPayload?.dimensions,
            weekStart: String(promoDuration[0]?.week_of_year),
            weekEnd: String(promoDuration[lastWeekIndex]?.week_of_year),
            holidays: promoDuration
              ?.map((day: any) => day.holiday_name)
              .filter(Boolean),
          },
        }));
      }
    }
  }, [promoDuration]);

  useEffect(() => {
    if (promoDurationWeeks?.weeks) {
      const weeksObject = promoDurationWeeks?.weeks?.map((week: any) => ({
        value: week.toString(),
        label: week.toString(),
      }));
      setPromoDurationWeeksValue(weeksObject);
    }
    if (promoDurationWeeks.dateStart) {
      setPromoDurationValue({
        ...promoDurationValue,
        dateEnd: promoDurationWeeks?.dateEnd,
        dateStart: promoDurationWeeks?.dateStart,
      });
      setCreatePromoPayload({
        ...createPromoPayload,
        startDate: promoDurationWeeks?.dateStart,
        endDate: promoDurationWeeks?.dateEnd,
      });
    }
  }, [promoDurationWeeks]);

  useEffect(() => {
    dispatch(
      getPromoDuration({ startDate: currentDate, endDate: currentDate }),
    );

    dispatch(
      getPromoDurationWeeks({
        allWeeks: true,
        year: new Date().getFullYear(),
      }),
    );
    if (geoTree.length == 0) {
      dispatch(getGeoTree());
    }
    if (channelTree.length == 0) {
      dispatch(getChannelTree());
    }
    if (outlets.length == 0) {
      dispatch(getOutletList());
    }
    if (promoLevels.length > 0) {
      const levelName = promoLevels[0]?.level_name;
      setDefaultLevelValue(levelName);
      dispatch(getProdcutTree(levelName));
      const updatedPayload = {
        ...createPromoPayload,
        dimensions: {
          ...createPromoPayload?.dimensions,

          products: [
            {
              level: levelName,
              selections: createPromoPayload?.promoData?.products[0]
                ?.selections || ['all'],
            },
          ],
        },
      };
      setCreatePromoPayload(updatedPayload);
    }
  }, [dispatch, promoLevels]);

  //on tree get
  useEffect(() => {
    // products
    // if (checkedKeys.length === 0 && productTree.length > 0) {
    //   let checkedKeyVal = [];

    //   if (productTree.length > 0 && defaultLevelValue !== null) {
    //     checkedKeyVal = extractKeys(productTree);
    //   }
    //   setCheckedKeys(checkedKeyVal);
    // }
    if (checkedKeys.length === 0 && productTree.length > 0) {
      if (checkedKeys.length > 0) return;
      let checkedKeyVal = [];
      checkedKeyVal = extractKeys(geoTree);
      setGeoCheckedKeys(checkedKeyVal);
    }

    if (geoCheckedKeys.length === 0 && geoTree.length > 0) {
      if (geoCheckedKeys.length > 0) return;
      let geoCheckedKeyVal = [];
      geoCheckedKeyVal = extractKeys(geoTree);
      setGeoCheckedKeys(geoCheckedKeyVal);
    }

    if (!selectedTemplateDetail) {
      //for channel default selections
      if (channelCheckedKeys.length === 0 && channelTree.length > 0) {
        if (channelCheckedKeys.length > 0) return;
        let channelCheckedKeyVal = [];
        channelCheckedKeyVal = extractKeys(channelTree);

        setChannelCheckedKeys(channelCheckedKeyVal);
      }
    }
  }, [productTree, geoTree, channelTree, checkedKeys]);
  useEffect(() => {
    if (productTree.length > 0 && !selectedTemplateDetail) {
      let checkedKeyVal = extractKeys(productTree);
      setHierarchyModalType('-');
      setCheckedKeys(checkedKeyVal);
    }
  }, [productTree]);

  const handleChange = (id: string, name: string) => {
    let promoState: any = [];
    switch (name) {
      case 'promoType':
        promoState = promoTypes;
        break;
      case 'promoLevel':
        promoState = promoLevels;
        break;
      case 'scheme':
        promoState = promoSchemes;
        break;
      case 'buyType':
        promoState = promoBuyTypes;
        break;
      case 'buyMeasure':
        promoState = promoBuyUnitOfMeasures;
        break;
      case 'discountType':
        promoState = promoDiscountTypes;
        break;
      case 'applyOn':
        promoState = promoApplyOns;
        break;
      case 'applyUOM':
        promoState = promoApplyUoms;
        break;
      default:
        promoState = [];
    }

    if (name === 'promoLevel') {
      const filteredPromo = promoLevels?.filter(
        (item: { level_code: string }) => item.level_code === id,
      );

      const promoLevelName = promoLevels?.filter(
        (promo: any) => promo.level_code == id,
      );
      const levelName = promoLevelName[0]?.level_name;
      dispatch(getProdcutTree(levelName));
      setHierarchyModalType('');
      setDefaultLevelValue(levelName);

      const updatedPayload = {
        ...createPromoPayload,

        dimensions: {
          ...createPromoPayload?.dimensions,

          products: [
            {
              level: levelName,
              selections: createPromoPayload?.promoData?.products[0]
                ?.selections || ['all'],
            },
          ],
        },
      };

      setCreatePromoPayload(updatedPayload);
    } else {
      const filteredPromo = promoState?.filter(
        (type: { id: string }) => type.id === id,
      );
      setCreatePromoPayload({
        ...createPromoPayload,

        dimensions: {
          ...createPromoPayload?.dimensions,
          [name]: filteredPromo[0],
        },
      });
      if (name == 'promoType') {
        if (filteredPromo[0]?.id == 2) {
          setIsCustomerDisabled(false);
        } else {
          setIsCustomerDisabled(true);
          dispatch(getGeoTree());
          setGeoCheckedKeys(['all']);
          setCreatePromoPayload({
            ...createPromoPayload,

            dimensions: {
              ...createPromoPayload?.dimensions,
              customers: [],
              [name]: filteredPromo[0],
            },
          });
        }
      }
    }
  };

  function handleMultiSelectCustomersChange(selectedIds: any, allOptions: any) {
    const selectedObjects = allOptions.filter((option: any) =>
      selectedIds.includes(option.id),
    );
    let payload = {
      ...createPromoPayload,
      dimensions: {
        ...createPromoPayload?.dimensions,
        customers: selectedObjects || [],
      },
    };
    setCreatePromoPayload(payload);
    dispatch(getGeoTree(selectedObjects || []));
    dispatch(getTreeSelectionCount(payload));
  }

  const convertObjToIdFormat = (promoLevelsArr: IPromoLevel[], obj: any) => {
    const foundLevel: any = promoLevelsArr.find(
      (promoLevel) => promoLevel.level_name === obj.level,
    );
    return (
      foundLevel ||
      promoLevels[0] || {
        id: '00',
        name: 'Please select',
      }
    ); // Return the foundLevel object or null if not found
  };

  useEffect(() => {
    //For the mapping of template values into the form
    if (selectedTemplateDetail) {
      let productkeys = selectedTemplateDetail?.promoData?.products
        ? selectedTemplateDetail?.promoData?.products[0]?.selections?.map(
            (elem: any) => elem.key,
          )
        : ['all'];

      if (
        selectedTemplateDetail?.promoData?.products &&
        selectedTemplateDetail?.promoData?.products[0]?.selections?.length ==
          1 &&
        selectedTemplateDetail?.promoData?.products[0]?.selections[0] == 'all'
      ) {
        productkeys = ['all'];
      } else {
        productkeys = [];
      }

      let geokeys =
        selectedTemplateDetail?.promoData?.geos?.postProcessedSelections?.map(
          (elem: any) => elem.key,
        ) || ['all'];
      let channelkeys =
        selectedTemplateDetail?.promoData?.channels?.postProcessedSelections?.map(
          (elem: any) => elem.key,
        ) || ['all'];
      if (
        selectedTemplateDetail?.promoData?.channels &&
        selectedTemplateDetail?.promoData?.channels?.length == 1 &&
        selectedTemplateDetail?.promoData?.channels[0] == 'all'
      ) {
        channelkeys = ['all'];
      } else {
        channelkeys = [];
      }
      if (
        selectedTemplateDetail?.promoData?.geos &&
        selectedTemplateDetail?.promoData?.geos?.length == 1 &&
        selectedTemplateDetail?.promoData?.geos[0] == 'all'
      ) {
        geokeys = ['all'];
      } else {
        geokeys = [];
      }
      if (!selectedTemplateDetail?.promoData?.channels) {
        channelkeys = [];
      }
      if (!selectedTemplateDetail?.promoData?.geos) {
        geokeys = [];
      }
      if (!selectedTemplateDetail?.promoData?.products) {
        productkeys = [];
      }

      if (
        selectedTemplateDetail?.promoData?.geos?.postProcessedSelections
          .length == 1 &&
        selectedTemplateDetail?.promoData?.geos?.postProcessedSelections[0] ==
          'all'
      ) {
        geokeys = ['all'];
      }
      if (
        selectedTemplateDetail?.promoData?.channels?.postProcessedSelections
          .length == 1 &&
        selectedTemplateDetail?.promoData?.channels
          ?.postProcessedSelections[0] == 'all'
      ) {
        channelkeys = ['all'];
      }

      setCheckedKeys(productkeys);
      setGeoCheckedKeys(geokeys);
      setChannelCheckedKeys(channelkeys);
      setOutletKeys(selectedTemplateDetail?.promoData?.outlets || []);
      setOutletKeysLength(
        selectedTemplateDetail?.promoData?.outlets?.length || 0,
      );

      const levelName =
        selectedTemplateDetail?.promoData?.products?.length > 0 &&
        selectedTemplateDetail?.promoData?.products
          ? selectedTemplateDetail?.promoData?.products[0]?.level
          : 'Category';

      setDefaultLevelValue(levelName);
      dispatch(getProdcutTree(levelName));
      dispatch(getGeoTree());
      dispatch(getChannelTree());
      dispatch(getOutletList());

      let updatedPayload = {
        ...createPromoPayload,
        promoName: selectedTemplateDetail.promoName,
        description: selectedTemplateDetail.description,
        discountValue: Number(selectedTemplateDetail.discountValue),
        dimensions: {
          ...createPromoPayload.dimensions,
          applyOn: selectedTemplateDetail?.promoData?.applyOn,
          applyUOM: selectedTemplateDetail?.promoData?.applyUOM,
          discountType: selectedTemplateDetail?.promoData?.discountType,
          promoType: selectedTemplateDetail?.promoData?.promoType || {
            id: '1',
            name: 'Trade Promotions',
            shortCode: 'TPR',
          },
          geos: selectedTemplateDetail?.promoData?.geos?.preProcessedSelections,
          channels:
            selectedTemplateDetail?.promoData?.channels?.preProcessedSelections,
          products: [
            {
              level: levelName,
              selections:
                selectedTemplateDetail?.promoData?.products &&
                selectedTemplateDetail?.promoData?.products?.length > 0
                  ? selectedTemplateDetail?.promoData?.products[0]?.selections
                  : productkeys,
            },
          ],
        },
      };
      dispatch(getTreeSelectionCount(updatedPayload));
      setCreatePromoPayload(updatedPayload);
    }
  }, [selectedTemplateDetail]);

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[],
  ) => {
    if (dates) {
      setPromoDurationValue({
        ...promoDurationValue,
        dateStart: dateStrings[0],
        dateEnd: dateStrings[1],
      });
      dispatch(
        getPromoDuration({
          startDate: dateStrings[0],
          endDate: dateStrings[1],
        }),
      );
      setCreatePromoPayload({
        ...createPromoPayload,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
    } else {
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    name: string,
  ) => {
    const value = e.target.value;

    if (name === 'discountValue') {
      setCreatePromoPayload({
        ...createPromoPayload,
        [name]: Number(value),
      });
    } else {
      setCreatePromoPayload({
        ...createPromoPayload,
        [name]: value,
      });
    }
  };

  const createPromo = async () => {
    if (
      createPromoPayload?.promoCodeCompany?.length >= 5 ||
      !createPromoPayload?.promoCodeCompany
    ) {
      if (
        !createPromoPayload?.promoName ||
        !createPromoPayload?.description ||
        !createPromoPayload?.discountValue
      ) {
        // dispatch(
        //   toastMessageActions.showToastMessage({
        //     type: 'error',
        //     content:
        //       'Please enter Promotion Name, Description, and Discount Value!',
        //   }),
        // );
        return;
      }
      const updatedPayload = {
        ...createPromoPayload,
        dimensions: {
          ...createPromoPayload.dimensions,
          promoType: createPromoPayload.dimensions.promoType
            ? createPromoPayload.dimensions.promoType
            : promoTypes[0],
          discountType: createPromoPayload.dimensions.discountType
            ? createPromoPayload.dimensions.discountType
            : promoDiscountTypes[0],
          applyOn: createPromoPayload.dimensions.applyOn
            ? createPromoPayload.dimensions.applyOn
            : promoApplyOns[1],
          applyUOM: createPromoPayload.dimensions.applyUOM
            ? createPromoPayload.dimensions.applyUOM
            : promoApplyUoms[0],
          outlets:
            outletKeys.length === outlets.length ||
            outletsCount === outlets.length
              ? ['all']
              : [...outletKeys],
        },
      };
      if (updatedPayload.promoCodeCompany === '') {
        delete updatedPayload.promoCodeCompany;
      }
      let saved = await dispatch(
        createEvent(updatedPayload, selectedTemplateDetail),
      );
      // mixpanel?.track('Promotion Saved', {
      //   metadata: saved,
      // });
      // mixpanel?.identify(user.email);

      // mixpanel?.track('Calendar View');
      // mixpanel?.identify(user.email);

      if (history?.location?.pathname === '/') {
        dispatch(getPromoDurationWeeksSuccess({ data: { weeks: [] } })); //reset duration
      }
      setHierarchyModalType('');
    }
  };
  const saveAsTemplate = async () => {
    dispatch(setIsTempPromo(true));
    if (
      createPromoPayload?.promoCodeCompany?.length >= 5 ||
      !createPromoPayload?.promoCodeCompany
    ) {
      if (
        !createPromoPayload?.promoName ||
        !createPromoPayload?.description ||
        !createPromoPayload?.discountValue
      ) {
        // dispatch(
        //   toastMessageActions.showToastMessage({
        //     type: 'error',
        //     content:
        //       'Please enter Promotion Name, Description, and Discount Value!',
        //   }),
        // );
        return;
      }
      const updatedPayload = {
        ...createPromoPayload,
        dimensions: {
          ...createPromoPayload.dimensions,
          promoType: createPromoPayload.dimensions.promoType
            ? createPromoPayload.dimensions.promoType
            : promoTypes[0],
          discountType: createPromoPayload.dimensions.discountType
            ? createPromoPayload.dimensions.discountType
            : promoDiscountTypes[0],
          applyOn: createPromoPayload.dimensions.applyOn
            ? createPromoPayload.dimensions.applyOn
            : promoApplyOns[1],
          applyUOM: createPromoPayload.dimensions.applyUOM
            ? createPromoPayload.dimensions.applyUOM
            : promoApplyUoms[0],
          outlets:
            outletKeysLength == -1
              ? []
              : outletKeys.length == 0
              ? ['all']
              : [...outletKeys],
          // outletKeys.length === outlets.length ||
          // outletsCount === outlets.length
          //   ? ['all']
          //   : [outletKeys.length==0 ? ['all'] : ...outletKeys],
          // buyType: createPromoPayload.dimensions.promoBuyTypes
          //   ? createPromoPayload.dimensions.promoBuyTypes
          //   : promoBuyTypes[0],
        },
      };
      if (updatedPayload.promoCodeCompany === '') {
        delete updatedPayload.promoCodeCompany;
      }
      let created = await dispatch(
        createEvent(
          {
            ...updatedPayload,
            purchaseTo:
              updatedPayload.purchaseTo !== ''
                ? parseInt(updatedPayload.purchaseTo)
                : null,
            purchaseFrom:
              updatedPayload.purchaseFrom !== ''
                ? parseInt(updatedPayload.purchaseFrom)
                : null,
            budgetAllocated:
              updatedPayload.budgetAllocated !== ''
                ? parseInt(updatedPayload.budgetAllocated)
                : null,
            minimumBudget:
              updatedPayload.minimumBudget !== ''
                ? parseInt(updatedPayload.minimumBudget)
                : null,
            maximumBudget:
              updatedPayload.maximumBudget !== ''
                ? parseInt(updatedPayload.maximumBudget)
                : null,
          },
          selectedTemplateDetail,
          true,
        ),
      );

      dispatch(
        setNewTemplate({
          promoId: created.id,
        }),
      );

      //SHOW NEW TEMPLATE MODAL
      dispatch(setIsNewTemplateModalVisible(true));

      if (history?.location?.pathname === '/') {
        dispatch(getPromoDurationWeeksSuccess({ data: { weeks: [] } })); //reset duration
      }
      setHierarchyModalType('');
    }
  };
  const addMoreDetail = async () => {
    if (
      createPromoPayload?.promoCodeCompany?.length >= 5 ||
      !createPromoPayload?.promoCodeCompany
    ) {
      if (
        !createPromoPayload?.promoName ||
        !createPromoPayload?.description ||
        !createPromoPayload?.discountValue
      ) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content:
              'Please enter Promotion Name, Description, and Discount Value!',
          }),
        );
        return;
      }

      const updatedPayload = {
        ...createPromoPayload,
        dimensions: {
          ...createPromoPayload.dimensions,
          promoType: createPromoPayload.dimensions.promoType
            ? createPromoPayload.dimensions.promoType
            : promoTypes[0],
          discountType: createPromoPayload.dimensions.discountType
            ? createPromoPayload.dimensions.discountType
            : promoDiscountTypes[0],
          applyOn: createPromoPayload.dimensions.applyOn
            ? createPromoPayload.dimensions.applyOn
            : promoApplyOns[1],
          applyUOM: createPromoPayload.dimensions.applyUOM
            ? createPromoPayload.dimensions.applyUOM
            : promoApplyUoms[0],
          outlets:
            outletKeys.length === outlets.length ? ['all'] : [...outletKeys],
        },
      };
      try {
        const a = true;
        if (a) {
          const data: any = {};
          dispatch(updatePromotionsSuccess({ data })); //clear editPromo data
        }
        if (createPromoPayload?.promoCodeCompany?.length === 0) {
          delete updatedPayload.promoCodeCompany;
        }
        const data = await calendarViewSource.createEventApi(updatedPayload);
        const formateData = formateCalendarView(data);
        dispatch(loadingActions.hideLoader());
        dispatch(createEventSuccess(formateData));
        if (history?.location?.pathname === '/') {
          dispatch(getPromoDurationWeeksSuccess({ data: { weeks: [] } })); //reset duration
        }
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'success',
            content: 'Promotion successfuly created',
          }),
        );
        setCreatePromoPayload({
          ...createPromoPayload,
          promoName: '',
        });
        dispatch(isQuickCreateModalOpen(false));
        setHierarchyModalType('---');

        const {
          data: { id },
        } = data;
        history.push(`/promotion/edit/${id}`);
        // on save close the Modal dialog
      } catch (error) {
        dispatch(loadingActions.hideLoader());
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: error.message,
          }),
        );
      }
    }
  };
  const onChange = (key: string | string[]) => {};

  const getPromotionDurationWeeks = (startWeek?: any, endWeek?: any) => {
    const startWeekValue = startWeek || promoDurationValue?.startWeek;
    const endWeekValue = endWeek || promoDurationValue?.endWeek;

    if (+endWeekValue >= +startWeekValue) {
      dispatch(
        getPromoDurationWeeks({
          startWeek: startWeekValue,
          startYear: new Date().getFullYear(),
          endWeek: endWeekValue,
          endYear: new Date().getFullYear(),
        }),
      );
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: 'End week is less than start week',
        }),
      );
    }
  };

  const onChangeSelectStartWeek = (value: string) => {
    setPromoDurationValue({
      ...promoDurationValue,
      startWeek: value,
    });
    getPromotionDurationWeeks(value);
    setCreatePromoPayload({
      ...createPromoPayload,
      dimensions: {
        ...createPromoPayload?.dimensions,
        weekStart: value,
      },
    });
  };

  const onChangeSelectEndWeek = (value: string) => {
    setPromoDurationValue({
      ...promoDurationValue,
      endWeek: value,
    });
    getPromotionDurationWeeks(null, value);
    setCreatePromoPayload({
      ...createPromoPayload,
      dimensions: {
        ...createPromoPayload?.dimensions,
        weekEnd: value,
      },
    });
  };

  const filterOptionStartWeek = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const filterOptionEndWeek = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const getCurrentFormattedDate = (isStartDate: boolean) => {
    if (promoDurationValue?.dateStart && isStartDate) {
      return promoDurationValue?.dateStart;
    }
    if (promoDurationValue?.dateEnd && !isStartDate) {
      return promoDurationValue?.dateEnd;
    }
    if (!promoDurationValue?.dateEnd && !promoDurationValue?.dateStart) {
      const today = new Date();
      const year = today.getFullYear();
      let month: any = today.getMonth() + 1;
      let day: any = today.getDate();

      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }

      return `${year}-${month}-${day}`;
    }
  };

  useEffect(() => {
    if (isOpen && !selectedTemplateDetail) {
      setOutletKeys([]);
    }
  }, [isOpen]);

  const handleButtonClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    form.submit();
    createPromo();
  };

  const handleMenuClick: MenuProps['onClick'] = async (e) => {};

  const items: MenuProps['items'] = [
    {
      label: 'Save as template',
      key: '1',
      onClick: () => {
        form.submit();
        saveAsTemplate();
        // mixpanel?.track('Save as template click');
        // mixpanel?.identify(user.email);
      },
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const submitPromo = async () => {
    if (
      createPromoPayload?.promoCodeCompany?.length >= 5 ||
      !createPromoPayload?.promoCodeCompany
    ) {
      if (
        !createPromoPayload?.promoName ||
        !createPromoPayload?.description ||
        !createPromoPayload?.discountValue
      ) {
        // dispatch(
        //   toastMessageActions.showToastMessage({
        //     type: 'error',
        //     content:
        //       'Please enter Promotion Name, Description, and Discount Value!',
        //   }),
        // );
        return;
      }
      const updatedPayload = {
        ...createPromoPayload,
        dimensions: {
          ...createPromoPayload.dimensions,
          promoType: createPromoPayload.dimensions.promoType
            ? createPromoPayload.dimensions.promoType
            : promoTypes[0],
          discountType: createPromoPayload.dimensions.discountType
            ? createPromoPayload.dimensions.discountType
            : promoDiscountTypes[0],
          applyOn: createPromoPayload.dimensions.applyOn
            ? createPromoPayload.dimensions.applyOn
            : promoApplyOns[1],
          applyUOM: createPromoPayload.dimensions.applyUOM
            ? createPromoPayload.dimensions.applyUOM
            : promoApplyUoms[0],
          outlets:
            outletKeys.length === outlets.length ||
            outletsCount === outlets.length
              ? ['all']
              : [...outletKeys],
        },
      };
      if (updatedPayload.promoCodeCompany === '') {
        delete updatedPayload.promoCodeCompany;
      }
      dispatch(
        createEvent(updatedPayload, selectedTemplateDetail, false, true),
      );

      if (history?.location?.pathname === '/') {
        dispatch(getPromoDurationWeeksSuccess({ data: { weeks: [] } })); //reset duration
      }
      setHierarchyModalType('');
    }
  };

  const handleModalDialogClose = () => {
    setIsModalDialogOpen(false);
  };

  const handleModalDialogSubmit = () => {
    submitPromo();
  };

  return (
    <>
      <Drawer
        title={`Create Promotion / ${
          createPromoPayload?.promoCodeCompany || 'xxxxx'
        }`}
        placement="right"
        onClose={onClose}
        open={isOpen}
        className="ut-drawer"
        destroyOnClose={true}
        closeIcon={<CloseCircleOutlined />}
        rootStyle={{
          zIndex: 999,
        }}
      >
        <Form
          form={form}
          className="login-form ut-form ut-horizontal-form"
          // className="login-form ut-form"
          layout="horizontal"
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={() => {}}
          onFinishFailed={() => {}}
          // validateMessages={validateMessages}
        >
          <Collapse
            defaultActiveKey={['1']}
            onChange={onChange}
            className="ut-collapse"
            items={undefined}
            expandIconPosition={'end'}
            expandIcon={({ isActive }) => (
              <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
                <IconDownOutlined />
              </span>
            )}
          >
            <Panel header="Basic Information" key="1">
              {/* <Row
                // justify="space-between"
                align="middle"
                className="ut-form ut-mb-m"
              > */}
              {/* <Col span={8}>
                  <span className="ut-label ut-fs-s ut-mb-s">
                    Promotion Name{' '}
                  </span>
                </Col>
                <Col span={16}>
                  <Input
                    className="w-full"
                    value={createPromoPayload.promoName}
                    onChange={(e: any) => handleInputChange(e, 'promoName')}
                  />
                </Col> */}
              <Form.Item
                label={'Promotion Name'}
                name="promoName"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_FEILD,
                  },
                ]}
                initialValue={selectedTemplateDetail?.promoName || ''}
              >
                <Input
                  type="text"
                  onChange={(e: any) => handleInputChange(e, 'promoName')}
                  style={{
                    borderStyle: 'none',
                  }}
                />
              </Form.Item>
              <Form.Item
                label={'Description'}
                name="description"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_FEILD,
                  },
                ]}
                initialValue={selectedTemplateDetail?.description || ''}
              >
                <Input
                  type="text"
                  onChange={(e: any) => handleInputChange(e, 'description')}
                  style={{
                    borderStyle: 'none',
                  }}
                />
              </Form.Item>
              {/* </Row> */}
              {/* <Row
                justify="space-between"
                align="middle"
                className="ut-form ut-mb-m"
              >
                <Col span={8}>
                  <span className="ut-label ut-fs-s ut-mb-s">Description</span>
                </Col>
                <Col span={16}>
                  <Input
                    className="w-full"
                    onChange={(e: any) => handleInputChange(e, 'description')}
                  />
                </Col>
              </Row> */}
              <Form.Item
                label={'Reference Code'}
                name="promoCodeCompany"
                rules={[
                  {
                    min: 5,
                    message: 'Code must be equal or more than 5',
                  },
                ]}
              >
                <Input
                  onBlur={(e: any) => handleInputChange(e, 'promoCodeCompany')}
                />
              </Form.Item>
              {/* <Row justify="space-between" align="middle" className="ut-form">
                <Col span={8}>
                  <span className="ut-label">Reference Code</span>
                </Col>
                <Col span={16}>
                  <Input
                    onBlur={(e: any) =>
                      handleInputChange(e, 'promoCodeCompany')
                    }
                  />
                </Col>
              </Row> */}
            </Panel>
          </Collapse>
          <Collapse
            defaultActiveKey={['1']}
            onChange={onChange}
            className="ut-collapse"
            expandIcon={({ isActive }) => (
              <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
                <IconDownOutlined />
              </span>
            )}
            items={undefined}
            expandIconPosition={'end'}
          >
            <Panel header="Duration" key="1">
              <Row
                justify="space-between"
                align="middle"
                className="ut-mb-m ut-form"
              >
                <Col span={8} className="ut-mb-s">
                  <span className="ut-label">Start and End Date</span>
                </Col>
                <Col span={16}>
                  <DatePicker.RangePicker
                    defaultValue={[dayjs(), dayjs()]}
                    value={[
                      dayjs(getCurrentFormattedDate(true), 'YYYY-MM-DD'),
                      dayjs(getCurrentFormattedDate(false), 'YYYY-MM-DD'),
                    ]}
                    onChange={onRangeChange}
                    format={'YYYY-MM-DD'}
                    // status="error"
                    className="w-full"
                  />
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                className="ut-form ut-mb-m"
              >
                <Col span={8}>
                  <span className="ut-label">Start Week</span>
                </Col>
                <Col span={16}>
                  <Select
                    showSearch
                    className="ut-select"
                    placeholder="Select a week"
                    optionFilterProp="children"
                    suffixIcon={<CaretDownFilled />}
                    options={promoDurationWeeksValue}
                    onChange={onChangeSelectStartWeek}
                    filterOption={filterOptionStartWeek}
                    value={promoDurationValue?.startWeek}
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle" className="ut-form">
                <Col span={8}>
                  <span className="ut-label">End Week</span>
                </Col>
                <Col span={16}>
                  <Select
                    showSearch
                    className="ut-select"
                    placeholder="Select a week"
                    optionFilterProp="children"
                    suffixIcon={<CaretDownFilled />}
                    onChange={onChangeSelectEndWeek}
                    options={promoDurationWeeksValue}
                    filterOption={filterOptionEndWeek}
                    value={promoDurationValue?.endWeek}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Collapse
            defaultActiveKey={['1']}
            onChange={onChange}
            className="ut-collapse"
            expandIcon={({ isActive }) => (
              <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
                <IconDownOutlined />
              </span>
            )}
            items={undefined}
            expandIconPosition={'end'}
          >
            <Panel header="Tactics" key="1">
              <Row
                justify="space-between"
                align="middle"
                className="ut-form ut-mb-m"
              >
                <Col span={8}>
                  <span className="ut-label">Level</span>
                </Col>
                <Col span={16}>
                  {promoLevels.length < 1 ? (
                    <Skeleton.Input active={true} size={'default'} />
                  ) : (
                    <Select
                      className="ut-select"
                      suffixIcon={<CaretDownFilled />}
                      defaultValue={
                        selectedTemplateDetail?.promoData?.products
                          ? formateSelectVal(
                              convertObjToIdFormat(
                                promoLevels,
                                selectedTemplateDetail?.promoData?.products &&
                                  selectedTemplateDetail?.promoData?.products
                                    ?.length > 0
                                  ? selectedTemplateDetail?.promoData
                                      ?.products[0]
                                  : { level: '' },
                              ),
                              'promoLevel',
                            )
                          : formateSelectVal(
                              promoLevels[0] || {
                                id: '00',
                                name: 'Please select',
                              },
                              'promoLevel',
                            ) || 'Please Select'
                      }
                      onChange={(e: any) => handleChange(e, 'promoLevel')}
                      options={promoLevels?.map((item: any) => {
                        return formateSelectVal(item, 'promoLevel');
                      })}
                    />
                  )}
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                className="ut-form ut-mb-m"
              >
                <Col span={8}>
                  <span className="ut-label">Type</span>
                </Col>
                <Col span={16}>
                  {promoTypes.length < 1 ? (
                    <Skeleton.Input active={true} size={'default'} />
                  ) : (
                    <Select
                      className="ut-select"
                      suffixIcon={<CaretDownFilled />}
                      defaultValue={
                        selectedTemplateDetail?.promoData?.promoType
                          ? formateSelectVal(
                              selectedTemplateDetail?.promoData?.promoType,
                              'promoType',
                            )
                          : formateSelectVal(
                              promoTypes[0] || {
                                id: '00',
                                name: 'Please select',
                              },
                              'promoType',
                            )
                      }
                      onChange={(e: any) => handleChange(e, 'promoType')}
                      options={promoTypes?.map((type: any) => {
                        return formateSelectVal(type, 'promoType');
                      })}
                    />
                  )}
                </Col>
              </Row>
              {!isCustomerDisabled && (
                <Row
                  justify="space-between"
                  align="middle"
                  className="ut-form ut-mb-m multi-customer"
                >
                  <Col span={8}>
                    <span className="ut-label">Customers</span>
                  </Col>
                  <Col span={16}>
                    {promoCustomers.length < 1 ? (
                      <Skeleton.Input
                        active={true}
                        size={'default'}
                        className="!w-[100%]"
                      />
                    ) : (
                      <Select
                        mode="multiple"
                        className="ut-select"
                        suffixIcon={<CaretDownFilled />}
                        onChange={(selectedIds) =>
                          handleMultiSelectCustomersChange(
                            selectedIds,
                            promoCustomers,
                          )
                        }
                        options={
                          true
                            ? promoCustomers
                                ?.filter(
                                  (type: any, index: number, self: any[]) =>
                                    index ===
                                    self.findIndex((t) => t.id === type.id),
                                )
                                .map((type: any) =>
                                  formateSelectVal(type, 'customers'),
                                )
                            : []
                        }
                        disabled={isCustomerDisabled}
                        placeholder="Please select customers"
                        // open={
                        //   !isCustomerDisabled &&
                        //   createPromoPayload?.dimensions?.customers?.length == 0
                        // }
                      />
                    )}
                  </Col>
                </Row>
              )}
            </Panel>
          </Collapse>
          <Collapse
            defaultActiveKey={['1']}
            onChange={onChange}
            className="ut-collapse"
            expandIcon={({ isActive }) => (
              <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
                <IconDownOutlined />
              </span>
            )}
            items={undefined}
            expandIconPosition={'end'}
          >
            <Panel header="Hierarchy" key="1">
              <Row
                gutter={8}
                className="ut-hierarchy-columns ut-hierarchy-detail-columns"
              >
                <Col span={12}>
                  <Col>
                    {false ? (
                      <div className="ut-boxes products active">
                        <Skeleton.Avatar
                          shape={'square'}
                          style={{ width: '100%', height: 78 }}
                          active={true}
                        />
                      </div>
                    ) : (
                      <div
                        className="ut-boxes products active"
                        onClick={() => showHierarchyModal('products')}
                      >
                        <div className="top">
                          <h6>{productsCount} Products are selected</h6>
                          <span className="label">Products</span>
                        </div>
                        <div className="bottom">
                          {productLevels?.length > 0 &&
                            productLevels.map((product: any, index: number) => {
                              if (index > 1) {
                                return null;
                              } else {
                                return (
                                  <>
                                    <div>
                                      <span>
                                        {splitCamelCase(product?.level_name)}:{' '}
                                      </span>
                                      {formateHierarchyBoxTagsDynamic(
                                        productNames,
                                        product.level_name
                                          .toLowerCase()
                                          .split(' ')
                                          .join('_'),
                                        true, //isEdit=true to handle edit flow
                                        1,
                                      )}
                                    </div>
                                  </>
                                );
                              }
                            })}
                        </div>
                      </div>
                    )}
                  </Col>
                </Col>
                <Col span={12}>
                  <Col>
                    {' '}
                    {false ? (
                      <div className="ut-boxes geo active">
                        <Skeleton.Avatar
                          shape={'square'}
                          style={{ width: '100%', height: 78 }}
                          active={true}
                        />
                      </div>
                    ) : (
                      <div
                        className="ut-boxes geo active"
                        onClick={() => showHierarchyModal('geo')}
                      >
                        <div className="top">
                          <h6>{geosCount} Distributors are selected</h6>
                          <span className="label">Geo</span>
                        </div>
                        <div className="bottom">
                          {geoLevels?.length > 0 &&
                            geoLevels.map((geoItem: any, index: number) => {
                              if (index > 1) {
                                return null;
                              } else {
                                return (
                                  <>
                                    <div>
                                      <span>
                                        {splitCamelCase(geoItem?.level_name)}:{' '}
                                      </span>
                                      {formateHierarchyBoxTagsDynamic(
                                        geoNames,
                                        geoItem.level_name
                                          .toLowerCase()
                                          .split(' ')
                                          .join('_'),
                                        true,
                                        1, //tags count
                                      )}
                                    </div>
                                  </>
                                );
                              }
                            })}
                        </div>
                      </div>
                    )}
                  </Col>
                </Col>
                {isCustomerDisabled && (
                  <Col span={12}>
                    <Col>
                      {false ? (
                        <div className="ut-boxes channel active">
                          <Skeleton.Avatar
                            shape={'square'}
                            style={{ width: '100%', height: 78 }}
                            active={true}
                          />
                        </div>
                      ) : (
                        <div
                          className="ut-boxes channel active"
                          onClick={() => showHierarchyModal('channel')}
                        >
                          <div className="top">
                            <h6>{channelsCount} Channels are selected</h6>
                            <span className="label">Channel</span>
                          </div>
                          <div className="bottom">
                            {channelLevels?.length > 0 &&
                              channelLevels.map(
                                (channelItem: any, index: number) => {
                                  if (index > 1) {
                                    return null;
                                  } else {
                                    return (
                                      <>
                                        <div>
                                          <span>
                                            {splitCamelCase(
                                              channelItem?.level_name?.split(
                                                ' ',
                                              )[0],
                                            )}
                                            :{' '}
                                          </span>
                                          {formateHierarchyBoxTagsDynamic(
                                            channelNames,
                                            channelItem.level_name
                                              .toLowerCase()
                                              .split(' ')
                                              .join('_'),
                                            true,
                                            1, //tags count
                                          )}
                                        </div>
                                      </>
                                    );
                                  }
                                },
                              )}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Col>
                )}
                {isCustomerDisabled && (
                  <Col span={12}>
                    <Col>
                      {false ? (
                        <div className="ut-boxes channel active">
                          <Skeleton.Avatar
                            shape={'square'}
                            style={{ width: '100%', height: 78 }}
                            active={true}
                          />
                        </div>
                      ) : (
                        <div
                          className="ut-boxes outlet active"
                          onClick={() => showHierarchyModal('outlet')}
                          style={{
                            height: 88,
                          }}
                        >
                          <div className="top">
                            <h6 className="mr-1">
                              {outletKeysLength && outletKeysLength > 0
                                ? outletKeysLength
                                : outletsCount}{' '}
                              Outlets are selected
                            </h6>
                            <span className="label">Outlet</span>
                          </div>
                          {(outletKeys?.length === 0 &&
                            outletKeys?.length !== outlets.length) ||
                            (outletsCount !== outlets.length && (
                              <div className="bottom">
                                <div>
                                  <span>Outlets:</span>
                                </div>
                              </div>
                            ))}
                          {(outletKeys?.length || outletsCount) ===
                            outlets.length && (
                            <div className="bottom">
                              <div>
                                <span>Outlets: All</span>
                              </div>
                            </div>
                          )}
                          {outletKeys?.length >= 1 &&
                            outletKeys?.length !== outlets.length && (
                              <div className="bottom">
                                <div>
                                  <span>Outlets:</span>
                                  {outletKeys?.map((item: any, index: any) => {
                                    if (index === 0) {
                                      return (
                                        <Tag key={item}>
                                          {
                                            outlets.find(
                                              (outlet: any) =>
                                                outlet.id === item,
                                            ).name
                                          }
                                        </Tag>
                                      );
                                    }
                                  })}
                                  {outletKeys?.length > 1 && (
                                    <Popover
                                      content={outletKeys?.map(
                                        (item: any, idx: any) => {
                                          if (idx > 0) {
                                            return (
                                              <Tag style={{ marginBottom: 10 }}>
                                                {
                                                  outlets.find(
                                                    (outlet: any) =>
                                                      outlet.id === item,
                                                  ).name
                                                }
                                              </Tag>
                                            );
                                          }
                                        },
                                      )}
                                      trigger="hover"
                                    >
                                      <Tag>{outletKeys?.length - 1} more</Tag>
                                    </Popover>
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                    </Col>
                  </Col>
                )}
              </Row>
            </Panel>
          </Collapse>
          <Collapse
            defaultActiveKey={['1']}
            onChange={onChange}
            className="ut-collapse"
            expandIcon={({ isActive }) => (
              <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
                <IconDownOutlined />
              </span>
            )}
            items={undefined}
            expandIconPosition={'end'}
          >
            <Panel header="Offer" key="1" className="ut-form">
              <Row justify="space-between" align="middle" className="ut-mb-m">
                <Col span={8}>
                  <span className="ut-label">Discount Method</span>
                </Col>
                <Col span={16}>
                  {promoDiscountTypes.length < 1 ? (
                    <Skeleton.Input active={true} size={'default'} />
                  ) : (
                    <Select
                      className="ut-select"
                      suffixIcon={<CaretDownFilled />}
                      defaultValue={
                        selectedTemplateDetail?.promoData?.discountType
                          ? formateSelectVal(
                              selectedTemplateDetail?.promoData?.discountType,
                              'discountType',
                            )
                          : formateSelectVal(
                              promoDiscountTypes[0] || {
                                id: '00',
                                name: 'Please select',
                              },
                              'discountType',
                            ) || 'Please Select'
                      }
                      onChange={(e: any) => handleChange(e, 'discountType')}
                      options={promoDiscountTypes?.map((discount: any) => {
                        return formateSelectVal(discount, 'discountType');
                      })}
                    />
                  )}
                </Col>
              </Row>
              <Form.Item
                label={'Discount'}
                name="discountValue"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_FEILD,
                  },
                ]}
                initialValue={
                  Number(selectedTemplateDetail?.discountValue) || null
                }
                className="discountValueQuick"
              >
                <Input
                  type="number"
                  onChange={(e: any) => handleInputChange(e, 'discountValue')}
                  suffix={
                    createPromoPayload?.dimensions?.discountType?.id == '7'
                      ? '%'
                      : 'PKR'
                  }
                  className="!py-0 !px-0 !pe-2"
                  style={{
                    borderStyle: 'none',
                  }}
                />
              </Form.Item>
              <Row justify="space-between" align="middle" className="ut-mb-m">
                <Col span={8}>
                  <span className="ut-label">Apply On</span>
                </Col>
                <Col span={16}>
                  {promoApplyOns.length < 1 ? (
                    <Skeleton.Input active={true} size={'default'} />
                  ) : (
                    <Select
                      className="ut-select"
                      suffixIcon={<CaretDownFilled />}
                      defaultValue={
                        selectedTemplateDetail?.promoData?.applyOn
                          ? formateSelectVal(
                              selectedTemplateDetail?.promoData?.applyOn,
                              'applyOn',
                            )
                          : formateSelectVal(
                              promoApplyOns[1] || {
                                id: '00',
                                name: 'Please select',
                              },
                              'applyOn',
                            ) || 'Please Select'
                      }
                      onChange={(e: any) => handleChange(e, 'applyOn')}
                      options={promoApplyOns?.map((apply: any) => {
                        return formateSelectVal(apply, 'applyOn');
                      })}
                    />
                  )}
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={8}>
                  <span className="ut-label">Apply UOM</span>
                </Col>
                <Col span={16}>
                  {promoApplyUoms.length < 1 ? (
                    <Skeleton.Input active={true} size={'default'} />
                  ) : (
                    <Select
                      className="ut-select"
                      suffixIcon={<CaretDownFilled />}
                      defaultValue={
                        selectedTemplateDetail?.promoData?.applyUOM
                          ? formateSelectVal(
                              selectedTemplateDetail?.promoData?.applyUOM,
                              'applyUOM',
                            )
                          : formateSelectVal(
                              promoApplyUoms[0] || {
                                id: '00',
                                name: 'Please select',
                              },
                              'applyUOM',
                            ) || 'Please Select'
                      }
                      onChange={(e: any) => handleChange(e, 'applyUOM')}
                      options={promoApplyUoms?.map((uom: any) => {
                        return formateSelectVal(uom, 'applyUOM');
                      })}
                    />
                  )}
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Row justify="end" align="middle">
            <Button
              type="primary"
              onClick={addMoreDetail}
              htmlType="submit"
              className="ut-btn light-btn mr-auto"
            >
              Add more detail
            </Button>
            {/* <Dropdown.Button
              className="w-fit mr-2"
              menu={menuProps}
              onClick={handleButtonClick}
            >
              Save
            </Dropdown.Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                if (
                  !createPromoPayload?.promoName ||
                  !createPromoPayload?.description ||
                  !createPromoPayload?.discountValue
                ) {
                  // dispatch(
                  //   toastMessageActions.showToastMessage({
                  //     type: 'error',
                  //     content:
                  //       'Please enter Promotion Name, Description, and Discount Value!',
                  //   }),
                  // );
                  return;
                } else {
                  setIsModalDialogOpen(true);
                }
              }}
              className="ut-btn"
            >
              Submit
            </Button> */}
            {/* <Button
              type="primary"
              htmlType="submit"
              onClick={saveAsTemplate}
              className="ut-btn mr-2"
            >
              Save as template
            </Button>
            */}
            <Button
              type="primary"
              htmlType="submit"
              onClick={createPromo}
              className="ut-btn"
            >
              Create
            </Button>
          </Row>
        </Form>
      </Drawer>
      {isHierarchyModalOpen && (
        <HierarchyModal
          modalType={hierarchyModalType}
          isModalOpen={isHierarchyModalOpen}
          handleOk={handleHierarchyOk}
          handleCancel={handleHierarchyCancel}
          handleHierarchyModalType={showHierarchyModal}
          // handleProductDetailModal={showProductDetailModal}
          isProductDetailModal={false}
          editPromoPayload={createPromoPayload}
          defaultLevelValue={defaultLevelValue}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          geoCheckedKeys={geoCheckedKeys}
          channelCheckedKeys={channelCheckedKeys}
          outletCheckedKeys={outletCheckedKeys}
          treeSelectionPayload={createPromoPayload}
          isEdit={false}
          setOutletKeys={setOutletKeys}
          outletKeys={outletKeys}
          setOutletKeysLength={setOutletKeysLength}
          isCustomerDisabled={isCustomerDisabled}
        />
      )}
      <ModalDialogSmall
        isOpen={isModalDialogOpen}
        handleModalCancel={handleModalDialogClose}
        handleModalSubmit={handleModalDialogSubmit}
        modalDialogType={'Submit'}
        promoId={null}
      />
    </>
  );
};

export default QuickCreateEventModal;
