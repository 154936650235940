import dayjs from 'dayjs';
import {
  ICalendarView,
  ICalendarViewListFormate,
  ICalendarViewPromotion,
  ICalendarViewPromotionListResponse,
} from './ducks/types';

// Orange - Send Back
// Red - Rejected
// Green - Approved
// Yellow - Submitted
// Purple - Draft
export enum Status {
  Draft = 'draft',
  SendBack = 'sentback',
  Approved = 'approved',
  Submitted = 'submitted',
  Rejected = 'rejected',
}
export const statusColor = (color: string) => {
  let statusC = '';
  switch (color) {
    case 'draft':
      statusC = 'purple';
      break;
    case 'send back':
      statusC = 'orange';
      break;
    case 'approved':
      statusC = 'green';
      break;
    case 'submitted':
      statusC = 'yellow';
      break;
    default:
      statusC = '';
  }
  return statusC;
};

export const statusClass = (color: string) => {
  let statusC = '';
  switch (color) {
    case 'draft':
      statusC = 'draft';
      break;
    case 'send back':
      statusC = 'sendback';
      break;
    case 'approved':
      statusC = 'approved';
      break;
    case 'submitted':
      statusC = 'submitted';
      break;
    case 'rejected':
      statusC = 'rejected';
      break;
    default:
      statusC = 'sendback';
  }
  return statusC;
};

export const oneDayMinusFromStringDate = (dateStr: string) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() - 1);
  return date?.toISOString()?.split('T')[0];
};
export const oneDayPlusFromStringDate = (dateStr: string) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() + 1);
  return date?.toISOString()?.split('T')[0];
};

export const formateCalendarView = (
  res: ICalendarViewPromotionListResponse,
): ICalendarViewListFormate => {
  let payload: ICalendarView[] = [];
  if (Array.isArray(res.data)) {
    payload = res.data.map((view: ICalendarViewPromotion) => {
      const endDate = dayjs(view.endDate).format('YYYY-MM-DD');
      const p: ICalendarView = {
        id: view.id,
        promoId: view.id,
        title: view.promoName,
        promoCode: view.promoCode,
        status: view.status,
        start: dayjs(view.startDate).format('YYYY-MM-DD'),
        end: oneDayPlusFromStringDate(endDate),
        promoType: view.promoType,
        totalROI: view.totalROI,
        classNames: ['ut-event', `ut-bg-${statusClass(view.status)}`],
        promoLevel: view.promoLevel,
        totalVolume: view.totalVolume,
        totalCost: view.totalCost,
        totalLift: view.totalLift,
        productsCount: view.productsCount,
        geosCount: view.geosCount,
        channelsCount: view.channelsCount,
        outletsCount: view.outletsCount,
      };
      return p;
    });
  } else {
    const view = res.data as ICalendarViewPromotion;
    const endDate = dayjs(view.endDate).format('YYYY-MM-DD');
    const p: ICalendarView = {
      id: view.id,
      promoId: view.id,
      title: view.promoName,
      promoCode: view.promoCode,
      status: view.status,
      start: dayjs(view.startDate).format('YYYY-MM-DD'),
      end: oneDayPlusFromStringDate(endDate),
      promoType: view.promoType,
      totalROI: view.totalROI,
      classNames: ['ut-event', `ut-bg-${statusClass(view.status)}`],
      promoLevel: view.promoLevel,
      totalVolume: view.totalVolume,
      totalCost: view.totalCost,
      totalLift: view.totalLift,
      productsCount: view.productsCount,
      geosCount: view.geosCount,
      channelsCount: view.channelsCount,
      outletsCount: view.outletsCount,
    };
    payload.push(p);
  }

  return { data: payload };
};
