import { ReactElement, useEffect, useState } from 'react';
import { Row, Button, Drawer } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import HierarchyFilter from './HierarchyFilter';
// import DiscountFilter from './DiscountFilter';
import type { Dayjs } from 'dayjs';
import TacticsFilter from './TacticsFilter';
import PromotionFilter from './PromotionFilter';
import DurationFilter from './DurationFilter';
import {
  getHeaderFilterPromoDuration,
  headerActions,
} from 'app/modules/AppHeader/ducks/slice';
import { removeEmptyKeys } from 'utils/helper';
import {
  clearFilterCalendarView,
  filterPromotions,
} from 'app/modules/CalendarView/ducks/slice';
import HierarchyModal from 'app/modules/Promotion/hierarchyModal';
import {
  extractKeys,
  getLevelWiseSelections,
  removeChildrenKey,
} from 'app/modules/Promotion/helper';
import { toastMessageActions } from '../ToastMessage/ducks/slice';
import {
  getChannelTree,
  getGeoTree,
  getProdcutTree,
  getPromoDuration,
  getPromoDurationWeeks,
  getOutletList,
} from 'app/modules/Promotion/ducks/slice';

interface IProps {
  onFilter: () => void;
}
const HeaderFilter = ({ onFilter }: IProps): ReactElement => {
  // {
  //   "promotionType": "string",
  //   "promotionStatus": "string",
  //   "promoCode": "string",
  //   "promoName": "string",
  //   "startDate": "string",
  //   "endDate": "string",
  //   "dimensions": {
  //     "products": [
  //       "string"
  //     ],
  //     "geos": [
  //       "string"
  //     ],
  //     "channels": [
  //       "string"
  //     ],
  //     "outlets": [
  //       "string"
  //     ]
  //   }
  // }
  const currentDate = dayjs().format('YYYY-MM-DD');
  const header = useAppSelector((state) => state.appHeader);
  const { promotions } = useAppSelector((state: any) => state);
  const {
    promotions: { productTree, geoTree, channelTree },
  } = useAppSelector((state: any) => state);
  const { promoStatus, promoTypes, promoDuration, promoDurationWeeks } =
    promotions;
  const dispatch = useAppDispatch();

  const onCancel = () => dispatch(headerActions.setHeaderFilterModal(false));

  const [filterPayload, setfilterPayload] = useState<any>({
    dimensions: {
      geos: ['all'],
      channels: ['all'],
      products: [
        {
          level: 'lastLevel', //to get last level
          selections: ['all'],
        },
      ],
    },
  });

  const [promoDurationValue, setPromoDurationValue] = useState<any>({});
  const [promoDurationWeeksValue, setPromoDurationWeeksValue] = useState<any>(
    [],
  );

  //modal
  const [defaultLevelValue, setDefaultLevelValue] = useState('lastLevel');
  const [hierarchyModalType, setHierarchyModalType] = useState('');
  const [isHierarchyModalOpen, setIsHierarchyModalOpen] = useState(false);
  //tree
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [geoCheckedKeys, setGeoCheckedKeys] = useState<React.Key[]>([]);
  const [channelCheckedKeys, setChannelCheckedKeys] = useState<React.Key[]>([]);
  const [outletCheckedKeys, setOutletCheckedKeys] = useState<React.Key[]>([]);

  // Quick Hierarchy Selection
  const showHierarchyModal = (modalType: string) => {
    setHierarchyModalType(modalType);
    setIsHierarchyModalOpen(true);
  };

  const handleHierarchyOk = () => {
    setIsHierarchyModalOpen(false);
    setHierarchyModalType('');
  };

  const handleHierarchyCancel = () => {
    setIsHierarchyModalOpen(false);
    setHierarchyModalType('');
  };

  useEffect(() => {
    dispatch(
      getPromoDuration({ startDate: currentDate, endDate: currentDate }),
    );
    dispatch(getProdcutTree('lastLevel'));
    dispatch(getGeoTree());
    dispatch(getChannelTree());
    dispatch(getOutletList());
    dispatch(
      getPromoDurationWeeks({ allWeeks: true, year: new Date().getFullYear() }),
    );
  }, []);

  //on tree get
  useEffect(() => {
    // products
    if (checkedKeys.length === 0 && productTree.length > 0) {
      let checkedKeyVal = [];

      if (productTree.length > 0 && defaultLevelValue !== null) {
        checkedKeyVal = extractKeys(productTree);
      }
      setCheckedKeys(checkedKeyVal);
    }
    if (geoCheckedKeys.length === 0 && geoTree.length > 0) {
      if (geoCheckedKeys.length > 0) return;
      let geoCheckedKeyVal = [];
      geoCheckedKeyVal = extractKeys(geoTree);
      setGeoCheckedKeys(geoCheckedKeyVal);
    }

    //for channel default selections
    if (channelCheckedKeys.length === 0 && channelTree.length > 0) {
      if (channelCheckedKeys.length > 0) return;
      let channelCheckedKeyVal = [];
      channelCheckedKeyVal = extractKeys(channelTree);

      setChannelCheckedKeys(channelCheckedKeyVal);
    }
  }, [productTree, geoTree, channelTree, checkedKeys]);
  useEffect(() => {
    if (promoDuration) {
      const lastWeekIndex = promoDuration.length - 1;
      const finalVal =
        Number(promoDuration && promoDuration[lastWeekIndex]?.week_of_year) -
        Number(promoDuration[0]?.week_of_year);
      setPromoDurationValue({
        duration: finalVal,
        startWeek: promoDuration && promoDuration[0]?.week_of_year,
        endWeek: promoDuration && promoDuration[lastWeekIndex]?.week_of_year,
        dateEnd: promoDuration?.dateEnd || promoDurationValue?.dateEnd,
        dateStart: promoDuration?.dateStart || promoDurationValue?.dateStart,
      });
      if (promoDuration && promoDuration[0]?.calendar_date) {
        setfilterPayload({
          ...filterPayload,
          startDate: promoDuration[0]?.calendar_date,
          endDate: promoDuration[lastWeekIndex]?.calendar_date,
        });
      }
    }
  }, [promoDuration]);

  useEffect(() => {
    if (promoDurationWeeks?.weeks) {
      const aweeksObject = promoDurationWeeks?.weeks?.map((week: any) => ({
        value: week.toString(),
        label: week.toString(),
      }));
      setPromoDurationWeeksValue(aweeksObject);
    }
    if (promoDurationWeeks?.dateStart) {
      setPromoDurationValue({
        ...promoDurationValue,
        dateEnd: promoDurationWeeks?.dateEnd || promoDurationValue?.endDate,
        dateStart:
          promoDurationWeeks?.dateStart || promoDurationValue?.startDate,
      });
      setfilterPayload({
        ...filterPayload,
        startDate:
          promoDurationWeeks?.dateStart || promoDurationValue?.startDate,
        endDate: promoDurationWeeks?.dateEnd || promoDurationValue?.endDate,
      });
    }
    if (promoDurationWeeks?.weekStart) {
      setPromoDurationValue({
        ...promoDurationValue,
        weekEnd: promoDurationWeeks?.weekEnd || promoDurationValue?.weekEnd,
        weekStart:
          promoDurationWeeks?.weekStart || promoDurationValue?.weekStart,
      });
    }
  }, [promoDurationWeeks]);

  const getPromotionDurationWeeks = (startWeek?: any, endWeek?: any) => {
    const startWeekValue = startWeek || promoDurationValue?.startWeek;
    const endWeekValue = endWeek || promoDurationValue?.endWeek;
    if (+endWeekValue >= +startWeekValue) {
      dispatch(
        getPromoDurationWeeks({
          startWeek: startWeekValue,
          startYear: new Date().getFullYear(),
          endWeek: endWeekValue,
          endYear: new Date().getFullYear(),
        }),
      );
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: 'End week is less than start week',
        }),
      );
    }
  };

  const onChangeSelectStartWeek = (value: string) => {
    setPromoDurationValue({
      ...promoDurationValue,
      startWeek: value,
    });
    getPromotionDurationWeeks(value);
  };

  const onChangeSelectEndWeek = (value: string) => {
    setPromoDurationValue({
      ...promoDurationValue,
      endWeek: value,
    });
    getPromotionDurationWeeks(null, value);
  };

  const filterOptionStartWeek = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const filterOptionEndWeek = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onCheck: any = (checkedKeysValue: React.Key[], info: any) => {
    if (info.checked) {
      let updatedPayload = {
        ...filterPayload,
      };
      if (hierarchyModalType === 'products') {
        updatedPayload = {
          ...filterPayload,

          dimensions: {
            ...filterPayload?.dimensions,
            products: [
              {
                level:
                  (filterPayload &&
                    filterPayload?.dimensions?.products?.length &&
                    filterPayload?.dimensions?.products[0]?.level) ||
                  defaultLevelValue,
                selections: ['all'],
              },
            ],
          },
        };

        if (info.node.key !== 'all' && checkedKeysValue.length) {
          if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
            updatedPayload = {
              ...filterPayload,

              dimensions: {
                ...filterPayload?.dimensions,

                products: [
                  {
                    level:
                      (filterPayload &&
                        filterPayload?.dimensions?.products?.length &&
                        filterPayload?.dimensions?.products[0]?.level) ||
                      defaultLevelValue,
                    selections: ['all'],
                  },
                ],
              },
            };
          } else {
            const selectedObj = info.checkedNodes.filter((obj: any) =>
              obj.hasOwnProperty('selectionID'),
            );

            updatedPayload = {
              ...filterPayload,

              dimensions: {
                ...filterPayload?.dimensions,

                products: [
                  {
                    level:
                      (filterPayload &&
                        filterPayload?.dimensions?.products?.length &&
                        filterPayload?.dimensions?.products[0]?.level) ||
                      defaultLevelValue,
                    selections: selectedObj,
                  },
                ],
              },
            };
          }
        }

        setfilterPayload(updatedPayload);
        //dispatch(updatePromotion(updatedPayload, promotionId));
      } else if (hierarchyModalType === 'geo') {
        updatedPayload = {
          ...filterPayload,
          dimensions: {
            ...filterPayload?.dimensions,
            geos: ['all'],
          },
        };

        if (info.node.key !== 'all' && checkedKeysValue.length) {
          if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
            updatedPayload = {
              ...filterPayload,
              dimensions: {
                ...filterPayload?.dimensions,
                geos: ['all'],
              },
            };
          } else {
            const selectedObj = info.checkedNodes.filter((obj: any) =>
              obj.hasOwnProperty('selectionID'),
            );
            updatedPayload = {
              ...filterPayload,
              dimensions: {
                ...filterPayload?.dimensions,
                geos: getLevelWiseSelections(
                  removeChildrenKey(info.checkedNodes),
                ),
              },
            };
          }
        }
        setfilterPayload(updatedPayload);
        // dispatch(updatePromotion(updatedPayload, promotionId));
      } else if (hierarchyModalType === 'channel') {
        updatedPayload = {
          ...filterPayload,
          dimensions: {
            ...filterPayload?.dimensions,
            channels: ['all'],
          },
        };

        if (info.node.key !== 'all' && checkedKeysValue.length) {
          if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
            updatedPayload = {
              ...filterPayload,
              dimensions: {
                ...filterPayload?.dimensions,
                channels: ['all'],
              },
            };
          } else {
            const selectedObj = info.checkedNodes.filter((obj: any) =>
              obj.hasOwnProperty('selectionID'),
            );
            updatedPayload = {
              ...filterPayload,
              dimensions: {
                ...filterPayload?.dimensions,
                channels: getLevelWiseSelections(
                  removeChildrenKey(info.checkedNodes),
                ),
              },
            };
          }
        }
        setfilterPayload(updatedPayload);
        // dispatch(updatePromotion(updatedPayload, promotionId));
      }
    } else {
      if (info.node.key !== 'all' && checkedKeysValue.length) {
        let updatedPayload = {
          ...filterPayload,
        };
        if (hierarchyModalType === 'products') {
          if (info.checkedNodes.some((obj: any) => obj.key === 'all')) {
            updatedPayload = {
              ...filterPayload,

              dimensions: {
                ...filterPayload?.dimensions,

                products: [
                  {
                    level:
                      (filterPayload &&
                        filterPayload?.dimensions?.products?.length &&
                        filterPayload?.dimensions?.products[0]?.level) ||
                      defaultLevelValue,
                    selections: ['all'],
                  },
                ],
              },
            };
          } else {
            const selectedObj = info?.checkedNodes?.filter((obj: any) =>
              obj.hasOwnProperty('selectionID'),
            );
            updatedPayload = {
              ...filterPayload,

              dimensions: {
                ...filterPayload?.dimensions,

                products: [
                  {
                    level:
                      (filterPayload &&
                        filterPayload?.dimensions?.products?.length &&
                        filterPayload?.dimensions?.products[0]?.level) ||
                      defaultLevelValue,
                    selections: selectedObj,
                  },
                ],
              },
            };
          }
        } else if (hierarchyModalType === 'geo') {
          updatedPayload = {
            ...filterPayload,

            dimensions: {
              ...filterPayload?.dimensions,
              geos: getLevelWiseSelections(
                removeChildrenKey(info.checkedNodes),
              ),
            },
          };
        } else if (hierarchyModalType === 'channel') {
          updatedPayload = {
            ...filterPayload,

            dimensions: {
              ...filterPayload?.dimensions,
              channels: getLevelWiseSelections(
                removeChildrenKey(info.checkedNodes),
              ),
            },
          };
        }

        setfilterPayload(updatedPayload);
        //dispatch(updatePromotion(updatedPayload, promotionId));
      } else if (!checkedKeysValue.length) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: 'Select atleast one product',
          }),
        );
      }
    }

    if (hierarchyModalType === 'products') {
      setCheckedKeys(checkedKeysValue);
    } else if (hierarchyModalType === 'geo') {
      setGeoCheckedKeys(checkedKeysValue);
    } else if (hierarchyModalType === 'channel') {
      setChannelCheckedKeys(checkedKeysValue);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    name: string,
  ) => {
    const value = e.target.value;

    setfilterPayload((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (id: string, name: string) => {
    let promoState: any = [];
    switch (name) {
      case 'promotionType':
        promoState = promoTypes;
        break;
      case 'promotionStatus':
        promoState = promoStatus;
        break;

      default:
        promoState = [];
    }

    if (name === 'promotionStatus') {
      setfilterPayload((prevState: any) => ({
        ...prevState,
        [name]: id,
      }));
    } else {
      const filteredPromo = promoState?.filter(
        (type: { id: string }) => type.id === id,
      );

      const updatedPayload = {
        ...filterPayload,
        //on promoType show all if shortCode not exists
        [name]: filteredPromo[0]?.shortCode || 'all',
      };
      setfilterPayload(updatedPayload);
    }
  };

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[],
  ) => {
    const updatedPayload = {
      ...filterPayload,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    };
    setfilterPayload(updatedPayload);
    if (dateStrings[0] && dateStrings[1]) {
      setPromoDurationValue({
        ...promoDurationValue,
        dateStart: dateStrings[0],
        dateEnd: dateStrings[1],
      });
      dispatch(
        getPromoDuration({
          startDate: dateStrings[0],
          endDate: dateStrings[1],
        }),
      );
      dispatch(
        getHeaderFilterPromoDuration({
          startDate: dateStrings[0],
          endDate: dateStrings[1],
        }),
      );
    }
  };

  const handleFilter = () => {
    const payload = removeEmptyKeys(filterPayload);

    if (Object.keys(payload).length >= 1) {
      dispatch(filterPromotions(payload));
    }
  };
  const handleClearFilter = () => {
    dispatch(clearFilterCalendarView());

    setfilterPayload({});
  };

  return (
    <>
      <Drawer
        className="ut-drawer"
        title="Filter Selection"
        placement="right"
        open={header.filter?.isFilterModalOpen}
        onClose={onCancel}
        closeIcon={<CloseCircleOutlined />}
        footer={
          <div className="flex justify-between px-4">
            <Button className="ut-btn light-btn" onClick={handleClearFilter}>
              Clear filter
            </Button>
            <Button className="ut-btn" onClick={handleFilter} type="primary">
              Apply filter
            </Button>
          </div>
        }
      >
        <HierarchyFilter showHierarchyModal={showHierarchyModal} />
        <DurationFilter
          filterPayload={filterPayload}
          onRangeChange={onRangeChange}
          onChangeSelectStartWeek={onChangeSelectStartWeek}
          onChangeSelectEndWeek={onChangeSelectEndWeek}
          filterOptionStartWeek={filterOptionStartWeek}
          filterOptionEndWeek={filterOptionEndWeek}
          promoDurationWeeksValue={promoDurationWeeksValue}
          promoDurationValue={promoDurationValue}
        />
        <TacticsFilter
          filterPayload={filterPayload}
          handleChange={handleChange}
        />
        <PromotionFilter
          filterPayload={filterPayload}
          handleInputChange={handleInputChange}
        />
      </Drawer>
      {isHierarchyModalOpen && (
        <HierarchyModal
          modalType={hierarchyModalType}
          isModalOpen={isHierarchyModalOpen}
          handleOk={handleHierarchyOk}
          handleCancel={handleHierarchyCancel}
          handleHierarchyModalType={showHierarchyModal}
          // handleProductDetailModal={showProductDetailModal}
          isProductDetailModal={false}
          editPromoPayload={filterPayload}
          defaultLevelValue={defaultLevelValue}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          geoCheckedKeys={geoCheckedKeys}
          channelCheckedKeys={channelCheckedKeys}
          outletCheckedKeys={outletCheckedKeys}
          treeSelectionPayload={filterPayload}
          isEdit={false}
          isCustomerDisabled={true}
        />
      )}
    </>
  );
};

export default HeaderFilter;
