import { ReactElement } from 'react';
import ResetPassword from 'app/modules/Auth/ResetPassword';

const ResetPasswordPage = (): ReactElement => (
  <>
    <ResetPassword />
  </>
);

export default ResetPasswordPage;
