import { useEffect, useState } from 'react';
import FilterHeader from './FilterHeader';
import ReportTable from './ReportTable';
import { getReportDataApi } from './ducks/service';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { headerActions } from '../AppHeader/ducks/slice';
import HeaderFilter from 'app/molecules/HeaderFilter';
import { IReportDataCategory } from './ducks/type';

const List = () => {
  const [reportData, setReportData] = useState<IReportDataCategory[]>([]);
  const filter = useAppSelector((state) => state.appHeader.filter);
  const dispatch = useAppDispatch();
  useEffect(() => {
    getReportDataApi()
      .then((res) => setReportData(res.data))
      .catch((err) => {});
  }, []);

  const onFilter = () => {
    dispatch(headerActions.setHeaderFilterModal(false));
    //  use 'filter' here
    getReportDataApi()
      .then((res) => {
        if (res) {
          setReportData(res.data);
        }
      })
      .catch((err) => {});
  };
  return (
    <div className="ut-card">
      <FilterHeader />

      {reportData ? <ReportTable reportData={reportData} /> : ''}
      <HeaderFilter onFilter={onFilter} />
    </div>
  );
};

export default List;
