import React, { ReactElement } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import './style.css';

const ButtonWrap = (props: any): ReactElement => {
  const { children, value, btnclass, loading, disabled } = props;
  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={`button-class ${!btnclass ? '' : btnclass}`}
      disabled={loading || disabled}
    >
      {children}
      {value}
    </Button>
  );
};

ButtonWrap.defaultProps = {
  value: 'Button',
  btnclass: 'btn-class',
  loading: false,
  children: <span />,
};

// interface ButtonInterface extends ButtonProps {
//   value?: string;
//   btnclass?: string;
//   loading?: boolean;
//   children?: ReactElement;
// }
export default ButtonWrap;
