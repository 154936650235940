import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'store';
import { loadingActions } from 'app/molecules/Loader/ducks/slice';
import { toastMessageActions } from 'app/molecules/ToastMessage/ducks/slice';
import * as promotionSource from './services';

import {
  INewTemplate,
  IPromoDuration,
  IPromoDurationWeeks,
  IPromotion,
  IPromotionPreLoadState,
  IPromotionsListResponse,
  IPromotionState,
  ISinglePromotionsResponse,
} from './types';
import { clean } from 'app/modules/ListView/helper';
import { createExcelFromJsonObject } from 'utils/dataSheet';

// The initial state of the {{ properCase ModuleName }} container
export const initialState: IPromotionState = {
  preLoadState: 'empty',
  isQuickCreateEventModalOpen: false,
  triggerSubmit: false,
  promotionsList: [],
  loading: false,
  singleObj: {} as IPromotion,
  treeSelectionCount: {},
  promoSchemes: [],
  promoLevels: [],
  productLevel: {},
  productTree: [],
  geoTree: [],
  channelTree: [],
  promoTypes: [],
  offerTypes: [],
  applyTos: [],
  promoStatus: [],
  promoBuyTypes: [],
  promoBuyUnitOfMeasures: [],
  promoApplyOns: [],
  promoApplyUoms: [],
  promoDiscountTypes: [],
  promoDuration: [],
  promoDurationWeeks: [],
  levels: {
    productLevels: [],
    geoLevels: [],
    channelLevels: [],
  },
  editPromoPayloadGState: {},
  error: '',
  pageInfo: {
    size: 10,
    page: 1,
    totalItems: 0,
    totalPages: 0,
  },
  outlets: [],
  templates: [],
  selectedTemplateDetail: null,
  other: null,
  promoHistory: null,
  summaryObject: null,
  promoCustomers: [],
  newTemplate: {
    promoId: '',
  },
  isNewTemplateModalVisible: false,
  isTempPromo: false,
};

// for async action follow blow link
// https://redux-toolkit.js.org/tutorials/advanced-tutorial#thinking-in-thunks
// OR
// https://redux-toolkit.js.org/api/createAsyncThunk

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    getPromotionsListInProgress(state) {
      state.loading = true;
    },
    getPromotionsListSuccess(
      state,
      { payload }: PayloadAction<IPromotionsListResponse>,
    ) {
      state.loading = false;
      state.promotionsList = payload.data.promotions;
      state.pageInfo = payload.data.pageInfo.pageInfo;
    },
    getPromotionsListFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    createPromotionsInProgress(state) {
      state.loading = true;
    },
    createPromotionsSuccess(state) {
      state.loading = false;
    },
    createPromotionsFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    removePromotionsInProgress(state) {
      state.loading = true;
    },
    removePromotionsSuccess(state) {
      state.loading = false;
    },
    removePromotionsFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    updatePromotionsInProgress(state) {
      state.loading = true;
    },
    updatePromotionsSuccess(
      state,
      { payload }: PayloadAction<ISinglePromotionsResponse>,
    ) {
      state.loading = false;
      state.singleObj = payload.data;
    },
    updatePromotionsFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    treeSelectionCountInProgress(state) {
      state.loading = true;
    },
    treeSelectionCountSuccess(
      state,
      { payload }: PayloadAction<ISinglePromotionsResponse>,
    ) {
      state.loading = false;
      state.treeSelectionCount = payload.data;
    },
    treeSelectionCountFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromotionInProgress(state) {
      state.loading = true;
    },
    getPromotionSuccess(
      state,
      { payload }: PayloadAction<ISinglePromotionsResponse>,
    ) {
      state.loading = false;
      state.singleObj = payload.data;
    },
    getPromotionFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromoSchemesInProgress(state) {
      state.loading = true;
    },
    getPromoSchemesSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoSchemes = payload.data;
    },
    getPromoSchemesFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromoLevelsInProgress(state) {
      state.loading = true;
    },
    getPromoLevelsSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoLevels = payload.data;
    },
    getPromoLevelsFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromoTypesInProgress(state) {
      state.loading = true;
    },
    getPromoTypesSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoTypes = payload.data;
    },
    getPromoTypesFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getOfferTypesInProgress(state) {
      state.loading = true;
    },
    getOfferTypesSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.offerTypes = payload.data;
    },
    getOfferTypesFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getApplyToInProgress(state) {
      state.loading = true;
    },
    getApplyToSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.applyTos = payload.data;
    },
    getApplyToFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromoBuyTypesInProgress(state) {
      state.loading = true;
    },
    getPromoBuyTypesSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoBuyTypes = payload.data;
    },
    getPromoBuyTypesFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromoBuyUnitMeasuresInProgress(state) {
      state.loading = true;
    },
    getPromoBuyUnitMeasuresSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoBuyUnitOfMeasures = payload.data;
    },
    getPromoBuyUnitMeasuresFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromoApplyOnsInProgress(state) {
      state.loading = true;
    },
    getPromoApplyOnsSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoApplyOns = payload.data;
    },
    getPromoApplyOnsFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromoApplyUomsInProgress(state) {
      state.loading = true;
    },
    getPromoApplyUomsSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoApplyUoms = payload.data;
    },
    getPromoApplyUomsFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromoDiscountTypesInProgress(state) {
      state.loading = true;
    },
    getPromoDiscountTypesSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoDiscountTypes = payload.data;
    },
    getPromoDiscountTypesFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromoDurationInProgress(state) {
      state.loading = true;
    },
    getPromoDurationSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoDuration = payload.data;
    },
    getPromoDurationFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromoDurationWeeksInProgress(state) {
      state.loading = true;
    },
    getPromoDurationWeeksSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoDurationWeeks = payload.data;
    },
    getPromoDurationWeeksFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    setPreLoadState(state, { payload }: PayloadAction<IPromotionPreLoadState>) {
      state.preLoadState = payload;
    },

    isQuickCreateEventModalOpenInProgress(state) {
      state.loading = true;
    },
    isQuickCreateEventModalOpenSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.isQuickCreateEventModalOpen = payload;
    },
    isQuickCreateEventModalOpenFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getProdcutTreeInProgress(state) {
      state.loading = true;
    },
    getProdcutTreeSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.productTree = [
        {
          title: 'Select all',
          key: 'all',
          ...payload?.data?.tree,
        },
      ];
    },
    getProdcutTreeFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getGeoTreeInProgress(state) {
      state.loading = true;
    },
    getGeoTreeSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.geoTree = [
        {
          title: 'Select all',
          key: 'all',
          ...payload?.data?.tree,
        },
      ];
    },
    getGeoTreeFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getChannelTreeInProgress(state) {
      state.loading = true;
    },
    getChannelTreeSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.channelTree = [
        {
          title: 'Select all',
          key: 'all',
          ...payload?.data?.tree,
        },
      ];
    },
    getChannelTreeFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getOutletListInProgress(state) {
      state.loading = true;
    },
    getOutletListSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.outlets = payload?.data;
    },
    getOutletListFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getProductLevelInProgress(state) {
      state.loading = true;
    },
    getProductLevelSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.levels.productLevels = payload.data;
    },
    getProductLevelFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    getGeoLevelInProgress(state) {
      state.loading = true;
    },
    getGeoLevelSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.levels.geoLevels = payload.data;
    },
    getGeoLevelFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getChannelLevelInProgress(state) {
      state.loading = true;
    },
    getChannelLevelSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.levels.channelLevels = payload.data;
    },
    getChannelLevelFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    setEditPromoPayloadInProgress(state) {
      state.loading = true;
    },
    setEditPromoPayloadSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.editPromoPayloadGState = payload;
    },
    setEditPromoPayloadFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getPromoStatusInProgress(state) {
      state.loading = true;
    },
    getPromoStatusSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      const all: string[] = ['all', ...payload.data];
      state.promoStatus = all;
    },
    getPromoStatusFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    deleteSelectedProductInProgress(state) {
      state.loading = true;
    },
    deleteSelectedProductSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.singleObj = {
        ...state.singleObj,
        // selections: {
        //   ...state.singleObj.selections,
        //   products: payload.data.products,
        // },
        aggregatedCalculations: payload?.data?.aggregatedCalculations,
        selectedProductsSegregated: payload?.data?.selectedProductsSegregated,
      };
    },
    deleteSelectedProductFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    updateSelectedProductInProgress(state) {
      state.loading = true;
    },
    updateSelectedProductSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      // const updateFilter = JSON.parse(
      //   JSON.stringify(state.singleObj.selections.products),
      // ).filter((product: any) => product.id !== payload.data.products[0].id);

      // const selection = {
      //   ...state.singleObj.selections,
      //   products: [payload.data.products[0], ...updateFilter],
      // };
      // state.singleObj = {
      //   ...state.singleObj,
      //   selections: selection,
      //   aggregatedCalculations: payload?.data?.aggregatedCalculations,
      // };
      state.singleObj = {
        ...state.singleObj,
        // selections: selection,
        aggregatedCalculations: payload?.data?.aggregatedCalculations,
        selectedProductsSegregated: payload?.data?.selectedProductsSegregated,
      };
    },
    updateSelectedProductFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    getTemplateListInProgress(state) {
      state.loading = true;
    },
    getTemplateListSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.templates = payload?.data;
    },
    getTemplateListFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    getSelectedTemplateDetailInProgress(state) {
      state.loading = true;
    },
    getSelectedTemplateDetailSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.selectedTemplateDetail = payload?.data;
    },
    getSelectedTemplateDetailFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    getIncreaseTemplateCountInProgress(state) {
      state.loading = true;
    },
    getIncreaseTemplateCountSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
    },
    getIncreaseTemplateCountFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    getPromotionHistoryInProgress(state) {
      state.loading = true;
    },
    getPromotionHistorySuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoHistory = payload?.data;
    },
    getPromotionHistoryFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    getPromotionSummaryInProgress(state) {
      state.loading = true;
    },
    getPromotionSummarySuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.summaryObject = payload?.data;
    },
    getPromotionSummaryFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    getPromoCustomersInProgress(state) {
      state.loading = true;
    },
    getPromoCustomersSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.promoCustomers = payload.data;
    },
    getPromoCustomersFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    setNewTemplate(state, { payload }: PayloadAction<INewTemplate>) {
      state.newTemplate = payload;
    },
    setIsNewTemplateModalVisible(state, { payload }: PayloadAction<boolean>) {
      state.isNewTemplateModalVisible = payload;
    },
    setAddNewTemplateInProgress(state) {
      state.loading = true;
    },
    setAddNewTemplateSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
    },
    setAddNewTemplateFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    setIsTempPromo(state, { payload }) {
      state.isTempPromo = payload;
    },
    setTriggerSubmit(state, { payload }) {
      state.triggerSubmit = payload;
    },
  },
});

export const {
  actions: promotionsActions,
  reducer: promotionsReducer,
  name: sliceKey,
} = promotionsSlice;

export const {
  getPromotionsListInProgress,
  getPromotionsListSuccess,
  getPromotionsListFailed,

  createPromotionsInProgress,
  createPromotionsSuccess,
  createPromotionsFailed,

  removePromotionsInProgress,
  removePromotionsSuccess,
  removePromotionsFailed,

  updatePromotionsInProgress,
  updatePromotionsSuccess,
  updatePromotionsFailed,

  getPromotionInProgress,
  getPromotionSuccess,
  getPromotionFailed,

  getPromoSchemesInProgress,
  getPromoSchemesSuccess,
  getPromoSchemesFailed,

  getPromoLevelsInProgress,
  getPromoLevelsSuccess,
  getPromoLevelsFailed,

  getPromoBuyTypesInProgress,
  getPromoBuyTypesSuccess,
  getPromoBuyTypesFailed,

  getPromoBuyUnitMeasuresInProgress,
  getPromoBuyUnitMeasuresSuccess,
  getPromoBuyUnitMeasuresFailed,

  getPromoApplyOnsInProgress,
  getPromoApplyOnsSuccess,
  getPromoApplyOnsFailed,

  getPromoApplyUomsInProgress,
  getPromoApplyUomsSuccess,
  getPromoApplyUomsFailed,

  getPromoDiscountTypesInProgress,
  getPromoDiscountTypesSuccess,
  getPromoDiscountTypesFailed,

  getPromoTypesInProgress,
  getPromoTypesSuccess,
  getPromoTypesFailed,

  getOfferTypesInProgress,
  getOfferTypesSuccess,
  getOfferTypesFailed,

  getApplyToInProgress,
  getApplyToSuccess,
  getApplyToFailed,

  getPromoDurationInProgress,
  getPromoDurationSuccess,
  getPromoDurationFailed,

  getPromoDurationWeeksInProgress,
  getPromoDurationWeeksSuccess,
  getPromoDurationWeeksFailed,

  isQuickCreateEventModalOpenInProgress,
  isQuickCreateEventModalOpenSuccess,
  isQuickCreateEventModalOpenFailed,

  getProdcutTreeInProgress,
  getProdcutTreeSuccess,
  getProdcutTreeFailed,

  getGeoTreeInProgress,
  getGeoTreeSuccess,
  getGeoTreeFailed,

  getChannelTreeInProgress,
  getChannelTreeSuccess,
  getChannelTreeFailed,

  getOutletListInProgress,
  getOutletListSuccess,
  getOutletListFailed,

  setEditPromoPayloadInProgress,
  setEditPromoPayloadSuccess,
  setEditPromoPayloadFailed,

  deleteSelectedProductInProgress,
  deleteSelectedProductSuccess,
  deleteSelectedProductFailed,

  updateSelectedProductInProgress,
  updateSelectedProductSuccess,
  updateSelectedProductFailed,

  getPromoStatusInProgress,
  getPromoStatusSuccess,
  getPromoStatusFailed,

  getProductLevelInProgress,
  getProductLevelSuccess,
  getProductLevelFailed,

  getChannelLevelInProgress,
  getChannelLevelSuccess,
  getChannelLevelFailed,

  getGeoLevelInProgress,
  getGeoLevelSuccess,
  getGeoLevelFailed,

  treeSelectionCountInProgress,
  treeSelectionCountSuccess,
  treeSelectionCountFailed,

  getTemplateListInProgress,
  getTemplateListSuccess,
  getTemplateListFailed,

  getSelectedTemplateDetailInProgress,
  getSelectedTemplateDetailSuccess,
  getSelectedTemplateDetailFailed,

  getIncreaseTemplateCountInProgress,
  getIncreaseTemplateCountSuccess,
  getIncreaseTemplateCountFailed,

  getPromotionHistoryInProgress,
  getPromotionHistorySuccess,
  getPromotionHistoryFailed,

  getPromotionSummaryInProgress,
  getPromotionSummarySuccess,
  getPromotionSummaryFailed,

  getPromoCustomersInProgress,
  getPromoCustomersSuccess,
  getPromoCustomersFailed,

  setNewTemplate,
  setIsNewTemplateModalVisible,

  setAddNewTemplateInProgress,
  setAddNewTemplateSuccess,
  setAddNewTemplateFailed,

  setIsTempPromo,
  setTriggerSubmit,

  setPreLoadState,
} = promotionsSlice.actions;
export const updateSelectedProduct =
  (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(updateSelectedProductInProgress());
    try {
      const data = await promotionSource.updateSelectedProductApi(payload);

      dispatch(loadingActions.hideLoader());
      dispatch(updateSelectedProductSuccess(data));
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: 'Product Updated successfully',
        }),
      );
    } catch (error) {
      dispatch(loadingActions.hideLoader());

      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(updateSelectedProductFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };

export const deleteSelectedProduct =
  (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(getProdcutTreeInProgress());
    try {
      const data = await promotionSource.deleteSelectedProductApi(payload);
      dispatch(loadingActions.hideLoader());
      dispatch(deleteSelectedProductSuccess(data));
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: 'Product Deleted successfully',
        }),
      );
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(deleteSelectedProductFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };
export const getProdcutTree =
  (productLevel: string) => async (dispatch: AppDispatch) => {
    // dispatch(loadingActions.showLoader());
    dispatch(getProdcutTreeInProgress());
    try {
      const data = await promotionSource.getProdcutTreeApi(productLevel);
      // dispatch(loadingActions.hideLoader());
      dispatch(getProdcutTreeSuccess(data));
    } catch (error) {
      // dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(getProdcutTreeFailed({ error }));
    }
  };

export const getGeoTree =
  (customers: any = []) =>
  async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(getGeoTreeInProgress());
    try {
      const data = await promotionSource.getGeoTreeApi(customers);
      dispatch(loadingActions.hideLoader());
      dispatch(getGeoTreeSuccess(data));
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(getGeoTreeFailed({ error }));
    }
  };

export const getChannelTree = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getChannelTreeInProgress());
  try {
    const data = await promotionSource.getChannelTreeApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getChannelTreeSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getChannelTreeFailed({ error }));
  }
};

export const getOutletList = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getOutletListInProgress());
  try {
    const data = await promotionSource.getOutletListApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getOutletListSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getOutletListFailed({ error }));
  }
};

export const getProductLevels = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getProductLevelInProgress());
  try {
    const data = await promotionSource.getProdcutLevelsApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getProductLevelSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    const { response } = error;
    const { data } = response?.data;
    if (data.statusCode > 300) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(getProductLevelFailed({ error: data.message }));
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
    }
  }
};

export const getTreeSelectionCount =
  (payload: any) => async (dispatch: AppDispatch) => {
    // dispatch(loadingActions.showLoader());
    dispatch(treeSelectionCountInProgress());
    try {
      const data = await promotionSource.getTreeSelectionCountApi(payload);
      // dispatch(loadingActions.hideLoader());
      dispatch(treeSelectionCountSuccess(data));
    } catch (error) {
      // dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(treeSelectionCountFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };

export const getGeoLevels = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getGeoLevelInProgress());
  try {
    const data = await promotionSource.getGeoLevelsApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getGeoLevelSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    const { response } = error;
    const { data } = response?.data;
    if (data.statusCode > 300) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(getGeoLevelFailed({ error: data.message }));
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
    }
  }
};

export const getChannelLevels = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getChannelLevelInProgress());
  try {
    const data = await promotionSource.getChannelLevelsApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getChannelLevelSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    const { response } = error;
    const { data } = response?.data;
    if (data.statusCode > 300) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(getChannelLevelFailed({ error: data.message }));
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
    }
  }
};

export const getPromotion =
  (params: any, download = false) =>
  async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(getPromotionInProgress());
    try {
      const data = await promotionSource.getSinglePromotionApi(params);
      // get the promotion level name from the promotion to send the API call for getProductTree()
      let promoLevelName = '';
      if (
        data.data?.promoData.hasOwnProperty('products') &&
        data.data?.promoData.products.length > 0
      ) {
        promoLevelName = data.data?.promoData?.products[0]?.level;
        //to load the tree on load
      }
      dispatch(loadingActions.hideLoader());
      dispatch(getPromotionSuccess(data));

      dispatch(getProdcutTree(promoLevelName));
      dispatch(getGeoTree());
      dispatch(getChannelTree());

      if (download) {
        if (Object.keys(data.data).length > 0) {
          let cleaned: any = clean(data.data);
          createExcelFromJsonObject(cleaned, 'promo-detail');
        }
      }
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(getPromotionFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };
export const getPromoSchemes = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getPromoSchemesInProgress());
  try {
    const data = await promotionSource.getPromotionSchemesApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getPromoSchemesSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getPromoSchemesFailed({ error }));
  }
};
export const getPromoLevels = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getPromoLevelsInProgress());
  try {
    const data = await promotionSource.getPromotionLevelsApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getPromoLevelsSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    const { response } = error;
    const { data } = response?.data;
    if (data.statusCode > 300) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(getPromoLevelsFailed({ error: data.message }));
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
    }
  }
};
export const getPromoBuyTypes = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getPromoTypesInProgress());
  try {
    const data = await promotionSource.getPromotionBuyTypesApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getPromoBuyTypesSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getPromoBuyTypesFailed({ error }));
  }
};
export const getPromoTypes = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getPromoTypesInProgress());
  try {
    const data = await promotionSource.getPromotionTypesApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getPromoTypesSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getPromoTypesFailed({ error }));
  }
};

export const getOfferTypes = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getOfferTypesInProgress());
  try {
    const data = await promotionSource.getOfferTypesApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getOfferTypesSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getOfferTypesFailed({ error }));
  }
};

export const getApplyTo = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getApplyToInProgress());
  try {
    const data = await promotionSource.getApplyToApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getApplyToSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getApplyToFailed({ error }));
  }
};

export const getPromoStatus = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getPromoStatusInProgress());
  try {
    const data = await promotionSource.getPromotionStatusApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getPromoStatusSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getPromoStatusFailed({ error }));
  }
};

export const getPromoBuyUnitMeasures = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getPromoBuyUnitMeasuresInProgress());
  try {
    const data = await promotionSource.getPromotionBuyUnitMeasuresApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getPromoBuyUnitMeasuresSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getPromoBuyUnitMeasuresFailed({ error }));
  }
};
export const getPromoApplyOns = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getPromoApplyOnsInProgress());
  try {
    const data = await promotionSource.getPromotionApplyOnsApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getPromoApplyOnsSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getPromoApplyOnsFailed({ error }));
  }
};
export const getPromoApplyUoms = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getPromoApplyUomsInProgress());
  try {
    const data = await promotionSource.getPromotionApplyUomsApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getPromoApplyUomsSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getPromoApplyUomsFailed({ error }));
  }
};
export const getPromoDiscountTypes = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getPromoDiscountTypesInProgress());
  try {
    const data = await promotionSource.getPromotionDiscountTypesApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getPromoDiscountTypesSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getPromoDiscountTypesFailed({ error }));
  }
};

export const getPromoDuration =
  (payload: IPromoDuration) => async (dispatch: AppDispatch) => {
    // dispatch(loadingActions.showLoader());
    dispatch(getPromoDurationInProgress());
    try {
      const data = await promotionSource.getPromotionDurationApi(payload);
      // dispatch(loadingActions.hideLoader());
      dispatch(getPromoDurationSuccess(data));
    } catch (error) {
      // dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(getPromoDurationFailed({ error }));
    }
  };

export const getPromoDurationWeeks =
  (payload: IPromoDurationWeeks) => async (dispatch: AppDispatch) => {
    // dispatch(loadingActions.showLoader());
    dispatch(getPromoDurationWeeksInProgress());
    try {
      const data = await promotionSource.getPromotionDurationWeeksApi(payload);
      // dispatch(loadingActions.hideLoader());
      dispatch(getPromoDurationWeeksSuccess(data));
    } catch (error) {
      // dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(getPromoDurationWeeksFailed({ error }));
    }
  };

export const setEditPromotionPayload =
  (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(setEditPromoPayloadInProgress());
    dispatch(loadingActions.hideLoader());
    dispatch(setEditPromoPayloadSuccess(payload));
  };

export const updatePromotion =
  (payload: any, promoId: string) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(updatePromotionsInProgress());
    try {
      const data = await promotionSource.updatePromotionApi(payload, promoId);
      dispatch(loadingActions.hideLoader());
      dispatch(updatePromotionsSuccess(data));
      dispatch(getPromotionSummarySuccess(data));
      dispatch(setEditPromotionPayload({}));

      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: 'Promotion updated successfully',
        }),
      );
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        if (data?.message == 'This Updation is not Allowed') {
        } else {
          dispatch(
            toastMessageActions.showToastMessage({
              type: 'error',
              content: data?.message || 'Something went wrong!',
            }),
          );
          // handle error
          dispatch(updatePromotionsFailed({ error: data.message }));
        }
      } else {
        if (data?.message != 'This Updation is not Allowed') {
          dispatch(
            toastMessageActions.showToastMessage({
              type: 'error',
              content: data?.message || 'Something went wrong!',
            }),
          );
        }
      }
    }
  };

export const isQuickCreateModalOpen =
  (isOpen: boolean) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(isQuickCreateEventModalOpenInProgress());
    dispatch(loadingActions.hideLoader());
    dispatch(isQuickCreateEventModalOpenSuccess(isOpen));
  };
export const getTemplateList = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getTemplateListInProgress());
  try {
    const data = await promotionSource.getAllTemplatesApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getTemplateListSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    dispatch(
      toastMessageActions.showToastMessage({
        type: 'error',
        content: error.message,
      }),
    );
    // handle error
    dispatch(getTemplateListFailed({ error }));
  }
};

export const getTemplateDetail =
  (templateId: string) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(getSelectedTemplateDetailInProgress());
    try {
      const data = await promotionSource.getTemplateByIdApi(templateId);
      dispatch(loadingActions.hideLoader());
      dispatch(getSelectedTemplateDetailSuccess(data));
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(getSelectedTemplateDetailFailed({ error }));
    }
  };

export const getIncreaseTemplateCount =
  (templateId: string) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(getIncreaseTemplateCountInProgress());
    try {
      const data = await promotionSource.increaseTemplateCountApi(templateId);
      dispatch(loadingActions.hideLoader());
      dispatch(getIncreaseTemplateCountSuccess(data));
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(getIncreaseTemplateCountFailed({ error }));
    }
  };

export const getPromotionHistory =
  (promoId: string) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(getPromotionHistoryInProgress());
    try {
      const data = await promotionSource.getPromotionHistoryApi(promoId);
      dispatch(loadingActions.hideLoader());
      dispatch(getPromotionHistorySuccess(data));
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: error.message,
        }),
      );
      // handle error
      dispatch(getPromotionHistoryFailed({ error }));
    }
  };

export const getPromotionSummary =
  (promoId: string) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(getPromotionSummaryInProgress());
    try {
      const data = await promotionSource.getPromotionSummaryApi(promoId);
      dispatch(loadingActions.hideLoader());
      dispatch(getPromotionSummarySuccess(data));
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      // dispatch(
      //   toastMessageActions.showToastMessage({
      //     type: 'error',
      //     content: error.message,
      //   }),
      // );
      // handle error
      dispatch(getPromotionSummaryFailed({ error }));
    }
  };

export const getPromoCustomers = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getPromoCustomersInProgress());
  try {
    const data = await promotionSource.getPromotionCustomersApi();
    dispatch(loadingActions.hideLoader());
    dispatch(getPromoCustomersSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    const { response } = error;
    const { data } = response?.data;
    if (data.statusCode > 300) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(getPromoCustomersFailed({ error: data.message }));
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
    }
  }
};

export const addNewTemplate =
  (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(setAddNewTemplateInProgress());
    try {
      const data = await promotionSource.addTemplateApi(payload);
      dispatch(loadingActions.hideLoader());
      dispatch(setAddNewTemplateSuccess(data));
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: 'Template successfuly created',
        }),
      );
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(setAddNewTemplateFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };
