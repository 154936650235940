import axios, { Method } from 'axios';
import { TOKEN_KEY } from 'configs';
import { Token } from 'app/modules/Auth/ducks/types';
import { getFromLocal, removeFromLocal } from './cache';
import {
  refreshToken,
  saveTokenToLocal,
} from 'app/modules/Auth/ducks/services';

interface IRequestArgs {
  url: string;
  method: Method;
  data?: any;
  params?: any;
  cancelToken?: any;
  customHeaders?: any;
  headerVariant?: string;
}

// Remove this function and import function getting token from local storage.
const getToken = async () => {
  const token: Token | null = getFromLocal(TOKEN_KEY);
  if (token) {
    return token.access_token;
  }
  return '';
};

/**
 * @description Get authorization token
 * @return {String}
 */
export const onGetToken = async (): Promise<string | boolean> => {
  // Call function to get token
  const token = await getToken();
  if (token) {
    return token; // Second fallback from local storage state
  }
  return false;
};

/**
 * @description Get {Object} header based on the variant
 * @param {String} type
 * @define type: possible values 'authorization' || 'public'
 * @return {Object}
 */
export const onGetHeaders = async (type: string): Promise<any> => {
  const ALL_TYPES = ['authorization', 'public'];
  if (!type) {
    throw new Error(
      'request func internal onGetHeaders arg @param type is missing',
    );
  }
  if (!ALL_TYPES.includes(type)) {
    throw new Error(`
      request func internal onGetHeaders arg @param type can only be
      ${ALL_TYPES.join(',')}
    `);
  }
  if (type === 'authorization') {
    const authorizationToken = await onGetToken();
    return {
      authorization: `Bearer ${authorizationToken}`,
    };
  }
  if (type === 'public') {
    return {
      'Content-Type': 'application/json',
    };
  }
  return {};
};

/**
 * @description Call an asynchronous XHR request
 * @param {String} url* required
 * @param {String} method* required
 * @param {Object} data optional
 * @param {Signal} cancelToken optional
 * @param {Object} customHeader optional
 * @param {String} headerVariant* required, by default 'authorization'
 * @return {Object} XHR response
 */
const request = async ({
  url,
  method,
  data,
  params,
  cancelToken,
  customHeaders,
  headerVariant = 'authorization',
  ...rest
}: IRequestArgs) => {
  if (!url) {
    throw new Error('request func arg @param url is missing');
  }
  if (!method) {
    throw new Error(
      'request func arg @param method is missing. Valid options "GET"|"POST"|"PUT" etc',
    );
  }

  // Overrides headers object based on variant if "customHeaders" object provided
  const authHeaders = await onGetHeaders(headerVariant);
  const headers = { ...customHeaders, ...authHeaders, withCredentials: true };
  // eslint-disable-next-line no-useless-catch
  try {
    const result = await axios(url, {
      method,
      headers,
      ...(data && { data }),
      ...(params && { params }),
      ...(cancelToken && { cancelToken }),
      ...rest,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

interface IInterceptorsConfig {
  debug: boolean;
}
function blockForSeconds(seconds: number) {
  // Convert seconds to milliseconds
  var milliseconds = seconds * 1000;

  // Block execution for the specified time
  var startTime = new Date().getTime();
  while (new Date().getTime() < startTime + milliseconds);
}
export const setupInterceptors = ({ debug = false }: IInterceptorsConfig) => {
  /**
   * @description echo() prints a message if allowed, of a certain type of console variant
   * @param {*} show {Boolean}
   * @param {*} type {String} Can be log, warn, debug
   * @param {*} message {String}
   */
  const echo = ({ show }: any) => {
    if (!show) {
      /* eslint-disable no-console */
      console.log('');
    }
    /* eslint-disable no-console */
    // console.group('🤯🤯 @@@AXIOS INTERCEPTOR@@@ 🤯🤯');
    // console[type](message);
    // console.groupEnd('@@@AXIOS INTERCEPTOR@@@');
    /* eslint-enable */
  };

  axios.interceptors.response.use(
    (response) => {
      echo({ show: debug, type: 'dir', message: response });
      return response;
    },
    async (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { status } = error.response;
        if (status === 402) {
          removeFromLocal(TOKEN_KEY);
          localStorage.setItem('sessionexpired', 'true');
          (window as Window).location = process.env.PUBLIC_URL || '/';
        }
        if (status === 401) {
          // Refresh token
          try {
            let resp = await refreshToken();
            //backend should send refresh token as well as access token back and also update the count of refreshes
            saveTokenToLocal(resp.data);
            const newToken = resp.data.access_token;
            error.config.headers.Authorization = `Bearer ${newToken}`;
            blockForSeconds(1);
            return await axios.request(error.config);
          } catch (err) {
            removeFromLocal(TOKEN_KEY);
            localStorage.setItem('sessionexpired', 'true');
            (window as Window).location = process.env.PUBLIC_URL || '/';
          }
          // 401 status means unauthorized
          // Add code to handle unauthorized request
        }
        if (status === 404) {
          // 404 status means not found
          // Add code to handle a request whin API is not available
        }
        if (status === 500) {
          // 500 status means server error
          // Add code to handle when error happens on tne server
        }
      } else if (error.request) {
        // Request made, but no response was received
        echo({ show: debug, type: 'dir', message: error.request });
      } else {
        echo({
          show: debug,
          type: 'warn',
          message: `Something occurred setting the request that set off an error ${error.message}`,
        });
      }
      return Promise.reject(error);
    },
  );
};

export default request;
