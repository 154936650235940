import request from 'utils/axios';

import { httpVerbs } from 'configs';
import {
  IBulkRequestPayload,
  ICalendarViewPromotionListResponse,
  ICalendarViewReqestPayload,
  IDuplicateRequestPayload,
} from './types';
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const getCalendarViewApi = async (
  payload: ICalendarViewReqestPayload,
): Promise<ICalendarViewPromotionListResponse> => {
  const url = `${BASE_URL}/promotions/calendarview`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: payload,
  };
  const response = await request(params);
  return response;
};

export const filterPromotionsApi = async (payload: any): Promise<any> => {
  // const url = `${BASE_URL}/promotions/calendarview`;
  const url = `${BASE_URL}/promotions/filter`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: payload,
  };
  const response = await request(params);
  return response;
};

export const updateCalendarViewApi = async (
  payload: any,
  promoId: string,
): Promise<any> => {
  const url = `${BASE_URL}/promotions/${promoId}/update`;
  const params: any = {
    url: url,
    method: httpVerbs.PUT,
    data: payload,
  };
  const response = await request(params);
  return response;
};
export const createEventApi = async (
  payload: any,
  submit: boolean = false,
): Promise<any> => {
  const url = `${BASE_URL}/promotions`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: { ...payload, submit },
  };
  const response = await request(params);
  return response;
};

export const deleteEventApi = async (promoId: string): Promise<any> => {
  const url = `${BASE_URL}/promotions/${promoId}`;
  const params: any = {
    url: url,
    method: httpVerbs.DELETE,
  };
  const response = await request(params);
  return response;
};

export const submitEventApi = async (promoId: string): Promise<any> => {
  const url = `${BASE_URL}/promotions/${promoId}/submit/`;
  const params: any = {
    url: url,
    method: httpVerbs.PUT,
  };
  const response = await request(params);
  return response;
};

export const approveEventApi = async (
  promoId: string,
  payload: any,
): Promise<any> => {
  const url = `${BASE_URL}/promotions/${promoId}/finalize/`;
  const params: any = {
    url: url,
    method: httpVerbs.PUT,
    data: payload,
  };
  const response = await request(params);
  return response;
};

export const duplicateEventApi = async (
  payload: IDuplicateRequestPayload,
): Promise<any> => {
  const url = `${BASE_URL}/promotions/duplicate`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: payload,
  };
  const response = await request(params);
  return response;
};

export const bulkEventApi = async (
  payload: IBulkRequestPayload,
): Promise<any> => {
  const url = `${BASE_URL}/promotions/bulk-action`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: { ids: payload.ids, action: payload.action, comment: payload.reason },
  };
  const response = await request(params);
  return response;
};

export const bulkDeleteApi = async (payload: any): Promise<any> => {
  const url = `${BASE_URL}/promotions/bulk-delete`;
  const params: any = {
    url: url,
    method: httpVerbs.DELETE,
    data: { ids: payload.ids },
  };
  const response = await request(params);
  return response;
};
