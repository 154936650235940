import { ReactElement, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory, History } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import store from 'store';
import { ROOT } from 'configs/routeNames';
import { setupInterceptors } from 'utils/axios';
import Pages from './Pages';
import 'styles/index.less';
import Loader from '../app/molecules/Loader';
import ToastMessage from '../app/molecules/ToastMessage';
import ForgotPasswordPage from 'app/pages/ForgotPasswordPage';
import ResetPasswordPage from 'app/pages/ResetPasswordPage';
import { notification } from 'antd';

const history: History = createBrowserHistory();

setupInterceptors({ debug: false });

const App = (): ReactElement => {
  const [status, setStatus] = useState(true);
  const [prevNETstate, setPrevNETstate] = useState(true);

  useEffect(() => {
    window.addEventListener('online', () => setStatus(true));
    window.addEventListener('offline', () => setStatus(false));
  }, []);

  useEffect(() => {
    if (!status) {
      notification.destroy();
      notification.error({
        message: 'No internet connection',
        duration: 0,
      });
      setPrevNETstate(false);
    } else if (status && !prevNETstate) {
      notification.destroy();
      notification.success({
        message: 'Internet connection restored',
      });
      setPrevNETstate(true);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [status]);

  return (
    <Provider store={store}>
      <HelmetProvider>
        <Router history={history}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Loader />
            <ToastMessage />
            <Switch>
              <Route path="/forgot-password" component={ForgotPasswordPage} />
              <Route path="/reset-password" component={ResetPasswordPage} />
              <Route path={ROOT} component={Pages} />
            </Switch>
          </BrowserRouter>
        </Router>
      </HelmetProvider>
    </Provider>
  );
};
export default App;
