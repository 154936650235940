import request from 'utils/axios';

import { httpVerbs } from 'configs';
import {
  IBulkDuplicateRequestPayload,
  IListViewPromotionListResponse,
  IListViewReqestPayload,
} from './types';
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const getListViewApi = async (
  payload: IListViewReqestPayload,
): Promise<IListViewPromotionListResponse> => {
  const url = `${BASE_URL}/promotions/listview`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: payload,
  };
  const response = await request(params);
  return response;
};
export const bulkDuplicateEventApi = async (
  payload: IBulkDuplicateRequestPayload,
): Promise<any> => {
  const url = `${BASE_URL}/promotions/duplicate`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
    data: payload,
  };
  const response = await request(params);
  return response;
};
