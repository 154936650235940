const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const utc = require('dayjs/plugin/utc');
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const removeEmptyKeys = (obj: any) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

// date time / formateDate

export const formateUTCDateTime = (utcString: string) => {
  const date = dayjs(utcString);
  const day = date.format('Do');
  const month = date.format('MMMM');
  const time = date.utc().format('h:mm A');

  return `${day} ${month} - ${time}`;
};

export const formateUTCDateTimeWithoutDash = (utcString: string) => {
  const date = dayjs(utcString);
  let day = date.format('Do');
  const month = date.format('MMM');
  const time = date.utc().format('hh:mm A');

  return `${day.length === 3 ? '0' : ''}${day} ${month} ${time}`;
};

//for notification string
export const addStrongTags = (inputString: string) => {
  const addStrong = inputString.replace(/@@(.*?)@@/g, '<strong>$1</strong>');
  return { __html: addStrong };
};

export const formatNumberWithCommas = (numberString: string) => {
  const number = parseFloat(numberString);
  if (isNaN(number)) {
    // Handle invalid input (e.g., non-numeric strings)
    return 'Invalid input';
  }
  return number.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
export const formatNumber = (n: number, fix = 1): string => {
  if (n >= 1000000000) {
    return (n / 1000000000).toFixed(fix) + 'B';
  } else if (n >= 1000000) {
    return (n / 1000000).toFixed(fix) + 'M';
  } else if (n >= 10000) {
    return (n / 1000).toFixed(fix) + 'K';
  } else {
    return n.toFixed(0).toString();
  }
};

export const capitalizeFirstLetter = (str: string): string => {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getRoleTitle = (items: any): string => {
  if (items.length > 1) {
    return 'Super admin';
  }
  for (const item of items) {
    switch (item.role.slug) {
      case 'trade_manager':
        return 'Trade manager';
      case 'finance_manager':
        return 'Finance manager';
      case 'admin_manager':
        return 'Admin manager';
      default:
        return '';
    }
  }
  return ''; // Default value if no match found
};

export const formatNumberWithCommasNew = (numberString: string) => {
  const number = parseFloat(numberString);
  if (isNaN(number)) {
    // Handle invalid input (e.g., non-numeric strings)
    return 'Invalid input';
  }

  // Extract integer and decimal parts
  const [integerPart, decimalPart] = number.toFixed(2).split('.');

  // Format integer part with commas
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ',',
  );

  // Return formatted number with 2 decimal places
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
};

export function capitalizeFirstLetterOfEachWord(sentence: string): string {
  return sentence
    .split(' ') // Split the sentence into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words back together into a sentence
}

export function calculateBudget(
  actualCost: number,
  plannedCost: number,
  actualVolume: number,
  plannedVolume: number,
): {
  totalActualBudget: string;
  totalPlannedBudget: string;
  exceeded: boolean;
  percentage: number;
} {
  // Unit Cost calculate karein
  const totalActualBudget = actualCost;
  const totalPlannedBudget = plannedCost;

  // Total Budget calculate karein

  // Budget ka positivity ya negativity check karein
  let exceeded;
  if (totalActualBudget < totalPlannedBudget) {
    exceeded = false; // Agar actual cost, planned cost se kam hai
  } else if (totalActualBudget > totalPlannedBudget) {
    exceeded = true; // Agar actual cost, planned cost se zyada hai
  } else {
    exceeded = false; // Agar actual cost, planned cost ke barabar hai
  }

  // Calculate percentage
  const percentage = (totalActualBudget / totalPlannedBudget) * 100 || 0;

  // Total Budget aur Budget Status ko return karein
  return {
    totalActualBudget: formatNumber(totalActualBudget || 0),
    totalPlannedBudget: formatNumber(totalPlannedBudget || 0),
    exceeded,
    percentage: Math.floor(percentage),
  };
}
