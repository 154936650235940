import dayjs from 'dayjs';
import { Col, Collapse, DatePicker, Input, Row, Select } from 'antd';
import { useAppSelector } from 'store/hooks';
import { IconDownOutlined } from 'app/icons/icons';
import { CaretDownFilled } from '@ant-design/icons';

const { Panel } = Collapse;

export default function DurationFilter({
  onRangeChange,
  promoDurationValue,
  promoDurationWeeksValue,
  onChangeSelectStartWeek,
  onChangeSelectEndWeek,
  filterOptionStartWeek,
  filterOptionEndWeek,
}: any) {
  const filters = useAppSelector((state) => state.appHeader.filter);
  const isFetchingDuration = useAppSelector(
    (state) => state.appHeader.isFetchingDuration,
  );

  const getCurrentFormattedDate = (isStartDate: boolean) => {
    if (promoDurationValue?.dateStart && isStartDate) {
      return promoDurationValue?.dateStart;
    }
    if (promoDurationValue?.dateEnd && !isStartDate) {
      return promoDurationValue?.dateEnd;
    }
    if (!promoDurationValue?.dateEnd && !promoDurationValue?.dateStart) {
      const today = new Date();
      const year = today.getFullYear();
      let month: any = today.getMonth() + 1;
      let day: any = today.getDate();

      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return `${year}-${month}-${day}`;
    }
  };

  return (
    <Collapse
      defaultActiveKey={['1']}
      className="ut-collapse ut-form"
      expandIcon={({ isActive }) => (
        <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
          <IconDownOutlined />
        </span>
      )}
      items={undefined}
      expandIconPosition={'end'}
    >
      <Panel header="Duration" key="1">
        <Row className="ut-mb-m">
          <Col span={24} className="ut-mb-s">
            <span className="ut-label">Start and End Date</span>
          </Col>
          <Col span={24}>
            <DatePicker.RangePicker
              defaultValue={[dayjs(), dayjs()]}
              value={[
                dayjs(getCurrentFormattedDate(true), 'YYYY-MM-DD'),
                dayjs(getCurrentFormattedDate(false), 'YYYY-MM-DD'),
              ]}
              onChange={onRangeChange}
              format={'YYYY-MM-DD'}
              className="w-full"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} className="ut-mb-s">
            <span className="ut-label">Start and End Week</span>
          </Col>
          <Col span={24}>
            <Row justify="space-between" gutter={16}>
              <Col span={12}>
                <div className="ut-form w-full">
                  <Select
                    showSearch
                    className="ut-select"
                    placeholder="Select a week"
                    optionFilterProp="children"
                    suffixIcon={<CaretDownFilled />}
                    options={promoDurationWeeksValue}
                    onChange={onChangeSelectStartWeek}
                    filterOption={filterOptionStartWeek}
                    value={promoDurationValue?.startWeek}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div className="ut-form">
                  <Select
                    showSearch
                    className="ut-select"
                    placeholder="Select a week"
                    optionFilterProp="children"
                    suffixIcon={<CaretDownFilled />}
                    onChange={onChangeSelectEndWeek}
                    options={promoDurationWeeksValue}
                    filterOption={filterOptionEndWeek}
                    value={promoDurationValue?.endWeek}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}
