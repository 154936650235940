import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'store';
import { loadingActions } from 'app/molecules/Loader/ducks/slice';
import { toastMessageActions } from 'app/molecules/ToastMessage/ducks/slice';
import * as userSource from './services';
import {
  IAllCategoriesResponse,
  ICategory,
  ICreateUser,
  IEditUser,
  IUserList,
  IUserListFormate,
  IUserRole,
  IUserRolesResponse,
} from './types';

// The initial state of the {{ properCase ModuleName }} container
export const initialState: IUserList = {
  userList: [],
  userFilterList: [],
  userRolesList: [],
  categoriesList: [],
  regionList: [],
  channelList: [],
  loading: false,
  isUserModalOpen: false,
  error: '',
};

// for async action follow blow link
// https://redux-toolkit.js.org/tutorials/advanced-tutorial#thinking-in-thunks
// OR
// https://redux-toolkit.js.org/api/createAsyncThunk

const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    getUserListInProgress(state) {
      state.loading = true;
    },
    getUserListSuccess(state, { payload }: PayloadAction<IUserListFormate>) {
      state.loading = false;
      state.userList = payload.data;
    },
    getUserListFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },
    getCategoriesListInProgress(state) {
      state.loading = true;
    },

    getCategoriesListSuccess(
      state,
      { payload }: PayloadAction<{ data: ICategory[] }>,
    ) {
      const addAll = [{ id: 'all', name: 'All' }, ...payload.data];
      state.loading = false;
      state.categoriesList = addAll;
    },
    getCategoriesListFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getRegionListInProgress(state) {
      state.loading = true;
    },

    getRegionListSuccess(
      state,
      { payload }: PayloadAction<{ data: ICategory[] }>,
    ) {
      const addAll = [{ id: 'all', name: 'All' }, ...payload.data];
      state.loading = false;
      state.regionList = addAll;
    },
    getRegionListFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getChannelListInProgress(state) {
      state.loading = true;
    },

    getChannelListSuccess(
      state,
      { payload }: PayloadAction<{ data: ICategory[] }>,
    ) {
      const addAll = [{ id: 'all', name: 'All' }, ...payload.data];
      state.loading = false;
      state.channelList = addAll;
    },
    getChannelListFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    getUserRoleListInProgress(state) {
      state.loading = true;
    },
    getUserRoleListSuccess(
      state,
      { payload }: PayloadAction<{ data: IUserRole[] }>,
    ) {
      state.loading = false;
      state.userRolesList = payload.data;
    },
    getUserRoleListFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    createUserInProgress(state) {
      state.loading = true;
    },
    createUserSuccess(state) {
      state.loading = false;
    },
    createUserFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    editUserInProgress(state) {
      state.loading = true;
    },
    editUserSuccess(state) {
      state.loading = false;
    },
    editUserFailed(state, { payload }) {
      state.loading = false;
      state.error = payload.error;
    },

    isUserModalOpenSuccess(state, { payload }: PayloadAction<boolean>) {
      state.isUserModalOpen = payload;
    },
  },
});

export const {
  actions: adminUserActions,
  reducer: adminUserReducer,
  name: sliceKey,
} = adminUserSlice;

export const {
  getUserListInProgress,
  getUserListSuccess,
  getUserListFailed,

  getCategoriesListInProgress,
  getCategoriesListSuccess,
  getCategoriesListFailed,

  getRegionListInProgress,
  getRegionListSuccess,
  getRegionListFailed,

  getChannelListInProgress,
  getChannelListSuccess,
  getChannelListFailed,

  getUserRoleListInProgress,
  getUserRoleListSuccess,
  getUserRoleListFailed,

  createUserInProgress,
  createUserSuccess,
  createUserFailed,

  editUserInProgress,
  editUserSuccess,
  editUserFailed,

  isUserModalOpenSuccess,
} = adminUserSlice.actions;

export const userModalOpen =
  (isOpen: boolean) => async (dispatch: AppDispatch) => {
    dispatch(isUserModalOpenSuccess(isOpen));
  };

export const getUserList = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getUserListInProgress());
  try {
    dispatch(loadingActions.hideLoader());
    const data = await userSource.getUserListAPI();
    dispatch(getUserListSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    const { response } = error;
    const { data } = response?.data;
    if (data.statusCode > 300) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(getUserListFailed({ error: data.message }));
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
    }
  }
};

export const getUserRolesList = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getUserRoleListInProgress());
  try {
    dispatch(loadingActions.hideLoader());
    const data: IUserRolesResponse = await userSource.getAllRolesAPI();
    dispatch(getUserRoleListSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    const { response } = error;
    const { data } = response?.data;
    if (data.statusCode > 300) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(getUserRoleListFailed({ error: data.message }));
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
    }
  }
};

export const getCategoriesList = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getCategoriesListInProgress());
  try {
    dispatch(loadingActions.hideLoader());
    const data: IAllCategoriesResponse = await userSource.getAllCategoriesAPI();
    dispatch(getCategoriesListSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    const { response } = error;
    const { data } = response?.data;
    if (data.statusCode > 300) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(getCategoriesListFailed({ error: data.message }));
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
    }
  }
};

export const getRegionList = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getRegionListInProgress());
  try {
    dispatch(loadingActions.hideLoader());
    const data: IAllCategoriesResponse = await userSource.getAllRegionAPI();
    dispatch(getRegionListSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    const { response } = error;
    const { data } = response?.data;
    if (data.statusCode > 300) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(getRegionListFailed({ error: data.message }));
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
    }
  }
};

export const getChannelList = () => async (dispatch: AppDispatch) => {
  dispatch(loadingActions.showLoader());
  dispatch(getChannelListInProgress());
  try {
    dispatch(loadingActions.hideLoader());
    const data: IAllCategoriesResponse = await userSource.getAllChannelAPI();
    dispatch(getChannelListSuccess(data));
  } catch (error) {
    dispatch(loadingActions.hideLoader());
    const { response } = error;
    const { data } = response?.data;
    if (data.statusCode > 300) {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
      // handle error
      dispatch(getChannelListFailed({ error: data.message }));
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: data?.message || 'Something went wrong!',
        }),
      );
    }
  }
};

export const createNewUser =
  (payload: ICreateUser) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(createUserInProgress());
    try {
      dispatch(loadingActions.hideLoader());
      const data = await userSource.createUserAPI(payload);
      dispatch(createUserSuccess(data));
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: 'You have successfully created a new user',
        }),
      );
      dispatch(getUserList());
      dispatch(isUserModalOpenSuccess(false));
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(createUserFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };

export const editUser =
  (payload: IEditUser) => async (dispatch: AppDispatch) => {
    dispatch(loadingActions.showLoader());
    dispatch(editUserInProgress());
    try {
      dispatch(loadingActions.hideLoader());
      const data = await userSource.editUserAPI(payload);
      dispatch(editUserSuccess(data));
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'success',
          content: 'Data saved successfuly',
        }),
      );
      dispatch(getUserList());
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(editUserFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };
