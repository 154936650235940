import { reducer as directionReducer } from 'app/molecules/Direction/ducks/slice';
import { reducer as permissionReducer } from 'app/modules/Permission/ducks/slice';
import { reducer as AuthReducer } from 'app/modules/Auth/ducks/slice';
import { reducer as LoaderReducer } from 'app/molecules/Loader/ducks/slice';
import { reducer as ToastMessageReducer } from 'app/molecules/ToastMessage/ducks/slice';
import { promotionsReducer } from 'app/modules/Promotion/ducks/slice';
import { calendarViewReducer } from 'app/modules/CalendarView/ducks/slice';
import { reducer as appHeaderReducer } from 'app/modules/AppHeader/ducks/slice';
import { adminUserReducer } from 'app/modules/Users/ducks/slice';
import { listViewReducer } from 'app/modules/ListView/ducks/slice';
import { dashboardReducer } from 'app/modules/DashboardView/ducks/slice';
import { reportingReducer } from 'app/modules/Reporting/ducks/slice';
// [IMPORT NEW REDUCER ABOVE] < Needed for generating seamlessly
export const reducers = {
  direction: directionReducer,
  permission: permissionReducer,
  auth: AuthReducer,
  loader: LoaderReducer,
  toastMessage: ToastMessageReducer,
  promotions: promotionsReducer,
  calendarView: calendarViewReducer,
  appHeader: appHeaderReducer,
  adminUser: adminUserReducer,
  listView: listViewReducer,
  dashboardView: dashboardReducer,
  reportingView: reportingReducer,
  // [INSERT NEW REDUCER KEY ABOVE] < Needed for generating seamlessly
};
