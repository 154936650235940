import { Avatar, Skeleton, Table, Tag, Popover } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserList, userModalOpen } from './ducks/slice';
import { IUser } from './ducks/types';
import { formateUTCDateTimeWithoutDash } from 'utils/helper';
import UserModal from './userModal';

const UserList = (): ReactElement => {
  const dispatch: any = useAppDispatch();
  const {
    adminUser: { userList, loading, error },
  } = useAppSelector((state: any) => state);

  const [isEditUserModalOpen, setIsEditUserModalOpen] =
    useState<boolean>(false);

  const [editUserData, setEditUserData] = useState<IUser | {}>({});

  useEffect(() => {
    dispatch(getUserList());
  }, []);
  const onHandleEdit = (user: IUser) => {
    setIsEditUserModalOpen(true);
    setEditUserData(user);
  };

  const onCloseUserDrawer = () => {
    setIsEditUserModalOpen(false);
    dispatch(userModalOpen(false));
  };

  const Content = (props: any) => {
    const { customProp } = props;
    return (
      <ul className="cat-name-list">
        {customProp.map((str: any, index: any) => {
          if (index > 2) {
            return (
              <li>
                <span title={str}>
                  <Avatar src={''} className="m-1">
                    {str.length > 2
                      ? str.slice(0, 2).toUpperCase()
                      : str.toUpperCase()}
                  </Avatar>
                </span>
                {str}
              </li>
            );
          }
        })}
      </ul>
    );
  };

  const userListingColumns = [
    {
      title: '',
      render: (item: IUser) => (
        <div className="center">
          <Avatar src={item?.profileImage || ''}>
            {item?.firstName ? item?.firstName.charAt(0).toUpperCase() : ''}
          </Avatar>
        </div>
      ),
    },
    {
      title: 'User Name',
      render: (item: IUser) => (
        <div
          className="font-semibold cursor-pointer"
          key={`${item.id}-${item.firstName}`}
          onClick={() => onHandleEdit(item)}
        >
          {item?.firstName || ''} {item?.lastName || ''}
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      render: (item: IUser) => (
        <Tag
          color={`${item.isActive ? 'green' : 'orange'}`}
          key={`${item.id}-tag`}
          className="ut-tag-status"
        >
          {item.isActive ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Created at',
      render: (item: IUser) => (
        <span>{formateUTCDateTimeWithoutDash(item.createdAt)}</span>
      ),
    },
    {
      title: 'Modified at',
      render: (item: IUser) => (
        <span>{formateUTCDateTimeWithoutDash(item.updatedAt)}</span>
      ),
    },

    {
      title: 'Role',
      dataIndex: 'rolesAssigned',
      key: 'rolesAssigned',
    },

    {
      title: 'Assigned Categories',
      render: (item: IUser) => (
        <>
          {item?.categoriesAssigned.map((str: string, index: any) => {
            if (index <= 2) {
              return (
                <span title={str}>
                  <Avatar src={''} className="m-1">
                    {str.length > 2
                      ? str.slice(0, 2).toUpperCase()
                      : str.toUpperCase()}
                  </Avatar>
                </span>
              );
            }
          })}
          {item?.categoriesAssigned.length > 3 && (
            <Popover
              content={<Content customProp={item?.categoriesAssigned} />}
              trigger="hover"
              placement="bottomRight"
            >
              <span>
                <Avatar src={''} className="m-1">
                  + {item?.categoriesAssigned.length - 3}
                </Avatar>
              </span>
            </Popover>
          )}
        </>
      ),
    },
    {
      title: 'Assigned Regions',
      render: (item: IUser) => (
        <>
          {item?.regionsAssigned.map((str: string, index: any) => {
            if (index <= 2) {
              return (
                <span title={str}>
                  <Avatar src={''} className="m-1">
                    {str.length > 2
                      ? str.slice(0, 2).toUpperCase()
                      : str.toUpperCase()}
                  </Avatar>
                </span>
              );
            }
          })}
          {item?.regionsAssigned.length > 3 && (
            <Popover
              content={<Content customProp={item?.regionsAssigned} />}
              trigger="hover"
              placement="bottomRight"
            >
              <span>
                <Avatar src={''} className="m-1">
                  + {item?.regionsAssigned.length - 3}
                </Avatar>
              </span>
            </Popover>
          )}
        </>
      ),
    },
    {
      title: 'Assigned Channels',
      render: (item: IUser) => (
        <>
          {item?.channelsAssigned.map((str: string, index: any) => {
            if (index <= 2) {
              return (
                <span title={str}>
                  <Avatar src={''} className="m-1">
                    {str.length > 2
                      ? str.slice(0, 2).toUpperCase()
                      : str.toUpperCase()}
                  </Avatar>
                </span>
              );
            }
          })}
          {item?.channelsAssigned.length > 3 && (
            <Popover
              content={<Content customProp={item?.channelsAssigned} />}
              trigger="hover"
              placement="bottomRight"
            >
              <span>
                <Avatar src={''} className="m-1">
                  + {item?.channelsAssigned.length - 3}
                </Avatar>
              </span>
            </Popover>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="bg-white p-3 relative">
        {/* <Skeleton loading={loading}> */}
        <Table
          className="ut-table-user"
          dataSource={userList}
          loading={loading}
          columns={userListingColumns}
        />
        {/* </Skeleton> */}
        {/* <HeaderFilter onFilter={onFilter} /> */}
        <UserModal
          isOpen={isEditUserModalOpen}
          onClose={onCloseUserDrawer}
          data={editUserData}
          isEdit={true}
        />
      </div>
    </>
  );
};

export default UserList;
