import { Fragment } from 'react';
import BrandPromoRow from './BrandPromoRow';
import RowCategorySummary from './RowCategorySummary';
import { IReportDataCategory } from './ducks/type';

interface IProps {
  reportData: IReportDataCategory[];
}
export default function TableBody({ reportData }: IProps) {
  if (!reportData) {
    return null;
  }

  return (
    <>
      {reportData.map((category) => (
        <Fragment key={category.id}>
          <RowCategorySummary
            data={category.aggregatedCatData.monthly}
            name={category.category}
          />
          {category.brands.map((brand, index) => (
            <BrandPromoRow key={brand.id} index={index} brand={brand} />
          ))}
        </Fragment>
      ))}
    </>
  );
}
