import { useEffect, useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  addNewTemplate,
  setIsNewTemplateModalVisible,
  setNewTemplate,
} from './ducks/slice';
import { deleteEvent } from '../CalendarView/ducks/slice';
import { getListView } from '../ListView/ducks/slice';
import { PAGE_SIZE_LIST_VIEW } from '../ListView/helper';

function NewTemplateModal({ isNewTemplateModalVisible }: any) {
  const dispatch = useAppDispatch();

  const {
    promotions: { newTemplate, isTempPromo },
  } = useAppSelector((state: any) => state);

  const [templateName, setTemplateName] = useState('');
  const [isError, setError] = useState(false);

  const handleModalCancel = () => {
    dispatch(setIsNewTemplateModalVisible(false));
    if (newTemplate.promoId != '') {
      dispatch(
        setNewTemplate({
          promoId: '',
        }),
      );
      setTemplateName('');
      setError(false);

      if (isTempPromo) {
        dispatch(deleteEvent(newTemplate.promoId, false, true));
      }
    }
  };

  const handleModalSubmit = async () => {
    if (templateName == '') {
      setError(true);
      dispatch(setIsNewTemplateModalVisible(false));
      return;
    } else if (newTemplate.promoId != '') {
      dispatch(
        addNewTemplate({
          promoId: newTemplate.promoId,
          templateName: templateName,
        }),
      );

      setTemplateName('');
      setError(false);
      dispatch(setIsNewTemplateModalVisible(false));

      dispatch(
        setNewTemplate({
          promoId: '',
        }),
      );

      if (isTempPromo) {
        await dispatch(deleteEvent(newTemplate.promoId, false, true));
      }

      dispatch(
        getListView({
          pageNumber: 1,
          pageSize: PAGE_SIZE_LIST_VIEW,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(
      setNewTemplate({
        promoId: '',
      }),
    );
  }, []);

  return (
    <Modal
      centered={true}
      open={isNewTemplateModalVisible}
      title={
        <div className="flex flex-row items-center">
          <PlusOutlined />
          <span
            style={{ fontSize: 16, marginLeft: 10 }}
          >{`Add new template`}</span>
        </div>
      }
      className="ut-modal delete-modal"
      onCancel={handleModalCancel}
      footer={[
        <Button className="ut-btn me-4" key="back" onClick={handleModalCancel}>
          Cancel
        </Button>,
        <Button
          className="ut-btn normal-text"
          key="submit"
          type="primary"
          onClick={handleModalSubmit}
        >
          <span>Create</span>
        </Button>,
      ]}
    >
      {
        <>
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
          >
            <label className="ms-1">
              <span
                style={{
                  color: '#494949',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                }}
              >
                Enter template name{' '}
              </span>
              <span style={{ color: '#D36500' }}>*</span>
            </label>
            <Input
              value={templateName}
              onChange={(e) => {
                if (setTemplateName) setTemplateName(e.target.value);
              }}
              className="mt-1"
            />
          </Form.Item>
          {isError && templateName === '' && (
            <p
              style={{
                color: 'red',
                fontSize: 12,
                position: 'relative',
                top: -15,
                textAlign: 'left',
              }}
            >
              Field is required
            </p>
          )}
        </>
      }
    </Modal>
  );
}

export default NewTemplateModal;
