import { Permission } from 'app/modules/Permission/ducks/types';

const isContainDot = (iPerm: string): boolean => iPerm.includes('.');

/**
 * @description transform string permission to object permission
 */
export const str2ObjPermission = (permissions: string[]): Permission[] =>
  permissions.filter(isContainDot).map((iPerm) => {
    const [subject, action] = iPerm.split(`.`);
    return {
      action,
      subject,
    };
  });
