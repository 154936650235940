import React, { ReactElement, useEffect } from 'react';
import { message } from 'antd';
import { selectMessage } from './ducks/selectors';
import { useAppSelector } from 'store/hooks';
import { useAppDispatch } from 'store/hooks';
import { toastMessageActions } from './ducks/slice';
const ToastMessage = (): ReactElement => {
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const messageState = useAppSelector(selectMessage);
  const { messageType, content } = messageState;

  useEffect(() => {
    if (content) {
      messageApi.open({
        type: messageType,
        content: content,
        duration: 2.5,
        onClose: () => {
          dispatch(toastMessageActions.clearToastMessage());
        },
      });
    }
  }, [content]);

  return <>{contextHolder}</>;
};

export default ToastMessage;
