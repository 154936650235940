/* eslint-disable */
import React from 'react';
import { ConfigProvider } from 'antd';
import { createRoot } from 'react-dom/client';
import App from './routing/Root';
import * as serviceWorker from './serviceWorker';
import { coustomTheme } from './theme';
import './App.css';
import './locales/i18n';
// import { MixpanelProvider } from 'react-mixpanel-browser';
import * as Sentry from '@sentry/react';
 
Sentry.init({
  dsn: 'https://809aa61399e0e99245ea35574a1b1423@o4507174483656704.ingest.us.sentry.io/4507174485491712',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'https://www.up-trade.ai/demo-unilever',
    'https://unilever-demo-app.azurewebsites.net',
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container!);
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

const MIXPANEL_CONFIG = {
  track_pageview: true, // Set to `false` by default
};

root.render(
  <ConfigProvider
    theme={{
      token: {
        ...coustomTheme,
      },
    }}
    input={{ autoComplete: 'off' }}
  >
    {/* <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}> */}
      <App />
    {/* </MixpanelProvider> */}
  </ConfigProvider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
