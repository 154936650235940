import { BASE_URL, httpVerbs } from 'configs';
import { str2ObjPermission } from 'utils/auth';
import request from 'utils/axios';
import { Permission } from './types';

const PERM_URL = `${BASE_URL}/user/permissions`;

const getPermissions = async (): Promise<Permission[]> => {
  const params: any = {
    url: PERM_URL,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  const permissions = response.data.permissions || [];
  return str2ObjPermission(permissions);
};

export default getPermissions;
