/* eslint-disable */
import React from 'react';
import { Row, Col, Modal, Table } from 'antd';
import { useAppSelector } from 'store/hooks';
import { capitalizeFirstLetter } from './helper';
const SelectionDetailModal = (props: any) => {
  const {
    modalType,
    handleHierarchyModalType,
    isModalOpen,
    handleOk,
    handleCancel,
  } = props;
  const { promotions } = useAppSelector((state: any) => state);
  const { singleObj, levels } = promotions;
  let data = [];
  let columns: any = [];

  const columnsProduct = levels?.productLevels?.map((level: any) => ({
    title: (
      <div
        style={{
          minWidth: '60px',
        }}
      >
        {'Level ' + level?.level} <br />
        {level?.level_name}
      </div>
    ),
    render: (item: any) => (
      <div>
        {/* <div className="ut-count">
          {item[`${level?.level_name.replace(' ', '_').toLowerCase()}_id`] ||
            ''}
        </div> */}
        <div className="ut-label">
          {capitalizeFirstLetter(
            item[level?.level_name?.replace(' ', '_').toLowerCase()] || '',
          )}
        </div>
      </div>
    ),
  }));
  const columnsGeo = levels?.geoLevels?.map((level: any) => ({
    title: (
      <div
        style={{
          minWidth: '60px',
        }}
      >
        {'Level ' + level?.geo_level_id} <br />
        {level?.level_name}
      </div>
    ),
    render: (item: any) => (
      <div>
        {/* <div className="ut-count">
          {item[`${level?.level_name.toLowerCase()}_id`] || ''}
        </div> */}
        <div className="ut-label">
          {capitalizeFirstLetter(item[level?.level_name.toLowerCase()] || '')}
        </div>
      </div>
    ),
  }));
  const columnsChannel = levels?.channelLevels?.map((level: any) => ({
    title: (
      <div
        style={{
          minWidth: '60px',
          textAlign: 'center',
          margin: 'auto',
        }}
      >
        {'Level ' + level?.channel_level_id} <br />
        {level?.level_name}
      </div>
    ),
    render: (item: any) => (
      <div>
        {/* <div className="ut-count">
          {item[`${level?.level_name.replace(' ', '_').toLowerCase()}_id`] ||
            ''}
        </div> */}
        <div className="ut-label">
          {capitalizeFirstLetter(
            item[`${level?.level_name.replace(' ', '_').toLowerCase()}`] || '',
          )}
        </div>
      </div>
    ),
  }));

  switch (modalType) {
    case 'products':
      data = singleObj?.selections?.products;
      columns = columnsProduct;
      break;
    case 'geo':
      data = singleObj?.selections?.geos;
      columns = columnsGeo;
      break;
    case 'channel':
      data = singleObj?.selections?.channels;
      columns = columnsChannel;
      break;
    default:
      data = singleObj?.selections?.products;
      columns = columnsProduct;
      break;
  }

  return (
    <>
      <Modal
        title="Selection Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        className="ut-modal hierarchy-modal table-hierarchy full"
      >
        <Row className="p-6" gutter={24}>
          <Col span={6}>
            <Row gutter={8} className="ut-hierarchy-columns">
              <Col span={24}>
                <Col>
                  <div
                    className={`ut-boxes products ${
                      modalType === 'products' ? 'active' : ''
                    }`}
                    onClick={() => handleHierarchyModalType('products')}
                  >
                    <span className="label block">Products</span>
                  </div>
                </Col>
                <Col>
                  <div
                    className={`ut-boxes geo ${
                      modalType === 'geo' ? 'active' : ''
                    }`}
                    onClick={() => handleHierarchyModalType('geo')}
                  >
                    <span className="label block">Geo</span>
                  </div>
                </Col>
              </Col>
              <Col span={24}>
                <Col>
                  <div
                    className={`ut-boxes channel ${
                      modalType === 'channel' ? 'active' : ''
                    }`}
                    onClick={() => handleHierarchyModalType('channel')}
                  >
                    <span className="label block">Channel</span>
                  </div>
                </Col>
                <Col className="hidden">
                  <div className="ut-boxes outlet">
                    <span className="label block">Geo</span>
                  </div>
                </Col>
              </Col>
            </Row>
          </Col>
          <Col span={18}>
            <Table
              dataSource={data}
              columns={columns}
              pagination={{
                pageSize: 8, // Number of items per page
              }}
              className="ut-table ut-table-two"
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default SelectionDetailModal;
