import request from 'utils/axios';
import { httpVerbs, BASE_URL, TOKEN_KEY } from 'configs';
import {
  CredsInterface,
  CurrenUser,
  ForgotPasswordInterface,
  ResetPasswordInterface,
  Status,
  Token,
} from './types';
import { getFromLocal, removeFromLocal, saveToLocal } from 'utils/cache';

const AUTH_URL = `${BASE_URL}/auth`;

export const signIn = (
  credentials: CredsInterface,
): Promise<{ data: Token }> => {
  const params: any = {
    url: `${AUTH_URL}/login`,
    method: httpVerbs.POST,
    data: credentials,
    headerVariant: 'public',
  };
  return request(params);
};

/* export const signOut = (): any | null => {
  const params: any = {
    url: `${AUTH_URL}/logout`,
    method: httpVerbs.POST,
  };

  return request(params);
}; */

export const getUser = (): Promise<{ data: CurrenUser }> => {
  const params: any = {
    url: `${BASE_URL}/user/me`,
    method: httpVerbs.GET,
  };
  return request(params);
};

export const forgotPassword = (
  data: ForgotPasswordInterface,
): Promise<{ data: any }> => {
  const params: any = {
    url: `${AUTH_URL}/forgot-password`,
    method: httpVerbs.POST,
    data,
    headerVariant: 'public',
  };
  return request(params);
};

export const resetPassword = (data: any): Promise<{ data: Status }> => {
  const params: any = {
    url: `${AUTH_URL}/forgot-password`,
    method: httpVerbs.PUT,
    headerVariant: 'public',
    data,
  };
  return request(params);
};

export const refreshToken = (): Promise<{ data: any }> => {
  let tokens = getFromLocal(TOKEN_KEY);
  const params: any = {
    url: `${BASE_URL}/auth/refresh`,
    method: httpVerbs.POST,
    data: {
      accessToken: tokens.access_token,
      refreshToken: tokens.refresh_token,
      remember: localStorage.getItem('remember') == 'true' ? true : false,
    },
  };
  return request(params);
};

export const saveTokenToLocal = (token: Token) => saveToLocal(TOKEN_KEY, token);
export const removeTokenFromLocal = () => removeFromLocal(TOKEN_KEY);
export const getTokenFromLocal = () => getFromLocal(TOKEN_KEY);
