const COLOR_PRIMARY = '#054096';
const TEXT_BASE_COLOR = '#000000';
const DEFAULT_FONT = 'Roboto';
const SECONDARY_FONT = 'Mulish';

export const coustomTheme = {
  colorPrimary: COLOR_PRIMARY,
  colorTextBase: TEXT_BASE_COLOR,
  colorLink: COLOR_PRIMARY,
  colorIconHover: COLOR_PRIMARY,
  colorLinkHover: COLOR_PRIMARY,
  colorLinkActive: COLOR_PRIMARY,
  colorFillContentHover: COLOR_PRIMARY,
  fontFamily: DEFAULT_FONT,
  fontSize: 16,
};
