import React, { ReactElement } from 'react';
import { Typography } from 'antd';
const { Title } = Typography;

const PageNotFound = (): ReactElement => (
  <>
    <Title level={4}>Page not found</Title>
  </>
);

export default PageNotFound;
