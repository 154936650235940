import { BASE_URL, httpVerbs } from 'configs';
import request from 'utils/axios';
import { IFilterBodyParam, IPromoFilterData } from './type';

const PROMOTION_URL = `${BASE_URL}/promotions`;

export const searchPromotionApi = (
  filter: Partial<IFilterBodyParam>,
): Promise<IPromoFilterData[]> =>
  request({
    method: 'post',
    url: `${PROMOTION_URL}/filter`,
    data: filter,
  }).then((res) => res.data);

export const getUserNotificationsApi = async (): Promise<any> => {
  const url = `${BASE_URL}/notification`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const resetNotificationsCountApi = async (): Promise<any> => {
  const url = `${BASE_URL}/notification/reset-count`;
  const params: any = {
    url: url,
    method: httpVerbs.POST,
  };
  const response = await request(params);
  return response;
};

export const searchResultApi = async (
  search: string | number,
): Promise<any> => {
  const url = `${BASE_URL}/promotions/search?text=${search}`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};

export const recentSearchApi = async (): Promise<any> => {
  const url = `${BASE_URL}/user/promotion-views`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};
