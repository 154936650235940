import { Row } from 'antd';

import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { IReportDataCategory } from './ducks/type';

interface IProps {
  reportData: IReportDataCategory[];
}

export default function ReportTable({ reportData }: IProps) {
  return (
    <Row className="ut-reporting-container">
      {/* Reporting Calendar Container */}

      {/* Monthly - Yearly - Week Columns */}
      <TableHeader />
      <TableBody reportData={reportData} />
    </Row>
  );
}
