import { Button, Col, Collapse, Modal, Row, Tag, Popover, message } from 'antd';
import TreeView from './treeView';
import { formateHierarchyBoxTagsDynamic, splitCamelCase } from './helper';
import { useEffect } from 'react';
import {
  getChannelLevels,
  getGeoLevels,
  getProductLevels,
  getTreeSelectionCount,
} from './ducks/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
const { Panel } = Collapse;
const HierarchyModal = (props: any) => {
  const {
    modalType,
    isProductDetailModal,
    isModalOpen,
    handleOk,
    handleCancel,
    handleHierarchyModalType,
    handleProductDetailModal,
    handleSelectionDetailModal,
    onCheck,
    checkedKeys,
    geoCheckedKeys,
    channelCheckedKeys,
    singleObj,
    isEdit,
    treeSelectionPayload,
    singleObj: {
      selectionsCount: {
        geoNames: singleObjGeoNames,
        productNames: singleObjProducts,
        channelNames: singleObjChannelNames,
        productComponentsCount: singleObjProductComponentsCount,
        channelComponentsCount: singleObjChannelComponentsCount,
        geoComponentCount: singleObjGeoComponentCount,
      } = {
        productComponentsCount: {},
        channelComponentsCount: {},
        geoComponentCount: {},
        geoNames: {},
        productNames: {},
        channelNames: {},
      },
    } = {},
    setOutletKeys,
    outletKeys,
    setOutletKeysLength,
    isCustomerDisabled,
  } = props;

  const onChange = (key: string | string[]) => {};
  // Initializations
  const dispatch: any = useAppDispatch();
  const {
    promotions: {
      levels: { productLevels, geoLevels, channelLevels },
      treeSelectionCount: {
        channelsCount,
        productComponentsCount,
        geoComponentCount,
        channelComponentsCount,
        productsCount,
        geosCount,
        productNames,
        geoNames,
        channelNames,
        outletsCount,
      },
      outlets,
      selectedTemplateDetail,
    },
  } = useAppSelector((state: any) => state);
  let checkedHKeys;
  switch (modalType) {
    case 'products':
      checkedHKeys = checkedKeys;
      break;
    case 'geo':
      checkedHKeys = geoCheckedKeys;
      break;
    case 'channel':
      checkedHKeys = channelCheckedKeys;
      break;
    default:
      checkedHKeys = checkedKeys;
      break;
  }
  useEffect(() => {
    if (!isEdit) {
      if (productLevels.length == 0) {
        dispatch(getProductLevels());
      }
      if (geoLevels.length == 0) {
        dispatch(getGeoLevels());
      }
      if (channelLevels.length == 0) {
        dispatch(getChannelLevels());
      }
      if (!selectedTemplateDetail) {
        dispatch(getTreeSelectionCount(treeSelectionPayload));
      }
    }
  }, []);

  useEffect(() => {
    if (!isEdit) {
      //dispatch(getTreeSelectionCount(treeSelectionPayload));
    }
  }, [dispatch, treeSelectionPayload, isEdit]);
  const capitalizeFirstLetter = (string: string) =>
    string
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  const treeSelectionCountToList = (obj: any) => {
    const element = Object.keys(obj).map((key: string) => {
      return (
        <li key={key}>
          <span className="whitespace-nowrap">
            {' '}
            {capitalizeFirstLetter(key)}
          </span>
          <span className="dots"></span>
          <span>{obj[key]}</span>
        </li>
      );
    });
    return element;
  };
  const handleClickProductModal = () => {
    let count: number = Number(
      singleObj?.selectionsCount?.productsCount
        ? singleObj?.selectionsCount?.productsCount
        : productsCount
        ? productsCount
        : 0,
    );
    if (count == 0) {
      message.error('Please select level first');
      setTimeout(() => {
        handleCancel();
      }, 1000);
      return;
    }

    handleHierarchyModalType('products');
  };
  return (
    <>
      <Modal
        title="Hierarchy Selection"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        className="ut-modal hierarchy-modal full"
      >
        {isProductDetailModal && (
          <Row>
            <Col span={24} className="header-bottom">
              <Button
                type="primary"
                className="mr-2 ut-btn ligth-orange-btn"
                onClick={handleProductDetailModal}
              >
                Calculation
              </Button>
              <Button
                type="primary"
                className="mr-2 ut-btn orange-btn"
                onClick={handleSelectionDetailModal}
              >
                Selection
              </Button>
            </Col>
          </Row>
        )}
        <Row justify="space-between">
          <Col span={19}>
            <Row
              gutter={16}
              className="ut-hierarchy-columns ut-hierarchy-detail-columns"
            >
              <Col span={8}>
                <Row gutter={8}>
                  <Col span={24}>
                    <Col>
                      <div
                        className={`ut-boxes products ${
                          modalType === 'products' ? 'active' : ''
                        }`}
                        onClick={handleClickProductModal}
                      >
                        <div className="top">
                          <h6>
                            {isEdit
                              ? singleObj?.selectionsCount?.productsCount
                              : productsCount}{' '}
                            Products are selected
                          </h6>
                          <span className="label">Products</span>
                        </div>
                        <div className="bottom">
                          {productLevels?.length > 0 &&
                            productLevels.map((product: any, index: number) => {
                              if (index > 1) {
                                return null;
                              } else {
                                return (
                                  <>
                                    <div>
                                      <span>
                                        {splitCamelCase(product?.level_name)}:{' '}
                                      </span>
                                      {formateHierarchyBoxTagsDynamic(
                                        isEdit
                                          ? singleObjProducts
                                          : productNames,
                                        product.level_name
                                          .toLowerCase()
                                          .split(' ')
                                          .join('_'),
                                        isEdit,
                                      )}
                                    </div>
                                  </>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </Col>
                  </Col>
                  <Col span={24}>
                    <Col>
                      <div
                        className={`ut-boxes geo ${
                          modalType === 'geo' ? 'active' : ''
                        }`}
                        onClick={() => handleHierarchyModalType('geo')}
                      >
                        <div className="top">
                          <h6>
                            {isEdit
                              ? singleObj?.selectionsCount?.geosCount
                              : geosCount}{' '}
                            Distributors are selected
                          </h6>
                          <span className="label">Geo</span>
                        </div>
                        <div className="bottom">
                          {geoLevels?.length > 0 &&
                            geoLevels.map((geoItem: any, index: number) => {
                              if (index > 1) {
                                return null;
                              } else {
                                return (
                                  <>
                                    <div>
                                      <span>
                                        {splitCamelCase(geoItem?.level_name)}:{' '}
                                      </span>
                                      {formateHierarchyBoxTagsDynamic(
                                        isEdit ? singleObjGeoNames : geoNames,
                                        geoItem.level_name
                                          .toLowerCase()
                                          .split(' ')
                                          .join('_'),
                                        isEdit,
                                        2,
                                      )}
                                    </div>
                                  </>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </Col>
                  </Col>
                  {isCustomerDisabled && (
                    <Col span={24}>
                      <Col>
                        <div
                          className={`ut-boxes channel ${
                            modalType === 'channel' ? 'active' : ''
                          }`}
                          onClick={() => handleHierarchyModalType('channel')}
                        >
                          <div className="top">
                            <h6>
                              {isEdit
                                ? singleObj?.selectionsCount?.channelsCount
                                : channelsCount}{' '}
                              Channels are selected
                            </h6>
                            <span className="label">Channel</span>
                          </div>
                          <div className="bottom">
                            {channelLevels?.length > 0 &&
                              channelLevels.map(
                                (channelItem: any, index: number) => {
                                  if (index > 1) {
                                    return null;
                                  } else {
                                    return (
                                      <>
                                        <div>
                                          <span>
                                            {splitCamelCase(
                                              channelItem?.level_name,
                                            )}
                                            :{' '}
                                          </span>
                                          {formateHierarchyBoxTagsDynamic(
                                            isEdit
                                              ? singleObjChannelNames
                                              : channelNames,
                                            channelItem.level_name
                                              .toLowerCase()
                                              .split(' ')
                                              .join('_'),
                                            true,
                                            1, //tags count
                                          )}
                                        </div>
                                      </>
                                    );
                                  }
                                },
                              )}
                          </div>
                        </div>
                      </Col>
                    </Col>
                  )}
                  {isCustomerDisabled && (
                    <Col span={24}>
                      <Col>
                        <div
                          className={`ut-boxes outlet ${
                            modalType === 'outlet' ? 'active' : ''
                          }`}
                          onClick={() => handleHierarchyModalType('outlet')}
                        >
                          <div className="top">
                            <h6>
                              {isEdit
                                ? singleObj?.selectionsCount?.outletsCount
                                : outletKeys?.length}{' '}
                              Outlets are selected
                            </h6>
                            <span className="label">Outlet</span>
                          </div>
                          {outletKeys?.length === 0 &&
                            outletKeys?.length !== outlets.length && (
                              <div className="bottom">
                                <div>
                                  <span>Outlets:</span>
                                </div>
                              </div>
                            )}
                          {outletKeys?.length === outlets.length && (
                            <div className="bottom">
                              <div>
                                <span>Outlets: All</span>
                              </div>
                            </div>
                          )}
                          {outletKeys?.length >= 1 &&
                            outletKeys?.length !== outlets.length && (
                              <div className="bottom">
                                <div>
                                  <span>Outlets:</span>
                                  {outletKeys?.map((item: any, index: any) => {
                                    if (index === 0) {
                                      return (
                                        <Tag key={item}>
                                          {
                                            outlets.find(
                                              (outlet: any) =>
                                                outlet.id === item,
                                            )?.name
                                          }
                                        </Tag>
                                      );
                                    }
                                  })}
                                  {outletKeys?.length > 1 && (
                                    <Popover
                                      content={outletKeys?.map(
                                        (item: any, idx: any) => {
                                          if (idx > 0) {
                                            return (
                                              <Tag style={{ marginBottom: 10 }}>
                                                {
                                                  outlets.find(
                                                    (outlet: any) =>
                                                      outlet.id === item,
                                                  )?.name
                                                }
                                              </Tag>
                                            );
                                          }
                                        },
                                      )}
                                      trigger="hover"
                                    >
                                      <Tag>{outletKeys?.length - 1} more</Tag>
                                    </Popover>
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      </Col>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col span={16}>
                <div className="ut-product-selection">
                  <h4 className="title ut-mb-r capitalize">
                    {modalType} Selection
                  </h4>
                  <TreeView
                    treeType={modalType}
                    checkedKeys={checkedHKeys}
                    onCheck={onCheck}
                    isEdit={isEdit}
                    setOutletKeys={setOutletKeys}
                    outletKeys={outletKeys}
                    setOutletKeysLength={setOutletKeysLength}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={5}>
            <div className="ut-summary modal-summary">
              <h4 className="header border-0">Summary Statistics</h4>
              <Collapse
                defaultActiveKey={['1']}
                onChange={onChange}
                className="ut-collapse"
                items={undefined}
              >
                <Panel header="Products" key="1">
                  <Row>
                    <Col span={24}>
                      <ul className="listing">
                        {isEdit
                          ? singleObjProductComponentsCount &&
                            treeSelectionCountToList(
                              singleObjProductComponentsCount,
                            )
                          : productComponentsCount &&
                            treeSelectionCountToList(productComponentsCount)}
                      </ul>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>

              <Collapse
                defaultActiveKey={['1']}
                onChange={onChange}
                className="ut-collapse"
                items={undefined}
              >
                <Panel header="Geo" key="1">
                  <Row>
                    <Col span={24}>
                      <ul className="listing">
                        {isEdit
                          ? singleObjGeoComponentCount &&
                            treeSelectionCountToList(singleObjGeoComponentCount)
                          : geoComponentCount &&
                            treeSelectionCountToList(geoComponentCount)}
                      </ul>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <Collapse
                defaultActiveKey={['1']}
                onChange={onChange}
                className="ut-collapse"
                items={undefined}
              >
                <Panel header="Channel" key="1">
                  <Row>
                    <Col span={24}>
                      <ul className="listing">
                        {isEdit
                          ? singleObjChannelComponentsCount &&
                            treeSelectionCountToList(
                              singleObjChannelComponentsCount,
                            )
                          : channelComponentsCount &&
                            treeSelectionCountToList(channelComponentsCount)}
                      </ul>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default HierarchyModal;
