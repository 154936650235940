import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReportingSlice } from './type';

export const initialState: IReportingSlice = {
  viewByOption: 'totalCost',
};

const reportingSlice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    setViewBy(state, { payload }: PayloadAction<string>) {
      state.viewByOption = payload;
    },
  },
});

export const {
  actions: reportingActions,
  reducer: reportingReducer,
  name: sliceKey,
} = reportingSlice;

export const { setViewBy } = reportingSlice.actions;
