/**
 *
 * Forgot Passwrod
 *
 */
/* eslint-disable */
import React, { ReactElement, useState } from 'react';
// import { useMixpanel } from 'react-mixpanel-browser';
import { Form, Col, Row, Input, notification } from 'antd';
import Button from 'app/atoms/Button';
import { MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { selectAuth } from './ducks/selectors';
import { LOGIN_PAGE, validateMessages } from './constants';
import { authActions, forgotPassword } from './ducks/slice';
import './style.less';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useHistory } from 'react-router-dom';

import * as services from './ducks/services';
import { toastMessageActions } from 'app/molecules/ToastMessage/ducks/slice';

const ForgotPassword = (): ReactElement => {
  const history = useHistory();
  // const mixpanel = useMixpanel();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const auth = useAppSelector(selectAuth);

  const {
    auth: { user },
  } = useAppSelector((state: any) => state);

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: ForgotPasswordForm) => {
    setLoading(true);
    try {
      let d = await services.forgotPassword({ email: values.email });
      localStorage.setItem('forgotPassEmail', values?.email);
      notification.success({ message: d.data.message });
      setLoading(false);
     // mixpanel?.track('forgot password initiated');
     // mixpanel?.identify(values.email);
      setTimeout(() => {
        history.push('/reset-password');
      }, 500);
    } catch (error) {
      notification.error({ message: error.response.data.data.message });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  // const backtoLogin = () => dispatch(authActions.changePage(LOGIN_PAGE));
  const backtoLogin = () => history.push('/');
  return (
    <div className="ut-login-container">
      <div className="main-container">
        <div className="ut-login-body">
          <Row justify="center" align="middle">
            <Col sm={20} md={8}>
              <div className="ut-card radius login">
                <Col span={24}>
                  <Row justify="center" align="middle">
                    <Col md={18}>
                      <h2 className="title">{t('auth.forgot.heading')}</h2>
                    </Col>
                  </Row>
                  <Row justify="center" align="middle">
                    <Col md={18}>
                      <Form
                        name="normal_login"
                        layout="vertical"
                        className="login-form ut-form"
                        onFinish={onFinish}
                        validateMessages={validateMessages}
                      >
                        <Form.Item
                          label={t('email')}
                          name="email"
                          rules={[
                            {
                              required: true,
                              type: 'email',
                              message: t('form.email.required'),
                            },
                          ]}
                        >
                          <Input
                            prefix={
                              <MailOutlined className="site-form-item-icon" />
                            }
                            placeholder="Enter Email Address"
                            type="email"
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            btnclass="button ut-btn ut-mt-l"
                            type="primary"
                            loading={loading}
                            htmlType="submit"
                            block
                            value={t('Submit')}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            btnclass="custom-link-bt forgot-password ut-mb-s ut-pb-s"
                            type="link"
                            htmlType="button"
                            onClick={backtoLogin}
                            value={t('back_to_login')}
                          />
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

interface ForgotPasswordForm {
  email: string;
}

export default ForgotPassword;
