import { Collapse, Row, Col } from 'antd';
import { IconDownOutlined } from 'app/icons/icons';

const { Panel } = Collapse;

export default function HierarchyFilter({ showHierarchyModal }: any) {
  return (
    <Collapse
      defaultActiveKey={['1']}
      className="ut-collapse"
      expandIcon={({ isActive }) => (
        <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
          <IconDownOutlined />
        </span>
      )}
      items={undefined}
      expandIconPosition={'end'}
    >
      <Panel header="Hierarchy" key="1">
        <Row gutter={8} className="ut-hierarchy-columns">
          <Col span={12}>
            <div
              className="ut-boxes products"
              onClick={() => showHierarchyModal('products')}
            >
              <span className="label block">Products</span>
            </div>
          </Col>
          <Col span={12}>
            <div
              className="ut-boxes geo"
              onClick={() => showHierarchyModal('geo')}
            >
              <span className="label block">Geo</span>
            </div>
          </Col>
          <Col span={12}>
            <div
              className="ut-boxes channel"
              onClick={() => showHierarchyModal('channel')}
            >
              <span className="label block">Channel</span>
            </div>
          </Col>
          <Col span={12} className="hidden">
            <div
              className="ut-boxes outlet"
              // onClick={() => showHierarchyModal('outlet')}
            >
              <span className="label block">Outlet</span>
            </div>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}
