/* eslint-disable */
import React, { useEffect, useState } from 'react';
// import { useMixpanel } from 'react-mixpanel-browser';
import {
  Collapse,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Modal,
  Descriptions,
  Table,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  CaretDownFilled,
  DownOutlined,
} from '@ant-design/icons';
import type { FormItemProps } from 'antd';
import { MyFormItemGroupProps } from 'utils/types';
import { calculateActivityDuration, formateSelectVal } from './helper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  deleteSelectedProduct,
  updatePromotion,
  updateSelectedProduct,
} from 'app/modules/Promotion/ducks/slice';
import { formatNumberWithCommasNew } from 'utils/helper';
const { Panel } = Collapse;
const ProductDetailModal = (props: any) => {
  // const mixpanel = useMixpanel();

  const {
    auth: { user },
  } = useAppSelector((state: any) => state);

  const {
    isModalOpen,
    handleOk,
    handleCancel,
    handleChange,
    handleInputChange,
    defaultApplyUomsVal,
    defaultBuyTypesVal,
    defaultBuyUnitOfMeasuresVal,
    defaultDiscountTypesVal,
    defaultApplyOnsVal,
    promoActivityDuration,
  } = props;
  const { promotions } = useAppSelector((state: any) => state);
  const {
    singleObj,
    promoBuyTypes,
    offerTypes,
    promoBuyUnitOfMeasures,
    promoApplyOns,
    promoApplyUoms,
    promoDiscountTypes,
    editPromoPayloadGState,
    singleObj: { selectedProductsSegregated },
  } = promotions;
  const editPromotionData = singleObj;
  const dispatch = useAppDispatch();

  const [productsDiscountValue, setProductsDiscountValue] = useState({} as any);
  const [productsIncValue, setProductsIncValue] = useState({} as any);
  const [editableInput, setEditableInput] = useState('');
  const [tableKey, setTableKey] = useState(0);
  const [visibleEntries, setVisibleEntries] = useState(10);

  const { isFinanceManager } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (isModalOpen) {
      // mixpanel?.track('Calculations click');
      // mixpanel?.identify(user.email);
    }
  }, [isModalOpen]);

  useEffect(() => {
    setTableKey(tableKey + 1);
  }, [singleObj, selectedProductsSegregated]);

  const handleInputFocusIncVol: any = (product: any, field: string) => {
    setEditableInput(`${field}-${product.id}`);
    const arr = { ...productsIncValue };
    setProductsIncValue(arr);
    arr[product.id] = { id: product.id, [field]: product.incrementalVolume };
  };

  const handleInputFocusDiscount: any = (product: any, field: string) => {
    setEditableInput(`${field}-${product.id}`);
    const arr = { ...productsDiscountValue };
    setProductsDiscountValue(arr);
    arr[product.id] = { id: product.id, [field]: product.discount };
  };

  const handleProductDiscountInputChange = (
    e: any,
    product: any,
    name: string,
  ) => {
    const value = Number(e.target.value);
    const arr = { ...productsDiscountValue };
    arr[product.id] = { id: product.id, [name]: value };

    setProductsDiscountValue(arr);
  };

  const handleProductIncInputChange = (e: any, product: any, name: string) => {
    const value = Number(e.target.value);
    const arr = { ...productsIncValue };
    arr[product.id] = { id: product.id, [name]: value };

    setProductsIncValue(arr);
  };

  const handleUpdateDiscountProduct = (productId: any, name: string) => {
    const productDetails = productsDiscountValue[productId];
    const payload = {
      products: [productDetails],
      id: singleObj?.id,
    };
    dispatch(updateSelectedProduct(payload));
    setEditableInput('');
    // mixpanel?.track('Update product discount');
    // mixpanel?.identify(user.email);
  };

  const handleUpdateIncProduct = (productId: any, name: string) => {
    const productDetails = productsIncValue[productId];
    let payload;
    if (productDetails !== undefined) {
      payload = {
        products: [productDetails],
        id: singleObj?.id,
      };
    } else {
      payload = {
        products: [],
        id: singleObj?.id,
      };
    }
    dispatch(updateSelectedProduct(payload));
    setEditableInput('');
    // mixpanel?.track('Update product incremental volume');
    // mixpanel?.identify(user.email);
  };

  const handleProductDelete = (product: any) => {
    const payload = {
      id: singleObj?.id,
      products: [product],
    };
    dispatch(deleteSelectedProduct(payload));
    // mixpanel?.track('Delete product click');
    // mixpanel?.identify(user.email);
  };

  const handleSeeMore = () => {
    setVisibleEntries((prevVisibleEntries) => prevVisibleEntries + 20);
  };

  const FormItemContext = React.createContext<(string | number)[]>([]);

  function toArr(
    str: string | number | (string | number)[],
  ): (string | number)[] {
    return Array.isArray(str) ? str : [str];
  }

  const MyFormItemGroup = ({ prefix, children }: MyFormItemGroupProps) => {
    const prefixPath = React.useContext(FormItemContext);
    const concatPath = React.useMemo(
      () => [...prefixPath, ...toArr(prefix)],
      [prefixPath, prefix],
    );

    return (
      <FormItemContext.Provider value={concatPath}>
        {children}
      </FormItemContext.Provider>
    );
  };

  const MyFormItem = ({ name, ...prop }: FormItemProps) => {
    const prefixPath = React.useContext(FormItemContext);
    const concatName =
      name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;

    return <Form.Item name={concatName} {...prop} />;
  };

  const handleApplySelection = () => {
    dispatch(updatePromotion(editPromoPayloadGState.payload, singleObj?.id));
    // mixpanel?.track('Apply selection click , update promotion');
    // mixpanel?.identify(user.email);
  };

  const columns = [
    {
      title: 'Action',
      key: 'action',
      render: (product: any) => (
        <Button type="text" onClick={() => handleProductDelete(product)}>
          <DeleteOutlined
            style={{ display: 'flex', justifyContent: 'center' }}
          />
        </Button>
      ),
    },
    {
      title: 'Product Code',
      dataIndex: 'id',
      key: 'id',
      // sorter: (a: any, b: any) => a.id - b.id,
      // defaultSortOrder: 'ascend' as SortOrder,
      render: (id: any) => id,
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Pricing',
      dataIndex: 'pricing',
      key: 'pricing',
      render: (text: any, record: any) => (
        <div>{text ? formatNumberWithCommasNew(text) : 'N/A'}</div>
      ),
    },
    {
      title: 'GPT',
      dataIndex: 'gpt',
      key: 'gpt',
      render: (text: any, record: any) => (
        <div>{text ? formatNumberWithCommasNew(text) : 'N/A'}</div>
      ),
    },
    {
      title: 'Grammage',
      dataIndex: 'grammage',
      key: 'grammage',
      render: (text: any, record: any) => (
        <div>{text ? Number(text).toFixed(2) : 'N/A'}</div>
      ),
    },
    {
      title: 'Discount',
      render: (product: any) => (
        <div key={`incrementalVolume-${product.id}`} className="ut-form flex">
          <Input
            disabled
            defaultValue={product.discount}
            value={productsDiscountValue.discount}
            onChange={(e) =>
              handleProductDiscountInputChange(e, product, 'discount')
            }
            onFocus={() => handleInputFocusDiscount(product, 'discount')}
            style={{ width: 100 }}
          />
          {editableInput === `discount-${product.id}` && (
            <div>
              <Button
                type="text"
                className="h-100 ut-pr-x ut-pl-x ml-1"
                onClick={() =>
                  handleUpdateDiscountProduct(product.id, 'discount')
                }
              >
                <CheckCircleOutlined />
              </Button>
              <Button
                type="text"
                className="h-100 ut-pr-x ut-pl-x"
                onClick={() => setEditableInput('')}
              >
                <CloseCircleOutlined />
              </Button>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Baseline',
      dataIndex: 'baseline',
      key: 'baseline',
      render: (text: any, record: any) => (
        <div>{text ? Number(text).toFixed(2) : 'N/A'}</div>
      ),
    },
    {
      title: 'Lift%',
      dataIndex: 'lift',
      key: 'lift',
      render: (text: any, record: any) => (
        <div>{text ? Number(text).toFixed(2) : 'N/A'}</div>
      ),
    },
    {
      title: 'INC Volume',
      key: 'incrementalVolume',
      render: (product: any) => (
        <div key={`incrementalVolume-${product.id}`} className="ut-form flex">
          <Input
            defaultValue={product.incrementalVolume}
            value={productsIncValue.incrementalVolume}
            style={{ width: 100 }}
            onChange={(e) =>
              handleProductIncInputChange(e, product, 'incrementalVolume')
            }
            disabled={isFinanceManager}
            onFocus={() => handleInputFocusIncVol(product, 'incrementalVolume')}
          />
          {editableInput === `incrementalVolume-${product.id}` && (
            <div>
              <Button
                type="text"
                className="h-100 ut-pr-x ut-pl-x"
                onClick={() =>
                  handleUpdateIncProduct(product.id, 'incrementalVolume')
                }
              >
                <CheckCircleOutlined />
              </Button>
              <Button
                type="text"
                className="h-100 ut-pr-x ut-pl-x"
                onClick={() => setEditableInput('')}
              >
                <CloseCircleOutlined />
              </Button>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Total Volume',
      dataIndex: 'volume',
      key: 'volume',
      render: (text: any, record: any) => (
        <div>{text ? formatNumberWithCommasNew(text) : 'N/A'}</div>
      ),
    },
    {
      title: 'Cost (PKR)',
      dataIndex: 'cost',
      key: 'cost',
      render: (text: any, record: any) => (
        <div>{text ? formatNumberWithCommasNew(text) : 'N/A'}</div>
      ),
    },
    {
      title: 'ROI%',
      dataIndex: 'roi',
      key: 'roi',
      render: (text: any, record: any) => (
        <div>{text ? Number(text).toFixed(2) : 'N/A'}</div>
      ),
    },
  ];

  const isOptionDisabled = () => {
    return (
      isFinanceManager || !['draft', 'sentback'].includes(singleObj?.status)
    );
  };

  return (
    <>
      <Modal
        title="Product Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        className="ut-modal hierarchy-modal table-hierarchy full model-index-2"
      >
        <Row className="p-6">
          <Col span={24}>
            <Form name="product-selection-form" layout="vertical">
              <Collapse
                className="ut-collapse"
                collapsible="header"
                defaultActiveKey={['1']}
                items={undefined}
                expandIconPosition={'end'}
              >
                <Panel header="Offer" key="1" className="ut-collapse  mb-0">
                  <MyFormItemGroup prefix={['discount']}>
                    <Row gutter={16}>
                      <Col className="gutter-row" span={8}>
                        <MyFormItem
                          name="offerType"
                          label="Buy Type"
                          className="ut-form"
                        >
                          <Select
                            className="ut-select"
                            suffixIcon={<CaretDownFilled />}
                            defaultValue={
                              formateSelectVal(
                                singleObj?.promoData?.offerType
                                  ? singleObj?.promoData?.offerType
                                  : {
                                      id: '',
                                      name: '',
                                    },
                                'offerType',
                              ) ||
                              defaultBuyTypesVal ||
                              'Please Select'
                            }
                            onChange={(e: any) => handleChange(e, 'offerType')}
                            options={offerTypes?.map((offerType: any) => {
                              return formateSelectVal(offerType, 'offerType');
                            })}
                            disabled={isOptionDisabled()}
                          />
                        </MyFormItem>
                      </Col>
                      <Col className="gutter-row" span={8}>
                        <MyFormItem
                          name="buyUnitOfMeasure"
                          label="Buy Unit of Measure"
                          className="ut-form"
                        >
                          <Select
                            disabled={isOptionDisabled()}
                            className="ut-select"
                            suffixIcon={<CaretDownFilled />}
                            defaultValue={
                              formateSelectVal(
                                singleObj?.promoData?.applyUOM
                                  ? singleObj?.promoData?.applyUOM
                                  : {
                                      id: '',
                                      name: '',
                                    },
                                'applyUOM',
                              ) ||
                              defaultBuyUnitOfMeasuresVal ||
                              'Please Select'
                            }
                            onChange={(e: any) => handleChange(e, 'applyUOM')}
                            options={promoBuyUnitOfMeasures?.map(
                              (unit: any) => {
                                return formateSelectVal(unit, 'applyUOM');
                              },
                            )}
                          />
                        </MyFormItem>
                      </Col>
                      <Col className="gutter-row" span={8}>
                        <MyFormItem
                          name="Discount Method"
                          label="Discount Method"
                          className="ut-form"
                        >
                          <Select
                            disabled={isOptionDisabled()}
                            className="ut-select"
                            suffixIcon={<CaretDownFilled />}
                            defaultValue={
                              formateSelectVal(
                                singleObj?.promoData?.discountType
                                  ? singleObj?.promoData?.discountType
                                  : {
                                      id: '',
                                      name: '',
                                    },
                                'discountType',
                              ) ||
                              defaultDiscountTypesVal ||
                              'Please Select'
                            }
                            onChange={(e: any) =>
                              handleChange(e, 'discountType')
                            }
                            options={promoDiscountTypes?.map(
                              (discount: any) => {
                                return formateSelectVal(
                                  discount,
                                  'discountType',
                                );
                              },
                            )}
                          />
                        </MyFormItem>
                      </Col>
                      <Col className="gutter-row" span={8}>
                        <MyFormItem
                          name="BuyFrom"
                          label="Buy From (Minimum Buy)"
                          className="ut-form"
                        >
                          <Input
                            disabled={isOptionDisabled()}
                            defaultValue={editPromotionData.purchaseFrom}
                            onBlur={(e: any) =>
                              handleInputChange(e, 'purchaseFrom')
                            }
                          />
                        </MyFormItem>
                      </Col>
                      <Col className="gutter-row" span={8}>
                        <MyFormItem
                          name="BuyTo"
                          label="Buy To (Maximum Buy)"
                          className="ut-form"
                        >
                          <Input
                            disabled={isOptionDisabled()}
                            defaultValue={editPromotionData.purchaseTo}
                            onBlur={(e: any) =>
                              handleInputChange(e, 'purchaseTo')
                            }
                          />
                        </MyFormItem>
                      </Col>
                      <Col className="gutter-row" span={8}>
                        <MyFormItem
                          name="Discount"
                          label="Discount"
                          className="ut-form"
                        >
                          <Input
                            disabled={isOptionDisabled()}
                            defaultValue={
                              singleObj?.discountValue ||
                              editPromotionData?.discountValue
                            }
                            onBlur={(e: any) => {
                              handleInputChange(e, 'discountValue');
                              setProductsDiscountValue([]);
                            }}
                          />
                        </MyFormItem>
                      </Col>
                      <Col className="gutter-row" span={8}>
                        <MyFormItem
                          name="ApplyOn"
                          label="Apply On"
                          className="ut-form"
                        >
                          <Select
                            disabled={isOptionDisabled()}
                            className="ut-select"
                            suffixIcon={<CaretDownFilled />}
                            defaultValue={
                              formateSelectVal(
                                singleObj?.promoData?.applyOn
                                  ? singleObj?.promoData?.applyOn
                                  : {
                                      id: '',
                                      name: '',
                                    },
                                'applyOn',
                              ) ||
                              defaultApplyOnsVal ||
                              'Please Select'
                            }
                            onChange={(e: any) => handleChange(e, 'applyOn')}
                            options={promoApplyOns?.map((apply: any) => {
                              return formateSelectVal(apply, 'applyOn');
                            })}
                          />
                        </MyFormItem>
                      </Col>
                      <Col className="gutter-row" span={8}>
                        <MyFormItem
                          name="ApplyUOM"
                          label="Apply UOM"
                          className="ut-form"
                        >
                          <Select
                            disabled={isOptionDisabled()}
                            className="ut-select"
                            suffixIcon={<CaretDownFilled />}
                            defaultValue={
                              formateSelectVal(
                                singleObj?.promoData?.applyUOM
                                  ? singleObj?.promoData?.applyUOM
                                  : {
                                      id: '',
                                      name: '',
                                    },
                                'applyUOM',
                              ) ||
                              defaultApplyUomsVal ||
                              'Please Select'
                            }
                            onChange={(e: any) => handleChange(e, 'applyUOM')}
                            options={promoApplyUoms?.map((uom: any) => {
                              return formateSelectVal(uom, 'applyUOM');
                            })}
                          />
                        </MyFormItem>
                      </Col>
                      <Col
                        className="gutter-row items-right flex right"
                        span={8}
                      >
                        <Button
                          type="primary"
                          className="btn btn-primary ut-btn ms-auto !p-0 !px-3"
                          onClick={handleApplySelection}
                          style={{ marginTop: 28 }}
                          disabled={isOptionDisabled()}
                        >
                          Apply selection
                        </Button>
                      </Col>
                    </Row>
                  </MyFormItemGroup>
                </Panel>
              </Collapse>
            </Form>
          </Col>
          {editPromotionData?.promoData?.promoType?.id == '2' &&
            selectedProductsSegregated?.length > 0 &&
            selectedProductsSegregated
              .slice(0, visibleEntries)
              .map((selectedProduct: any) => {
                return editPromotionData?.promoData?.customers?.length > 0 ? (
                  editPromotionData?.promoData?.customers?.map(
                    (customer: any) => {
                      return (
                        <div className="ut-product-detail-table-container">
                          <Col>
                            <Descriptions className="ut-description ut-description-two">
                              <Descriptions.Item label="Code">
                                {singleObj?.promoCode || ''}
                              </Descriptions.Item>
                              <Descriptions.Item label="Lift%">
                                {formatNumberWithCommasNew(
                                  selectedProduct?.totalLift?.toFixed(2),
                                ) || ''}
                              </Descriptions.Item>
                              <Descriptions.Item label="Total Volume">
                                {formatNumberWithCommasNew(
                                  selectedProduct?.totalVolume?.toFixed(2) ||
                                    '',
                                )}
                              </Descriptions.Item>
                              <Descriptions.Item label="Brand Code">
                                {selectedProduct?.brand_id || ''}
                              </Descriptions.Item>
                              <Descriptions.Item label="Incremental Volume">
                                {formatNumberWithCommasNew(
                                  selectedProduct?.totalIncrementalVolume?.toFixed(
                                    2,
                                  ) || 0,
                                )}
                              </Descriptions.Item>
                              <Descriptions.Item label="Total Cost (PKR)">
                                {formatNumberWithCommasNew(
                                  selectedProduct?.totalCost?.toFixed(2) || 0,
                                )}
                              </Descriptions.Item>
                              <Descriptions.Item label="Activity Duration">
                                {promoActivityDuration &&
                                  calculateActivityDuration(
                                    promoActivityDuration,
                                  )}
                              </Descriptions.Item>
                              <Descriptions.Item label="No.of Products">
                                {formatNumberWithCommasNew(
                                  selectedProduct?.totalProducts || 0,
                                )}
                              </Descriptions.Item>
                              <Descriptions.Item label="Total ROI">
                                <span className="ut-t-large">
                                  {formatNumberWithCommasNew(
                                    selectedProduct?.totalROI?.toFixed(2) || 0,
                                  )}
                                  %
                                </span>
                              </Descriptions.Item>
                            </Descriptions>
                          </Col>
                          <Col>
                            <div className="flex justify-between py-2 customer-row relative">
                              <div className="ml-4">
                                Customer: {customer.name}
                              </div>
                              <div className="flex flex-row">
                                <div
                                  className="absolute"
                                  style={{
                                    right: '412px',
                                  }}
                                >
                                  {selectedProduct?.totalIncrementalVolume.toFixed(
                                    2,
                                  ) || 0}
                                </div>
                                <div
                                  className="absolute"
                                  style={{
                                    right: '237px',
                                  }}
                                >
                                  {selectedProduct?.totalVolume?.toFixed(2) ||
                                    ''}
                                </div>
                                <div
                                  className="absolute"
                                  style={{
                                    right: '97px',
                                  }}
                                >
                                  {selectedProduct?.totalCost?.toFixed(2) || 0}
                                </div>
                                <div
                                  className="absolute"
                                  style={{
                                    right: '11px',
                                  }}
                                >
                                  {selectedProduct?.totalROI?.toFixed(2) || 0}%
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <Descriptions className="ut-description">
                              <Descriptions.Item label="Category Code">
                                {selectedProduct?.category_id || ''}
                              </Descriptions.Item>
                              <Descriptions.Item label="Category Name">
                                {selectedProduct?.category || ''}
                              </Descriptions.Item>
                              <Descriptions.Item label="Category Modified Date">
                                {''}
                              </Descriptions.Item>
                              <Descriptions.Item label="Brand Code">
                                {selectedProduct?.brand_id || ''}
                              </Descriptions.Item>
                              <Descriptions.Item label="Brand Name">
                                {selectedProduct?.brand || ''}
                              </Descriptions.Item>
                              <Descriptions.Item label="Brand Modified Date">
                                {''}
                              </Descriptions.Item>
                              <Descriptions.Item label="SKU Code">
                                {selectedProduct?.product_variant_id}
                              </Descriptions.Item>
                              <Descriptions.Item label="SKU Name">
                                {selectedProduct?.product_variant}
                              </Descriptions.Item>
                              <Descriptions.Item label="SKU Modified Date">
                                {''}
                              </Descriptions.Item>
                            </Descriptions>
                          </Col>
                          <Col span={24}>
                            <Table
                              key={tableKey}
                              dataSource={selectedProduct?.products}
                              columns={columns}
                              className="ut-table ut-product-detail-table"
                              pagination={{
                                pageSize: 5, // Number of items per page
                              }}
                            />
                          </Col>
                        </div>
                      );
                    },
                  )
                ) : (
                  <div className="ut-product-detail-table-container">
                    <Col>
                      <Descriptions className="ut-description ut-description-two">
                        <Descriptions.Item label="Code">
                          {singleObj?.promoCode || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Lift%">
                          {selectedProduct?.totalLift?.toFixed(2) || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total Volume">
                          {formatNumberWithCommasNew(
                            selectedProduct?.totalVolume?.toFixed(2) || '',
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Brand Code">
                          {selectedProduct?.brand_id || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Incremental Volume">
                          {formatNumberWithCommasNew(
                            selectedProduct?.totalIncrementalVolume.toFixed(
                              2,
                            ) || 0,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total Cost (PKR)">
                          {formatNumberWithCommasNew(
                            selectedProduct?.totalCost?.toFixed(2) || 0,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Activity Duration">
                          {promoActivityDuration &&
                            calculateActivityDuration(promoActivityDuration)}
                        </Descriptions.Item>
                        <Descriptions.Item label="No.of Products">
                          {formatNumberWithCommasNew(
                            selectedProduct?.totalProducts || 0,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total ROI">
                          <span className="ut-t-large">
                            {formatNumberWithCommasNew(
                              selectedProduct?.totalROI?.toFixed(2) || 0,
                            )}
                            %
                          </span>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col>
                      <Descriptions className="ut-description">
                        <Descriptions.Item label="Category Code">
                          {selectedProduct?.category_id || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Category Name">
                          {selectedProduct?.category || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Category Modified Date">
                          {''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Brand Code">
                          {selectedProduct?.brand_id || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Brand Name">
                          {selectedProduct?.brand || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Brand Modified Date">
                          {''}
                        </Descriptions.Item>
                        <Descriptions.Item label="SKU Code">
                          {selectedProduct?.product_variant_id}
                        </Descriptions.Item>
                        <Descriptions.Item label="SKU Name">
                          {selectedProduct?.product_variant}
                        </Descriptions.Item>
                        <Descriptions.Item label="SKU Modified Date">
                          {''}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col span={24}>
                      <Table
                        key={tableKey}
                        dataSource={selectedProduct?.products}
                        columns={columns}
                        className="ut-table ut-product-detail-table"
                        pagination={{
                          pageSize: 5, // Number of items per page
                        }}
                      />
                    </Col>
                  </div>
                );
              })}
          {editPromotionData?.promoData?.promoType?.id != '2' &&
            selectedProductsSegregated?.length > 0 &&
            selectedProductsSegregated
              .slice(0, visibleEntries)
              .map((selectedProduct: any) => {
                return (
                  <div className="ut-product-detail-table-container">
                    <Col>
                      <Descriptions className="ut-description ut-description-two">
                        <Descriptions.Item label="Code">
                          {singleObj?.promoCode || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Lift%">
                          {selectedProduct?.totalLift?.toFixed(2) || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total Volume">
                          {formatNumberWithCommasNew(
                            selectedProduct?.totalVolume?.toFixed(2) || '',
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Brand Code">
                          {selectedProduct?.brand_id || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Incremental Volume">
                          {formatNumberWithCommasNew(
                            selectedProduct?.totalIncrementalVolume?.toFixed(
                              2,
                            ) || 0,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total Cost (PKR)">
                          {formatNumberWithCommasNew(
                            selectedProduct?.totalCost?.toFixed(2) || 0,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Activity Duration">
                          {promoActivityDuration &&
                            calculateActivityDuration(promoActivityDuration)}
                        </Descriptions.Item>
                        <Descriptions.Item label="No.of Products">
                          {formatNumberWithCommasNew(
                            selectedProduct?.totalProducts || 0,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total ROI">
                          <span className="ut-t-large">
                            {formatNumberWithCommasNew(
                              selectedProduct?.totalROI?.toFixed(2) || 0,
                            )}
                            %
                          </span>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col>
                      <Descriptions className="ut-description">
                        <Descriptions.Item label="Category Code">
                          {selectedProduct?.category_id || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Category Name">
                          {selectedProduct?.category || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Category Modified Date">
                          {''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Brand Code">
                          {selectedProduct?.brand_id || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Brand Name">
                          {selectedProduct?.brand || ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Brand Modified Date">
                          {''}
                        </Descriptions.Item>
                        <Descriptions.Item label="SKU Code">
                          {selectedProduct?.product_variant_id}
                        </Descriptions.Item>
                        <Descriptions.Item label="SKU Name">
                          {selectedProduct?.product_variant}
                        </Descriptions.Item>
                        <Descriptions.Item label="SKU Modified Date">
                          {''}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col span={24}>
                      <Table
                        key={tableKey}
                        dataSource={selectedProduct?.products}
                        columns={columns}
                        className="ut-table ut-product-detail-table"
                        pagination={{
                          pageSize: 5, // Number of items per page
                        }}
                      />
                    </Col>
                  </div>
                );
              })}
          {selectedProductsSegregated?.length > visibleEntries && (
            <button
              className="mt-2 mx-auto ut-btn cursor-pointer"
              onClick={handleSeeMore}
              style={{
                color: '#fff',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
              }}
            >
              See more <DownOutlined color="#fff" />{' '}
            </button>
          )}
        </Row>
      </Modal>
    </>
  );
};
export default ProductDetailModal;
