import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Collapse, Select, Input, Form } from 'antd';
import { CaretDownFilled, CloseCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IconDownOutlined } from 'app/icons/icons';
import { validateMessages } from './constants';
import { ICategory, ICreateUser, IEditUser, IUserRole } from './ducks/types';
import {
  INVALID_EMAIL_MESSAGE,
  REQUIRED_FEILD,
  STRONG_PASSWORD_MESSAGE,
} from 'configs/constants';
import { EMAIL_REGEX, PASSWORD_REGEX } from 'configs/regex';
import {
  createNewUser,
  editUser,
  getCategoriesList,
  getUserRolesList,
  getRegionList,
  getChannelList,
} from './ducks/slice';
import { EStatus } from 'utils/types';

const { Panel } = Collapse;

const UserModal = (props: any): ReactElement => {
  const { isOpen, onClose, data, isEdit } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const {
    adminUser: {
      userRolesList,
      categoriesList,
      regionList,
      channelList,
      loading,
      error,
    },
  } = useAppSelector((state: any) => state);
  // const currentDate = dayjs().format('YYYY-MM-DD');

  const [userStatus, setUserStatus] = useState<EStatus[]>([
    EStatus.Active,
    EStatus.Inactive,
  ]);
  const [createUserPayload, setCreateUserPayload] = useState<ICreateUser>(
    () => {
      // const defaultRoleId = userRolesList.length > 0 ? userRolesList[0] : {};
      // const defaultCategoryId =
      //   categoriesList.length > 0 ? categoriesList[0] : {};
      const uStatus = EStatus.Active;
      return {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        profileImage: '',
        roleIDs: [],
        isActive: true,
        categoryIDs: [],
        channelIDs: [],
        regionIDs: [],
      };
    },
  );

  useEffect(() => {
    dispatch(getUserRolesList());
    dispatch(getCategoriesList());
    dispatch(getRegionList());
    dispatch(getChannelList());
  }, [dispatch]);

  useEffect(() => {
    if (userRolesList.length > 0 && createUserPayload.roleIDs.length === 0) {
      setCreateUserPayload((prevState) => ({
        ...prevState,
        roleIDs: [userRolesList[0]?.title], // Update roleIDs as an array
      }));
    }
    if (
      categoriesList.length > 0 &&
      createUserPayload.categoryIDs.length === 0
    ) {
      setCreateUserPayload((prevState) => ({
        ...prevState,
        categoryIDs: [categoriesList[0]?.name], // Update categoryIDs as an array
      }));
    }
  }, [userRolesList, categoriesList]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      // Extract an array of category IDs based on matching names
      const categoryIDs = data.categoriesAssigned.map(
        (categoryName: string) => {
          const category = categoriesList.find(
            (item: ICategory) => item.name === categoryName,
          );
          return category ? category.id : null;
        },
      );

      const getRole = userRolesList.find(
        (item: IUserRole) => data.rolesAssigned[0] === item.title,
      )?.id;

      // Create a payload object with the necessary values
      const payload = {
        ...data,
        categoryIDs: categoryIDs.filter(
          (id: string) => id !== null,
        ) as string[], // Remove null values and cast to string[]
        roleIDs: getRole ? [getRole] : [], // Ensure it's an array
        isActive: data.isActive,
        password: '', // Clear the password field
      };

      form.setFieldsValue(payload);
    }
  }, [data, categoriesList, userRolesList, form]);

  const handleChange = useCallback((value: any, name: any) => {
    const values = form.getFieldsValue();
    const modified = {
      ...values,
      [name]:
        name === 'categoryIDs' ||
        name === 'isActive' ||
        name === 'regionIDs' ||
        name === 'channelIDs'
          ? value
          : [value],
    };

    form.setFieldsValue(modified);
    setCreateUserPayload((prevPayload) => ({
      ...prevPayload,
      [name]: [value],
    }));
  }, []);

  const userRoleOptions = useMemo(
    () =>
      userRolesList.map((role: IUserRole) => ({
        value: role.id,
        label: role.title,
      })),
    [userRolesList],
  );

  const categoryOptions = useMemo(() => {
    return categoriesList.map((category: ICategory) => (
      <Select.Option key={category.id} value={category.id}>
        {category.name}
      </Select.Option>
    ));
  }, [categoriesList]);

  const regionOptions = useMemo(() => {
    return regionList.map((item: any) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));
  }, [regionList]);

  const channelOptions = useMemo(() => {
    return channelList.map((item: any) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));
  }, [channelList]);

  // const onCancel = () => dispatch(headerActions.setHeaderFilterModal(false));

  const onFinish = async (values: ICreateUser) => {
    const roleId = userRolesList
      .filter((role: IUserRole) => role.title === values.roleIDs[0])
      .map((role: IUserRole) => role.id);
    const categoryId = categoriesList
      .filter((cat: ICategory) => cat.name === values.categoryIDs[0])
      .map((cat: ICategory) => cat.id);

    let payload = {
      ...values,
      categoryIDs: categoryId.length > 0 ? categoryId : values.categoryIDs,
      roleIDs: roleId.length > 0 ? roleId : values.roleIDs,
      isActive: values.isActive,
    };
    if (isEdit) {
      payload = {
        ...payload,
        id: data.id,
      } as IEditUser;
      dispatch(editUser(payload));
    } else {
      dispatch(createNewUser(payload));
    }
  };
  const onFinishFailed = (errorInfo: any) => errorInfo;

  return (
    <>
      <Drawer
        title={`${isEdit ? 'Edit' : 'Create New'} User`}
        placement="right"
        onClose={onClose}
        open={isOpen}
        closeIcon={<CloseCircleOutlined />}
        className="ut-drawer bg-header-orange"
        destroyOnClose={true}
        rootStyle={{
          zIndex: 999,
        }}
        footer={
          <div className="flex justify-between px-4">
            <Button onClick={onClose} className="ut-btn light-btn">
              Cancel
            </Button>
          </div>
        }
      >
        <Form
          className="user-form ut-form ut-horizontal-form"
          layout="horizontal"
          name="basic"
          autoComplete="off"
          initialValues={createUserPayload}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
        >
          <Collapse
            defaultActiveKey={['1']}
            className="ut-collapse"
            items={undefined}
            expandIconPosition={'end'}
            expandIcon={({ isActive }) => (
              <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
                <IconDownOutlined />
              </span>
            )}
          >
            <Panel header="Basic Information" key="1">
              <Form.Item
                label={`User Name`}
                name="userName"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_FEILD,
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Enter Username"
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                label={`First Name`}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_FEILD,
                  },
                ]}
              >
                <Input type="text" placeholder="Enter First Name" />
              </Form.Item>
              <Form.Item
                label={`Last Name`}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_FEILD,
                  },
                ]}
              >
                <Input type="text" placeholder="Enter Last Name" />
              </Form.Item>
              <Form.Item
                label={`Email`}
                name="email"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_FEILD,
                  },
                  {
                    required: true,
                    pattern: EMAIL_REGEX,
                    message: INVALID_EMAIL_MESSAGE,
                  },
                ]}
              >
                <Input type="text" placeholder="Enter Email Address" />
              </Form.Item>

              <Form.Item
                label={'Password'}
                name="password"
                rules={[
                  {
                    required: !isEdit,
                    message: REQUIRED_FEILD,
                  },
                  {
                    required: !isEdit,
                    pattern: PASSWORD_REGEX,
                    message: STRONG_PASSWORD_MESSAGE,
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter Password"
                  type="password"
                  autoComplete="new-password"
                />
              </Form.Item>
            </Panel>
          </Collapse>

          <Collapse
            defaultActiveKey={['1']}
            className="ut-collapse"
            expandIcon={({ isActive }) => (
              <span style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}>
                <IconDownOutlined />
              </span>
            )}
            items={undefined}
            expandIconPosition={'end'}
          >
            <Panel header="Assignments" key="1">
              <Form.Item label={`User Role`} name="roleIDs">
                <Select
                  className="ut-select"
                  defaultValue={createUserPayload.roleIDs}
                  suffixIcon={<CaretDownFilled />}
                  onChange={(e) => handleChange(e, 'roleIDs')}
                  options={userRoleOptions}
                />
              </Form.Item>

              <Form.Item label={`Category`} name="categoryIDs">
                <Select
                  className="ut-select"
                  mode="multiple"
                  maxTagCount="responsive"
                  defaultValue={createUserPayload.categoryIDs}
                  suffixIcon={<CaretDownFilled />}
                  onChange={(e: any) => handleChange(e, 'categoryIDs')}
                >
                  {categoryOptions}
                </Select>
              </Form.Item>
              <Form.Item label={`Region`} name="regionIDs">
                <Select
                  className="ut-select"
                  mode="multiple"
                  maxTagCount="responsive"
                  defaultValue={createUserPayload.regionIDs}
                  suffixIcon={<CaretDownFilled />}
                  onChange={(e: any) => handleChange(e, 'regionIDs')}
                >
                  {regionOptions}
                </Select>
              </Form.Item>
              <Form.Item label={`Channel`} name="channelIDs">
                <Select
                  className="ut-select"
                  mode="multiple"
                  maxTagCount="responsive"
                  defaultValue={createUserPayload.channelIDs}
                  suffixIcon={<CaretDownFilled />}
                  onChange={(e: any) => handleChange(e, 'channelIDs')}
                >
                  {channelOptions}
                </Select>
              </Form.Item>
              <Form.Item label={`Status`} name="isActive">
                <Select
                  className="ut-select"
                  defaultValue={createUserPayload.isActive}
                  suffixIcon={<CaretDownFilled />}
                  onChange={(e: any) => handleChange(e, 'isActive')}
                  options={userStatus?.map((status: any) => {
                    return { value: status === EStatus.Active, label: status };
                  })}
                />
              </Form.Item>
            </Panel>
          </Collapse>

          <Button
            className="ut-btn ut-btn-submit-drawer-footer"
            htmlType="submit"
            type="primary"
          >
            {isEdit ? 'Save' : 'Create'}
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default UserModal;
