import {
  DownCircleOutlined,
  DownCircleFilled,
  UpCircleFilled,
} from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';
import PromotionYearSummaryLineGraph from './line';
import { IMonthlyReportData, PromoRowDetailShowState } from './ducks/type';

interface IProps {
  visibility: PromoRowDetailShowState;
  toggleVisibility: () => void;
  name: string;
  data: IMonthlyReportData[];
}

export default function RowBrandPromotionController({
  toggleVisibility,
  name,
  visibility,
  data,
}: IProps) {
  return (
    <Row className="chart-row ut-collapse" align="middle">
      <Col span={3} className="one">
        <div className="ut-fs-s ut-color-six">
          {name}
          <Tooltip title={visibility !== 'isShown' ? 'click to expand' : ''}>
            <span
              className="icon ml-3"
              onClick={toggleVisibility}
              style={{ cursor: 'pointer' }}
            >
              {visibility === 'isShown' ? (
                <UpCircleFilled />
              ) : (
                <DownCircleFilled />
              )}
            </span>
          </Tooltip>
          {/* <Button icon={<DownCircleOutlined />} size="small" shape="circle" /> */}
        </div>
      </Col>
      <Col span={21}>
        <PromotionYearSummaryLineGraph data={data} />
      </Col>
    </Row>
  );
}
