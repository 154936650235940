import { Row, Col, Tag } from 'antd';
import { ReactElement, useEffect } from 'react';
import { useState } from 'react';
import { calculateActivityDuration } from './helper';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import dayjs from 'dayjs';
import { capitalizeFirstLetter } from '../ListView/helper';
import { headerActions } from '../AppHeader/ducks/slice';
import { formatNumberWithCommas } from 'utils/helper';

const PromoSummary = ({
  singleObj,
  editPromotionData,
  editPromoPayload,
  promoActivityDuration,
  defaultLevelValue,
  defaultTypeValue,
  defaultDiscountTypesVal,
  defaultBuyUnitOfMeasuresVal,
  showHideHierarchyModal,
}: any): ReactElement => {
  const dispatch: any = useAppDispatch();
  const {
    promotions: { summaryObject },
  } = useAppSelector((state: any) => state);

  function convertDateFormat(inputDate: string): string {
    // Parse the input date using dayjs
    const parsedDate = dayjs(inputDate);

    // Format the date in the desired format
    const formattedDate = parsedDate.format('MMM DD, YYYY');

    return formattedDate;
  }

  let uniqueHolidays = [...new Set(summaryObject?.promoData?.holidays)];

  return (
    <div className="ut-summary">
      <h1 className="sum-heading">Summary</h1>
      <Row gutter={16}>
        <Col span={24}>
          <h5 className="text-italic mb-4 mt-5 promo-sub-heading">Financial</h5>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Baseline</span>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>
            {formatNumberWithCommas(
              summaryObject?.aggregatedCalculations?.totalBaseline || 0,
            ) || 0}
          </span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Lift %</span>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>{summaryObject?.aggregatedCalculations?.totalLift || 0}</span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Incremental Volume (tons)</span>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>
            {formatNumberWithCommas(
              summaryObject?.aggregatedCalculations?.totalIncrementalVolume ||
                0,
            ) || 0}{' '}
          </span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Total volume (tons)</span>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>
            {formatNumberWithCommas(
              summaryObject?.aggregatedCalculations?.totalVolume || 0,
            ) || 0}
          </span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Spend (PKR)</span>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>
            {formatNumberWithCommas(
              summaryObject?.aggregatedCalculations?.totalCost?.toFixed(3) || 0,
            ) || 0}
          </span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Profit %</span>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>{summaryObject?.aggregatedCalculations?.totalLift || 0}</span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>ROI %</span>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>
            {summaryObject?.aggregatedCalculations?.totalROI || '--'}
            {/* {summaryObject?.aggregatedCalculations?.totalROI ? '%' : null} */}
          </span>
        </Col>
      </Row>
      <hr className="w-[80%] mb-5" />
      {/* <Row gutter={16}>
        <Col span={12}>
          <span>Planned Cost</span>
        </Col>
        <Col span={12}>
          <span>
            {editPromotionData?.aggregatedCalculations?.totalCost || 0} PKR
          </span>
        </Col>
      </Row> */}
      <h5 className="text-italic mb-4 mt-1 promo-sub-heading">Promotion</h5>
      <h5 className="text-italic mb-4 mt-2 promo-sub-sub-heading">
        Primary Information
      </h5>
      <Row gutter={16}>
        <Col span={12}>
          <span>Program/Scheme</span>
        </Col>
        <Col span={12}>
          <span>
            {summaryObject?.dimensions?.scheme?.name ||
              summaryObject?.promoData?.scheme?.name ||
              '--'}
          </span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>
            Duration
            {summaryObject?.promoData?.promotionDuration &&
              '(' +
                summaryObject?.promoData?.promotionDuration?.split(' ')[1] +
                ')'}
          </span>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>
            {summaryObject?.promoData?.promotionDuration
              ? summaryObject?.promoData?.promotionDuration?.split(' ')[0]
              : '--'}{' '}
          </span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Start & End Date</span>
        </Col>
        <Col span={12}>
          <span>
            {summaryObject && convertDateFormat(summaryObject?.startDate) + '-'}{' '}
            {summaryObject && convertDateFormat(summaryObject?.endDate)}{' '}
            {!summaryObject && '--'}
          </span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Start & End Week</span>
        </Col>
        <Col span={12}>
          <span>
            {summaryObject && summaryObject?.promoData?.weekStart + '-'}{' '}
            {summaryObject && summaryObject?.promoData?.weekEnd}{' '}
            {!summaryObject && '--'}
          </span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Holiday(s)</span>
        </Col>
        <Col span={12}>
          <span>
            {uniqueHolidays?.length == 0
              ? 'No Holidays'
              : uniqueHolidays.map((holiday: any, index: any) => {
                  if (uniqueHolidays.length - 1 == index) {
                    return holiday;
                  } else {
                    return holiday + ', ';
                  }
                })}{' '}
          </span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Type</span>
        </Col>
        <Col span={12}>
          <span>
            {summaryObject?.promoData?.promoType?.shortCode}
            {summaryObject?.promoData?.promoType?.name
              ? '(' + summaryObject?.promoData?.promoType?.name + ')'
              : '--'}
          </span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Customer</span>
        </Col>
        <Col span={12}>
          <span>--</span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Level</span>
        </Col>
        <Col span={12}>
          <span>
            {summaryObject?.promoData?.products
              ? summaryObject?.promoData?.products[0]?.level
              : '--'}{' '}
          </span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Category</span>
        </Col>
        <Col span={12}>
          {typeof summaryObject?.selectionsCount?.productNames?.category !==
          'string' ? (
            summaryObject?.selectionsCount?.productNames?.category?.map(
              (cat: string) => (
                <Tag bordered={false} color="purple" style={{ fontSize: 12 }}>
                  {capitalizeFirstLetter(cat)}
                </Tag>
              ),
            )
          ) : (
            <Tag bordered={false} color="purple" style={{ fontSize: 12 }}>
              {capitalizeFirstLetter(
                summaryObject?.selectionsCount?.productNames?.category,
              )}
            </Tag>
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span className="!mb-0">Distributor(s) </span> <br />
          {/* <Tag
            bordered={false}
            color="orange"
            className="ms-2 view-list-tag cursor-pointer"
            onClick={() => showHideHierarchyModal('geo')}
          >
            View List
          </Tag> */}
          <button
            className="button-like-link"
            onClick={() => showHideHierarchyModal('geo')}
          >
            View List
          </button>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>{summaryObject?.selectionsCount?.geosCount}</span>
        </Col>
      </Row>
      {summaryObject?.promoData?.promoType?.id != '2' && (
        <Row gutter={16}>
          <Col span={12}>
            <span className="!mb-0">Channel(s)</span> <br />
            {/* <Tag
            bordered={false}
            color="orange"
            className="ms-2 view-list-tag cursor-pointer"
            onClick={() => showHideHierarchyModal('channel')}
          >
            View List
          </Tag> */}
            <button
              className="button-like-link"
              onClick={() => showHideHierarchyModal('channel')}
            >
              View List
            </button>
          </Col>
          <Col span={12} className="text-right padding-r">
            <span>{summaryObject?.selectionsCount?.channelsCount}</span>
          </Col>
        </Row>
      )}
      {summaryObject?.promoData?.promoType?.id != '2' && (
        <Row gutter={16}>
          <Col span={12}>
            <span className="!mb-0">Outlet(s)</span>
            <br />
            {/* <Tag
            bordered={false}
            color="orange"
            className="ms-2 view-list-tag cursor-pointer"
            onClick={() => showHideHierarchyModal('outlet')}
          >
            View List
          </Tag> */}
            <button
              className="button-like-link"
              onClick={() => showHideHierarchyModal('outlet')}
            >
              View List
            </button>
          </Col>
          <Col span={12} className="text-right padding-r">
            <span>{summaryObject?.selectionsCount?.outletsCount} </span>
          </Col>
        </Row>
      )}
      {/* <Row gutter={16}>
        <Col span={12}>
          <span>Region</span>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>--</span>
        </Col>
      </Row> */}

      <h5 className="text-italic mb-4 mt-2 promo-sub-sub-heading">
        Offer Information
      </h5>
      <Row gutter={16}>
        <Col span={12}>
          <span>Offer Level</span>
        </Col>
        <Col span={12}>
          <span>{summaryObject?.promoData?.buyType?.name || '--'}</span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Offer Type</span>
        </Col>
        <Col span={12}>
          <span>{summaryObject?.promoData?.offerType?.name || '--'}</span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Max Quantity or Amount </span>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>{summaryObject?.purchaseTo || '--'}</span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>
            Discount{' '}
            {summaryObject?.promoData?.discountType?.id == 6
              ? '(PKR) '
              : '(%) '}
            {summaryObject?.promoData?.discountType?.name &&
              '-' + summaryObject?.promoData?.discountType?.name}
          </span>
        </Col>
        <Col span={12} className="text-right padding-r">
          <span>{summaryObject?.discountValue}</span>
          {!summaryObject && '--'}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Apply on</span>
        </Col>
        <Col span={12}>
          <span>{summaryObject?.promoData?.applyOn?.name || '--'}</span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Apply To</span>
        </Col>
        <Col span={12}>
          <span>{summaryObject?.promoData?.applyTo?.name || '--'}</span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Apply UOM</span>
        </Col>
        <Col span={12}>
          <span>{summaryObject?.promoData?.applyUOM?.name}</span>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Allocated Budget </span>
        </Col>
        <Col span={12} className="text-right padding-r">
          {summaryObject?.budgetAllocated ? (
            <span>{summaryObject?.budgetAllocated} PKR</span>
          ) : (
            '--'
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <span>Budget threshold </span>
        </Col>
        <Col span={12} className="text-right padding-r">
          {summaryObject?.minimumBudget || summaryObject?.maximumBudget ? (
            <span>
              {summaryObject?.minimumBudget} PKR -{' '}
              {summaryObject?.maximumBudget} PKR
            </span>
          ) : (
            '--'
          )}
        </Col>
      </Row>
    </div>
  );
};
export default PromoSummary;
