import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AppPreLoadProps } from './types';
import { selectAuth } from '../Auth/ducks/selectors';
import { ReactElement, useEffect } from 'react';
import {
  getApplyTo,
  getOfferTypes,
  getPromoApplyOns,
  getPromoApplyUoms,
  getPromoBuyTypes,
  getPromoBuyUnitMeasures,
  getPromoCustomers,
  getPromoDiscountTypes,
  getPromoLevels,
  getPromoSchemes,
  getPromoStatus,
  getPromoTypes,
  setPreLoadState,
} from '../Promotion/ducks/slice';
import { getNotifications, getRecentSearch } from '../AppHeader/ducks/slice';

export default function AppPreLoad({
  children,
}: AppPreLoadProps): ReactElement {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const promotion = useAppSelector((state) => state.promotions);

  useEffect(() => {
    if (
      auth.fetchLoading === 'finished' &&
      promotion.preLoadState === 'empty'
    ) {
      dispatch(setPreLoadState('isFetching'));

      Promise.all([
        dispatch(getNotifications()),
        dispatch(getRecentSearch()),
        dispatch(getPromoSchemes()),
        dispatch(getPromoLevels()),
        dispatch(getPromoStatus()),
        dispatch(getPromoTypes()),
        dispatch(getOfferTypes()),
        dispatch(getApplyTo()),
        dispatch(getPromoBuyTypes()),
        dispatch(getPromoBuyUnitMeasures()),
        dispatch(getPromoApplyOns()),
        dispatch(getPromoApplyUoms()),
        dispatch(getPromoDiscountTypes()),
        dispatch(getPromoCustomers()),
      ])
        .then(() => {
          dispatch(setPreLoadState('settled'));
        })
        .catch(() => {
          dispatch(setPreLoadState('hasError'));
        });
    }
  }, [auth.fetchLoading, promotion.preLoadState, dispatch]);

  return <>{children}</>;
}
