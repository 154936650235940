import { Row, Col, Button, Select } from 'antd';
import {
  CaretDownFilled,
  FilterOutlined,
  DownCircleOutlined,
} from '@ant-design/icons';
import NavBreadCrumb from './NavBreadCrumb';
import { useAppDispatch } from 'store/hooks';
import { setViewBy } from './ducks/slice';

export default function FilterHeader() {
  const dispatch: any = useAppDispatch();
  const handleChange = (value: string) => {
    dispatch(setViewBy(value));
  };
  return (
    <Row gutter={16}>
      <Col span={24}>
        <div className="content-body ut-reporting">
          <Row align="middle">
            <Col span={8}>
              <NavBreadCrumb />
              <h1 className="ut-fs-l ut-mb-r ut-mt-s ut-color-two">
                Post Event Analysis Report
              </h1>
            </Col>
            <Col span={8}>
              <Row justify="center">
                <Col className="ut-calendar-btn">
                  <Button
                    className="ut-btn light-btn group opacity-50"
                    type="primary"
                    disabled
                  >
                    Year
                  </Button>
                  <Button
                    className="ut-btn light-btn group active"
                    type="primary"
                  >
                    Month
                  </Button>
                  <Button
                    className="ut-btn light-btn group opacity-50"
                    type="primary"
                    disabled
                  >
                    Week
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row justify="end" align="middle">
                <Col className="mr-5">
                  <Row justify="space-between" align="middle">
                    <Col>
                      <span className="ut-label ut-color-three ut-fs-s mr-3">
                        View By
                      </span>
                    </Col>
                    <Col>
                      <Select
                        defaultValue="totalCost"
                        className="ut-select"
                        suffixIcon={<CaretDownFilled />}
                        onChange={handleChange}
                        options={[
                          { value: 'totalCost', label: 'Cost' },
                          { value: 'totalVolume', label: 'Volume' },
                        ]}
                        style={{ width: 120 }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="ml-5">
                  <Button className="ut-btn circle-btn">
                    <span className="icon">
                      <FilterOutlined />
                    </span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={24} className="ut-mt-xl">
        <Row className="indicators" justify="end">
          <Col className="pointers one ut-mb-l">
            Actual (A) <span></span>
          </Col>
          <Col className="pointers two">
            Planned (P) <span></span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
