import { BASE_URL, httpVerbs } from 'configs';
import request from 'utils/axios';

export const getReportDataApi = async (): Promise<any> => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  const url = `${BASE_URL}/promotions/reporting?mode=monthly&year=${currentYear}`;
  const params: any = {
    url: url,
    method: httpVerbs.GET,
  };
  const response = await request(params);
  return response;
};
