import { Space, Tag, Popover } from 'antd';
import dayjs from 'dayjs';

export const getLevelWiseSelections = (selections: any) => {
  let result: any = {};
  for (let selection in selections) {
    let currentLevel = selections[selection].currentLevel;
    if (!result[currentLevel]) {
      result[currentLevel] = [];
    }
    result[currentLevel].push(selections[selection][currentLevel + '_id']);
  }
  let result2 = [];
  for (let elem in result) {
    result2.push({ level: elem, selections: result[elem] });
  }
  return result2;
};

export const removeChildrenKey = (obj: any) => {
  for (let key in obj) {
    if (key === 'children') {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeChildrenKey(obj[key]);
    }
  }
  return obj;
};
export const extractKeys = (data: any) => {
  let keys: any = [];

  const traverseData = (node: any) => {
    if (node.key) {
      keys.push(node.key);
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach((child: any) => traverseData(child));
    }
  };

  data.forEach((node: any) => traverseData(node));

  return keys;
};

export const formateSelectVal = (item: any, selectName: string) => {
  let payload = {};
  if (selectName === 'promoLevel') {
    payload = {
      value: item.level_code,
      label: item.level_name,
    };
  } else {
    payload = {
      value: item.id,
      label: item.name,
    };
  }
  return payload;
};

export const formateSchemeVal = (scheme: any) => {
  const payload = {
    value: scheme.id,
    label: scheme.name,
  };
  return payload;
};

/**
 * Calculates the duration of an activity based on the provided payload.
 *
 * @param payload - The payload containing activity date information.
 * @returns A string representing the activity duration in either days or weeks.
 */
export const calculateActivityDuration = (payload: any): string => {
  // Extract data from the payload
  const { weekStart, weekEnd, startDate: sDate, endDate: eDate } = payload;

  // Calculate the difference between weekEnd and weekStart
  const diff = Number(weekEnd) - Number(weekStart);

  if (diff === 0) {
    // If the difference is zero, calculate duration in days
    const startDate = dayjs(sDate);
    const endDate = dayjs(eDate);
    const numberOfDays = endDate.diff(startDate, 'day');
    return `${numberOfDays + 1 < 8 ? `${numberOfDays + 1} Days` : '1 Week'}`;
  } else {
    // Otherwise, calculate duration in weeks
    return `${diff + 1} Weeks`;
  }
};

/**
 * This function takes an array of products and a type (either 'category' or 'brand') and returns a formatted string
 * containing unique categories or brands from the products.
 *
 * @param {Array} products - An array of products, each having a 'selections' property with categories or brands.
 * @param {string} type - The type of selection to extract ('category' or 'brand').
 * @returns {tags} - A formatted tag containing unique categories or brands, or 'All' if all categories are selected.
 */

export const formateHierarchyBoxTagsDynamic = (
  products: any,
  level_name: string,
  isEdit: boolean,
  visibleTagsCont: number = 3,
) => {
  if (!products || products?.length === 0) return null;
  let categoriesOrBrands = [];
  let uniqueCategoriesOrBrands = [];

  if (
    (Object.keys(products)?.length > 0 && products[level_name] === 'all') ||
    products[0] === 'all'
  ) {
    return <span>All</span>;
  }

  if (isEdit) {
    categoriesOrBrands = products[level_name] || [];
    uniqueCategoriesOrBrands = [...new Set(categoriesOrBrands)];
  } else {
    uniqueCategoriesOrBrands = products[level_name];
  }

  // Display the first visibleTagsCont tags
  const visibleTags = uniqueCategoriesOrBrands?.slice(0, visibleTagsCont);

  const tags = visibleTags?.map((categoryOrBrand: string, index: number) => (
    <Tag key={index}>{categoryOrBrand}</Tag>
  ));

  if (uniqueCategoriesOrBrands?.length > visibleTagsCont) {
    // Create a content element for the popover with all tags
    const popoverContent = (
      <div>
        {uniqueCategoriesOrBrands?.map(
          (categoryOrBrand: string, index: number) => {
            if (index + 1 > visibleTagsCont) {
              return (
                <Tag key={index + visibleTagsCont - 1}>{categoryOrBrand}</Tag>
              );
            }
            return null;
          },
        )}
      </div>
    );

    // Create the popover component
    const popover = (
      <Popover
        content={popoverContent}
        // title="All Categories or Brands"
        trigger="hover"
      >
        <Tag key="show-more">
          {uniqueCategoriesOrBrands.length - visibleTagsCont} more
        </Tag>
      </Popover>
    );

    tags.push(popover);
  }

  return (
    <Space className="ut-tag-container" size={[0, 8]} wrap>
      {tags}
    </Space>
  );
};

function capitalizeFirstLetterOfEachWord(sentence: string) {
  // Split the sentence into an array of words
  const words = sentence.split(' ');

  // Iterate through each word
  const capitalizedWords = words.map((word: string) => {
    // Capitalize the first letter of each word and combine it with the rest of the word
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words back into a sentence
  return capitalizedWords.join(' ');
}

export const capitalizeFirstLetter = (word: string) => {
  let wordT = '';
  if (word && typeof word === 'string') {
    wordT = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  } else {
    wordT = word;
  }

  return capitalizeFirstLetterOfEachWord(wordT);
};

export const splitCamelCase = (input: string) => {
  return input.replace(/([a-z])([A-Z])/g, '$1 $2');
};
