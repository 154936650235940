import { Breadcrumb } from 'antd';

export default function NavBreadCrumb() {
  return (
    <Breadcrumb
      items={[
        {
          title: (
            <a href="" className="ut-fs-s ut-color-twelve">
              TPM
            </a>
          ),
        },
        {
          title: (
            <a href="" className="ut-fs-s ut-color-eleven">
              Reports
            </a>
          ),
        },
        {
          title: (
            <span className="ut-fs-s ut-color-five">Post Event Analysis</span>
          ),
        },
      ]}
    />
  );
}
