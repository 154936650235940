/**
 *
 * Auth
 *
 */
/* eslint-disable */
import React, { ReactElement, useEffect } from 'react';
import { Form, Checkbox, Col, Row, Input, Alert, notification } from 'antd';
import Button from 'app/atoms/Button';
import { useTranslation } from 'react-i18next';
import { selectAuth } from './ducks/selectors';
import { authActions, signIn } from './ducks/slice';
import { validateMessages, FORGOT_PASSWORD_PAGE } from './constants';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import LanguageSelection from 'app/molecules/Header/LanguageSelection';
import './style.less';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useHistory } from 'react-router-dom';
import Logo from '../../../assets/logo-new.png';
// import { useMixpanel } from 'react-mixpanel-browser';

const Login = (): ReactElement => {
  // const mixpanel = useMixpanel();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const auth = useAppSelector(selectAuth);
  const onFinish = async (values: LoginForm) => {
    const data = await dispatch(signIn(values));
    if (data.payload.data.access_token !== '') {
      // mixpanel?.track('Login Success');
      // mixpanel?.identify(data.payload.data.email);
      localStorage.setItem('remember', JSON.stringify(true));
      localStorage.removeItem('sessionexpired');
    } else {
      // mixpanel?.track('Login Failed');
    }
  };
  const onFinishFailed = (errorInfo: any) => errorInfo;
  const forgotPassword = () => history.push('/forgot-password');

  // useEffect(() => {
  //   mixpanel?.track('Login View');
  //   return () => {
  //     const data: any = localStorage.getItem('TOKEN_KEY_HERE')
  //     mixpanel?.track('Login Successful');
  //     mixpanel?.identify(data?.email);
  //   }
  // }, [auth]);

  useEffect(() => {
    console.log("inside use effect");
    setTimeout(() => {
      const sessionIsExpired = localStorage.getItem('sessionexpired');
      console.log('sessionIsExpired: ', sessionIsExpired);
      if (sessionIsExpired) {
        notification.error({
          message: 'Session expired, please login again to continue',
        });
        localStorage.removeItem('sessionexpired');
      }
    }, 1000);
  }, []);

  const renderForm = () => (
    <Form
      className="login-form ut-form"
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
    >
      <Form.Item
        label={t('form.email.label')}
        name="email"
        rules={[
          {
            required: true,
            message: t('form.email.required'),
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          type="text"
          placeholder="Enter Email Address"
        />
      </Form.Item>
      <Form.Item
        label={t('form.password.label')}
        name="password"
        rules={[
          {
            required: true,
            message: t('form.password.required'),
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Enter Password"
        />
      </Form.Item>
      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>{t('login.keep_signed')}</Checkbox>
      </Form.Item>
      <Form.Item>
        <Button
          btnclass="button ut-btn ut-mt-l"
          type="primary"
          loading={auth.loading}
          htmlType="submit"
          block
          value={t('submit')}
        />
      </Form.Item>
    </Form>
  );

  return (
    <div className="ut-login-container">
      <div className="main-container">
        <div className="ut-login-header">
          <div className="ut-language">
            <LanguageSelection />
          </div>
        </div>
        <div className="ut-login-body">
          <Row justify="center" align="middle">
            <Col sm={20} md={8}>
              <div className="ut-card radius login">
                <Col span={24}>
                  {auth.error && (
                    <Alert message={auth.error.message} type="error" />
                  )}
                </Col>
                <Col span={24}>
                  <Row justify="center" align="middle">
                    <Col md={24}>
                      <div style={{ width: 210, margin: '0 auto' }}>
                        <img src={Logo} className="logo" />
                      </div>
                    </Col>
                  </Row>
                  <Row justify="center" align="middle">
                    <Col md={18}>{renderForm()}</Col>
                  </Row>
                  <Row justify="center" align="middle">
                    <Col md={18}>
                      <Button
                        btnclass="custom-link-bt forgot-password"
                        type="link"
                        htmlType="button"
                        onClick={forgotPassword}
                        value={t('forgot_password')}
                      />
                    </Col>
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
        <div className="ut-login-footer">
          <Button
            btnclass="ut-btn-text"
            type="link"
            htmlType="button"
            // onClick={forgotPassword}
            value={t('Copyright')}
          />
          <Button
            btnclass="ut-btn-text"
            type="link"
            htmlType="button"
            // onClick={forgotPassword}
            value={t('privacy')}
          />
          <Button
            btnclass="ut-btn-text"
            type="link"
            htmlType="button"
            // onClick={forgotPassword}
            value={t('legal_disclosure')}
          />
        </div>
      </div>
    </div>
  );
};

interface LoginForm {
  email: string;
  password: string;
  remember: boolean;
}

export default Login;
