import {
  configureStore,
  ThunkAction,
  Action,
  StoreEnhancer,
} from '@reduxjs/toolkit';
// import thunkMiddleware from 'redux-thunk';
// We'll use redux-logger just as an example of adding another middleware
import logger from 'redux-logger';
// And use redux-batch as an example of adding enhancers
import { reduxBatch } from '@manaflair/redux-batch';
import { ENABLE_REDUX_LOGGER } from 'configs';
import { reducers } from './rootReducers';

const middleWares: any = [
  // thunkMiddleware
];

if (ENABLE_REDUX_LOGGER) {
  middleWares.push(logger);
}

const store = configureStore({
  reducer: reducers,
  enhancers: [reduxBatch as StoreEnhancer<{}, {}>],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...middleWares),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
