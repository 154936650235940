import { Col, Row } from 'antd';
import { IMonthlyReportData } from './ducks/type';
import { useAppSelector } from 'store/hooks';
import { calculateBudget, formatNumber } from 'utils/helper';

interface IProps {
  data: IMonthlyReportData[];
  name: string;
}

export default function RowCategorySummary({ data, name }: IProps) {
  const { reportingView } = useAppSelector((state: any) => state);
  const { viewByOption } = reportingView;
  return (
    <Col
      span={24}
      style={{
        background: 'rgba(5, 64, 150, 0.1)',
        padding: '16px 4px',
        color: '#000',
        fontSize: 12,
      }}
    >
      <Row align="middle" className="units-column">
        <Col span={3}>
          <Row>
            <Col className="ut-fs-s ut-color-six pl-3">{name}</Col>
          </Row>
        </Col>
        <Col span={21}>
          <Row className="total-counts">
            {data.map((item, index) => {
              let obj = calculateBudget(
                item.totalCostActual,
                item.totalCost,
                item.totalVolumeActual,
                item.totalVolume,
              );
              return (
                <Col key={item.month} className="ut-fs-s" span={2}>
                  <Row className="center">
                    <Col
                      span={24}
                      className={
                        'two mb-1' +
                        (obj.exceeded
                          ? ' ut-color-fourteen'
                          : ' ut-color-thirteen')
                      }
                    >
                      {obj.totalActualBudget}
                    </Col>
                    <Col span={24} className={'two'}>
                      {obj.totalPlannedBudget}
                    </Col>
                  </Row>
                </Col>
              );
            })}
            {/* <Col span={2}>
              <Row>
                <Col span={24} className="one ut-color-fourteen">
                  58,390,000
                </Col>
                <Col span={24} className="two">
                  427,564,556
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Row>
                <Col span={24} className="one">
                  58,390,000
                </Col>
                <Col span={24} className="two">
                  427,564,556
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Row>
                <Col span={24} className="one">
                  58,390,000
                </Col>
                <Col span={24} className="two">
                  427,564,556
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Row>
                <Col span={24} className="one ut-color-fourteen">
                  58,390,000
                </Col>
                <Col span={24} className="two">
                  427,564,556
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Row>
                <Col span={24} className="one">
                  58,390,000
                </Col>
                <Col span={24} className="two">
                  427,564,556
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Row>
                <Col span={24} className="one">
                  58,390,000
                </Col>
                <Col span={24} className="two">
                  427,564,556
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Row>
                <Col span={24} className="one ut-color-fourteen">
                  58,390,000
                </Col>
                <Col span={24} className="two">
                  427,564,556
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Row>
                <Col span={24} className="one">
                  58,390,000
                </Col>
                <Col span={24} className="two">
                  427,564,556
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Row>
                <Col span={24} className="one ut-color-fourteen">
                  58,390,000
                </Col>
                <Col span={24} className="two">
                  427,564,556
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Row>
                <Col span={24} className="one ut-color-fourteen">
                  58,390,000
                </Col>
                <Col span={24} className="two">
                  427,564,556
                </Col>
              </Row>
            </Col>
            <Col className="ut-fs-s center" span={2}>
              <Row>
                <Col span={24} className="one">
                  58,390,000
                </Col>
                <Col span={24} className="two">
                  427,564,556
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
