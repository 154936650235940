import { useMixpanel } from 'react-mixpanel-browser';
import { ReactElement, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Checkbox, Popover, DatePicker, Divider } from 'antd';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getCalendarView,
  getCalendarViewListFailed,
  getCalendarViewListInProgress,
  getCalendarViewListSuccess,
  selectEvent,
  deleteEvent,
} from './ducks/slice';
import * as calendarViewSource from './ducks/services';
import {
  Status,
  formateCalendarView,
  oneDayMinusFromStringDate,
} from './helper';
import { ICalendarView } from './ducks/types';
import { updateCalendarViewApi } from './ducks/services';
import { toastMessageActions } from 'app/molecules/ToastMessage/ducks/slice';
import { loadingActions } from 'app/molecules/Loader/ducks/slice';
import HeaderFilter from 'app/molecules/HeaderFilter';
import { getReportDataApi } from '../Reporting/ducks/service';
import { headerActions } from '../AppHeader/ducks/slice';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  PROMOTION_DRAG_ERROR_MSG,
  PROMOTION_SELECT_ERROR_MSG,
  ROLES_SLUG,
} from 'configs/constants';
import multiMonthPlugin from '@fullcalendar/multimonth';
import DeleteIcon from '../../../assets/delete.png';
import EditIcon from '../../../assets/edit.png';
import MenuIcon from '../../../assets/menu.png';
import CalIcon from '../../../assets/cal.png';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs, { Dayjs } from 'dayjs';
import PromoIcon from '../../../assets/promoType.png';
import LevelIcon from '../../../assets/level.png';
import PieIcon from '../../../assets/pie.png';
import ProfitIcon from '../../../assets/profit.png';
import GraphIcon from '../../../assets/graph.png';
import BoxIcon from '../../../assets/box.png';
import GlobeIcon from '../../../assets/globe.png';
import ChannelIcon from '../../../assets/channel.png';
import PinIcon from '../../../assets/pin.png';
import UpIcon from '../../../assets/up.png';
import DownIcon from '../../../assets/down.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ModalDialogSmall from 'app/atoms/ModalDialogSmall';

const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const CalendarView = (): ReactElement => {
  // const mixpanel = useMixpanel();
  const history = useHistory();
  const locationUse = useLocation();
  const dispatch: any = useAppDispatch();
  const {
    calendarView,
    auth: { user },
  } = useAppSelector((state: any) => state);
  // const calendarView = rState?.calendarView;
  const calendarViewData = calendarView?.calendarViewList;
  const { selectedEvent, calendarViewFilterList, calendarViewType } =
    calendarView;
  const [calendarEvents, setCalendarEvents] = useState<ICalendarView | any>([]);

  const [selectedEventId, setSelectedEventId] = useState<any>({
    selectEvent: '',
    selectedEventId: '',
  });
  const [showDraft, setShowDraft] = useState(true);
  const [showSubmitted, setShowSubmitted] = useState(true);
  const [showApproved, setShowApproved] = useState(true);
  const [showRejected, setShowRejected] = useState(true);
  const [showSendback, setShowSendback] = useState(true);
  const [selectedPromotion, setSelectedPromotion] = useState(true);
  const [isPopOverOpen, setPopOverOpen] = useState(false);
  // 'dayGridMonth,dayGridWeek',
  const [isModalDialogOpen, setIsModalDialogOpen] = useState(false);
  const [modalDialogType, setModalDialogType] = useState('');
  const handleModalDialogClose = () => {
    setIsModalDialogOpen(false);
  };

  useEffect(() => {
    const today = new Date();
    sessionStorage.removeItem('selectedEvent');
    dispatch(selectEvent({}));
    if (calendarEvents.length === 0)
      dispatch(
        getCalendarView({
          // Adding 1 because getMonth() returns zero-based index
          monthNumber: today.getMonth() + 1,
          year: today.getFullYear(),
        }),
      );
  }, []);

  useEffect(() => {
    if (
      user &&
      (locationUse.pathname === '/' || locationUse.pathname === '/calendar')
    ) {
      // mixpanel?.track('Calendar View');
      // mixpanel?.identify(user.email);
    }
  }, [user, locationUse]);

  // useEffect(() => {
  //   if (user) {
  //     mixpanel?.track('User login');
  //     mixpanel?.identify(user.email);
  //   }
  // }, [user]);
  useEffect(() => {
    if (calendarViewFilterList?.length > 0) {
      setCalendarEvents(calendarViewFilterList);
    } else {
      setCalendarEvents(calendarViewData);
    }
  }, [calendarViewData, calendarViewFilterList]);

  const handleCheckboxChange = (event: CheckboxChangeEvent) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;

    switch (checkboxName) {
      case 'Draft':
        setShowDraft(isChecked);
        break;
      case 'Submitted':
        setShowSubmitted(isChecked);
        break;
      case 'Approved':
        setShowApproved(isChecked);
        break;
      case 'Sendback':
        setShowSendback(isChecked);
        break;
      case 'Rejected':
        setShowRejected(isChecked);
        break;
      default:
        break;
    }
  };

  const filterEvents = () => {
    return calendarEvents?.filter((event: any) => {
      return (
        (showDraft && event.status === 'draft') ||
        (showSubmitted && event.status === 'submitted') ||
        (showApproved && event.status === 'approved') ||
        (showSendback && event.status === 'sentback') ||
        (showRejected && event.status === 'rejected')
      );
    });
  };

  //drag drop
  const handleEventChange = async (changeInfo: any) => {
    const {
      event: {
        startStr,
        endStr,
        extendedProps: { promoId },
      },
    } = changeInfo;
    let payload;
    if (endStr === '' || endStr === startStr) {
      payload = {
        calendarView: true,
        startDate: startStr,
        endDate: startStr,
      };
    } else {
      payload = {
        calendarView: true,
        startDate: startStr,
        endDate: oneDayMinusFromStringDate(endStr),
      };
    }
    try {
      dispatch(loadingActions.showLoader());
      const res = await updateCalendarViewApi(payload, promoId);
      if (res.data) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'success',
            content: 'Successfully updated',
          }),
        );
        dispatch(loadingActions.hideLoader());
      }
    } catch (error) {
      dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        dispatch(loadingActions.hideLoader());
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        dispatch(loadingActions.hideLoader());
      }
    }
  };

  const calendarRef = useRef<any>(null);

  const handleNextClick = async () => {
    calendarRef.current.getApi().next();
  };

  const handlePrevClick = () => {
    calendarRef.current.getApi().prev();
  };

  const handleDatesSet = async () => {
    // Check if the current view's range includes the current date
    // Call your API here
    dispatch(loadingActions.hideLoader());
    const today = new Date();

    const currentYear = calendarRef.current
      ?.getApi()
      .view.currentStart.getFullYear();
    const currentMonth = calendarRef.current
      ?.getApi()
      .view.currentStart.getMonth();

    // dispatch(loadingActions.showLoader());
    try {
      const calendarData = await calendarViewSource.getCalendarViewApi({
        //   Adding 1 because getMonth() returns zero-based index
        monthNumber: (currentMonth >= 0 ? currentMonth : today.getMonth()) + 1,
        year: currentYear ? currentYear : today.getFullYear(),
      });
      const { data } = formateCalendarView(calendarData);
      dispatch(getCalendarViewListSuccess({ data: data }));

      //to init rerender of whole calender
      setTimeout(() => {
        calendarRef.current?.getApi().render();
        dispatch(loadingActions.hideLoader());
      }, 50);

      setCalendarEvents(data);
    } catch (error) {
      // dispatch(loadingActions.hideLoader());
      const { response } = error;
      const { data } = response?.data;
      if (data.statusCode > 300) {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
        // handle error
        dispatch(getCalendarViewListFailed({ error: data.message }));
      } else {
        dispatch(
          toastMessageActions.showToastMessage({
            type: 'error',
            content: data?.message || 'Something went wrong!',
          }),
        );
      }
    }
  };

  const handleEditEvent = async (id: any) => {
    history.push(`/promotion/edit/${id}`);
    // mixpanel?.track('Promotion edit view');
    // mixpanel?.identify(user.email);
  };

  const handleDeleteEvent = async () => {
    dispatch(deleteEvent(selectedEventId.selectedEvent));
    handleModalDialogClose();
    // mixpanel?.track('Promotion deleted');
    // mixpanel?.identify(user.email);
  };

  const showModal = () => {
    setIsModalDialogOpen(true);
  };

  const handleModalDialogEv = (type: string) => {
    setModalDialogType(type);
    showModal();
  };

  const handleModalDialogSubmit = () => {
    switch (modalDialogType) {
      case 'Delete':
        handleDeleteEvent();
        break;
      default:
        break;
    }
  };

  const dateDiiference = (start: any, end: any) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Calculate the difference in days
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    // Calculate the difference in weeks and remaining days
    const weeksDifference = Math.floor(daysDifference / 7);
    const remainingDays = daysDifference % 7;
    let result;
    if (remainingDays > 0 && remainingDays === 1 && weeksDifference === 0) {
      result = `${remainingDays} day`;
    } else if (remainingDays > 1 && weeksDifference === 0) {
      result = `${remainingDays} days`;
    } else if (
      weeksDifference > 0 &&
      weeksDifference === 1 &&
      remainingDays === 0
    ) {
      result = `${weeksDifference} week`;
    } else if (weeksDifference > 1 && remainingDays === 0) {
      result = `${weeksDifference} weeks`;
    } else {
      result = `${weeksDifference} week${
        weeksDifference > 1 ? 's' : ''
      } (${remainingDays} day${remainingDays > 1 ? 's' : ''})`;
    }
    return result;
  };

  const oneDayPlusFromStringDate = (dateStr: string) => {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 1);
    return date?.toISOString()?.split('T')[0];
  };

  const Content = (contentProps: any) => {
    const { customProp } = contentProps;
    const data = calendarViewData.find(
      (item: any) => item.id === customProp.extendedProps?.promoId,
    );
    return (
      <div className="calendar-details-popover">
        <div className="head-top">
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}
          >
            <p>{customProp.extendedProps?.promoCode}</p>
            <div className={`status-bar ${data.status}`}>{data.status}</div>
          </div>
          {ROLES_SLUG[2] !== user?.roles[0]?.role?.slug &&
            (data.status === 'draft' || data.status === 'sentback' ? (
              <div className="icon-container">
                {data.status !== 'sentback' && (
                  <button
                    onClick={() => {
                      handleModalDialogEv('Delete');
                      setSelectedEventId({
                        selectedEvent: customProp.extendedProps?.promoId,
                        selectedEventId: customProp.extendedProps?.promoCode,
                      });
                    }}
                  >
                    <img src={DeleteIcon} />
                  </button>
                )}
                <button
                  onClick={() => {
                    handleEditEvent(customProp.extendedProps?.promoId);
                  }}
                >
                  <img src={EditIcon} />
                </button>
                {/* <button>
                <img src={MenuIcon} />
              </button> */}
              </div>
            ) : (
              <Link
                to={`/promotion/view/${customProp.extendedProps?.promoId}`}
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  textDecoration: 'underline',
                }}
              >
                View Details
              </Link>
            ))}
          <h4>{customProp?.title}</h4>
          <RangePicker
            className="disabled-date-picker"
            defaultValue={[
              dayjs(data.start, dateFormat),
              dayjs(oneDayPlusFromStringDate(data.end), dateFormat),
            ]}
            disabled
          />
        </div>
        <Divider />
        <div className="pop-over-containt">
          <ul>
            <li>
              <div className="right">
                <img src={CalIcon} />
                <p>Duration</p>
              </div>
              <div className="left">{dateDiiference(data.start, data.end)}</div>
            </li>
            <li>
              <div className="right">
                <img src={PromoIcon} />
                <p>Promo Type</p>
              </div>
              <div className="left">{data.promoType}</div>
            </li>
            <li>
              <div className="right">
                <img src={LevelIcon} />
                <p>Promo Level</p>
              </div>
              <div className="left">
                {data.promoLevel ? data.promoLevel : 'N/A'}
              </div>
            </li>
          </ul>
          <Divider />
          <ul>
            <li>
              <div className="right">
                <img src={PieIcon} />
                <p>Volume (Tons)</p>
              </div>
              <div className="left">
                {data.totalVolume ? data.totalVolume : 'N/A'}
              </div>
            </li>
            <li>
              <div className="right">
                <img src={ProfitIcon} />
                <p>Spend (PKR)</p>
              </div>
              <div className="left">
                {data.totalCost ? data.totalCost : 'N/A'}
              </div>
            </li>
            <li>
              <div className="right">
                <img src={ProfitIcon} />
                <p>Profit (PKR)</p>
              </div>
              <div className="left">
                {data.totalLift > 0 ? (
                  <img src={UpIcon} style={{ marginRight: 10 }} />
                ) : (
                  <img src={DownIcon} style={{ marginRight: 10 }} />
                )}

                {data.totalLift ? data.totalLift : 'N/A'}
              </div>
            </li>
            <li>
              <div className="right">
                <img src={GraphIcon} />
                <p>ROI (%)</p>
              </div>
              <div className="left">
                {data.totalROI > 0 ? (
                  <img src={UpIcon} style={{ marginRight: 10 }} />
                ) : (
                  <img src={DownIcon} style={{ marginRight: 10 }} />
                )}
                {data.totalROI ? data.totalROI : 'N/A'}
              </div>
            </li>
          </ul>
          {/* <Divider />
          <ul>
            <li>
              <div className="right">
                <img src={BoxIcon} />
                <p>Products (SKUs)</p>
              </div>
              <div className="left">
                {data.productsCount ? data.productsCount : 'N/A'}
              </div>
            </li>
            <li>
              <div className="right">
                <img src={GlobeIcon} />
                <p>Geo (Count)</p>
              </div>
              <div className="left">
                {data.geosCount ? data.geosCount : 'N/A'}
              </div>
            </li>
            <li>
              <div className="right">
                <img src={ChannelIcon} />
                <p>Channels (Count)</p>
              </div>
              <div className="left">
                {data.channelsCount ? data.channelsCount : 'N/A'}
              </div>
            </li>
            <li>
              <div className="right">
                <img src={PinIcon} />
                <p>Outlets (Count)</p>
              </div>
              <div className="left">
                {data.outletsCount ? data.outletsCount : 'N/A'}
              </div>
            </li>
          </ul> */}
        </div>
      </div>
    );
  };

  const handleOpenChange = () => {};

  const handleOpenPopoverChanges = (promoCode: any, range: any) => {
    // mixpanel?.track('Check Promotion', { promoCode, range });
    // mixpanel?.identify(user.email);
    // setPopOverOpen(!isPopOverOpen);
  };

  const CalendarEvent = (current: any) => {
    const { event } = current;

    const isSelected = selectedEvent === event.id;
    // Define the custom class based on the selected state
    const customClass = isSelected ? 'ut-selected-event' : '';

    // Return the event content with the custom class
    return (
      <>
        <Popover
          content={<Content customProp={event} />}
          trigger="click"
          placement="left"
          //open={true}
          zIndex={9999}
          overlayInnerStyle={{ position: 'relative', margin: 20 }}
          arrow={false}
          id="popover-customid"
          onOpenChange={() => {
            handleOpenPopoverChanges(
              event.extendedProps.promoCode,
              event._instance.range,
            );
          }}
        >
          <div className={`ut-event-content ${customClass}`} key={event.id}>
            <div
              className={`ut-promotype-box ut-bg-solid-${event.extendedProps.status}`}
            >
              {event.extendedProps.promoType}
            </div>
            <div className="ut-event-title">
              <span className="ml-2 ut-promocode">
                {event.extendedProps.promoCode}
              </span>
              <span className="mr-2 ut-username">{event.title} </span>
            </div>
            <span className="total-roi">{event.extendedProps.totalROI}</span>

            {/* <b>{event.timeText}</b> */}
          </div>
        </Popover>
      </>
    );
  };
  const handleEventClick = (info: any) => {
    const {
      event: {
        // startStr,
        // endStr,
        id,
        extendedProps: {
          // promoId,
          promoCode,
          status,
        },
      },
    } = info;
    setSelectedPromotion(id);
    // Check if the event is already selected
    const { role } = user?.roles[0];
    if (
      (status === 'draft' || status === 'sendback') &&
      ROLES_SLUG[0] === role?.slug
    ) {
      setSelectedEventId({ selectedEvent: id, selectedEventId: promoCode });
      const isSelected = selectedEventId?.selectedEvent === id;
      let storedEventIds = {};

      if (!isSelected) {
        storedEventIds = { selectedEvent: id, selectedEventId: promoCode };
      }
      // sessionStorage.setItem('selectedEvent', storedEventId);
      dispatch(selectEvent(storedEventIds));
      setSelectedEventId(storedEventIds);
    } else {
      dispatch(
        toastMessageActions.showToastMessage({
          type: 'error',
          content: PROMOTION_SELECT_ERROR_MSG,
        }),
      );
    }
  };

  //filter

  // const filter = useAppSelector((state) => state.appHeader.filter);
  useEffect(() => {
    getReportDataApi();
  }, []);

  const onFilter = () => {
    dispatch(headerActions.setHeaderFilterModal(false));
    //  use 'filter' here
    getReportDataApi()
      .then((res) => {
        if (res) {
          // setReportData(res.data);
        }
      })
      .catch((err) => {});
  };

  const handleMoreLinkClick = (arg: any) => {
    // arg.date contains the date associated with the "more" link
    // You can perform any custom logic here
  };

  const getFirstDayOfYear = () => {
    const today = new Date();
    return new Date(today.getFullYear(), 0, 1);
  };

  return (
    <>
      <div className="bg-white p-3 relative">
        <div className="toolbar-indicator-btn-group">
          <Checkbox
            name="Draft"
            onChange={handleCheckboxChange}
            checked={showDraft}
            style={{
              display:
                ROLES_SLUG[0] === user?.roles[0]?.role?.slug ? 'flex' : 'none',
            }}
          >
            Draft
          </Checkbox>
          <Checkbox
            name="Submitted"
            onChange={handleCheckboxChange}
            checked={showSubmitted}
          >
            Submitted
          </Checkbox>
          <Checkbox
            name="Approved"
            onChange={handleCheckboxChange}
            checked={showApproved}
          >
            Approved
          </Checkbox>
          <Checkbox
            name="Rejected"
            onChange={handleCheckboxChange}
            checked={showRejected}
          >
            Rejected
          </Checkbox>
          <Checkbox
            name="Sendback"
            onChange={handleCheckboxChange}
            checked={showSendback}
          >
            Sendback
          </Checkbox>
        </div>
        <div
          onMouseDown={(evt: any) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
        >
          <FullCalendar
            key={calendarViewType}
            plugins={[dayGridPlugin, interactionPlugin, multiMonthPlugin]}
            firstDay={1}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: '',
            }}
            weekNumbers={true}
            weekNumberFormat={{ week: 'numeric' }}
            initialView={calendarViewType}
            initialDate={
              calendarViewType == 'multiMonthYear'
                ? getFirstDayOfYear()
                : new Date()
            }
            multiMonthMaxColumns={1}
            // dateClick={handleDateClick}
            // eventAdd={handleDateClick}
            editable={true}
            dayMaxEventRows={4}
            eventOrder="totalROI"
            views={{
              week: {
                dayMaxEventRows: 4,
              },
              day: {
                dayMaxEventRows: 5,
              },
              multiMonthYear: {
                duration: { months: 3 },
              },
            }}
            // events={calendarEvents}
            events={filterEvents()}
            eventOverlap={true}
            eventContent={({ event }) => <CalendarEvent event={event} />}
            //eventClick={handleEventClick}
            eventChange={handleEventChange} // called for drag-n-drop/resize
            // eventRemove={this.handleEventRemove}
            eventAllow={(dropInfo, draggedEvent) => {
              // Your condition to prevent dragging here
              if (draggedEvent?.extendedProps?.status === Status.Draft) {
                return true; // Allow dragging
              }
              dispatch(
                toastMessageActions.showToastMessage({
                  type: 'error',
                  content: PROMOTION_DRAG_ERROR_MSG,
                }),
              );
              return false; // Prevent dragging
            }}
            customButtons={{
              prev: {
                text: 'Custom Prev',
                click: handlePrevClick,
              },
              next: {
                text: 'Custom Next',
                click: handleNextClick,
              },
            }}
            datesSet={handleDatesSet}
            ref={calendarRef}
          />
        </div>
        {/* <CreateProjectDrawer /> */}
        <HeaderFilter onFilter={onFilter} />
      </div>
      <ModalDialogSmall
        isOpen={isModalDialogOpen}
        handleModalCancel={handleModalDialogClose}
        handleModalSubmit={handleModalDialogSubmit}
        modalDialogType={modalDialogType}
        promoId={selectedEventId.selectedEventId}
      />
    </>
  );
};

export default CalendarView;
