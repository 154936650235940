import {
  INVALID_EMAIL_MESSAGE,
  REQUIRED_FEILD,
  STRONG_PASSWORD_MESSAGE,
} from 'configs/constants';

interface UserFieldsError {
  email: string;
  password: string;
}
interface IValidateMessages {
  required: string;
  types: UserFieldsError;
}

export const validateMessages: IValidateMessages = {
  required: REQUIRED_FEILD,
  types: {
    email: INVALID_EMAIL_MESSAGE,
    password: STRONG_PASSWORD_MESSAGE,
  },
};
